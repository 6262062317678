import React, { useRef, useState } from "react";
import { Typeahead } from "react-bootstrap-typeahead";
import "react-bootstrap-typeahead/css/Typeahead.css";

const SelectDropdown = ({ ...props }) => {
  const [focused, setFocused] = useState(false);
  const [searchValue, setSearchValue] = useState("");
  const typeaheadRef = useRef<null>(null);

  return (
    // 'is-disabled' next to 'has-typehead'
    <div
      className={`has-typehead ${props.isDisabled ? "is-disabled" : ""} ${focused || (props.selected && props.selected.length) ? "is-focused" : ""
        } ${searchValue && searchValue.length === 0 ? "empty" : ""} ${props.error ? "has-error" : ""
        }`}
    >
      <Typeahead
        id="basic-example"
        ref={typeaheadRef}
        onChange={(e: any) => {
          if (props.onChange) {
            // @ts-ignore
            props.onChange(e);
          }
          if (searchValue === "") setSearchValue(e[0]);
        }}
        labelKey={props.labelKey}
        onFocus={(e: any) => {
          return focused === e ? "" : setFocused(e);
        }}
        onBlur={(e: any) => {
          if(focused === e) {
            setFocused(e)
          }
          // if searched value not found, clear input box
          if(props.selected && props.selected.length === 0) {
            if(typeaheadRef.current)      {
              // @ts-ignore
              typeaheadRef.current.clear()
            }
          } else {
            if(typeaheadRef.current){
              // @ts-ignore
              typeaheadRef.current.hideMenu()
            }
          }
        }}
        onInputChange={(e: any) => {
          if (e === "" && props.handleClear) {
            props.handleClear(props.label)
          }
          setSearchValue(e)
        }}
        filterBy={(option:any, props:any) => {
          if (props.selected.length) {
            // Display all the options if there's a selection.
            return true;
          }

          // Otherwise filter on some criteria.
          if(props.labelKey){
            return option[props.labelKey].toLowerCase().indexOf(props.text.toLowerCase()) !== -1;
          } else {
            return option.toLowerCase().indexOf(props.text.toLowerCase()) !== -1;
          }
        }}
        options={props.options}
        multiple={props.multiple || false}
        placeholder="Choose a option..."
        selected={props.selected}
        clearButton={true}
        disabled={props.disabled || false}
      />
      <label className="text-label">{props.label}</label>
      {props.selected && props.selected.length === 0 ? (
        <i className="has-icon icon-dropdown"></i>
      ) : (
        ""
      )}
    </div>
  );
};

export default SelectDropdown;
