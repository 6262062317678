export const defaultHeaders = [{
    name: 'Service Contract No',
    id: 1,
    isChecked: true,
    key: 'serviceContractDisplayNumber',
    isSearchable: true
}, {
    name: 'Created By',
    id: 2,
    isChecked: true,
    key: 'createdByUser'
}, {
    name: 'Contract Status',
    id: 3,
    isChecked: true,
    key: 'status'
}, {
    name: 'Project',
    id: 4,
    isChecked: true,
    key: 'projectName'
}, {
    name: 'Contract Date',
    id: 5,
    isChecked: true,
    key: 'contractDate'
}, {
    name: 'Duration',
    id: 6,
    isChecked: true,
    key: 'duration'
}, {
    name: 'Value',
    id: 7,
    isChecked: true,
    key: 'contractValue'
}, {
    name: 'Pay Item Id',
    id: 8,
    isChecked: true,
    key: 'payItem'
}, {
    name: 'Description',
    id: 9,
    isChecked: true,
    key: 'note'
}, {
    name: 'Fields',
    id: 10,
    isChecked: false,
    key: 'ticketFields'
}, {
    name: 'Activity',
    id: 11,
    isChecked: true,
    key: 'activity'
}, {
    name: 'UOM',
    id: 12,
    isChecked: true,
    key: 'UOM'
}, {
    name: 'Amount',
    id: 13,
    isChecked: true,
    key: 'unitCost'
}, {
    name: 'Service Ticket No',
    id: 14,
    isChecked: true,
    key: 'serviceTicketDisplayNumber',
    isSearchable: true
}, {
    name: 'Work Order',
    id: 15,
    isChecked: true,
    key: 'workOrderNumber'
}, {
    name: 'Pole Number',
    id: 16,
    isChecked: true,
    key: 'poleNumber'
}, {
    name: 'Purchase Order',
    id: 17,
    isChecked: true,
    key: 'poNumber'
}, {
    name: 'Town',
    id: 18,
    isChecked: true,
    key: 'city'
}, {
    name: 'State',
    id: 19,
    isChecked: true,
    key: 'state'
}, {
    name: 'Address/Cross Street',
    id: 20,
    isChecked: true,
    key: 'addressCrossStreet'
}, {
    name: 'Work date',
    id: 21,
    isChecked: true,
    key: 'timeCardDate'
}, {
    name: 'Today Payroll hours',
    id: 22,
    isChecked: true,
    key: 'totalPayrollHours'
}, {
    name: 'Actual Target Hours',
    id: 23,
    isChecked: true,
    key: 'actualTargetHours'
}, {
    name: 'Actual Qtys',
    id: 24,
    isChecked: true,
    key: 'serviceQty'
}, {
    name: 'Estimated Quantity',
    id: 25,
    isChecked: true,
    key: 'estimatedQuantity'
}, {
    name: 'Percent Complete',
    id: 26,
    isChecked: true,
    key: 'percentComplete'
}, {
    name: 'Marked Out of Scope',
    id: 27,
    isChecked: true,
    key: 'isOutOfScope'
}, {
    name: 'Notes',
    id: 28,
    isChecked: true,
    key: 'stNotes'
}, {
    name: 'Contract Start Date',
    id: 29,
    isChecked: true,
    key: 'serviceContractStartDate'
}, {
    name: 'Contract End Date',
    id: 30,
    isChecked: true,
    key: 'serviceContractEndDate'
}, {
    name: 'Ticket Status',
    id: 31,
    isChecked: true,
    key: 'ticketStatus'
}];