import React from 'react';
import LandingCards from './LandingCards/LandingCards';
import { withRouter, RouteComponentProps } from "react-router-dom";

const Landing: React.FC<RouteComponentProps> = (props) => {
    return (
        <section className="landing-wrapper">
            <LandingCards history={props.history}/>
        </section>
    )
}

export default withRouter(Landing);