/* eslint-disable array-callback-return */
import { useEffect, useState } from "react";
// @ts-ignore
import MultiSelect from "../../shared/MultiSelect";

const FilterTimecard = (props: any) => {
  // STATE DECLARATION
  const { filterDropData, handleFilterSubmit, toggleFilter } = props;
  const [filteredDropdown, setFilteredDropdown] = useState({
    projects: [],
    serviceTickets: [],
    costCodes: [],
    employees: [],
    employeeTags: [],
    hourTags: []
  } as any);

  const [filtersToShow, setFiltersToShow] = useState({
    projects: [],
    serviceTickets: [],
    costCodes: []
  } as any)
  const [projectSelected, setProjectSelected] = useState([])
  const [serviceTickets, setServiceTickets] = useState([])
  const [costCodes, setCostCodes] = useState([])
  const [employees, setEmployees] = useState([])
  const [employeeTags, setEmployeeTags] = useState([])
  const [hourTags, setHourTags] = useState([])
  const handleResetFilter = () => {
    setProjectSelected([]);
    setServiceTickets([])
    setCostCodes([])
    setEmployees([])
    setEmployeeTags([])
    setHourTags([])
  }


  // HANDLER/HELPER METHODS
  const handleSubmit = () => {
    handleFilterSubmit({
      projectIds: projectSelected,
      costCodeIds: costCodes,
      serviceTicketIds: serviceTickets,
      employeeIds: employees,
      employeeTagIds: employeeTags,
      hourTagIds: hourTags
    })
  }

  // REACT HOOKS
  useEffect(() => {
    let filteredServiceTickets: { projectId?: any; }[] = []
    if(filterDropData.serviceTickets){
      projectSelected.forEach((selectedProjectId) => {
        filterDropData.serviceTickets.forEach((eachServiceTicket: { projectId?: any; }) => {
          if(eachServiceTicket.projectId === selectedProjectId){
            filteredServiceTickets.push(eachServiceTicket)
          }
        })
      })
    }
    let filteredCostCodes: { projectId: any; }[] = []
    if(filterDropData.costCodes){
      projectSelected.forEach((selectedProjectId) => {
        filterDropData.costCodes.forEach((eachCostCode: { projectId: any; }) => {
          if(eachCostCode.projectId === selectedProjectId){
            filteredCostCodes.push(eachCostCode)
          }
        })
      })
    }
    setFilteredDropdown({
      ...filteredDropdown,
      costCodes: filteredCostCodes,
      serviceTickets: filteredServiceTickets
    })
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [projectSelected])

  useEffect(() => {
    if (filteredDropdown.projects) {
      let filterParentObj = {...filteredDropdown}
      Object.keys(filteredDropdown).map((each: any) => {
        switch (each) {
          case 'projects':
            filterParentObj.projects = filteredDropdown[each].map((eachItem: any) => ({ ...eachItem, label: eachItem.projectLabel, value: eachItem.id }))
            break;

          case 'costCodes':
            filterParentObj.costCodes = filteredDropdown[each].map((eachItem: any) => ({ ...eachItem, label: eachItem.costCodeLabel.split("- ")[1].trim(), value: eachItem.id }))
            break;

          case 'serviceTickets':
            filterParentObj.serviceTickets = filteredDropdown[each].map((eachItem: any) => ({ ...eachItem, label: `${eachItem.id} - ${eachItem.projectLabel.split(' - ')[1]}`, value: eachItem.id })).sort((a: { id: number; }, b: { id: number; }) => (a.id - b.id))
            break;

          case 'employees':
            filterParentObj.employees = filteredDropdown[each].map((eachItem: any) => ({ ...eachItem, label: eachItem.fullName, value: eachItem.id }))
            break;
            
          case 'employeeTags':
            filterParentObj.employeeTags = filteredDropdown[each].map((eachItem: any) => ({ ...eachItem, label: eachItem.timeCardTagCode, value: eachItem.id }))
            break;

          case 'hourTags':
            filterParentObj.hourTags = filteredDropdown[each].map((eachItem: any) => ({ ...eachItem, label: eachItem.timeCardTagCode, value: eachItem.id }))
            break;
        }
        setFiltersToShow({ ...filterParentObj })
        return true
      })
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [filteredDropdown])

  useEffect(() => {
    if(filterDropData){
      setFilteredDropdown({
        ...filteredDropdown,
        projects: filterDropData.projects,
        hourTags: filterDropData.hourTags,
        employees: filterDropData.employees,
        employeeTags: filterDropData.employeeTags,
      })
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [filterDropData])



  return (
    <form className="timecard-filters">
      <h3 className="filter-heading">
        Filter
        {/* @ts-ignore */}
        <a className="close-filter" href={() => ""} onClick={toggleFilter}>
          {" "}
        </a>
      </h3>
      <div className="filter-content">
        <div className="row">
          <div className="col-6">
            <div className="form-group">
              <MultiSelect
                items={filtersToShow?.projects}
                selectedValues={[]}
                noOptionsText="No Options"
                disabledInput={false}
                label="Projects"
                placeholder="Search Projects"
                selectAllLabel="Select all"
                onChange={(e: any) => {
                  setProjectSelected(e.map((x: any) => x.value));
                }}
              />
            </div>
          </div>
          <div className="col-6">
            <div className="form-group">
              <MultiSelect
                items={projectSelected.length > 0 ? filtersToShow?.serviceTickets : []}
                noOptionsText="No Options"
                disabledInput={projectSelected.length === 0}
                selectedValues={[]}
                label="Service Tickets"
                placeholder="Search Service Tickets"
                selectAllLabel="Select all"
                onChange={(e: any) => {
                  setServiceTickets(e.map((x: any) => x.value));
                }}
              />
            </div>
          </div>
          <div className="col-6">
            <div className="form-group">
              <MultiSelect
                items={projectSelected.length > 0 ? filtersToShow?.costCodes : []}
                noOptionsText="No Options"
                disabledInput={projectSelected.length === 0}
                selectedValues={[]}
                label="Cost Code"
                placeholder="Search Cost Codes"
                selectAllLabel="Select all"
                onChange={(e: any) => {
                  setCostCodes(e.map((x: any) => x.value));
                }}
              />
            </div>
          </div>
          <div className="col-6">
            <div className="form-group">
              <MultiSelect
                items={projectSelected.length > 0 ? filtersToShow?.employees : []}
                noOptionsText="No Options"
                disabledInput={projectSelected.length === 0}
                selectedValues={[]}
                label="Employees"
                placeholder="Search Employees"
                selectAllLabel="Select all"
                onChange={(e: any) => {
                  setEmployees(e.map((x: any) => x.value));
                }}
              />
            </div>
          </div>
          <div className="col-6">
            <div className="form-group">
              <MultiSelect
                items={projectSelected.length > 0 ? filtersToShow?.employeeTags : []}
                noOptionsText="No Options"
                disabledInput={projectSelected.length === 0}
                selectedValues={[]}
                label="Employee Tags"
                placeholder="Search Employee Tags"
                selectAllLabel="Select all"
                onChange={(e: any) => {
                  setEmployeeTags(e.map((x: any) => x.value));
                }}
              />
            </div>
          </div>
          <div className="col-6">
            <div className="form-group">
              <MultiSelect
                items={projectSelected.length > 0 ? filtersToShow?.hourTags : []}
                noOptionsText="No Options"
                disabledInput={projectSelected.length === 0}
                selectedValues={[]}
                label="Hour Tags"
                placeholder="Search Hour Tags"
                selectAllLabel="Select all"
                onChange={(e: any) => {
                  setHourTags(e.map((x: any) => x.value));
                }}
              />
            </div>
          </div>
        </div>
      </div>
      <div className="filter-footer">
        {/* eslint-disable-next-line */}
        <a className="filters-reset" onClick={handleResetFilter}>
          Reset Filters
        </a>
        <button type="button" className="btn btn-primary" onClick={handleSubmit}>
          Apply
        </button>
      </div>
    </form>
  );
};

export default FilterTimecard;
