import React from "react";
import { Route, Redirect } from "react-router-dom";
import { useServiceStore } from "../store";
import { useAuthStore } from "../store";
import { accessNestedProperty } from "./shared/Helpers";
export const PrivateRoute = ({
  component: Component,
  path,
  hasAccess,
  accessModule,
  ...rest
}: {
  component: any;
  hasAccess: string;
  path: string;
  accessModule: any;
}) => {
  const { token } = useAuthStore.getState();

  const { userAccessList } = useServiceStore.getState();
  const getAccessDetails = (path: any, obj: any, separator = '.') => {
    var properties = Array.isArray(path) ? path : path.split(separator);
    return properties.reduce((prev: any, curr: any) => prev && prev[curr], obj);
  }
  return (
    <Route
      path={path}
      {...rest}
      render={(props) => {
        // check if route is restricted by role
        if (!token || !accessNestedProperty(hasAccess, userAccessList)) {
          // role not authorised so redirect to home page
          return <Redirect to={{ pathname: "/" }} />;
        }

        // authorised so return component
        return <Component {...props} access={getAccessDetails(accessModule, userAccessList)} />;
      }}
    />
  );
};
