import React, { useState, useEffect, useCallback } from "react";
import SelectDropdown from "../../../shared/SelectDropdown/SelectDropdown";
import DateSelector from "../../../shared/DateSelector/DateSelector";
import { get, post, put } from "../../../../types/api";
import {
  useServiceStore,
  useAuthStore,
  useServerConstants,
  useServiceContractStore,
  useTimeCardStore,
} from "../../../../store";
import Loader from "../../../shared/Loader/Loader";
import { useHistory } from "react-router-dom";
import "react-dropzone-uploader/dist/styles.css";
import Dropzone from "react-dropzone-uploader";
import axios from "axios";
import Joi from "joi";
import { TouchBackend } from "react-dnd-touch-backend";
import { HTML5Backend } from "react-dnd-html5-backend";
import { isMobile } from "react-device-detect";
import { DndProvider } from "react-dnd";
import { ServiceContractCreateAssignedFields } from "./ServiceContractCreateAssignedFields";
import { ServiceContractCreateAvailableFields } from "./ServiceContractCreateAvailableFields";
import { ItemTypes, StatusType } from "../../../shared/Constants";
import { addCommas, removeExtraField, removeNonNumeric } from "../../../shared/Helpers";
import { ReactComponent as ImgAccordian } from "../../../../assets/images/back.svg";
import { toast, ToastContainer } from "react-toastify";
import 'react-toastify/dist/ReactToastify.css';
import shallow from "zustand/shallow";
import { ServiceContractCreateTickedFields } from "./ServiceContractCreateTickedFields";
import { ServiceContractCreateTicketFields } from "./ServiceContractCreateTicketFields";

interface IErrorFields {
  startDate: boolean;
  endDate: boolean;
  assignedFields: boolean;
  projectId: boolean;
}

const NoDropzoneLayout = ({ previews, submitButton, input, files, dropzoneProps } : any) => {
  const { ref, className, style } = dropzoneProps
  return (
    <div ref={ref} className={className} style={style}>
      {previews}

      {input}

      {files.length > 0 && submitButton}
    </div>
  )
}

const ServiceContractCreate = ({ ...props }) => {
  const [serviceContractValues, setServiceContractValues] = useState({
    startDate: null,
    endDate: null,
    assignedFields: [] as any,
    assignedFields_Custom_ServiceTicketItem: [] as any,
    assignedFields_Custom_ServiceTicket: [] as any,
    assignedFields_Native_ServiceTicket: [] as any,
    contractDate: null,
    id: 0,
    projectLocationId: 0,
    status: 0,
    projectId: "" as any,
    note: "",
    contractValue: "" as any,
    filePath: [] as any,
    externalContactName: "" as any,
    externalContactEmail: "" as any,
    externalContactPhone: "" as any,
    externalContactTitle: "" as any,
    externalContactCompany: "" as any,
    internalContactName: "" as any,
    internalContactEmail: "" as any,
    internalContactPhone: "" as any,
    internalContactTitle: "" as any,
  });
  const dateRange = [null as any, null as any];
  const [newField, setNewField] = useState("");
  const [newFieldOne, setNewFieldOne] = useState("");

  const constants = useServerConstants(
    (serverConstants) => serverConstants.constants
  );
  const { selectedProject, setServiceValue } = useServiceContractStore(
    (serviceContractStore) => serviceContractStore
  );
  const [selectedJob, setSelectedJob] = useState([] as any);
  const [selectedStatus, setSelectedStatus] = useState([] as any);
  const [projectLocation, setProjectLocation] = useState([] as any);
  const history = useHistory();
  const [loading, setLoading] = useState(false);
  const editContract = props.editContract || false;
  const [contractDropdownOptions, setContractDropdownOptions] = useState({
    projects: [],
    worklocation: [],
    availableFields: [] as any,
    jobNumbers: [],
    availableFields_Native_ServiceTicket: [] as any,
    availableFields_Custom_ServiceTicket: [] as any
  });

  const currentPageContract = 0;
  const pageLimitContract = 25;

  const [startDate, endDate] = dateRange;
  const { companyId } = useServiceStore.getState();
  const [fieldError, setFieldError] = useState<IErrorFields>({} as any);
  const { projectsAndSupervisors } = useTimeCardStore(
    (state) => ({ projectsAndSupervisors: state.projectsAndSupervisors }),
    shallow
  );
  const tokenDetails = useServiceStore(
    (serviceStore) => serviceStore.tokenDetails
  );

  const newServiceTicketFieldsSchema = Joi.object({
    projectId: Joi.number().required(),
    startDate: Joi.date().required(),
    endDate: Joi.date().required(),
  }).unknown();

  const checkForValidations = () => {
    const { error } = newServiceTicketFieldsSchema.validate(
      serviceContractValues,
      { abortEarly: false }
    );
    let errorFields: any = {};
    error?.details.forEach((eachErrorDetail) => {
      errorFields[eachErrorDetail.path[0]] = true;
    });
    setFieldError(errorFields);
    return error;
  };

  const checkForValidationsOnChange = useCallback((obj: any, key: string) => {
    const { error } = newServiceTicketFieldsSchema.validate(
      { [key]: obj },
      { abortEarly: false }
    );
    const err = error?.details.filter((el) => el.path[0] === key).length === 0;

    if (!err) {
      setFieldError({ ...fieldError, [key]: true });
    } else {
      setFieldError({ ...fieldError, [key]: false });
    }
  }, [fieldError, newServiceTicketFieldsSchema]);

  const handleContractSubmit = (e: any) => {
    e.preventDefault();
    const error = checkForValidations();
    if (!error) {
      createContract();
    }
  };
  const [persionCollapse, setPersionCollapse] = useState(false);
  const [persionCollapseOne, setPersionCollapseOne] = useState(false);
  const [persionCollapseTwo, setPersionCollapseTwo] = useState(false);

  const [ticketStatus, setTicketStatus] = useState([] as any);
  const [editAmtErrorCode, setEditAmtErrorCode] = useState(false);
  const toggle = () => {
    if (props.modal) {
      setFieldError({} as any);
    }
    props.toggle();
  };

  // setting ticket status dropdown values using global state server constants
  useEffect(() => {
    if (constants.serviceContract?.CT.status) {
      const statusList = Object.entries(constants.serviceContract?.CT.status).map(
          ([name, id]: [string, number]) => ({ id, name, displayName : StatusType[name] || name })
        );
      setTicketStatus(statusList);
      if(!props.editContract) {
        setDefaultStatus(statusList);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [constants]);

  // @ts-ignore
  useEffect(() => {
    async function fetchData() {
      setLoading(true);
      await getCreateContract();
      await getContracts();
      setLoading(false);
    }
    if (!editContract) {
      fetchData();
    }
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  // @ts-ignore
  useEffect(() => {
    if (props.editContract) {
      getServiceContract(props.contractValue);
    }
  }, [props.editContract]); // eslint-disable-line react-hooks/exhaustive-deps

  const setDefaultStatus = (statusList : any) => {
      //set default status for create contract
      const status = statusList.find((e: any) => e.displayName === StatusType['DRAFT'])
      setSelectedStatus([status]);
      setServiceContractValues({
        ...serviceContractValues,
        // @ts-ignore
        status: status ? status.id : "",
      });
  }

  const getCreateContract = async (id?: Number) => {
    let apiURL = `/v1/api/service/contract/loadcreatecontract`;
    if (companyId) {
      apiURL += `?companyId=${companyId}`;
      if (id || serviceContractValues.projectId) {
        apiURL += `&projectId=${id || serviceContractValues.projectId}`;
      }
    }
    // @ts-ignore
    const res = await get(apiURL);
    setContractDropdownOptions({
      ...contractDropdownOptions,
      worklocation: res.data.worklocation,
      availableFields: res.data.availableFields,
      availableFields_Native_ServiceTicket: res.data.availableFields_Native_ServiceTicket,
      availableFields_Custom_ServiceTicket: res.data.availableFields_Custom_ServiceTicket
    });
    return res.data.worklocation;
  };

  const createField = async () => {
    const data = {
      id: 0,
      tenantId: tokenDetails?.tenantId,
      refObjectType: "",
      refObjectId: serviceContractValues.projectId,
      fieldName: newField,
      target: "SC_STI_HEADER"
    };
    let apiURL = `/v1/api/service/contract/fieldglobals/project`;
    // @ts-ignore
    const res = await post(apiURL, data);
    if (res.data?.id) {
      setContractDropdownOptions({
        ...contractDropdownOptions,
        availableFields: [
          ...contractDropdownOptions.availableFields,
          {
            id: res.data?.id,
            name: res.data?.fieldName,
          },
        ],
      });
    }
  };

  const createFieldTicket = async () => {
    const data = {
      id: 0,
      tenantId: tokenDetails?.tenantId,
      refObjectType: "",
      refObjectId: serviceContractValues.projectId,
      fieldName: newFieldOne,
      target: 'ST_HEADER'
    };
    let apiURL = `/v1/api/service/contract/fieldglobals/project`;
    // @ts-ignore
    const res = await post(apiURL, data);
    if (res.data?.id) {
      setContractDropdownOptions({
        ...contractDropdownOptions,
        availableFields_Custom_ServiceTicket: [
          ...contractDropdownOptions.availableFields_Custom_ServiceTicket,
          {
            id: res.data?.id,
            name: res.data?.fieldName,
          },
        ],
      });
    }
  };

  const getContracts = async () => {
    let apiURL = `/v1/api/service/contract/grouplisting`;
    if (companyId) {
      apiURL += `?companyId=${companyId}`;
    }
    const payload = {
      orgEntityId: companyId,
      projectIds: selectedProject,
      createdByUserIds: [],
      fromStartDate: "",
      toEndDate: "",
      fromContractDate: startDate || "",
      toContractDate: endDate || "",
      page: currentPageContract,
      limit: pageLimitContract,
    };
    // @ts-ignore
    const res = await post(apiURL, payload);

    setContractDropdownOptions({
      ...contractDropdownOptions,
      projects: res.data.filters.projects,
    });
    setServiceValue(res.data);
    return res.data.filters.projects;
  };

  //API to get particular service contract details
  const getServiceContract = async (serviceItem: any) => {
    setLoading(true);
    // get projects list
    await getContracts();
    const worklocationRes = await getCreateContract(serviceItem.projectId);
    let apiURL = `/v1/api/service/contract/id/${serviceItem.id}`;
    if (companyId) {
      apiURL += `?companyId=${companyId}`;
      if (serviceItem.projectId) {
        apiURL += `&projectId=${serviceItem.projectId}`;
      }
    }
    if (serviceItem.workLocationId) {
      serviceItem["projectLocationId"] = serviceItem.workLocationId || 0;
    }
    // @ts-ignore
    const res = await get(apiURL);
    setServiceContractValues({
      ...serviceContractValues,
      id: serviceItem.id,
      projectLocationId: serviceItem.projectLocationId || 0,
      status: serviceItem.status,
      assignedFields: res.data.assignedFields || [],
      assignedFields_Custom_ServiceTicketItem: res.data.assignedFields_Custom_ServiceTicketItem || [],
      assignedFields_Native_ServiceTicket: res.data.assignedFields_Native_ServiceTicket || [],
      assignedFields_Custom_ServiceTicket: res.data.assignedFields_Custom_ServiceTicket || [],
      filePath: res.data.files || [],
      externalContactName: res.data.externalContactName,
      externalContactEmail: res.data.externalContactEmail,
      externalContactPhone: res.data.externalContactPhone,
      externalContactTitle: res.data.externalContactTitle,
      externalContactCompany: res.data.externalContactCompany,
      internalContactName: res.data.internalContactName,
      internalContactEmail: res.data.internalContactEmail,
      internalContactPhone: res.data.internalContactPhone,
      internalContactTitle: res.data.internalContactTitle,
      projectId: serviceItem.projectId,
      note: serviceItem.note!,
      contractValue: addCommas(removeNonNumeric(serviceItem.contractValue!)) || '',
      // @ts-ignore
      endDate: new Date(serviceItem.endDate!),
      // @ts-ignore
      startDate: new Date(serviceItem.startDate),
      // @ts-ignore
      contractDate: new Date(
        serviceItem.contractDate || serviceItem.effectiveDate
      ),
    });
    setContractDropdownOptions({
      ...contractDropdownOptions,
      availableFields: res.data.availableFields || [],
      availableFields_Native_ServiceTicket: res.data.availableFields_Native_ServiceTicket || [],
      availableFields_Custom_ServiceTicket: res.data.availableFields_Custom_ServiceTicket || []
    });
    if (serviceItem.projectId) {
      const res = projectsAndSupervisors.filteredProjects.find((e: any) => e.id === serviceItem.projectId)
      setSelectedJob(res ? [res] : []);
    } else {
      setSelectedJob([]);
    }

    if (serviceItem.projectLocationId) {
      const res = worklocationRes.find(
        (e: any) => e.id === serviceItem.projectLocationId
      );
      setProjectLocation(res ? [res] : []);
    } else {
      setProjectLocation([]);
    }

    // set status field options and values
    if (constants.serviceContract?.CT.status) {
      const options = Object.entries(constants.serviceContract?.CT.status).map(
        ([name, id]: [string, number]) => ({ id, name, displayName : StatusType[name] || name })
      );
      if (serviceItem.status !== "" || serviceItem.status !== null) {
        const res = options.find(
          (e: { id: any }) => e.id === serviceItem.status
        );
        setSelectedStatus(res ? [res] : []);
      }
    } else {
      setSelectedStatus([]);
    }
    setLoading(false);
  };

  const createContract = async () => {
    setLoading(true);
    try {
      let dataAPI = {
        tenantId: tokenDetails?.tenantId,
        ...serviceContractValues,
        contractValue:
          serviceContractValues.contractValue !== ""
            ? removeNonNumeric(serviceContractValues.contractValue)
            : 0,
      };
      let res;
      if (editContract) {
        dataAPI = {
          ...dataAPI, 
          assignedFields : [...removeExtraField(dataAPI.assignedFields, ['belongsTo', 'customFieldIndex'])],
          assignedFields_Custom_ServiceTicketItem : [...removeExtraField(dataAPI.assignedFields_Custom_ServiceTicketItem, ['belongsTo', 'customFieldIndex'])],
          assignedFields_Native_ServiceTicket : dataAPI.assignedFields_Native_ServiceTicket.map((x: any) => {
            if(x.hasOwnProperty('id')){
              return x;
            } else {
              x.id = 0;
              x.projectId = serviceContractValues.projectId;
              return x;
            }
          }),
          assignedFields_Custom_ServiceTicket : [...removeExtraField(dataAPI.assignedFields_Custom_ServiceTicket, ['belongsTo', 'customFieldIndex'])],
        }
        // @ts-ignore
        res = await put(
          `/v1/api/service/contract/id/${serviceContractValues.id}`,
          dataAPI
        );
      } else {
        dataAPI = {
          ...dataAPI, 
          assignedFields : dataAPI.assignedFields.map((e:any) => {e.id = 0; return e;}) || [],
          assignedFields_Custom_ServiceTicketItem : dataAPI.assignedFields_Custom_ServiceTicketItem.map((e:any) => {e.id = 0; return e;}) || [],
          assignedFields_Native_ServiceTicket : dataAPI.assignedFields_Native_ServiceTicket.map((e:any) => {e.id = 0; e.projectId = serviceContractValues.projectId; return e;}) || [],
          assignedFields_Custom_ServiceTicket : dataAPI.assignedFields_Custom_ServiceTicket.map((e:any) => {e.id = 0; return e;}) || []
        }
        // @ts-ignore
        res = await post(`/v1/api/service/contract`, dataAPI);
      }

      if (res && res.status) {
        if (!editContract) {
          if(res.data.id && res.data.projectId) {
            props.setModal(false);
            history.push(
              `/servicecontractsetup/${res.data.id}/${res.data.projectId}`
            );
          } else {
            toast.warn("Something went wrong", {
              autoClose: 3000,
              toastId: 'createContract'
            });
          }
        } else {
          props.setModal(false);
        }
      } else {
        console.error("post/push service/contract err: ", res);
      }
    } catch (e) {
      console.log(e);
    }
    setLoading(false);
  };

  const getPresignedUploadParams = async (name: any, type: any) => {
    const url = `${process.env.REACT_APP_RISKCAST_API_URL}/api/core/aws/s3/sign/img?fileName=${name}&fileType=${type}`;
    const { token } = useAuthStore.getState();

    const headers = {
      "Content-Type": "application/json",
      Authorization: `Bearer ${token}`,
    };
    const res = await axios.get(url, { headers });

    return {
      uploadUrl: res.data.signedRequest,
      fileUrl: res.data.url,
    };
  };

  const handleChangeStatus = (files: any, status: any) => {
    if (status === "done" || status === "headers_received") {
      handleSubmit(files);
      files.remove();
    } else if (status === "aborted") {
    }
  };

  const deleteHandler = (index: number) => {
    const filePathValue = [...serviceContractValues.filePath];
    filePathValue[index]['isDeleted'] = true;
    setServiceContractValues({
      ...serviceContractValues,
      filePath: filePathValue
    });
  };

  const handleSubmit = async (files: any) => {
    setLoading(true);
    const f = files.file;
    const { uploadUrl, fileUrl } = await getPresignedUploadParams(
      files.meta.name,
      files.meta.type
    );

    // put file to s3
    await fetch(uploadUrl, {
      method: "PUT",
      body: f,
    })
      .then((e) => {
        const fileName = fileUrl.substring(
          fileUrl.lastIndexOf("/") + 1,
          fileUrl.length
        );
        setServiceContractValues({
          ...serviceContractValues,
          filePath: [
            ...serviceContractValues.filePath,
            {
              id: 0,
              fileName: fileName,
              refObjectType: "ServiceContract",
              refObjectId: editContract ? serviceContractValues.id : 0,
              url: fileUrl,
            },
          ],
        });
      })
      .catch((err) => {
        console.log("err :", err);
      });
    setLoading(false);
  };

  const removeContractDropdownOption = useCallback(
    (field: any) => {
      setContractDropdownOptions({
        ...contractDropdownOptions,
        availableFields: [
          ...contractDropdownOptions.availableFields.filter(
            (e: any) => e.id !== field.id
          ),
        ],
      });
    },
    [contractDropdownOptions]
  );

  const removeContractDropdownOptionItem = useCallback(
    (field: any) => {
      setContractDropdownOptions({
        ...contractDropdownOptions,
        availableFields_Native_ServiceTicket: [
          ...contractDropdownOptions.availableFields_Native_ServiceTicket.filter(
            (e: any) => e.fieldName !== field.fieldName
          ),
        ],
      });
    },
    [contractDropdownOptions]
  );

  const removeContractDropdownOptionHeader = useCallback(
    (field: any) => {
      setContractDropdownOptions({
        ...contractDropdownOptions,
        availableFields_Custom_ServiceTicket: [
          ...contractDropdownOptions.availableFields_Custom_ServiceTicket.filter(
            (e: any) => e.id !== field.id
          ),
        ],
      });
    },
    [contractDropdownOptions]
  );

  const saveContractField = useCallback(
    (field: any) => {
      checkForValidationsOnChange(
        [
          ...serviceContractValues.assignedFields,
          {
            id: field.id,
            fieldId: field.id,
            order: serviceContractValues.assignedFields.length + 1,
            fieldName: field.name || field.fieldName,
            isActive: 1,
          },
        ],
        "assignedFields"
      );
      // @ts-ignore
      setServiceContractValues({
        ...serviceContractValues,
        assignedFields: [
          ...serviceContractValues.assignedFields,
          {
            id: field.id,
            fieldId: field.id,
            order: serviceContractValues.assignedFields.length + 1,
            fieldName: field.name || field.fieldName,
            isActive: 1,
          },
        ],
      });
      removeContractDropdownOption(field);
    },
    [
      checkForValidationsOnChange,
      removeContractDropdownOption,
      serviceContractValues,
    ]
  );

  const saveTicketField = useCallback(
    (field: any) => {
      // @ts-ignore
      setServiceContractValues({
        ...serviceContractValues,
        assignedFields_Custom_ServiceTicketItem: [
          ...serviceContractValues.assignedFields_Custom_ServiceTicketItem,
          {
            id: field.id,
            fieldId: field.id,
            order: serviceContractValues.assignedFields_Custom_ServiceTicketItem.length + 1,
            fieldName: field.name || field.fieldName,
            isActive: 1,
          },
        ],
      });
      removeContractDropdownOption(field);
    },
    [removeContractDropdownOption, serviceContractValues]
  );

  const saveTicketHeaderField = useCallback(
    (field: any) => {
      // @ts-ignore
      setServiceContractValues({
        ...serviceContractValues,
        assignedFields_Custom_ServiceTicket: [
          ...serviceContractValues.assignedFields_Custom_ServiceTicket,
          {
            id: field.id,
            fieldId: field.id,
            order: serviceContractValues.assignedFields_Custom_ServiceTicket.length + 1,
            fieldName: field.name || field.fieldName || field.label,
            isActive: 1,
          },
        ],
      });
      removeContractDropdownOptionHeader(field);
    },
    [removeContractDropdownOptionHeader, serviceContractValues]
  );

  const saveTicketHeaderFieldOne = useCallback(
    (field: any) => {
      // @ts-ignore
      setServiceContractValues({
        ...serviceContractValues,
        assignedFields_Native_ServiceTicket: [
          ...serviceContractValues.assignedFields_Native_ServiceTicket,
          field
        ],
      });
      removeContractDropdownOptionItem(field);
    },
    [removeContractDropdownOptionItem, serviceContractValues]
  );

  const updateContractDropdownOptions = useCallback(
    (field: any) => {
      setContractDropdownOptions({
        ...contractDropdownOptions,
        availableFields: [...contractDropdownOptions.availableFields, field],
      });
    },
    [contractDropdownOptions]
  );

  const updateContractDropdownOptionsItem = useCallback(
    (field: any) => {
      setContractDropdownOptions({
        ...contractDropdownOptions,
        availableFields_Native_ServiceTicket: [...contractDropdownOptions.availableFields_Native_ServiceTicket, field],
      });
    },
    [contractDropdownOptions]
  );

  const updateContractDropdownOptionsHeader = useCallback(
    (field: any) => {
      setContractDropdownOptions({
        ...contractDropdownOptions,
        availableFields_Custom_ServiceTicket: [...contractDropdownOptions.availableFields_Custom_ServiceTicket, field],
      });
    },
    [contractDropdownOptions]
  );

  const removeContractField = useCallback(
    (field: any) => {
      setServiceContractValues({
        ...serviceContractValues,
        assignedFields: serviceContractValues.assignedFields.filter(
          (e: any) => e.id !== field.id
        ),
      });
      updateContractDropdownOptions(field);
    },
    [serviceContractValues, updateContractDropdownOptions]
  );

  const removeTicketField = useCallback(
    (field: any) => {
      setServiceContractValues({
        ...serviceContractValues,
        assignedFields_Custom_ServiceTicketItem: serviceContractValues.assignedFields_Custom_ServiceTicketItem.filter(
          (e: any) => e.id !== field.id
        ),
      });
      updateContractDropdownOptions(field);
    },
    [serviceContractValues, updateContractDropdownOptions]
  );

  const removeTicketHeaderField = useCallback(
    (field: any) => {
      setServiceContractValues({
        ...serviceContractValues,
        assignedFields_Native_ServiceTicket: serviceContractValues.assignedFields_Native_ServiceTicket.filter(
          (e: any) => e.fieldName !== field.fieldName
        ),
      });
      updateContractDropdownOptionsItem(field);
    },
    [serviceContractValues, updateContractDropdownOptionsItem]
  );

  const removeTicketHeaderFieldOne = useCallback(
    (field: any) => {
      setServiceContractValues({
        ...serviceContractValues,
        assignedFields_Custom_ServiceTicket: serviceContractValues.assignedFields_Custom_ServiceTicket.filter(
          (e: any) => e.id !== field.id
        ),
      });
      updateContractDropdownOptionsHeader(field);
    },
    [serviceContractValues, updateContractDropdownOptionsHeader]
  );

  const handleDrop = useCallback(
    (item: { field: any }, type: string, action: string) => {
      const { field } = item;
      if (action === "add") {
        if (type === "contract") {
          saveContractField(field);
        } else if (type === "ticket") {
          saveTicketField(field);
        } else if (type === "ticketheader") {
          if(field.hasOwnProperty('tableName')){
            saveTicketHeaderFieldOne(field);
          } else {
            saveTicketHeaderField(field);
          }
        }
      } else if (action === "remove") {
        if (type === "contract") {
          removeContractField(field);
        } else if (type === "ticket") {
          removeTicketField(field);
        } else if (type === "ticketheader") {
          if(field.hasOwnProperty('tableName')){
            removeTicketHeaderField(field);
          } else {
            removeTicketHeaderFieldOne(field);
          }
        }
      }
    },
    [saveContractField, saveTicketField, saveTicketHeaderField, saveTicketHeaderFieldOne, removeContractField, removeTicketField, removeTicketHeaderField, removeTicketHeaderFieldOne]
  );

  return (
    <>
      <ToastContainer
        position="top-right"
        autoClose={5000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
      />
      <div className={"create-new-contract "+(props.modal?'show-contract-popup':'')}>
        <div className="contract-backdrop"></div>
        <div className="contract-content">
          <div className="contract-header">
            <h5>{editContract ? "Edit" : "Create"} Service Contract</h5>
            <span className="close-icon" onClick={toggle}></span>
          </div>
          <div className="contract-body">
            {loading && <Loader></Loader>}
            <form className="contractfilter-form" onSubmit={handleContractSubmit}>
              <div className="row">
                <div className="col-4">
                  <div className="form-group">
                    <SelectDropdown
                      options={projectsAndSupervisors.filteredProjects || []}
                      label={"Job Number"}
                      labelKey="projectDisplayNameUI"
                      selected={selectedJob}
                      isDisabled={editContract}
                      onChange={async (e: any) => {
                        checkForValidationsOnChange(
                          e && e.length ? e[0].id : "",
                          "projectId"
                        );
                        setSelectedJob(e);
                        if (
                          e &&
                          e.length &&
                          e[0].id === serviceContractValues.projectId
                        ) {
                          return;
                        }
                        setServiceContractValues({
                          ...serviceContractValues,
                          // @ts-ignore
                          projectId: e && e.length ? e[0].id : "",
                        });
                        if (e && e.length) {
                          setLoading(true);
                          await getCreateContract(e[0].id);
                          setLoading(false);
                        }
                      }}
                    />
                    <div className="errorBox">
                      {fieldError.projectId ? (
                        <span className="text-danger help-block">
                          Job No. Required
                        </span>
                      ) : null}
                    </div>
                  </div>
                </div>
                <div className="col-4">
                  <div className="form-group">
                    <SelectDropdown
                      options={contractDropdownOptions.worklocation}
                      label={"Worksite Location"}
                      labelKey="name"
                      selected={projectLocation}
                      isDisabled={editContract}
                      onChange={(e: any) => {
                        setServiceContractValues({
                          ...serviceContractValues,
                          // @ts-ignore
                          projectLocationId: e && e.length ? e[0].id : 0,
                        });
                        setProjectLocation(e);
                      }}
                    />
                  </div>
                </div>
                <div className="col-4">
                  <div className="form-group">
                    {/* @ts-ignore */}
                    <DateSelector
                      selected={serviceContractValues.contractDate}
                      onChange={(e: any) =>
                        setServiceContractValues({
                          ...serviceContractValues,
                          // @ts-ignore
                          contractDate: e,
                        })
                      }
                      isClearable={false}
                      placeholderText="Contract Date"
                      labelText="Contract Date"
                    />
                  </div>
                </div>
                <div className="col-4">
                  <div className="form-group">
                    {/* @ts-ignore */}
                    <DateSelector
                      selected={serviceContractValues.startDate}
                      onChange={(e: any) => {
                        checkForValidationsOnChange(e, "startDate");
                        setServiceContractValues({
                          ...serviceContractValues,
                          // @ts-ignore
                          startDate: e,
                        });
                      }}
                      isClearable={false}
                      placeholderText="Contract Start Date"
                      labelText="Contract Start Date"
                    />
                    <div className="errorBox">
                      {fieldError.startDate ? (
                        <span className="text-danger help-block">
                          Please Select Start Date
                        </span>
                      ) : null}
                    </div>
                  </div>
                </div>
                <div className="col-4">
                  <div className="form-group">
                    {/* @ts-ignore */}
                    <DateSelector
                      selected={serviceContractValues.endDate}
                      onChange={(e: any) => {
                        checkForValidationsOnChange(e, "endDate");

                        setServiceContractValues({
                          ...serviceContractValues,
                          // @ts-ignore
                          endDate: e,
                        });
                      }}
                      isClearable={false}
                      placeholderText="Contract End Date"
                      labelText="Contract End Date"
                    />
                    <div className="errorBox">
                      {fieldError.endDate ? (
                        <span className="text-danger help-block">
                          Please Select End Date
                        </span>
                      ) : null}
                    </div>
                  </div>
                </div>
                <div className="col-4">
                  <div className="form-group">
                    <input
                      type="text"
                      onChange={(e: any) => {
                        if (e.target.value === '' || removeNonNumeric(e.target.value) >= 0) {
                          setEditAmtErrorCode(false);
                        } else {
                          setEditAmtErrorCode(true);
                        }
                        setServiceContractValues({
                          ...serviceContractValues,
                          // @ts-ignore
                          contractValue: addCommas(removeNonNumeric(e.target.value) * 1),
                        });
                      }}
                      className="form-control"
                      id="contractValue"
                      // @ts-ignore
                      value={serviceContractValues.contractValue}
                      aria-describedby="contractValue"
                      placeholder=""
                    />
                    <label className="text-label" htmlFor="contractValue">
                      Contract Value
                    </label>
                    <div className="errorBox">
                      {editAmtErrorCode ? (
                        <span className="text-danger help-block">
                          Please Enter Numeric Value
                        </span>
                      ) : null}
                    </div>
                  </div>
                </div>
                {props.editContract ? (
                  <div className="col-4">
                    <div className="form-group">
                      <SelectDropdown
                        labelKey="displayName"
                        options={ticketStatus}
                        label={"Status"}
                        selected={selectedStatus || []}
                        onChange={(e: any) => {
                          setSelectedStatus(e);
                          setServiceContractValues({
                            ...serviceContractValues,
                            // @ts-ignore
                            status: e && e.length ? e[0].id : "",
                          });
                        }}
                      />
                    </div>
                  </div>
                ) : null}
                <div className="col-12">
                  <div className="form-group">
                    <textarea
                      className="form-control"
                      // @ts-ignore
                      value={serviceContractValues.note}
                      onChange={(e: any) =>
                        setServiceContractValues({
                          ...serviceContractValues,
                          // @ts-ignore
                          note: e.target.value,
                        })
                      }
                      id="contractValue"
                      aria-describedby="contractValue"
                      placeholder="Enter"
                    ></textarea>
                    <label className="text-label" htmlFor="contractValue">
                      Description
                    </label>
                  </div>
                </div>
                <div className="col-12">
                  <div className="form-group upload-documents">
                    <h6>Upload Documents</h6>
                    <Dropzone
                      onChangeStatus={handleChangeStatus}
                      canCancel={false}
                      maxFiles={1}
                      multiple={false}
                      inputContent="Please upload the file"
                      LayoutComponent={NoDropzoneLayout}
                      styles={{ dropzone: { minHeight: 100, maxHeight: 150 } }}
                    />
                    {/* <div className="select-File">
                      <input
                        className="d-none"
                        type="file"
                        name="files[]"
                        id="uploadDocs"
                      />
                      <label htmlFor="uploadDocs">
                        <p>
                          Please Upload / Drag & Drop the file
                          <span>Max file Size 2MB</span>
                        </p>
                        <button type="button" className="btn btn-primary">
                          Select File
                        </button>
                      </label>
                    </div> */}
                    <div className="uploaded-documents-list">
                      <ul className="uf-list">
                        {serviceContractValues.filePath.map(
                          (file: any, index: number) => {
                            if(!file.isDeleted) {
                              return (
                                <li className="uf-detail" key={index}>
                                  <small className="uf-image uf-image-png"></small>
                                  <span className="uf-name">{file.fileName}</span>
                                  <small className="uf-close" onClick={() => deleteHandler(index)}></small>
                                </li>
                              );
                            } 
                            return null;
                          }
                        )}
                      </ul>
                    </div>
                  </div>
                </div>
                <div
                  className="sc-list-content col-12 mb-3"
                >
                  <div className="r-card r-card-outer">
                    <div
                      className={
                        "r-card-header " +
                        (persionCollapse
                          ? "has-accordian"
                          : "")
                      }
                      onClick={() =>
                        setPersionCollapse(!persionCollapse)
                      }
                    >
                      <h3 className="contract-group-title">
                        Contact Details
                      </h3>
                      {/* eslint-disable-next-line */}
                      <a href="javascript:void(0);" className="contract-group-arrow">
                        <ImgAccordian />
                      </a>
                    </div>
                    {persionCollapse && (
                      <div className="sc-list-content r-card-body">
                        {/* external contact */}
                        <div className="contract-wrapper row">
                          <div className="col-12">External Contact</div>
                          <hr />
                          <div className="col-4">
                            <div className="form-group">
                              <input
                                type="text"
                                onChange={(e: any) => {
                                  setServiceContractValues({
                                    ...serviceContractValues,
                                    // @ts-ignore
                                    externalContactCompany: e.target.value,
                                  });
                                }}
                                className="form-control"
                                id="externalContactCompany"
                                // @ts-ignore
                                value={serviceContractValues.externalContactCompany}
                                aria-describedby="externalContactCompany"
                                placeholder="Company"
                              />
                              <label className="text-label" htmlFor="externalContactCompany">
                                Company
                              </label>
                            </div>
                          </div>
                          <div className="col-4">
                            <div className="form-group">
                              <input
                                type="text"
                                onChange={(e: any) => {
                                  setServiceContractValues({
                                    ...serviceContractValues,
                                    // @ts-ignore
                                    externalContactName: e.target.value,
                                  });
                                }}
                                className="form-control"
                                id="externalContactName"
                                // @ts-ignore
                                value={serviceContractValues.externalContactName}
                                aria-describedby="externalContactName"
                                placeholder="Name"
                              />
                              <label className="text-label" htmlFor="externalContactName">
                                Name
                              </label>
                            </div>
                          </div>
                          <div className="col-4">
                            <div className="form-group">
                              <input
                                type="text"
                                onChange={(e: any) => {
                                  setServiceContractValues({
                                    ...serviceContractValues,
                                    // @ts-ignore
                                    externalContactTitle: e.target.value,
                                  });
                                }}
                                className="form-control"
                                id="externalContactTitle"
                                // @ts-ignore
                                value={serviceContractValues.externalContactTitle}
                                aria-describedby="externalContactTitle"
                                placeholder="Job Title"
                              />
                              <label className="text-label" htmlFor="externalContactTitle">
                                Job Title
                              </label>
                            </div>
                          </div>
                          <div className="col-4">
                            <div className="form-group">
                              <input
                                type="email"
                                onChange={(e: any) => {
                                  setServiceContractValues({
                                    ...serviceContractValues,
                                    // @ts-ignore
                                    externalContactEmail: e.target.value,
                                  });
                                }}
                                className="form-control"
                                id="externalContactEmail"
                                // @ts-ignore
                                value={serviceContractValues.externalContactEmail}
                                aria-describedby="externalContactEmail"
                                placeholder="Email"
                              />
                              <label className="text-label" htmlFor="externalContactEmail">
                                Email
                              </label>
                            </div>
                          </div>
                          <div className="col-4">
                            <div className="form-group">
                              <input
                                type="text"
                                onChange={(e: any) => {
                                  setServiceContractValues({
                                    ...serviceContractValues,
                                    // @ts-ignore
                                    externalContactPhone: e.target.value,
                                  });
                                }}
                                className="form-control"
                                id="externalContactPhone"
                                // @ts-ignore
                                value={serviceContractValues.externalContactPhone}
                                aria-describedby="externalContactPhone"
                                placeholder="Phone"
                              />
                              <label className="text-label" htmlFor="externalContactPhone">
                                Phone
                              </label>
                            </div>
                          </div>
                        </div>
                        {/* internal contact */}
                        <div className="contract-wrapper row">
                          <div className="col-12">Internal Contact</div>
                          <hr />
                          <div className="col-4">
                            <div className="form-group">
                              <input
                                type="text"
                                onChange={(e: any) => {
                                  setServiceContractValues({
                                    ...serviceContractValues,
                                    // @ts-ignore
                                    internalContactName: e.target.value,
                                  });
                                }}
                                className="form-control"
                                id="internalContactName"
                                // @ts-ignore
                                value={serviceContractValues.internalContactName}
                                aria-describedby="internalContactName"
                                placeholder="Name"
                              />
                              <label className="text-label" htmlFor="internalContactName">
                                Name
                              </label>
                            </div>
                          </div>
                          <div className="col-4">
                            <div className="form-group">
                              <input
                                type="text"
                                onChange={(e: any) => {
                                  setServiceContractValues({
                                    ...serviceContractValues,
                                    // @ts-ignore
                                    internalContactTitle: e.target.value,
                                  });
                                }}
                                className="form-control"
                                id="internalContactTitle"
                                // @ts-ignore
                                value={serviceContractValues.internalContactTitle}
                                aria-describedby="internalContactTitle"
                                placeholder="Job Title"
                              />
                              <label className="text-label" htmlFor="internalContactTitle">
                                Job Title
                              </label>
                            </div>
                          </div>
                          <div className="col-4">
                            <div className="form-group">
                              <input
                                type="email"
                                onChange={(e: any) => {
                                  setServiceContractValues({
                                    ...serviceContractValues,
                                    // @ts-ignore
                                    internalContactEmail: e.target.value,
                                  });
                                }}
                                className="form-control"
                                id="internalContactEmail"
                                // @ts-ignore
                                value={serviceContractValues.internalContactEmail}
                                aria-describedby="internalContactEmail"
                                placeholder="Email"
                              />
                              <label className="text-label" htmlFor="internalContactEmail">
                                Email
                              </label>
                            </div>
                          </div>
                          <div className="col-4">
                            <div className="form-group">
                              <input
                                type="text"
                                onChange={(e: any) => {
                                  setServiceContractValues({
                                    ...serviceContractValues,
                                    // @ts-ignore
                                    internalContactPhone: e.target.value,
                                  });
                                }}
                                className="form-control"
                                id="internalContactPhone"
                                // @ts-ignore
                                value={serviceContractValues.internalContactPhone}
                                aria-describedby="internalContactPhone"
                                placeholder="Phone"
                              />
                              <label className="text-label" htmlFor="internalContactPhone">
                                Phone
                              </label>
                            </div>
                          </div>
                        </div>
                      </div>
                    )}
                  </div>
                </div>
                <div
                  className="sc-list-content col-12 mb-3"
                >
                  <div className="r-card r-card-outer">
                  <div
                      className={
                        "r-card-header " +
                        (persionCollapseOne
                          ? "has-accordian"
                          : "")
                      }
                      onClick={() =>
                        setPersionCollapseOne(!persionCollapseOne)
                      }
                    >
                      <h3 className="contract-group-title">
                        Configure Contract and Ticket Line Item Fields
                      </h3>
                      {/* eslint-disable-next-line */}
                      <a href="javascript:void(0);" className="contract-group-arrow">
                        <ImgAccordian />
                      </a>
                    </div>
                    {persionCollapseOne && (
                      <div className="form-group fields-wrapper sc-list-content r-card-body">
                        <div className="fields-addition">
                          <DndProvider backend={isMobile ? TouchBackend : HTML5Backend} key={1}>
                            <div className="row">
                              <div className="col-6">
                                <div className="addition-left">
                                  <div className="al-heading">
                                    <h6 className="m-0">Available Fields</h6>
                                    {/* <Link to="/">+ Add New Field</Link> */}
                                  </div>
                                  <div
                                    className={`al-body ${
                                      editContract ? "is-disabled" : ""
                                    }`}
                                  >
                                    {contractDropdownOptions.availableFields.length ? 
                                      <div className="add-tag-content">
                                        {contractDropdownOptions.availableFields.map(
                                              (field: any, index: any) => (
                                                <ServiceContractCreateAvailableFields
                                                  type={ItemTypes.BOX}
                                                  key={index}
                                                  field={field}
                                                />
                                              )
                                        )}
                                      </div>
                                      : ""}
                                    <div className="add-new-tag">
                                      <div className="add-new-input">
                                        <input
                                          type="text"
                                          value={newField}
                                          onChange={(e) => setNewField(e.target.value)}
                                          onKeyPress={event => {
                                            if (event.key === 'Enter') {
                                              event.preventDefault();
                                              if (!editAmtErrorCode) {
                                                createField();
                                                setNewField("");
                                                setEditAmtErrorCode(false);
                                              } else {
                                                setEditAmtErrorCode(true);
                                              }
                                            }
                                          }}
                                          placeholder="Enter Field Name"
                                          className="form-control "
                                        />
                                        <div className="add-new-buttons">
                                          <button
                                            className="btn btn-secondary"
                                            onClick={(e) => {
                                              e.preventDefault();
                                              setNewField("");
                                            }}
                                          >
                                            <span className="new-cancel"></span>
                                          </button>
                                          <button
                                            className="btn btn-primary"
                                            onClick={(e: any) => {
                                              e.preventDefault();
                                              if (!editAmtErrorCode) {
                                                createField();
                                                setNewField("");
                                                setEditAmtErrorCode(false);
                                              } else {
                                                setEditAmtErrorCode(true);
                                              }
                                            }}
                                          >
                                            <span className="new-save"></span>
                                          </button>
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>
                              <div className="col-6">
                              <ServiceContractCreateAssignedFields
                                accept={[ItemTypes.BOX]}
                                lastDroppedItem={null}
                                onDrop={(item: any) => handleDrop(item, 'contract', 'add')}
                                onRemove={(item: any) => handleDrop(item, 'contract', 'remove')}
                                key={2}
                                heading = {'Contract Assigned Fields'}
                                editContract = {editContract}
                                iterateField = {'assignedFields'}
                                serviceContractValues = {serviceContractValues}
                                showStaticFields = {true}
                              />
                              <ServiceContractCreateAssignedFields
                                accept={[ItemTypes.BOX]}
                                lastDroppedItem={null}
                                onDrop={(item: any) => handleDrop(item, 'ticket', 'add')}
                                onRemove={(item: any) => handleDrop(item, 'ticket', 'remove')}
                                key={1}
                                heading = {'Ticket Assigned Fields'}
                                editContract = {editContract}
                                iterateField = {'assignedFields_Custom_ServiceTicketItem'}
                                serviceContractValues = {serviceContractValues}
                                />
                              </div>
                            </div>
                          </DndProvider>
                        </div>
                      </div>
                    )}
                  </div>
                </div>
                
                <div
                  className="sc-list-content col-12 mb-3"
                >
                  <div className="r-card r-card-outer">
                  <div
                      className={
                        "r-card-header " +
                        (persionCollapseTwo
                          ? "has-accordian"
                          : "")
                      }
                      onClick={() =>
                        setPersionCollapseTwo(!persionCollapseTwo)
                      }
                    >
                      <h3 className="contract-group-title">
                        Configure Ticket Header Fields
                      </h3>
                      {/* eslint-disable-next-line */}
                      <a href="javascript:void(0);" className="contract-group-arrow">
                        <ImgAccordian />
                      </a>
                    </div>
                    {persionCollapseTwo && (
                      <div className="form-group fields-wrapper sc-list-content r-card-body">
                        <div className="fields-addition">
                          <DndProvider backend={isMobile ? TouchBackend : HTML5Backend} key={1}>
                            <div className="row">
                              <div className="col-6">
                                <div className="addition-left">
                                  <div className="al-heading">
                                    <h6 className="m-0">Available Fields</h6>
                                    {/* <Link to="/">+ Add New Field</Link> */}
                                  </div>
                                  <div
                                    className={`al-body ${
                                      editContract ? "" : ""
                                    }`}
                                  >
                                    {contractDropdownOptions.availableFields_Native_ServiceTicket.length ? 
                                      <div className="add-tag-content">
                                        {contractDropdownOptions.availableFields_Native_ServiceTicket.map(
                                              (field: any, index: any) => (
                                                <ServiceContractCreateTicketFields
                                                  type={ItemTypes.BOX}
                                                  key={index}
                                                  field={field}
                                                />
                                              )
                                        )}
                                      </div>
                                      : ""}
                                    {contractDropdownOptions.availableFields_Custom_ServiceTicket.length ? 
                                      <div className="add-tag-content">
                                        {contractDropdownOptions.availableFields_Custom_ServiceTicket.map(
                                              (field: any, index: any) => (
                                                <ServiceContractCreateTicketFields
                                                  type={ItemTypes.BOX}
                                                  key={index}
                                                  field={field}
                                                />
                                              )
                                        )}
                                      </div>
                                      : ""}
                                    <div className="add-new-tag">
                                      <div className="add-new-input">
                                        <input
                                          type="text"
                                          value={newFieldOne}
                                          onChange={(e) => setNewFieldOne(e.target.value)}
                                          onKeyPress={event => {
                                            if (event.key === 'Enter') {
                                              event.preventDefault();
                                              if (!editAmtErrorCode) {
                                                createFieldTicket();
                                                setNewFieldOne("");
                                                setEditAmtErrorCode(false);
                                              } else {
                                                setEditAmtErrorCode(true);
                                              }
                                            }
                                          }}
                                          placeholder="Enter Field Name"
                                          className="form-control "
                                        />
                                        <div className="add-new-buttons">
                                          <button
                                            className="btn btn-secondary"
                                            onClick={(e) => {
                                              e.preventDefault();
                                              setNewFieldOne("");
                                            }}
                                          >
                                            <span className="new-cancel"></span>
                                          </button>
                                          <button
                                            className="btn btn-primary"
                                            onClick={(e: any) => {
                                              e.preventDefault();
                                              if (!editAmtErrorCode) {
                                                createFieldTicket();
                                                setNewFieldOne("");
                                                setEditAmtErrorCode(false);
                                              } else {
                                                setEditAmtErrorCode(true);
                                              }
                                            }}
                                          >
                                            <span className="new-save"></span>
                                          </button>
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>
                              <div className="col-6">
                              <ServiceContractCreateTickedFields
                                accept={[ItemTypes.BOX]}
                                lastDroppedItem={null}
                                onDrop={(item: any) => handleDrop(item, 'ticketheader', 'add')}
                                onRemove={(item: any) => handleDrop(item, 'ticketheader', 'remove')}
                                key={3}
                                heading = {'Service Ticket Header Fields'}
                                editContract = {editContract}
                                iterateField = {'assignedFields_Native_ServiceTicket'}
                                iterateFieldOne = {'assignedFields_Custom_ServiceTicket'}
                                serviceContractValues = {serviceContractValues}
                              />
                              </div>
                            </div>
                          </DndProvider>
                        </div>
                      </div>
                    )}
                  </div>
                </div>
                
              </div>
              <div className="contract-footer">
                <button className="btn btn-secondary" onClick={toggle}>
                  Cancel
                </button>
                <button className="btn btn-primary" type="submit">
                  Save
                </button>{" "}
              </div>
            </form>
          </div>
        </div>
      </div>
    </>
  );
};

export default ServiceContractCreate;
