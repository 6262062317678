import React, { useState, useEffect } from "react";
import "react-bootstrap-typeahead/css/Typeahead.css";
import { get, put } from "../../../../types/api";
import { rightPanelOptions } from "../TimecardConstants";
import SelectDropdown from "../../../shared/SelectDropdown/SelectDropdown";
import Loader from "../../../shared/Loader/Loader";

const PayclassPanel = (props: any) => {
  const [payClassCurrData, setPayClassCurr] = useState([] as any);
  const [availablePayClass, setAvailablePayClass] = useState([] as any);
  const [loader, setLoader] = useState(false);

  useEffect(() => {
    setPayClassCurr([]);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    getAvailablePayClass();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.selectedProjectsSupervisorsShift?.project[0]?.id]);

  /** API : GET available payclass */
  const getAvailablePayClass = async () => {
    try {
      setLoader(true);
      const projId = props.selectedProjectsSupervisorsShift?.project[0]?.id;
      if (projId) {
        let url = `/v1/api/project/${projId}/payclasses`;
        const { data } = await get(url);
        setAvailablePayClass(data || []);
      }
    } catch (error) {
      console.log(error);
    } finally {
      setLoader(false);
    }
  };

  // API to save payclass
  const savePayclass = async (data: any) => {
    try {
      if(data && data.length) {
        const url = `/v1/api/time-card/helper/payclasses`;
        const savingPayclass = data[0];
        const payClasses = [] as any;
        Object.keys(props.selectedPayclassCells).forEach((key: any) => {
          const payclassCell = props.selectedPayclassCells[key];
          const employee = payclassCell.employee;
          payClasses.push({
            id: employee.id,
            tenantId: props.tokenDetails?.tenantId,
            timeCardId: props.timecardId,
            employeeId: employee.employeeId || 0,
            equipmentAssetProjectId: employee.equipmentAssetProjectId || 0,
            crewUnionTradeClassId: savingPayclass["id"],
            crewUnionTradeClass: savingPayclass["unionTradeClass"],
            crewClassDisplayName: savingPayclass["classDisplayName"],
            sortOrder: employee.sortOrder || 0,
            crewStatus: 0,
            regRate: savingPayclass["regRate"],
            ovtRate: savingPayclass["ovtRate"],
            othRate: savingPayclass["othRate"],
            _classDisplayName: savingPayclass["_classDisplayName"] || "",
          });
        });
        const payload = {
          payClasses,
        };
        const res = await put(url, payload);
        if (res.status === 200) {
          props.setSelectedPayclassCells({});
          props.getTimecardById();
        }
      }
    } catch (error: any) {
      console.log(error.response);
    }
  };

  return (
    <>
      <div className="table-filling-wrapper">
        {loader ? <Loader showComponentLoader={true} /> : null}
        <form className="tf-content">
          <div className="tf-header">
            {/* Payclass Start */}
            <h5 className="tf-title">Pay Class</h5>
            {/* Payclass End */}

            {/* eslint-disable-next-line */}
            <a
              // eslint-disable-next-line
              href="javascript:void(0);"
              onClick={() => {
                props.toggleRightPanel({
                  ...rightPanelOptions,
                  isPayClassOpened: false,
                });
                setPayClassCurr([]);
                props.setSelectedPayclassCells({});
              }}
              className="tf-close"
            ></a>
          </div>
          <div className="tf-body">
            {/* Payclass Start */}
            <div className="form-group">
              <SelectDropdown
                labelKey="classDisplayName"
                options={availablePayClass}
                onChange={(e: any) => {
                  if (e && e.length) {
                    let data = e[0];
                    data["employeeId"] = props.selectedEmployee.employeeId;
                    setPayClassCurr([data]);
                  } else {
                    setPayClassCurr(e);
                  }
                }}
                label={"Select Pay Class"}
              />
            </div>
            {/* Payclass End */}
          </div>
          <div className="tf-footer d-flex align-items-center justify-content-end">
            <button
              type="button"
              className="btn btn-secondary mr-3"
              onClick={() => {
                props.toggleRightPanel({
                  ...rightPanelOptions,
                  isPayClassOpened: false,
                });
                setPayClassCurr([]);
                props.setSelectedPayclassCells({});
              }}
            >
              Cancel{" "}
            </button>
            <button
              type="button"
              className="btn btn-primary"
              onClick={() => {
                props.toggleRightPanel({
                  ...rightPanelOptions,
                  isPayClassOpened: false,
                });
                savePayclass(payClassCurrData);
              }}
            >
              Save{" "}
            </button>
          </div>
        </form>
      </div>
    </>
  );
};

export default PayclassPanel;
