import { Button, Modal, ModalBody, ModalFooter, ModalHeader } from "reactstrap";

const ConfirmModal = (props: any) => {
    return (
        <Modal
        isOpen={props.modalActive}
        fade={false}
        toggle={props.toggleCard}
        className="delete-timecard-modal modal-md"
      >
        <ModalHeader toggle={props.toggleCard}>{props.modalData.heading}</ModalHeader>
        <ModalBody>
          <form className="delete-timecard-form">
            <p>{props.modalData.subHeading}</p>
            {props.modalData.addRemarks ? (
              <div className="crew-search">
                <textarea
                  className="crew-search-field"
                  placeholder="Add Remarks"
                  onChange={(e) => props.setModalRemark(e.target.value)}
                ></textarea>
              </div>
            ) : (
              ""
            )}

            <ModalFooter>
              <Button color="secondary" onClick={props.cancelModal}>
                Cancel
              </Button>
              <Button
                color="primary"
                type="button"
                onClick={() => {
                  props.handleTimeCardSubmit(props.modalData.modalIndex);
                }}
              >
                {props.modalData.modalName}
              </Button>{" "}
            </ModalFooter>
          </form>
        </ModalBody>
      </Modal>
    )
}
export default ConfirmModal;
