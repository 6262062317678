import React from "react";
import {
  BrowserRouter as Router,
  Switch,
  Route,
  Redirect,
} from "react-router-dom";
import NavbarTop from "./components/NavbarTop/NavbarTop";
import Landing from "./components/Landing/Landing";
import ServiceContract from "./components/Landing/ServiceContracts/ServiceContracts";
import ServiceTicket from "./components/Landing/ServiceTickets/ServiceTickets";
import Timecard from "./components/Timecard/Timecard";
import "font-awesome/css/font-awesome.min.css";
import "./App.scss";
import ServiceTicketDetails from "./components/Landing/ServiceTickets/ServiceTicketDetails/ServiceTicketDetails";
import ServiceContractSetup from "./components/Landing/ServiceContracts/ServiceContractSetup/ServiceContractSetup";
import TimecardList from "./components/Timecard/TimecardList/TimecardList";
import ReviewTimecard from "./components/Timecard/ReviewTimecard/ReviewTimecard";
import { PrivateRoute } from "./components/PrivateRoute";
import Reports from "./components/Landing/Reports/Reports";
import {ToolsPath} from "./components/shared/Constants";

const AppRouter: React.FC = () => {
  return (
    <>
      <Router>
        <NavbarTop></NavbarTop>
        <Switch>
          <PrivateRoute
            path="/servicecontract"
            hasAccess={ToolsPath.SERVICE_TICKET + '.contract.hasAccess'}
            accessModule={ToolsPath.SERVICE_TICKET}
            component={ServiceContract}
          />
          <PrivateRoute
            path="/servicecontractsetup/:id/:projId"
            hasAccess={ToolsPath.SERVICE_TICKET + '.contract.hasAccess'}
            accessModule={ToolsPath.SERVICE_TICKET}
            component={ServiceContractSetup}
          />
          <PrivateRoute
            path="/serviceticket"
            hasAccess={ToolsPath.SERVICE_TICKET + '.ticket.hasAccess'}
            accessModule={ToolsPath.SERVICE_TICKET + '.ticket'}
            component={ServiceTicket}
          />
          <PrivateRoute
            path="/serviceTicketDetails/:serviceTicketId"
            hasAccess={ToolsPath.SERVICE_TICKET + '.ticket.hasAccess'}
            accessModule={ToolsPath.SERVICE_TICKET + '.ticket'}
            component={ServiceTicketDetails}
          />
          <PrivateRoute
            path="/timecardServiceTicketDetails/:timecardId"
            hasAccess={ToolsPath.SERVICE_TICKET + '.ticket.hasAccess'}
            accessModule={ToolsPath.SERVICE_TICKET + '.ticket'}
            component={ServiceTicketDetails}
          />
          <Route path="/timecardList/:timecardId?" component={TimecardList} />
          <Route path="/reports" component={Reports} />
          <Route path="/timecard" component={Timecard} />
          <Route path="/timecardReview/:tid" component={ReviewTimecard} />
          <Route exact path="/" component={Landing} />
          <Route
            render={(routeProps) => {
              return <Redirect to="/" />;
            }}
          />
        </Switch>
      </Router>
    </>
  );
};

export default AppRouter;
