import type { FC } from "react";
import React from "react";
import { useRef } from "react";
import { useDrag, useDrop } from "react-dnd";
import { ItemTypes } from "../Constants";

const DragDropTableHeader: FC<any> =
  function DragDropTableHeader({ field, handleAssignedFields, index, onDrop, attributes, children, tableSection = 'th', accept = ItemTypes.CELL}) {
    const ref = useRef(null);
    const [{ handlerId, canDrop }, drop] = useDrop({
      accept,
      collect(monitor) {
        return {
          handlerId: monitor.getHandlerId(),
          canDrop: monitor.canDrop()
        };
      },
      hover(item: any, monitor) {
        if (!ref.current) {
          return;
        }
        const dragIndex = item.index;
        const hoverIndex = index;

        // Don't replace items with themselves
        if (dragIndex === hoverIndex) {
          return;
        }
        item.index = hoverIndex;
      },
    });

    const [{ isDragging }, drag] = useDrag({
      type: accept,
      item: () => {
        return { field, index };
      },
      collect: (monitor: any) => ({
        isDragging: monitor.isDragging(),
      }),
      end: (item, monitor) => {
        if (item) {
          const hoverIndex = item.index;
          const dragIndex = index;
          handleAssignedFields(dragIndex, hoverIndex);
        }
      },
    });

    const TableSectionTag = `${tableSection}`
    const opacity = isDragging ? 0 : 1;
    drag(drop(ref));
    return (
      <TableSectionTag ref={ref} style={{ opacity, backgroundColor: canDrop ? "lightgreen" : "" }} data-handler-id={handlerId} {...attributes}>
        {children}
      </TableSectionTag>
    );
  };

export default DragDropTableHeader;
