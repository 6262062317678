import React, { useState } from "react";
import { ReactComponent as Close } from "../../../assets/images/close.svg";
import { CSVLink } from "react-csv";
import { put } from "../../../../src/types/api";
import Loader from "../../shared/Loader/Loader";


interface IAllowanceTabProps {
    allowanceData: any;
    setAllowanceData: (val: Array<object>) => any;
    getAllowanceData: (val: any) => any;
    timecardId: any;
    compLoader: boolean;
}
const AllowanceTab = (props: IAllowanceTabProps) => {
    const [searchText, setSearchText] = useState('');
    // const [edit, setEdit] = useState(false);
    const [amount, setAmount] = useState(0);
    const [notes, setNotes] = useState('');

    const handleSearch = (text: string) => {
        setSearchText(text);
        let data = [];
        data = props.allowanceData.filter(
            (el: any) => {
                if (text === "") {
                    return el
                }
                else {
                    return el.firstName.includes(text) || el.timeCardCrewId.includes(text) || el.allowanceTypeName.includes(text);
                }
            }
        )
        props.setAllowanceData(data);

    }

    const updateAmountAndNotes = async (item: any, amt: number, notes: string) => {
        try {
            // setCompLoader(true)
            let url = `/v1/api/time-card/helper/allowances`
            const payload = {
                "allowances": [
                    {   
                        "id": item.id,
                        "allowanceTypeId": item.allowanceTypeId,
                        "allowanceAmount": amt,
                        "notes": notes,
                        "timeCardCrewId": item.timeCardCrewId,
                        "_allowanceTypeCode": item.allowanceTypeCode,
                        "_allowanceTypeName": item.allowanceTypeName,
                        "_allowanceTypeColor": item.allowanceTypeColor,
                        "_calcTypeLabel": item.calcTypeLabel,
                        "_isTaxableLabel": item.isTaxableLabel
                    }
                ]
            }
            const { data } = await put(url, payload)
            if (data) {
                //setCompLoader(false)
                // setStatusData(data.data.notes || []);
                /** add get call */
                props.getAllowanceData(props.timecardId)
            }
        }
        catch (e) {
            // setCompLoader(false)
            console.log(e);
        }
    }

    /** handle edit  */
    const handleEdit = (e: any, val: boolean) => {
        props.allowanceData.map((data: any) => {
            if (data.id === e.id) {
                data["isEditing"] = val;
                props.setAllowanceData([...props.allowanceData]);
                if (val) {
                    setAmount(e.allowanceAmount);
                    setNotes(e.notes);
                }
            }
            return data;
        });

    }

    return (
        <div className="rr-list-wrapper">
            {props.compLoader && <Loader showComponentLoader={true} />}
            <div className="rr-list-heading d-flex align-items-center justify-content-between">
                <h3 className="heading-sub">
                    <div className="table-search">
                        <input
                            className="table-search-field table-search-icon"
                            placeholder="Search"
                            onChange={(e) => handleSearch(e.target.value)}
                            value={searchText}
                        ></input>
                        {/* eslint-disable-next-line */}
                        <a href="javascript:void(0)" className="table-search-close">
                            <Close />
                        </a>
                    </div>
                </h3>

                <div className="rr-list-btns">
                    <button className="btn btn-primary">
                        <span className="send-excel"></span>
                        <CSVLink data={props.allowanceData}> Export to CSV</CSVLink>
                    </button>
                </div>
            </div>
            <div className="rr-list-content">
                <div className="r-table-wrapper">
                    {props.allowanceData.length ? <table className="table">
                        <thead>
                            <tr>
                                <th>Employee</th>
                                <th>Employee ID</th>
                                <th>Code</th>
                                <th>Allowance(s)</th>
                                <th>Amount</th>
                                <th>Notes</th>
                                <th>Action</th>
                            </tr>
                        </thead>
                        <tbody>
                            {props.allowanceData.map((field: any) => (
                                <>
                                    {
                                        field.isEditing ? <tr>
                                            <td>{field.firstName} {field.lastName}</td>
                                            <td> {field.timeCardCrewId}</td>
                                            <td>{field.allowanceTypeCode}</td>
                                            <td>{field.allowanceTypeName}</td>
                                            <td>
                                                <input placeholder="Enter" value={amount} onChange={(e) => { setAmount(Number(e.target.value)) }} />
                                            </td>
                                            <td>
                                                <input placeholder="Enter" value={notes} onChange={(e) => { setNotes(e.target.value) }} />
                                            </td>
                                            <td>
                                                <div className="action-wrapper">
                                                    <button type="button" className="btn btn-primary" onClick={() => {
                                                        updateAmountAndNotes(field, amount, notes)
                                                    }}>
                                                        Save
                                        </button>
                                                    <button type="button" className="btn btn-secondary" onClick={() => { handleEdit(field, false) }}>
                                                        Cancel
                                        </button>
                                                </div>
                                            </td>
                                        </tr> :
                                            <tr>
                                                <td>{field.firstName} {field.lastName}</td>
                                                <td> {field.timeCardCrewId}</td>
                                                <td>{field.allowanceTypeCode}</td>
                                                <td>{field.allowanceTypeName}</td>
                                                <td>{field.allowanceAmount.toFixed(2)}</td>
                                                <td>{field.notes}</td>
                                                <td>
                                                    <div className="action-wrapper">
                                                        <button type="button" className="btn btn-secondary" onClick={() => { handleEdit(field, true) }}>
                                                            Edit
                                                        </button>
                                                    </div>
                                                </td>
                                            </tr>
                                    }


                                </>

                            ))}




                        </tbody>
                    </table> : <div>No data available</div>}

                </div>
                <div className="table-footer-content"></div>
            </div>
        </div>
    )
}
export default AllowanceTab