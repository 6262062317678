import React, { useState, useEffect } from "react";
import ologo from "../../assets/images/logo.png";
import { Navbar } from "reactstrap";
import { Link } from "react-router-dom";
import SelectDropdown from "../shared/SelectDropdown/SelectDropdown";
import { useAuthStore, useServiceStore, useServerConstants } from "../../store";
import { get, getUserAccessList, getUserSettings, parseJwt } from "../../types/api";
import Loader from "../shared/Loader/Loader";
import { useLocation } from "react-router-dom";
import queryString from "query-string";
import shallow from "zustand/shallow";
import { getUserAccessAndFilterProjects, updateUserSettings } from "../shared/Helpers";

interface Props {}

const NavbarTop = (props: Props) => {
  const [loadingFilters, setLoadingFilters] = useState(false);
  const [selected, setSelected] = useState([] as any);
  const { token } = useAuthStore.getState();
  const { setData, userAccessList, setSelectedOrgEntity } = useServiceStore.getState();
  const { setStatus, setConstants } = useServerConstants.getState();
  const [companies, setCompanies] = useState([]);

  const { setToken, setSourceUrl, sourceUrl } = useAuthStore();
  const { search } = useLocation();
  const params = queryString.parse(search);
  const paramToken: string = params.token as string;
  const { companyId } = useServiceStore(
    (state) => ({ companyId: state.companyId }),
    shallow
  );
  useEffect(() => {
    if (paramToken && paramToken !== "" && token !== paramToken) {
      localStorage.setItem('token', paramToken);
      localStorage.setItem('sourceUrl', params.source_url ? params.source_url.toString() : '');
      
      setToken(paramToken)
      setSourceUrl(params.source_url);
      parseJwt(paramToken);
      getConfigDetails();
    }
  }, [params]); // eslint-disable-line react-hooks/exhaustive-deps

  const getConfigDetails = async () => {
    await Promise.all([getUserAccessList()]);
    const userSettings = await getUserSettings();
    getUserAccessAndFilterProjects(userSettings.selectedProjectId);
  }

  // @ts-ignore
  useEffect(() => {
    getContracts();
    getServerConstants();
  }, []); // eslint-disable-line react-hooks/exhaustive-deps
  
  const [loading, setLoading] = useState(false);

  // @ts-ignore
  useEffect(() => {
    // set selected company  
    if(!selected.length && Number(companyId) > 0 && companies.length) {
      const selectedCompany = companies.find((e: any) => e.id === Number(companyId));
      if(selectedCompany) {
        setSelected([selectedCompany]);
      } else {
        // if selected company is not found, update company
        handleCompanyChange(companies[0]['id']);
        setSelected([companies[0]]);
      }
    }
  }, [companyId, companies]); // eslint-disable-line react-hooks/exhaustive-deps

  const getContracts = async () => {
    const apiURL = `/v1/api/service/contract`;
    setLoadingFilters(true);
    
    const resData = await get(apiURL);
    // @ts-ignore
    setCompanies([...resData.data.filters.companies]);
    setLoadingFilters(false);
  };

  const getServerConstants = async () => {
    const apiURL = `/v1/api/serverconstants`;
    const { data } = await get(apiURL);
    setStatus(data);
    setConstants(data);
  };

  const getProjectId = async (
    selectedCompanyId: any
  ) => {
      let apiURL = `/v1/api/time-card/helper/projects-and-supervisors?orgEntityId=${selectedCompanyId}&projectId=0&noOfProjects=100`;

      // @ts-ignore
      const res = await get(apiURL);
      if (res.data && res.data.data) {
        let result;
        if(res.data.data.projects && res.data.data.projects.length) {
          result = res.data.data.projects[0];
          return result.id;
        }
      }
      return 0;
  };

  const handleUserSettings = async (selectedCompanyId: any, _projectCount: any) => {
    setLoading(true);
    let projectId = 0;
    if(_projectCount) {
      projectId = await getProjectId(selectedCompanyId);
    }
    await updateUserSettings(selectedCompanyId, projectId);
    setLoading(false);
  };

  // call on company change
  const handleCompanyChange = (updatedCompanyId : any) => {
    setData(updatedCompanyId);
    let _projectCount = 0;
    if(userAccessList && userAccessList.orgEntities && userAccessList.orgEntities.length){
      const selectedOrgEntity = userAccessList.orgEntities.find((entity:any) => entity.id === Number(updatedCompanyId))
      if(selectedOrgEntity && selectedOrgEntity._projectCount) {
        _projectCount = selectedOrgEntity._projectCount;
      }
      setSelectedOrgEntity(selectedOrgEntity);
    }
    handleUserSettings(updatedCompanyId, _projectCount); 
  }

  return (
    <section className="navigation-wrapper d-flex align-items-center justify-content-center">
      {loading && <Loader></Loader>}
      <Navbar
        className="navigation justify-content-center justify-content-md-start"
        expand="sm"
      >
        <div className="navigation-left-side">
          {/* eslint-disable-next-line */}
          <a className="left-toggler"> </a>
          {/* <NavbarSide></NavbarSide> */}
        </div>
        <Link to="/" className="navbar-brand">
          <img src={ologo} alt="logo" />
        </Link>
        <div className="company-selection-wrapper pr-1">
          <div className="form-group">
            {(!token || loadingFilters) && <Loader />}
            <SelectDropdown
              labelKey="name"
              options={companies}
              label={"Company"}
              onChange={(e: any) => {
                if(e && e.length && e[0].id) {
                  handleCompanyChange(e[0].id)
                } else {
                  setData("");
                }
                setSelected(e);
              }}
              selected={selected}
            />
          </div>
        </div>
        <div className="ml-auto" style={{ marginRight: "30px" }}>
          <a
            href={`${sourceUrl}?token=${token}&source_url=${window.location.href}`}
            target={"_self"}
          >
            {`Riskcast `}
            <i className={"fa fa-solid fa-toggle-off"}></i>{" "}
          </a>
        </div>
      </Navbar>
    </section>
  );
};

export default NavbarTop;
