import React from 'react';
import Router from './Router';

const App: React.FC = () => {
  return (
    <div data-testid="app" className="App landing-wrapper">
      <Router />
    </div>
  )
};

export default App;
