import React, { useEffect, useState } from 'react';
import moment from 'moment';
import Checkbox from "@material-ui/core/Checkbox";
import Header from '../../shared/Header/Header';
import SelectDropdown from "../../shared/SelectDropdown/SelectDropdown";
import Loader from "../../shared/Loader/Loader";
import { get } from "../../../types/api";

import {
  useServiceStore,
  useServerConstants
} from "../../../store";
import { Dropdown, DropdownItem, DropdownToggle, DropdownMenu } from 'reactstrap';
import { defaultHeaders } from './headerConstants';

function tableToCSV() {
  var csv_data: any = [];
  var rows = document.getElementsByTagName('tr');
  for (var i = 0; i < rows.length; i++) {
    var cols = rows[i].querySelectorAll('td,.label');
    var csvrow = [];
    for (var j = 0; j < cols.length; j++) {
      let html = cols[j].innerHTML;
      html = html.replaceAll(',', '');
      csvrow.push(html);
    }
    csv_data.push(csvrow.join(","));
  }
  csv_data = csv_data.join('\n');
  downloadCSVFile(csv_data);
}

function downloadCSVFile(csv_data: any) {
  var CSVFile = new Blob([csv_data], {
    type: "text/csv"
  });
  var temp_link = document.createElement('a');

  // Download csv file
  temp_link.download = "report.csv";
  var url = window.URL.createObjectURL(CSVFile);
  temp_link.href = url;
  temp_link.style.display = "none";
  document.body.appendChild(temp_link);
  temp_link.click();
  document.body.removeChild(temp_link);
}

export default function Reports(props: any) {

  const companyId = useServiceStore((serviceStore) => serviceStore.companyId);
  const { status } = useServerConstants.getState();
  const ticketStatus = useServerConstants(
    (serverConstants) => serverConstants.status
  );

  const [serviceValue, setServiceValue] = useState({
    filters: {
      projects: [],
      contracts: [],
    },
    result: {
      page: 0,
      limit: 25,
      totalResults: 0,
      data: [],
      allData: [],
    },
  });

  // filters for fetching service contract report
  const [selectedProject, setSelectedProject] = useState({
    name: "",
    id: undefined,
  });
  const [selectedContract, setSelectedContract] = useState({
    name: "",
    id: undefined,
  });

  const [searchInfo, setSearchInfo] = useState({} as any);

  const [pageLoader, setLoading] = useState(false);
  const [componentLoader, setComponentLoader] = useState(false);

  const [headers, setHeaders] = useState(defaultHeaders);
  const [selectedHeader, setSelectedHeader] = useState(false);
  const [showColumnSelect, setShowColumnSelect] = useState(false);

  const handleColumnVisibility = async (item: any, value: boolean) => {
    let _headers = [];
    for await (const _item of headers) {
      if (_item.id === item.id) {
        _headers.push({
          ..._item,
          isChecked: value
        })
      } else _headers.push(_item)
    }
    setHeaders(_headers);
  }

  // API CALLING
  // fetch ticket details
  const getServiceContractRecords = async () => {
    let url = `/v1/api/report?`;
    url = selectedProject.id ? `${url}&projectId=${selectedProject.id}` : url;
    url = selectedContract.id ? `${url}&contractId=${selectedContract.id}` : url;
    setLoading(true)
    const res = await get(url);
    setLoading(false)
    if (res.status === 200) {
      setServiceValue({
        filters: { ...serviceValue.filters },
        result: { ...serviceValue.result, data: res.data.data, allData: res.data.data },
      });
      setSearchInfo({});
    }
  };

  const getServiceContractFilter = async () => {
    setComponentLoader(true);
    let url = `/v1/api/report/filters`;
    if (companyId) {
      url += selectedProject.id ? `?projectId=${selectedProject.id}` : url;
    }
    // @ts-ignore
    const res = await get(url);
    if (res.status === 200) {
      setServiceValue({
        filters: {
          projects: [...serviceValue.filters.projects],
          contracts: res.data.data,
        },
        result: { ...serviceValue.result }
      });
    }
    setComponentLoader(false);
  };

  const getProjects = async () => {
    setComponentLoader(true);
    let url = `/v1/api/report/projects`;
    if (companyId) {
      url += `?companyId=${companyId}`;
    }
    // @ts-ignore
    const res = await get(url);
    if (res.status === 200) {
      setServiceValue({
        filters: {
          projects: res.data.data,
          contracts: [...serviceValue.filters.contracts],
        },
        result: { ...serviceValue.result }
      });
    }
    setComponentLoader(false);
  };

  useEffect(() => {
    if (selectedProject.id) {
      getServiceContractFilter();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedProject.id]);

  useEffect(() => {
    if (companyId) {
      getProjects();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [companyId])

  const getFields = (fieldString: string) => {
    let field = JSON.parse(fieldString)
    let result: any[] = []
    for (const i in field) {
      result.push(i + ': ' + field[i]);
    }
    return result.join('; ');
  }

  const handleInputChange = (e: any) => {
    const { name, value } = e.target;
    setSearchInfo((prevSearchValues: any) => ({ ...prevSearchValues, [name]: value }));
  };

  const filterData = () => {
    const filtered = serviceValue.result.allData.filter((item) => {
      return Object.keys(searchInfo).every((key) => {
        const searchValue = searchInfo[key].toLowerCase();
        const itemValue = String(item[key]).toLowerCase();
        return itemValue.includes(searchValue);
      });
    });
    setServiceValue({
      filters: { ...serviceValue.filters },
      result: { ...serviceValue.result, data: [...filtered] },
    });
  };

  const renderTableCell = (headerItem: any, reportItem: any) => {
    switch (headerItem.key) {
      case 'status':
        return status[reportItem[headerItem.key]];
      case 'contractDate':
      case 'serviceContractStartDate':
      case 'timeCardDate':
      case 'serviceContractEndDate':
        return reportItem[headerItem.key]
          ? moment.utc(reportItem[headerItem.key]).format('MM/DD/YYYY')
          : '--';
      case 'duration':
        return (
          <>
            {moment.utc(reportItem.effectiveDate).format('MM/DD/YYYY')} -{' '}
            {moment.utc(reportItem.endDate).format('MM/DD/YYYY')}
          </>
        );
      case 'ticketFields':
        return reportItem[headerItem.key] ? getFields(reportItem[headerItem.key]) : '--';
      case 'ticketStatus':
        return reportItem[headerItem.key]
          ? ticketStatus[reportItem[headerItem.key]]
          : '--';
      case 'isOutOfScope':
        return reportItem[headerItem.key] >= 0
          ? reportItem[headerItem.key] > 0
            ? 'Yes'
            : 'No'
          : '--';
      case 'serviceQty':
      case 'actualTargetHours':
      case 'totalPayrollHours':
      case 'estimatedQuantity':
      case 'percentComplete':
        return reportItem[headerItem.key] >= 0
          ? reportItem[headerItem.key]
          : '--';
      default:
        return reportItem[headerItem.key] || '--';
    }
  };

  return (
    <div className="reports-wrapper">
      <Header breadcrumb={"Reports"} title={"Reports"} />

      <div className="inapp-filter-wrapper">
        <div className="inapp-filters">
          <h2 className="page-title-sub">Search By</h2>
          <form className="contractfilter-form">
            <div className="row">
              <div className="col-3">
                <div className="form-group">
                  {!selectedProject.id && componentLoader && !pageLoader && (
                    <Loader showComponentLoader={true} />
                  )}
                  <SelectDropdown
                    labelKey="label"
                    options={serviceValue.filters?.projects.map((e: any) => {
                      return { ...e, label: e.projectDisplayNumber + ' - ' + e.name };
                    })}
                    label={"Project"}
                    selected={selectedProject?.id ? [selectedProject] : []}
                    onChange={(e: any) => {
                      setSelectedProject(
                        e?.length && e[0]?.id
                          ? e[0]
                          : { name: e, id: undefined }
                      );
                    }}
                  />
                </div>
              </div>

              <div className="col-3">
                <div className="form-group">
                  {!selectedContract.id && componentLoader && !pageLoader && (
                    <Loader showComponentLoader={true} />
                  )}
                  <SelectDropdown
                    labelKey="serviceContractDisplayNumber"
                    options={serviceValue.filters?.contracts}
                    label={"Service Contract"}
                    selected={selectedContract?.id ? [selectedContract] : []}
                    onChange={(e: any) => {
                      setSelectedContract(
                        e?.length && e[0]?.id
                          ? e[0]
                          : { name: e, id: undefined }
                      );
                    }}
                  />
                </div>
              </div>
              <div className="col-3">
                <div className="form-group submit-filter">
                  <button
                    type="button"
                    className="btn btn-primary"
                    onClick={() => {
                      getServiceContractRecords();
                    }}
                  >
                    Search
                  </button>
                </div>
              </div>
            </div>
          </form>
        </div>
      </div>

      <div className="reports-content">
        <div className="rr-list-wrapper">
          <div className="rr-list-heading d-flex align-items-center justify-content-between">
            <h3 className="heading-sub">
              <div className="table-search">
              </div>
            </h3>
            {serviceValue.result && serviceValue.result.data.length > 0 && (
              <div className="rr-list-btns">
                <div
                  className={
                    "multi-select-wrap " +
                    (selectedHeader ? "is-selected" : "")
                  }
                >
                  <div className="rr-toggle-btns">
                    <Dropdown isOpen={showColumnSelect} direction='down'>
                      <DropdownToggle caret onClick={() => {
                        setShowColumnSelect(!showColumnSelect)
                      }}>
                        <span className="column"></span>
                        {/* <span className="column"></span> */}
                      </DropdownToggle>
                      <DropdownMenu
                        className="column-dropdown"
                        container="body" >
                        {headers.map((headerItem) =>
                          <DropdownItem onBlur={(e) => {
                            e.stopPropagation();
                          }}>
                            <div className="custom-control custom-checkbox">
                              <Checkbox
                                checked={headerItem.isChecked}
                                onChange={(
                                  e: any,
                                  checked: boolean
                                ) => {
                                  if (checked) setSelectedHeader(true);
                                  handleColumnVisibility(headerItem, checked);
                                }}
                                value={headerItem.name}
                                name={`checkBox-${headerItem?.name}`}
                                id={`column-${headerItem.id}`}
                              />
                              <label
                                //   @ts-ignore
                                for={`column-${headerItem.id}`}
                              >
                                {headerItem.name}
                              </label>
                            </div>
                          </DropdownItem>
                        )}
                      </DropdownMenu>
                    </Dropdown>
                  </div>

                </div>
                <button className="btn btn-primary" onClick={tableToCSV}>
                  <span className="send-excel"></span>
                  Export to CSV
                </button>
              </div>
            )}
          </div>
          <div className="rr-list-content">
            {pageLoader && <Loader></Loader>}
            {serviceValue.result?.allData?.length === 0 ? (
              <span className="no-results-contract">
                Please select atleast one valid filter
              </span>
            ) : (
              <div className="r-table-wrapper">
                <table className="table">
                  <thead>
                    <tr>
                      {headers.map((headerItem, i) => headerItem.isChecked &&
                        (
                          <th className="column-header">
                            <div className="label">{headerItem.name}</div>
                            <div className="column">
                              {(headerItem.isSearchable || false) &&
                                (
                                  <div className="table-search">
                                    <input
                                      className="table-search-field table-search-icon"
                                      placeholder="Search"
                                      name={headerItem.key}
                                      value={searchInfo[headerItem.key] || ''}
                                      onChange={handleInputChange}
                                      onKeyUp={(e) => {
                                        e.preventDefault();
                                        if (e.key === 'Enter') {
                                          filterData();
                                        }
                                      }}
                                    />
                                  </div>
                                )
                              }
                            </div>
                          </th>
                        )
                      )}
                    </tr>
                  </thead>
                  <tbody>
                    {serviceValue.result?.data.map((reportItem: any, i: any) => {
                      return (
                        <tr key={i}>
                          {headers.map((headerItem) =>
                            headerItem.isChecked && (
                              <td key={headerItem.key}>{renderTableCell(headerItem, reportItem)}</td>
                            )
                          )}
                        </tr>
                      )
                    })}
                  </tbody>
                </table>
              </div>
            )}
          </div>
        </div>
      </div>
    </div>
  )
}
