/* eslint-disable no-use-before-define */
import React, { useState } from "react";
import Autocomplete, {
  createFilterOptions
} from "@material-ui/lab/Autocomplete";
import { Checkbox, Chip, TextField } from "@mui/material";

import CheckBoxOutlineBlankIcon from '@mui/icons-material/CheckBoxOutlineBlank';
import CheckBoxIcon from '@mui/icons-material/CheckBox';

const icon = <CheckBoxOutlineBlankIcon fontSize="small" />;
const checkedIcon = <CheckBoxIcon fontSize="small" />;

const MultiSelect = ({
  items,
  label,
  placeholder,
  selectAllLabel,
  noOptionsText,
  onChange,
  disabledInput,
  selectedValues
}) => {
  const [selectedOptions, setSelectedOptions] = useState(JSON.parse(JSON.stringify(selectedValues)));
  const allSelected = items.length === selectedOptions.length;
  const handleToggleOption = (selectedOptions) =>
    setSelectedOptions(selectedOptions);
  const handleClearOptions = () => setSelectedOptions([]);
  const getOptionLabel = (option) => `${option.label}`;
  const handleSelectAll = (isSelected) => {
    if (isSelected) {
      setSelectedOptions(items);
    } else {
      handleClearOptions();
    }
  };

  const handleToggleSelectAll = () => {
    handleSelectAll && handleSelectAll(!allSelected);
  };

  const handleChange = (event, selectedOptions, reason) => {
    if (reason === "select-option" || reason === "remove-option") {
      if (selectedOptions.find((option) => option.value === "select-all")) {
        handleToggleSelectAll();
        let result = [];
        result = items.filter((el) => el.value !== "select-all");
        return onChange(result);
      } else {
        handleToggleOption && handleToggleOption(selectedOptions);
        return onChange(selectedOptions);
      }
    } else if (reason === "clear") {
      handleClearOptions && handleClearOptions();
    }
  };
  const optionRenderer = (option, { selected }) => {
    const selectAllProps =
      option.value === "select-all" // To control the state of 'select-all' checkbox
        ? { checked: allSelected }
        : {};
    return (
      <>
        <Checkbox
          color="primary"
          icon={icon}
          checkedIcon={checkedIcon}
          style={{ marginRight: 8 }}
          checked={selected}
          {...selectAllProps}
        />
        {getOptionLabel(option)}
      </>
    );
  };
  const inputRenderer = (params) => (
    <TextField {...params} sx={{ input: { backgroundColor: 'transparent !important', border: '0 !important', height: '20px !important' } }}  label={label} placeholder={placeholder} />
  );
  const getOptionSelected = (option, anotherOption) => {
    return option.value === anotherOption.value;
  }
  const filter = createFilterOptions();
  return (
    <Autocomplete
      multiple
      size="small"
      ListboxProps={{ style: { height: 250 } }}
      options={items}
      disabled={disabledInput}
      value={selectedOptions}
      disableCloseOnSelect
      defaultValue={selectedValues}
      getOptionLabel={getOptionLabel}
      getOptionSelected={getOptionSelected}
      noOptionsText={noOptionsText}
      filterOptions={(options, params) => {
        const filtered = filter(options, params);
        return [{ label: selectAllLabel, value: "select-all" }, ...filtered];
      }}
      renderTags={(value) => {
        if(value.length === 1){
          return <Chip variant="outlined" label={value[0].label}  />
        } else if(value.length > 1 && value.length !== items.length){
          return <Chip variant="outlined" label={"Multiple " + label}  />
        } else if(value.length === items.length){
          return <Chip variant="outlined" label={"All " + label}  />
        }
      }}
      onChange={handleChange}
      renderOption={optionRenderer}
      renderInput={inputRenderer}
    />
  );
};

MultiSelect.defaultProps = {
  limitTags: 5,
  items: [],
  selectedValues: [],
  getOptionLabel: (value) => value
};

export default MultiSelect;
