import type { FC } from "react";
import { useDrop } from "react-dnd";

export const ServiceContractCreateTickedFields: FC<any> =
  function ServiceContractCreateTickedFields({
    accept,
    heading,
    serviceContractValues,
    editContract,
    iterateField,
    iterateFieldOne,
    onDrop,
    onRemove
  }) {
    const [, drop] = useDrop({
      accept,
      drop: onDrop,
    });

    var newArray = serviceContractValues[iterateField].concat(serviceContractValues[iterateFieldOne]);

    return (
      <div ref={drop} data-testid="dustbin" className="addition-right">
        <div className="al-heading">
          <h6 className="m-0">{heading}</h6>
        </div>
        <div className={`al-body ${editContract ? "" : ""}`}>
          <div className="add-tag-content">
            {newArray?.map(
              (field: any, index: number) => (
                <span className={field.tableName ? "add-tag-item" : "add-tag-header"} key={index}>
                  {field.hasOwnProperty('tableName') ? field.label : field.fieldName}
                  <i
                    className="icon-remove"
                    onClick={(e) => {
                      onRemove({ field });
                    }}
                  ></i>
                </span>
              )
            )}
            {(newArray.length === 0) ? (
              <span className="d-block w-100 text-center">
                Please try adding from Available Fields
              </span>
            ) : (
              ""
            )}
          </div>
        </div>
      </div>
    );
  };
