import React, { useState } from 'react';
import { RouteComponentProps } from 'react-router-dom';
import { useServiceStore } from '../../../store';
import SweetAlert from 'react-bootstrap-sweetalert';
import shallow from "zustand/shallow";
import { ToolsPath } from '../../shared/Constants';
import { accessNestedProperty } from '../../shared/Helpers';

interface ChildComponentProps {
    history: RouteComponentProps["history"];
}

const LandingCard: React.FC<ChildComponentProps> = ({ history }) => {

    const { selectedOrgEntity } = useServiceStore(
        (state) => ({ selectedOrgEntity: state.selectedOrgEntity }),
        shallow
    );
    const [hideAlert, setHideAlert] = useState(false);

    const { userAccessList } = useServiceStore.getState();

    const cardItems = [
        { title: 'Time Cards', route: '/timecard', description: ' ', checkProjectCount: true, hasAccess: ToolsPath.SERVICE_TICKET + '.timeCard.hasAccess' },
        { title: 'Service Tickets', route: '/serviceticket', description: ' ', checkProjectCount: true, hasAccess: ToolsPath.SERVICE_TICKET + '.ticket.hasAccess' },
        { title: 'Service Contracts', route: '/servicecontract', description: ' ', checkProjectCount: true, hasAccess: ToolsPath.SERVICE_TICKET + '.contract.hasAccess' },
        { title: 'Reports', route: '/reports', description: ' ', checkProjectCount: true, hasAccess: ToolsPath.SERVICE_TICKET + '.reports.hasAccess' },
    ];

    const cardSpread = cardItems.map((cardItem, index) => {
        return accessNestedProperty(cardItem.hasAccess, userAccessList) ? (
            <div className="col-12 col-sm-6" key={index}>
                <div className="landing-card">
                    <div className="landing-card-heading d-flex align-items-center justify-content-start">
                        <span className="landing-card-heading-image d-flex align-items-center justify-content-center">
                            {cardItem.title.charAt(0)}
                        </span>
                        <h1 className="page-title">{cardItem.title}</h1>
                        <button
                            className="ml-auto btn btn-primary"
                            onClick={() => {
                                if (cardItem.checkProjectCount && selectedOrgEntity && selectedOrgEntity._projectCount === 0) {
                                    setHideAlert(true);
                                } else {
                                    history.push(`${cardItem.route}`);
                                }
                            }}
                        >
                            View
                        </button>
                    </div>
                    <div className="landing-card-body">
                        <p>{cardItem.description}</p>
                    </div>
                </div>
            </div>
        ) : null;
    });

    return (
        <div className="landing-cards-wrapper">
            {hideAlert && (
                <SweetAlert
                    title='Missing Projects'
                    type={"info"}
                    confirmBtnBsStyle={"info"}
                    onConfirm={() => setHideAlert(false)}
                >
                    {"You don't have access to any projects for this company.  Please contact your system administrator and try again."}
                </SweetAlert>
            )}
            <div className="row">
                {cardSpread}
            </div>
        </div>
    )
}

export default LandingCard;