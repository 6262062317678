import React, { useState, useEffect } from "react";
import { get, deleteAPI, put } from "../../../../src/types/api";
import { ReactComponent as Close } from "../../../assets/images/close.svg";
import { ReactComponent as TrashRed } from "../../../assets/images/trash-red.svg";
import { ReactComponent as Equipment } from "../../../assets/images/equipment.svg";
import { ReactComponent as Employee } from "../../../assets/images/employee.svg";
import { ReactComponent as AddPlus } from "../../../assets/images/add.svg";
import { ReactComponent as Minus } from "../../../assets/images/minus.svg";
import { ReactComponent as TempLabour } from "../../../assets/images/temp-labour.svg";
import { Button, Modal, ModalHeader, ModalBody, ModalFooter } from "reactstrap";
import Loader from "../../shared/Loader/Loader";
import { useTimeCardStore } from "../../../store";

interface IAddCrewModalProps {
  addCrewModal: boolean;
  timecardId: number;
  createTimecard: () => Promise<number>;
  toggleAddCrew: () => void;
  createTimecardPayload: (
    eachCrew: any,
    index: number,
    sortOrder: number,
    createdTimecardId: number
  ) => any;
  getAssignedCode: () => void;
  timeCardCostCodePayload: (assignedCode: any) => any;
  getTimecardById: () => void;
}

interface IAssignedCrew {
  id: number;
  ids: number[];
  employeeId: number;
  count: number;
}

interface ISaveCrewPayload {
  projectId?: number;
  timeCardId: number;
  timeCardCrew: any;
  timeCardCostCodes: [];
}

let timerId: NodeJS.Timeout;
function debounceFunction(fn: (arg: any) => void, n = 300) {
  return (arg?: any[]) => {
    clearTimeout(timerId);
    timerId = setTimeout(() => fn(arg), n);
  };
}

const AddCrewModal = (props: IAddCrewModalProps) => {
  const [availableCrew, setAvailableCrew] = useState([] as any);
  const [assignedCrew, setAssignedCrew] = useState([] as IAssignedCrew[]);
  const [searchText, setSearchText] = useState("");

  const [availableCrewLoader, setAvailableCrewLoader] = useState(false);
  const [assignedCrewLoader, setAssignedCrewLoader] = useState(false);
  const [modalLoader, setModalLoader] = useState(false);

  const { selectedProjectsSupervisorsShift } = useTimeCardStore.getState();

  /** counter for incrementing or decremention the crew counter */

  const crewCounter = (type: String, index: number) => {
    let crewToBeProcessed = assignedCrew[index];
    crewToBeProcessed.count =
      type === "INC"
        ? crewToBeProcessed.count + 1
        : crewToBeProcessed.count === 1
        ? 1
        : crewToBeProcessed.count - 1;
    setAssignedCrew([
      ...assignedCrew.slice(0, index),
      crewToBeProcessed,
      ...assignedCrew.slice(index + 1),
    ]);
  };

  const handleInput = (value: string) => {
    if (searchText.length >= 3 && value.length === 0) {
      getAvailableCrew(true);
    }
    setSearchText(value);
  };

  const handleCrew = (field: any) => {
    let id;
    let type;
    if( field?.equipmentAssetProjectId) {
      id = field.equipmentId;
      type = 'equipmentId'
    } else {
      id = field.employeeId;
      type = 'employeeId'
    }
    addCrew(id, type);
  }

  /** Add crew, get the id, update assignedCrew */
  const addCrew = (employeeOrEquipmentId: number, type: string) => {
    let alreadyExistingAssignedIndex: number = assignedCrew.findIndex(
      (eachAssignedCrew: any) =>
        eachAssignedCrew[type] === employeeOrEquipmentId
    );
    if (alreadyExistingAssignedIndex > -1) {
      setAssignedCrew([
        ...assignedCrew.slice(0, alreadyExistingAssignedIndex),
        {
          ...assignedCrew[alreadyExistingAssignedIndex],
          count: assignedCrew[alreadyExistingAssignedIndex].count + 1,
        },
        ...assignedCrew.slice(alreadyExistingAssignedIndex + 1),
      ]);
    } else {
      let selectedAvailableCrew = availableCrew.find(
        (eachAvailableCrew: any) =>
          eachAvailableCrew[type] === employeeOrEquipmentId
      );
      setAssignedCrew([
        ...assignedCrew,
        { ...selectedAvailableCrew, count: 1 },
      ]);
    }
  };

  /** API : GET available crew */
  const getAvailableCrew = async (reset = false) => {
    try {
      if (selectedProjectsSupervisorsShift?.project[0]?.id) {
        let url = `/v1/api/time-card/helper/available-crew?employeeId=1&projectId=${selectedProjectsSupervisorsShift.project[0].id}&timeCardId=${props.timecardId}`;
        url =
          searchText.length && !reset ? `${url}&searchText=${searchText}` : url;
        setAvailableCrewLoader(true);
        const { data } = await get(url);
        setAvailableCrewLoader(false);
        setAvailableCrew([...data?.data.employees, ...data?.data.equipment] || []);
      }
    } catch (error) {
      console.log(error);
    }
  };
  /** API : GET assigned crew */
  const getAssignedCrew = async () => {
    try {
      if (props.timecardId) {
        const url = `/v1/api/time-card/helper/assigned-crew?timeCardId=${props.timecardId}`;
        setAssignedCrewLoader(true);
        const { data } = await get(url);
        setAssignedCrewLoader(false);
        let result: IAssignedCrew[] = [];
        data.data.forEach((eachAssignedCrew: IAssignedCrew) => {
          let existingAssignedCrew = result.findIndex(
            (eachResult) =>
              eachResult.employeeId === eachAssignedCrew.employeeId
          );
          if (existingAssignedCrew > -1) {
            result[existingAssignedCrew].count =
              result[existingAssignedCrew].count + 1;
            result[existingAssignedCrew].ids.push(eachAssignedCrew.id);
          } else {
            // added ids array for adding all the id of that employeeId
            result.push({
              ...eachAssignedCrew,
              count: 1,
              ids: [eachAssignedCrew.id],
            });
          }
        });
        setAssignedCrew(result);
      }
    } catch (error) {
      console.log(error);
    }
  };

  /* API to save crew */
  const saveCrew = async () => {
    try {
      let createdTimecardId: number = 0;
      setModalLoader(true);
      const assignedCode = await props.getAssignedCode();
      if (!props.timecardId) {
        createdTimecardId = await props.createTimecard();
      }
      const url = `/v1/api/time-card/helper/crew-and-cost-codes`;
      let sortOrder = -1;
      const payload: ISaveCrewPayload = {
        projectId: selectedProjectsSupervisorsShift.project[0].id,
        timeCardId: props.timecardId,
        timeCardCrew: [],
        timeCardCostCodes: props.timeCardCostCodePayload(assignedCode),
      };

      assignedCrew.forEach((eachCrew) => {
        if (eachCrew.count === 1) {
          sortOrder++;
          payload.timeCardCrew.push(
            props.createTimecardPayload(
              eachCrew,
              0,
              sortOrder,
              createdTimecardId
            )
          );
        } else {
          let index = 0;
          while (index < eachCrew.count) {
            sortOrder++;
            payload.timeCardCrew.push(
              props.createTimecardPayload(
                eachCrew,
                0,
                sortOrder,
                createdTimecardId
              )
            );
            index++;
          }
        }
      });
      const res = await put(url, payload);
      if (res.status === 200 && !createdTimecardId) {
        props.getTimecardById();
      }
      setModalLoader(false);
      props.toggleAddCrew();
    } catch (error) {
      console.log(error);
    }
  };

  /** API: DELETE crew memeber */
  const deleteAssignedCrew = async (index: number) => {
    try {
      setAssignedCrewLoader(true);
      if (assignedCrew[index].ids) {
        let promise = assignedCrew[index].ids.map((eachId) => {
          const url = `/v1/api/time-card/helper/crew-member/${eachId}`;
          return deleteAPI(url);
        });
        await Promise.all(promise);
        getAssignedCrew();
      } else {
        const employeeId = assignedCrew[index].employeeId;
        let alreadyExistingAssignedIndex: number = assignedCrew.findIndex(
          (eachAssignedCrew: IAssignedCrew) =>
            eachAssignedCrew.employeeId === employeeId
        );
        if (alreadyExistingAssignedIndex > -1) {
          setAssignedCrew([
            ...assignedCrew.slice(0, alreadyExistingAssignedIndex),
            ...assignedCrew.slice(alreadyExistingAssignedIndex + 1),
          ]);
        }
      }
      setAssignedCrewLoader(false);
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    if (props.addCrewModal) {
      getAvailableCrew();
      getAssignedCrew();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.addCrewModal]);

  const debouncedGetAvailableCrew = debounceFunction(getAvailableCrew);
  useEffect(() => {
    if (searchText.length >= 3 || searchText.length === 0) {
      debouncedGetAvailableCrew();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [searchText]);

  return (
    <Modal
      isOpen={props.addCrewModal}
      fade={false}
      toggle={props.toggleAddCrew}
      className="add-crew-modal modal-lg"
    >
      <ModalHeader toggle={props.toggleAddCrew}>Timecard Crew</ModalHeader>
      <ModalBody>
        <form className="addcrew-form">
          {modalLoader ? <Loader showComponentLoader={true} /> : null}
          <div className="row">
            <div className="col-6">
              <div className="crew-search">
                <input
                  className="crew-search-field crew-search-icon"
                  placeholder="Search"
                  onChange={(e) => handleInput(e.target.value)}
                  value={searchText}
                ></input>
                {/* eslint-disable-next-line */}
                <a
                  //  eslint-disable-next-line
                  href="javascript:void(0)"
                  className="crew-search-close"
                  onClick={() => handleInput("")}
                >
                  <Close />
                </a>
              </div>
              <p className="crew-search-results">
                Found Crew/Equipment (<b>{availableCrew?.length}</b>)
              </p>
              <ul className="available-crew-list">
                {availableCrewLoader ? (
                  <Loader showComponentLoader={true} />
                ) : null}
                {availableCrew.map((field: any) => (
                  <li
                    key={field?.crewKey + field?.crewName}
                    className="available-crew"
                    onClick={() => {
                      handleCrew(field);
                    }}
                  >
                    {/* eslint-disable-next-line */}
                    <a href="javascript:void(0)" className="crew-info">
                      {field?.equipmentAssetProjectId ? (
                        <span className="crew-employee">
                          <Equipment title={field?.crewPic} />
                        </span>
                      ) : (
                        <span className="crew-employee">
                          <Employee title={field?.crewPic} />
                        </span>
                      )}
                      <div className="crew-logs">
                        <span className="crew-name">{field?.crewName || field?._crewName}</span>
                        <span className="crew-id">ID: {field?.crewKey || field?._crewKey}</span>
                      </div>
                      <span className="crew-addplus">
                        <Button
                          onClick={() => {
                            handleCrew(field);
                          }}
                        >
                          <AddPlus />
                        </Button>
                      </span>
                    </a>
                  </li>
                ))}
                {availableCrew.length === 0 ? (
                  <li className="d-block w-100 text-center">
                    Please search a Crew or <br />
                    {/* eslint-disable-next-line */}
                    <a href="javascript:void(0)" className="temp-labour">
                      {" "}
                      Click here
                    </a>{" "}
                    to add a Temp Labour.
                  </li>
                ) : (
                  ""
                )}
              </ul>
            </div>
            <div className="col-6">
              <div className="add-templabour">
                {/* eslint-disable-next-line */}
                <a href="javascript:void(0)" className="temp-labour">
                  <TempLabour />
                  Add Temp Labour
                </a>
              </div>
              <p className="crew-search-results">
                Assigned Crew(<b>{assignedCrew.length}</b>)
              </p>
              <ul className="available-crew-list">
                {assignedCrewLoader ? (
                  <Loader showComponentLoader={true} />
                ) : null}
                {assignedCrew.map((field: any, index: number) => (
                  <li
                    key={field?.employeeId + index}
                    className="available-crew"
                  >
                    <div className="crew-info">
                      {field?.equipmentAssetProjectId ? (
                        <span className="crew-employee">
                          <Equipment title={field?.crewPic} />
                        </span>
                      ) : (
                        <span className="crew-employee">
                          <Employee title={field?.crewPic} />
                        </span>
                      )}
                      <div className="crew-logs">
                        <span className="crew-name">{field?.crewName}</span>
                        <span className="crew-id d-flex align-items-center justify-content-start">
                          ID: {field?.employeeId}
                          <small className="inc-dec-crew d-flex align-items-center justify-content-start">
                            {/* eslint-disable-next-line */}
                            <a
                              //  eslint-disable-next-line
                              href="javascript:void(0)"
                              className="inc-crew"
                              onClick={() => {
                                crewCounter("INC", index);
                              }}
                            >
                              <AddPlus />
                            </a>
                            {field?.count}
                            {/* eslint-disable-next-line */}
                            <a
                              //  eslint-disable-next-line
                              href="javascript:void(0)"
                              className="inc-crew"
                              onClick={() => {
                                crewCounter("DEC", index);
                              }}
                            >
                              <Minus />
                            </a>
                          </small>
                        </span>
                      </div>
                      {/* eslint-disable-next-line */}
                      <a
                        //  eslint-disable-next-line
                        href="javascript:void(0)"
                        className="crew-addplus"
                        onClick={() => deleteAssignedCrew(index)}
                      >
                        <TrashRed />
                      </a>
                    </div>
                  </li>
                ))}
                {assignedCrew.length === 0 ? (
                  <li className="d-block w-100 text-center">
                    Please select from Found Crew/Equipment.
                  </li>
                ) : (
                  ""
                )}
              </ul>
            </div>
          </div>
          <ModalFooter>
            <Button color="secondary" onClick={props.toggleAddCrew}>
              Cancel
            </Button>
            <Button color="primary" type="button" onClick={() => saveCrew()}>
              Save
            </Button>{" "}
          </ModalFooter>
        </form>
      </ModalBody>
    </Modal>
  );
};

export default AddCrewModal;
