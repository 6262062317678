export const rightPanelOptions = {
  isAllowanceOpened: false,
  isPayClassOpened: false,
  isHoursOpened: false,
};

export const modalDataArray = [
  {
    heading: "Are you sure want to Delete Time Card ?",
    subHeading:
      "",
    modalName: "delete",
    modalIndex: "0",
    addRemarks: false,
  },
  {
    heading: "Are you sure want to Approve Time Card ?",
    subHeading:
      "",
    modalName: "approve",
    modalIndex: "4",
    addRemarks: true,
  },
  {
    heading: "Are you sure want to Reject Time Card ?",
    subHeading:
      "",
    modalName: "reject",
    modalIndex: "3",
    addRemarks: true,
  },
  {
    heading: "Are you sure want to Submit Time Card ?",
    subHeading:
      "",
    modalName: "Submit",
    modalIndex: "2",
    addRemarks: true,
  },
  {
    heading: "Are you sure want to Reopen Time Card ?",
    subHeading:
      "",
    modalName: "reopen",
    modalIndex: "1",
    addRemarks: false,
  },
  {
    heading: "Delete Cost Code",
    subHeading:
      "You are about to delete this cost code. Continue?",
    modalName: "delete",
    modalIndex: "5",
    addRemarks: false,
  },
  {
    heading: "Delete Crew",
    subHeading:
    "You are about to delete this crew with time, payclass and allowance.",
    modalName: "delete",
    modalIndex: "6",
    addRemarks: false,
  },
  {
    heading: "Delete Cost Code",
    subHeading:
      "You have hours associated to this code. This will also delete all your hours. Continue?",
    modalName: "delete",
    modalIndex: "7",
    addRemarks: false,
  },
  {
    heading: "Delete Service Ticket",
    subHeading:
      "You have cost code associated to this ticket. This will also delete all your associated cost codes. Continue?",
    modalName: "delete",
    modalIndex: "8",
    addRemarks: false,
  },
];