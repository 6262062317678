import React, { useState, useEffect } from "react";
import Header from "../../../shared/Header/Header";
import classnames from "classnames";
import Loader from "../../../shared/Loader/Loader";
import { get, post, put, deleteAPI } from "../../../../types/api";
import moment from "moment";
import ServiceContractCreate from "./../ServiceContractCreate/ServiceContractCreate";
import axios from "axios";
import {
  Button,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  TabContent,
  TabPane,
  Nav,
  NavItem,
  NavLink,
  CustomInput
} from "reactstrap";
import { useAuthStore, useServerConstants, useServiceStore } from "../../../../store";
import { IServiceResultItem } from "../ServiceContractInterface";
import ServiceContractSetupRows from "./ServiceContractSetupRows";
import { DndProvider } from 'react-dnd'
import { HTML5Backend } from 'react-dnd-html5-backend'
import DragDropTableHeader from "../../../shared/DragDrop/DragDropTableHeader";
import { TouchBackend } from 'react-dnd-touch-backend'
import { isMobile } from "react-device-detect";
import writeXlsxFile from 'write-excel-file';
import readXlsxFile from 'read-excel-file'
import { addCommas, removeExtraField } from "../../../shared/Helpers";
import { StatusType } from "../../../shared/Constants";
import { ToastContainer, toast } from "react-toastify";
import 'react-toastify/dist/ReactToastify.css';

const isNumeric = (inputtxt: string) => {
  var numbers = /^[0-9]+$/;
  if (inputtxt.match(numbers)) {
    return true;
  } else {
    return false;
  }
};
const ServiceContractSetup = (props: any) => {
  let { contract: contractAccess } = props.access;
  const tokenDetails = useServiceStore(
    (serviceStore) => serviceStore.tokenDetails
  );
  const defaultStaticValues = {
    id: 0,
    UOM: "",
    unitCost: "",
    activity: "",
    payItem: "",
    sortOrder: 1,
    unitHours: ""
  };
  const [contractValue, setContractValue] = useState({} as IServiceResultItem);

  const { match } = props;
  const [loading, setLoading] = useState(false);
  const [files, setFiles] = useState([]);

  const [fileModal, setFileModal] = useState(false);
  const [modal, setModal] = useState(false);
  const [contractModal, setContractModal] = useState(false);
  const [keyValueModal, setKeyValueModal] = useState(false);
  const [isEditing, setIsEditing] = useState(false);
  const { status } = useServerConstants.getState();

  const [stActivityListHeaders, setStActivityListHeaders] = useState([] as any);
  const [activityListHeaders, setActivityListHeaders] = useState([] as any);
  const [stActivityListHeadersEdit, setStActivityListHeadersEdit] = useState([] as any);
  const [activityListHeadersEdit, setActivityListHeadersEdit] = useState([] as any);
  const [activityList, setActivityList] = useState([] as any);
  const [staticValues, setStaticValues] = useState(defaultStaticValues);
  const [editAmtErrorCode, setEditAmtErrorCode] = useState(false);
  const [editUnitHours, setEditUnitHours] = useState(false);
  const toggleModal = () => setModal(!modal);
  const [editContract, setEditContract] = useState(false);

  const [selectedFileData, setSelectedFileData] = useState([] as any);
  const [activeTab, setActiveTab] = useState("1");
  const [activityTab, setActivityTab] = useState("serviceContract");

  //download files
  const handleDownload = async (file: any) => {
    const url = `${process.env.REACT_APP_RISKCAST_API_URL}/api/core/aws/s3/sign/downloadfile?fileNameS3URL=${file.url}`;
    const { token } = useAuthStore.getState();
    const headers = {
      "Content-Type": "application/json",
      Authorization: `Bearer ${token}`,
    };
    // get singned url for download
    const res = await axios.get(url, { headers });

    const signedRequest = res.data.signedRequest;
    let link = document.createElement("a");
    link.href = signedRequest;
    link.setAttribute("download", file.fileName);
    link.target = "_blank";

    // Append link to the body
    document.body.appendChild(link);
    // Dispatch click event on the link
    // This is necessary as link.click() does not work on the latest firefox
    // link.dispatchEvent(
    //   new MouseEvent("click", {
    //     bubbles: true,
    //     cancelable: true,
    //     view: window,
    //   })
    // );
    link.click();
    document.body.removeChild(link);
  };

  const handleDownloadAll = () => {
    if (files) {
      files.forEach((e) => handleDownload(e));
    }
  };

  const toggle = (tab: any) => {
    if (activeTab !== tab) setActiveTab(tab);
  };

  const toggleActivity = (tab: any) => {
    if (activityTab !== tab) setActivityTab(tab);
  };

  const deleteActivity = async (id: any) => {
    setLoading(true);
    let apiURL = `/v1/api/service/contract/activity/${id}`;
    await deleteAPI(apiURL);
    setLoading(false);
    await getContract();
  };

  const resetFieldValues = () => {
    const headersData = activityListHeaders.map((e: any) => {
      return {
        ...e,
        fieldValue: "",
      };
    });
    setStaticValues(defaultStaticValues);
    setEditUnitHours(false);
    setEditAmtErrorCode(false);
    setActivityListHeaders(headersData);
    setIsEditing(false);
  };

  const handleSave = async (
    id?: any,
    staticVal?: any,
    actHeader: any = activityListHeaders
  ) => {
    if (!editAmtErrorCode && !editUnitHours) {
      await createActivity(id, staticVal, actHeader);
      await getContract();
      resetFieldValues();
      setEditAmtErrorCode(false);
      setEditUnitHours(false)
    } else {
      setEditAmtErrorCode(true);
      setEditUnitHours(true)
    }
  };

  // @ts-ignore
  useEffect(() => {
    getContract();
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  const getContract = async () => {
    setLoading(true);
    setContractModal(false);
    let apiURL = `/v1/api/service/contract/id/${match.params.id}/setup`;
    const res = await get(apiURL);
    setContractValue(res.data);
    setActivityListHeaders(res.data.activityListHeaders);
    setStActivityListHeaders(res.data.stActivityListHeaders);
    setActivityList(res.data.activityList);
    setFiles(res.data.files || []);
    setLoading(false);
  };

  const createActivity = async (id?: any, staticVal?: any, actHeader?: any) => {
    try {
      setLoading(true);
      const dynamicFieldsData = [] as any;
      [...(actHeader ? actHeader : activityListHeaders)].forEach((e: any) => {
        if(e.fieldValue) {
          dynamicFieldsData.push({
          serviceItemFieldValue: e.fieldValue,
          customFieldIndex: e.customFieldIndex,
          });
        };
      });
  
      const data = {
        tenantId: tokenDetails?.tenantId,
        serviceContractId: match.params.id,
        ...staticVal,
        fields: dynamicFieldsData,
      };
  
      if (!id) {
        data["sortOrder"] = 1 + activityList.length;
      }
      let apiURL = `/v1/api/service/contract/activity`;
  
      const res = id ? await put(apiURL, data) : await post(apiURL, data);
  
      if (res.status && res.status === 200) {
        toast.success("Successfully activity created", {
          autoClose: 2000,
        });
      } else {
        toast.warn("Something went wrong", {
          autoClose: 2000,
        });
      }
    } catch(error : any) {
      if (error.response && error.response.data) {
        toast.warn(error.response.data.error.message, {
          autoClose: 5000
        });
      }
    } finally {
      setLoading(false);
    }
  };

  const toggleEditContract = () =>
    setContractModal(() => {
      return !contractModal;
    });

  const toggleKeyValue = () =>
    setKeyValueModal(() => {
      return !keyValueModal;
    });

  const addActivityHTML = (
    <tr>
      <td>
        <input
          placeholder="Enter Pay Item"
          value={staticValues.payItem}
          onChange={(e) => {
            setStaticValues({
              ...staticValues,
              payItem: e.target.value,
            });
          }}
        />
        <div className="errorBox"></div>
      </td>
      <td>
        <input
          placeholder="Enter Activity"
          value={staticValues.activity}
          onChange={(e) => {
            setStaticValues({
              ...staticValues,
              activity: e.target.value,
            });
          }}
        />
        <div className="errorBox"></div>
      </td>
      <td>
        <input
          placeholder="Enter UOM"
          value={staticValues.UOM}
          onChange={(e) => {
            setStaticValues({
              ...staticValues,
              UOM: e.target.value,
            });
          }}
        />
        <div className="errorBox"></div>
      </td>
      {activityListHeaders.map((field: any, index:number) => { 
        if(field.isActive === 1) {
          return (
            <td key={index + field.fieldName}>
              <input
                placeholder={`Enter ${field.fieldName}`}
                value={field.fieldValue || ""}
                onChange={(e) => {
                  const res = activityListHeaders.map(
                    (value: { fieldId: any; fieldValue: string }) => {
                      if (value.fieldId === field.fieldId) {
                        value["fieldValue"] = e.target.value;
                      }
                      return value;
                    }
                  );
                  setActivityListHeaders(res);
                }}
              />
              <div className="errorBox"></div>
            </td>
          )
        }
        return false;
      })}
      
      <td>
        <input
          placeholder="Enter Unit Hours"
          value={staticValues.unitHours}
          onChange={(e) => {
            if (isNumeric(e.target.value) || e.target.value === '') {
              setEditUnitHours(false);
            } else {
              setEditUnitHours(true);
            }
            setStaticValues({
              ...staticValues,
              unitHours: e.target.value,
            });
          }}
        />
        <div className="errorBox">
          {editUnitHours ? (
            <span className="text-danger help-block">
              Please Enter Numeric Value
            </span>
          ) : null}
        </div>
      </td>
      <td>
        <input
          placeholder="Enter Unit Amount"
          value={staticValues.unitCost}
          onChange={(e) => {
            if (isNumeric(e.target.value) || e.target.value === '') {
              setEditAmtErrorCode(false);
            } else {
              setEditAmtErrorCode(true);
            }
            setStaticValues({
              ...staticValues,
              unitCost: e.target.value,
            });
          }}
        />
        <div className="errorBox">
          {editAmtErrorCode ? (
            <span className="text-danger help-block">
              Please Enter Numeric Value
            </span>
          ) : null}
        </div>
      </td>
      <td>
        <div className="action-wrapper">
          <button
            type="button"
            disabled={!contractAccess.update}
            onClick={() => {
              const id = isEditing ? staticValues.id : 0;
              handleSave(id, staticValues);
            }}
            className="btn btn-primary"
          >
            {isEditing ? "Update" : "Save"}
          </button>
          <button
            type="button"
            onClick={resetFieldValues}
            className="btn btn-secondary"
          >
            Cancel
          </button>
        </div>
        <div className="errorBox"></div>
      </td>
    </tr>
  );

  const handleAssignedFields = async (dragIndex: number, hoverIndex: number, belongsTo = 'SC') => {
    try {
      setLoading(true);
      let updatedOrderData = [] as any;
      if(belongsTo === 'ST') {
        updatedOrderData = updateFieldsData(dragIndex, hoverIndex, contractValue.stActivityListHeaders)
      } else {
        updatedOrderData = updateFieldsData(dragIndex, hoverIndex, contractValue.activityListHeaders)
      }
      updateAssignedFieldsAPI(updatedOrderData, belongsTo);
    } catch (error) {
      setLoading(false);
    }
  };

  const updateFieldsData = (dragIndex: number, hoverIndex: number, header: any) => {
    let updatedOrderData = [] as any;
    if(header && header.length) {
      updatedOrderData = [...header];
      updatedOrderData.splice(dragIndex, 1);
      updatedOrderData.splice(hoverIndex, 0, header[dragIndex]);
      updatedOrderData.forEach((element:any, index:number) => {
        element.order = index;
      });
    }
    return updatedOrderData;
  }

  const updateFieldsStatus = async () => {
    try {
      setLoading(true);
      const belongsTo = activityTab === 'serviceContract' ? 'SC' : 'STI';
      let updatedOrderData = [] as any;
      if(belongsTo === 'SC' && activityListHeadersEdit?.length) {
        updatedOrderData = [...activityListHeadersEdit];
      } else if(belongsTo === 'STI' && activityListHeadersEdit?.length){
        updatedOrderData = [...stActivityListHeadersEdit];
      }
      updateAssignedFieldsAPI(updatedOrderData, belongsTo);
    } catch (error) {
      setLoading(false);
      toggleKeyValue();
    }
  };

  const updateAssignedFieldsAPI = async (updatedOrderData: any, belongsTo: string) => {
    const payload = {
      serviceContractId : match.params.id,
      belongsTo,
      assignedFields : [...removeExtraField(updatedOrderData, ['belongsTo', 'customFieldIndex', 'fieldValue'])],
    }
    // @ts-ignore
    const res = await put(
      `/v1/api/service/contract/assignedFields`,
      payload
    );
    if(res) {
      getContract();
      toggleKeyValue();
    }
  }

  const downloadTemplate = async () => {
    const dynamicHeaders = [] as any;
    activityListHeaders.forEach((e: any, index: number) => { 
      if (e.isActive === 1 ) {
        dynamicHeaders.push(e.fieldName);
      } 
    });

    let HEADER_ROW = ['Pay Item', 'Activity', 'UOM',
      ...dynamicHeaders, 'Unit Hours', 'Unit Amount'
    ];
    HEADER_ROW = HEADER_ROW.map(e => ({
        value: e,
        fontWeight: 'bold'
    }));

    await writeXlsxFile([HEADER_ROW], {
      fileName: 'file.xlsx'
    })
  }

  const uploadActivityFile = async (event: any) => {
    if(event.target.files[0]) {
      readXlsxFile(event.target.files[0]).then((rows) => {
        if(rows.length < 2) {
          errorAlert("File is empty.")
        } else {
          const data = rows.filter(row => row.filter(column => column !== null).length > 0);
          setSelectedFileData(data);
        }
      }).catch(e => {
        errorAlert("Found error in file upload. Please validate file type.")
      })
    }
  }

  const handleUploadFile = async () => {
    try {
      setLoading(true);
      const dynamicHeaders = [] as any;
      activityListHeaders.forEach((e: any) => { 
        if (e.isActive === 1 ) {
          dynamicHeaders.push(e);
        } 
      });
      //validate headers
      const isValid = validateFileHeader(dynamicHeaders);
      if(isValid) {
        const activities = [] as any;
        let isUnitCostAndHourValid = true;
        let sortOrderCounter = activityList.length + 1;

        for (let index = 0; index < selectedFileData.length; index++) {
          const row = selectedFileData[index];
          
          if (index > 0 && row[0]) {
            const dynamicHeadersData = row.slice(3, row.length - 2);
            const dynamicItemsData = [] as any;
            
            dynamicHeaders.forEach((e: any, index: number) => { 
              dynamicItemsData.push({ 
                serviceItemFieldValue: String(dynamicHeadersData[index]),
                customFieldIndex: e.customFieldIndex,
              });
            });

            const data = {
              tenantId: tokenDetails?.tenantId,
              serviceContractId: match.params.id,
              id: 0,
              payItem: String(row[0]),
              activity: String(row[1]),
              UOM: String(row[2]),
              sortOrder: sortOrderCounter,
              unitHours: Number(row[row.length - 2]),
              unitCost: Number(row[row.length - 1]),
              fields: dynamicItemsData,
            };

            if (isNaN(data.unitHours) && isNaN(data.unitCost)) {
              isUnitCostAndHourValid = false;
              errorAlert('Unit hours and unit cost must be number.');
              break;
            } else if (isNaN(data.unitHours)) {
              isUnitCostAndHourValid = false;
              errorAlert('Unit hours must be number.');
              break;
            } else if (isNaN(data.unitCost)) {
              isUnitCostAndHourValid = false;
              errorAlert('Unit cost must be number.');
              break;
            }

            activities.push(data);
            sortOrderCounter++;
          }
        }

        if(isUnitCostAndHourValid) {
          const apiURL = `/v1/api/service/contract/activities`;
          await post(apiURL, {activities}).then(async (res) => {
            if (res.status && res.status === 200) {
              const dupEntries = res.data.activities.filter((e: any) => e.success === false);
              if(dupEntries && dupEntries.length) {
                let msg = `Found ${dupEntries.length} duplicate `;
                if(dupEntries.length === 1) {
                  msg += 'activity.'
                } else {
                  msg += 'activities.'
                }
                if(res.data.activities.length !== dupEntries.length) {
                  msg += ' Other activities are created.';
                  await getContract();
                } 
                errorAlert(msg)
              } else {
                setFileModal(!fileModal)
                toast.success("Successfully activities created", {
                  autoClose: 2000,
                });
                await getContract();
              }
            } else {
              errorAlert()
            }
          }).catch(error => {
            errorAlert(JSON.stringify(error.response.data.message[0].message || error.message))
          });
        }
      }
    } catch (error:any) {
      console.log('error in file upload: ', error)
    } finally {
      setLoading(false);
    }
  }

  const validateFileHeader = (dynamicHeaders: any) => {
    const staticHeaders = 5;
    if((dynamicHeaders.length + staticHeaders) === selectedFileData[0].length){
      return true;
    }
    errorAlert('File headers are not valid. Please download below template and validate')
    return false;
  }

  const errorAlert = (msg = "Something went wrong", autoClose = 5000 as any) => {
    toast.warn(msg, {
      autoClose,
    });
  }

  const renderTh = (field: any, index: number) => {
    return (
      <DragDropTableHeader
        key={index}
        field={field}
        index={index}
        handleAssignedFields={handleAssignedFields}
        children={field.fieldName}
      />
    );
  };

  const renderActivityTr = (field: any, index: number, attributes: object, belongsTo: string, children: any) => {
    return (
      <DragDropTableHeader
        key={index}
        field={field}
        index={index}
        handleAssignedFields={(dragIndex: number, hoverIndex: number) => handleAssignedFields(dragIndex, hoverIndex, belongsTo)}
        attributes={attributes}
        children={children}
        tableSection="tr"
      />
    );
  };

  const displayContractField = (label: any, value: any) => {
    return (
      <div className="col-3">
        <div className="text-label-value">
          <div className="text-label">{label}: </div>
          <div className="text-value">
            {value}
          </div>
        </div>
      </div>
    )
  }

  return (
    <div className="contract-setup-wrapper">
      {loading && <Loader></Loader>}
      <ToastContainer
        position="top-right"
        autoClose={5000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
      />
      <Header
        breadcrumb={"Service Contract Setup"}
        title={"Service Contract Setup"}
      />
      <Nav tabs>
        <NavItem>
          <NavLink
            className={classnames({ active: activeTab === "1" })}
            disabled
            onClick={() => {
              toggle("1");
            }}
          >
            Service Contract
          </NavLink>
        </NavItem>
      </Nav>
      <TabContent activeTab={activeTab}>
        <TabPane tabId="1">
          <div className="ticket-info-wrapper">
            <div className="ticket-information">
              <div className="row">
                <div className="col-12">
                  <div className="ticket-info-header">
                    <div className="ticket-info-title">
                      <h2 className="page-title-sub">
                        Service Contract {contractValue.serviceContractDisplayNumber ? " : " + contractValue.serviceContractDisplayNumber : ''}
                      </h2>
                    </div>
                    <div className="ticket-info-actions">
                      <button
                        type="button"
                        className="btn btn-secondary mr-3"
                        onClick={() => {
                          setActivityListHeadersEdit(JSON.parse(JSON.stringify(activityListHeaders)));
                          toggleKeyValue()
                        }}
                        >
                        {/* disabled={!ticketAccess.update} */}
                          settings
                      </button>
                      <button
                        type="button"
                        className="btn btn-secondary"
                        // disabled={!ticketAccess.update}
                        onClick={(e) => {
                          setEditContract(true);
                          toggleEditContract();
                        }}
                      >
                        Edit
                      </button>
                    </div>
                  </div>
                  <div className="ticket-info-list">
                    <div className="row">
                      <div className="col-3">
                        <div className="text-label-value">
                          <div className="text-label">Job Number: </div>
                          <div className="text-value">
                            {contractValue.projectId}
                          </div>
                        </div>
                      </div>
                      <div className="col-3">
                        <div className="text-label-value">
                          <div className="text-label">Worksite Location: </div>
                          <div className="text-value">
                            {contractValue.workLocationName}
                          </div>
                        </div>
                      </div>
                      <div className="col-3">
                        <div className="text-label-value">
                          <div className="text-label">Contract Date: </div>
                          <div className="text-value">
                            {moment
                              .utc(contractValue.contractDate)
                              .format("MM/DD/YYYY")}
                          </div>
                        </div>
                      </div>
                      <div className="col-3">
                        <div className="text-label-value">
                          <div className="text-label">Contract Start Date: </div>
                          <div className="text-value">
                            {moment
                              .utc(contractValue.startDate)
                              .format("MM/DD/YYYY")}
                          </div>
                        </div>
                      </div>
                      <div className="col-3">
                        <div className="text-label-value">
                          <div className="text-label">Contract End Date: </div>
                          <div className="text-value">
                            {moment
                              .utc(contractValue.endDate)
                              .format("MM/DD/YYYY")}
                          </div>
                        </div>
                      </div>
                      <div className="col-3">
                        <div className="text-label-value">
                          <div className="text-label">Contract Value: </div>
                          <div className="text-value">
                            ${addCommas(contractValue.contractValue)}
                          </div>
                        </div>
                      </div>
                      <div className="col-3">
                        <div className="text-label-value">
                          <div className="text-label">Status: </div>
                          <div className="text-value">
                            <span className="setup-label label-open">
                              {StatusType[status[contractValue.status]] || status[contractValue.status]}{" "}
                            </span>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="row">
                      <div className="col-12">External Contact </div>
                      {displayContractField('Company', contractValue.externalContactCompany)}
                      {displayContractField('Name', contractValue.externalContactName)}
                      {displayContractField('Title', contractValue.externalContactTitle)}
                      {displayContractField('Email', contractValue.externalContactEmail)}
                      {displayContractField('Phone', contractValue.externalContactPhone)}
                    </div>
                    <div className="row">
                      <div className="col-12">Internal Contact</div>
                      {displayContractField('Name', contractValue.internalContactName)}
                      {displayContractField('Title', contractValue.internalContactTitle)}
                      {displayContractField('Email', contractValue.internalContactEmail)}
                      {displayContractField('Phone', contractValue.internalContactPhone)}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div className="st-list-wrapper">
            <div className="st-list-heading d-flex align-items-center justify-content-between">
              <h3 className="heading-sub">
                Activity List on: <b>01/03/2022</b>
                <span className="heading-sub-entries">
                  {/* Showing entries: <b>06 of 200</b> */}
                </span>
              </h3>
              <div className="st-list-btns">
                {/* <button className="btn btn-primary">
                  <span className="download-docs"></span>
                  Add Activity
                </button> */}
                <button
                  className="btn btn-primary"
                  disabled={!contractAccess.update}
                  onClick={() => setFileModal(!fileModal)}
                >
                  <span className=""></span>
                  Upload Activity Data
                </button>
                <button
                  className="btn btn-primary"
                  disabled={!contractAccess.read}
                  onClick={toggleModal}
                >
                  <span className="download-docs"></span>
                  Download Documents
                </button>
              </div>
            </div>
            <div className="st-list-content">
              {/* <div className="nodata-wrapper">
                                <p>Please Add/Create Service Ticket</p>
                            </div> */}
              <div className="r-table-wrapper">
                <DndProvider backend={isMobile ? TouchBackend : HTML5Backend}>
                  <table className="table">
                    <thead>
                      <tr>
                        <th>Pay Item</th>
                        <th>Activity</th>
                        <th>UOM</th>
                        {activityListHeaders.map((e: any, index: number) => { 
                          return (e.isActive === 1 ? (
                            renderTh(e, index)
                          ) : false) 
                        })}
                        <th>Unit Hours</th>
                        <th>Unit Amount</th>
                        <th>Action</th>
                      </tr>
                    </thead>
                    <tbody>
                      {activityList.map((activity: any, index:number) => (
                        <ServiceContractSetupRows
                          access={contractAccess}
                          deleteActivity={deleteActivity}
                          activityList={activityList}
                          setActivityList={setActivityList}
                          activity={activity}
                          activityListHeaders={activityListHeaders}
                          handleSave={handleSave}
                          key={index}
                        />
                      ))}
                      {!isEditing && contractAccess.update && addActivityHTML}
                    </tbody>
                  </table>
                </DndProvider>
              </div>
              <div className="table-footer-content"></div>
            </div>
          </div>
          {contractModal ? (
            <ServiceContractCreate
              toggle={toggleEditContract}
              modal={contractModal}
              setModal={getContract}
              editContract={editContract}
              contractValue={contractValue}
            />
          ) : (
            ""
          )}
          {/* Download Documents */}
          <Modal
            isOpen={modal}
            fade={false}
            toggle={toggleModal}
            className="download-docs-modal modal-md"
          >
            <ModalHeader toggle={toggleModal}>Download Documents</ModalHeader>
            <ModalBody>
              <div className="r-table-wrapper">
                <table className="table">
                  <thead>
                    <tr>
                      {/* commented - don't require checkbox as of now for download*/}
                      {/* <th className="ticket-checkbox">
                        <div className="form-group">
                          <div className="custom-control custom-checkbox">
                            <input
                              type="checkbox"
                              className="custom-control-input"
                              name="ticketHeading"
                              id="ticketHeading"
                            />
                            <label
                              className="custom-control-label"
                              htmlFor="ticketHeading"
                            ></label>
                          </div>
                        </div>
                      </th> */}
                      <th>Name</th>
                      <th>Action</th>
                    </tr>
                  </thead>
                  <tbody>
                    {files.map((file: any, index: number) => {
                      return (
                        <tr key={index}>
                          {/* commented - don't require checkbox as of now for download */}
                          {/* <td className="ticket-checkbox">
                            <div className="form-group">
                              <div className="custom-control custom-checkbox">
                                <input
                                  type="checkbox"
                                  className="custom-control-input"
                                  name="ticketRow1"
                                  id="ticketRow1"
                                />
                                <label
                                  className="custom-control-label"
                                  htmlFor="ticketRow1"
                                ></label>
                              </div>
                            </div>
                          </td> */}
                          <td>{file.fileName}</td>
                          <td>
                            <div className="action-wrapper">
                              <button
                                className="btn btn-primary"
                                onClick={() => handleDownload(file)}
                                type="button"
                              >
                                Download
                              </button>
                            </div>
                          </td>
                        </tr>
                      );
                    })}
                  </tbody>
                </table>
              </div>
              <ModalFooter>
                <Button color="secondary" onClick={toggleModal}>
                  Cancel
                </Button>
                <Button color="primary" onClick={() => handleDownloadAll()}>
                  Download All
                </Button>{" "}
              </ModalFooter>
            </ModalBody>
          </Modal>

          {/* Upload Activity file */}
          <Modal
            isOpen={fileModal}
            fade={false}
            toggle={() => { 
              setFileModal(!fileModal)
              }}
            className="download-docs-modal modal-md"
          >
            <ModalHeader toggle={() => { 
              setFileModal(!fileModal)
              }}>Upload Activity Excel File</ModalHeader>
            <ModalBody>
              {loading && <Loader></Loader>}
              <div className="custom-control">
                <Button color="secondary" onClick={() => downloadTemplate()}>
                  Download Template File 
                </Button>
              </div>
              <div className="custom-control">
                <CustomInput 
                  type="file"
                  id="input"
                  name="customFile"
                  label={'Choose an excel file'}
                  onChange={uploadActivityFile} />
              </div>
              <ModalFooter>
                <Button color="secondary" onClick={() => { 
                  setFileModal(!fileModal)
                }}>
                  Cancel
                </Button>
                <Button color="primary" onClick={() => handleUploadFile()}>
                  Upload
                </Button>{" "}
              </ModalFooter>
            </ModalBody>
          </Modal>

          {/* Activity Enable/Disable */}
          <Modal
            isOpen={keyValueModal}
            fade={false}
            toggle={toggleKeyValue}
            className="activity-toggle-wrapper modal-md"
          >
            <ModalHeader toggle={toggleKeyValue}>Activity Enable/Disable</ModalHeader>
            <ModalBody>
              <Nav tabs>
                <NavItem>
                  <NavLink
                    className={classnames({ active: activityTab === "serviceContract" })}
                    onClick={() => {
                      toggleActivity("serviceContract");
                    }}
                  >
                    Service Contract
                  </NavLink>
                </NavItem>
                <NavItem>
                  <NavLink
                    className={classnames({ active: activityTab === "serviceTicket" })}
                    onClick={() => {
                      if(stActivityListHeaders && stActivityListHeaders.length) {
                        setStActivityListHeadersEdit(JSON.parse(JSON.stringify(stActivityListHeaders)));
                      }
                      toggleActivity("serviceTicket");
                    }}
                  >
                    Service Ticket
                  </NavLink>
                </NavItem>
              </Nav>
              <TabContent activeTab={activityTab}>
                <TabPane tabId="serviceContract">
                  <div className="r-table-wrapper">
                    <table className="table">
                      <thead>
                        <tr>
                          <th>Name</th>
                          <th>Action</th>
                        </tr>
                      </thead>
                      <tbody>
                        {loading && <Loader></Loader>}
                        <DndProvider backend={isMobile ? TouchBackend : HTML5Backend}>
                          {activityListHeadersEdit.map((field: any, index: number) => (
                            renderActivityTr(field, index, {}, 'SC', <>
                              <td>{field.fieldName}</td>
                              <td className="toggle-td">
                                <div className="activity-toggle-wrapper">
                                  <input type="checkbox" checked={!(field.isActive === 1 ? true : false)} className="activity-checkbox" onChange={() => {
                                    const data = [...activityListHeadersEdit];
                                    data[index]['isActive'] = data[index]['isActive'] === 1 ? 0 : 1;
                                    setActivityListHeadersEdit([...data])
                                  }}/>
                                  <div className="knobs"></div>
                                </div>
                              </td>
                            </>)
                          ))}
                        </DndProvider>
                      </tbody>
                    </table>
                  </div>
                </TabPane>
                <TabPane tabId="serviceTicket">
                  <div className="r-table-wrapper">
                    <table className="table">
                      <thead>
                        <tr>
                          <th>Name</th>
                          <th>Action</th>
                        </tr>
                      </thead>
                      <tbody>
                        {loading && <Loader></Loader>}
                        <DndProvider backend={isMobile ? TouchBackend : HTML5Backend}>
                          {stActivityListHeadersEdit.map((field: any, index: number) => (
                            renderActivityTr(field, index, {}, 'ST', <>
                              <td>{field.fieldName}</td>
                              <td className="toggle-td">
                                <div className="activity-toggle-wrapper">
                                  <input type="checkbox" checked={!(field.isActive === 1 ? true : false)} className="activity-checkbox" onChange={() => {
                                    const data = [...stActivityListHeadersEdit];
                                    data[index]['isActive'] = data[index]['isActive'] === 1 ? 0 : 1;
                                    setStActivityListHeadersEdit([...data])
                                  }}/>
                                  <div className="knobs"></div>
                                </div>
                              </td>
                            </>)
                          ))}
                        </DndProvider>
                      </tbody>
                    </table>
                  </div>
                </TabPane>
              </TabContent>
              <ModalFooter>
                <Button color="secondary" onClick={toggleKeyValue}>
                  Cancel
                </Button>
                <Button color="primary" onClick={() => {
                  updateFieldsStatus();
                }}>
                  Save
                </Button>{" "}
              </ModalFooter>
            </ModalBody>
          </Modal>
        </TabPane>
      </TabContent>
    </div>
  );
};

export default ServiceContractSetup;
