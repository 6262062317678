import React, { useEffect, useState } from "react";
import {
  Button,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  CustomInput,
  Alert,
} from "reactstrap";
import Loader from "../../shared/Loader/Loader";
import writeXlsxFile from "write-excel-file";
import readXlsxFile from "read-excel-file";
import { ToastContainer, toast } from "react-toastify";
import { get, post } from "../../../types/api";
import { useServiceStore } from "../../../store";

type UploadActivityModalProps = {
  isOpen: any;
  toggle: any;
  selectedContract: any;
};
function UploadServiceTicketModal({
  isOpen,
  toggle,
  selectedContract,
}: UploadActivityModalProps) {
  const [loading, setLoading] = useState(false);
  const [selectedFileData, setSelectedFileData] = useState([] as any);
  const [pageLoader, setPageLoader] = useState(false);
  const [selectedFileHeaders, setSelectedFileHeaders] = useState([] as any);
  const [contractHeaders, setContractHeaders] = useState([] as any);
  const [errorMessages, setErrorMessages] = useState([] as any);
  const tokenDetails = useServiceStore(
    (serviceStore) => serviceStore.tokenDetails
  );

  const getServiceTicketsHeaders = async (serviceContractId: any) => {
    try {
      let url = `/v1/api/service/ticket/upload/template-headers?serviceContractId=${serviceContractId}`;
      setPageLoader(true);
      const res = await get(url);
      setPageLoader(false);
      setContractHeaders(res.data);
    } catch (error) {
      setPageLoader(false);
    }
  };

  useEffect(() => {
    const fetchServiceTickets = async () => {
      if (selectedContract) {
        try {
          await getServiceTicketsHeaders(selectedContract.id);
        } catch (error) {
          console.error("Failed to fetch service tickets headers:", error);
        }
      }
    };

    fetchServiceTickets();
  }, [selectedContract]);

  // Download excel file template
  const downloadTemplate = async () => {
    setLoading(true);
    setLoading(false);

    // validation
    const allLabels = [
      ...contractHeaders.nativeFields.map((field: any) => field.label),
      ...contractHeaders.customFields.map((field : any) => field.label),
    ];
    let HEADER_ROW = [] as any;
    HEADER_ROW.push(...allLabels);
    HEADER_ROW = HEADER_ROW.map((e: any) => ({
      value: e,
      fontWeight: "bold",
    }));
    let fileName = "Service_Tickets.xlsx";
    await writeXlsxFile([HEADER_ROW], {
      fileName,
    });
  };

  function createObjectFromData(
    dataRow: any,
    nativeFields: any,
    customFields: any
  ) {
    const obj = {
      id: 0,
      tenantId: tokenDetails?.tenantId,
      serviceContractId: selectedContract.id,
      serviceTicketName: "service ticket",
    };
    const nativeFieldMapping = {
      "Work Order": "workOrderNumber",
      "Pole Number": "poleNumber",
      "PO Number": "poNumber",
      "Address / Cross Street": "addressCrossStreet",
      "Town": "city",
      "State": "state",
      "Ticket Status": "ticketStatus",
      "Additional Info": "additionalInfo",
    };

    if(nativeFields.length > 0){
        nativeFields.forEach((field: any, index: any) => {
            //@ts-ignore
            const propertyName = nativeFieldMapping[field.label];
            //@ts-ignore
            obj[propertyName] = dataRow[index];
        });
    }

    //@ts-ignore
    obj.values_Headers_Custom_ServiceTicket = customFields.map(
      (customField: any, index: any) => {
        const customFieldValue = dataRow[nativeFields.length + index];
        return {
          id: 0,
          serviceContractFieldId: customField.id,
          fieldValue: customFieldValue,
        };
      }
    );

    return obj;
  }

  // Prepare payload and upload file data
  const handleUploadFile = async () => {
    try {
      setLoading(true);

      // validation
      const allLabels = [
        ...contractHeaders.nativeFields.map((field: any) => field.label),
        ...contractHeaders.customFields.map((field: any) => field.label),
      ];

      //validate headers
      const isValid = validateFileHeader(allLabels);
      if (isValid) {
        selectedFileData.shift();

        const result = selectedFileData.map((dataRow: any) =>
          createObjectFromData(
            dataRow,
            contractHeaders.nativeFields,
            contractHeaders.customFields
          )
        );

        // create ticket activity
        const apiURL = `/v1/api/service/ticket/upload`;
        // API call
        await post(apiURL, result)
          .then(async (res: any) => {
            if (res.data.success) {
                setErrorMessages([]);
                toggle(true);
                toast.success("Successfully service ticket updated", {
                  autoClose: 2000,
                });
            } else {
              setErrorMessages(res.data.errors);
            }
            setPageLoader(false);
          })
          .catch((error) => {
            setErrorMessages([]);
            setPageLoader(false);
            errorAlert();
          });
      }
    } catch (error: any) {
      console.log("error in file upload: ", error);
    } finally {
      setLoading(false);
    }
  };

  const errorAlert = (
    msg = "Something went wrong",
    autoClose = 5000 as any
  ) => {
    const config = {
      autoClose,
    } as any;
    toast.warn(msg, config);
  };

  // file selected for upload
  const uploadActivityFile = async (event: any) => {
    if (event.target.files[0]) {
      readXlsxFile(event.target.files[0])
        .then((rows) => {
          if (rows.length < 2) {
            errorAlert("File is empty.");
          } else {
            setSelectedFileHeaders(rows[0]);
            const data = rows.filter(
              (row) => row.filter((column) => column !== null).length > 0
            );
            setSelectedFileData(data);
          }
        })
        .catch((e) => {
          errorAlert("Found error in file upload. Please validate file type.");
        });
    }
  };

  const areArraysEqual = (arr1: any, arr2: any) => {
    // Check if the lengths are the same
    if (arr1.length !== arr2.length) {
      return false;
    }

    // Check if all elements are in the same order
    for (let i = 0; i < arr1.length; i++) {
      if (arr1[i] !== arr2[i]) {
        return false;
      }
    }

    return true;
  };

  // Validated uploaded file headers
  const validateFileHeader = (dynamicHeaders: any) => {
    if (!areArraysEqual(dynamicHeaders, selectedFileHeaders)) {
      errorAlert(
        "File headers are not valid. Please download below template and validate"
      );
      return false;
    } else {
      return true;
    }
  };

  return (
    <>
      <Modal
        isOpen={isOpen}
        fade={false}
        toggle={() => toggle(false)}
        className="download-docs-modal modal-md"
      >
        <ToastContainer
          position="top-right"
          autoClose={5000}
          hideProgressBar={false}
          newestOnTop={false}
          closeOnClick
          rtl={false}
          pauseOnFocusLoss
          draggable
          pauseOnHover
        />
        <ModalHeader
          toggle={() => {
            toggle(false);
          }}
        >
          Upload Service Ticket File
        </ModalHeader>
        <ModalBody>
          {(loading || pageLoader) && <Loader />}
          <div className="custom-control">
            <Button color="secondary" onClick={() => downloadTemplate()}>
              Download Service Ticket Template
            </Button>
          </div>
          <div className="custom-control">
            <CustomInput
              type="file"
              id="input"
              name="customFile"
              label={"Choose an excel file"}
              onChange={uploadActivityFile}
            />
          </div>
          {errorMessages.length > 0 && <Alert color="danger">
            Error Messages:
            <ul>
              {errorMessages.map((x: any, index: any) => (
                <li key={index}>{x.error_message}</li>
              ))}
            </ul>
          </Alert>}
          <ModalFooter>
            <Button color="secondary" onClick={() => toggle(false)}>
              Cancel
            </Button>
            <Button color="primary" onClick={() => handleUploadFile()}>
              Upload
            </Button>{" "}
          </ModalFooter>
        </ModalBody>
      </Modal>
    </>
  );
}

export default UploadServiceTicketModal;
