import type { FC } from "react";
import { useDrag } from "react-dnd";

export const ServiceContractCreateTicketFields: FC<any> =
  function ServiceContractCreateTicketFields({ field, type }) {
    const [{ opacity }, drag] = useDrag(
      () => ({
        type,
        item: { field: field },
        collect: (monitor) => ({
          opacity: monitor.isDragging() ? 0.4 : 1,
        }),
      }),
      [field, type]
    );

    return (
      <div
        className={field.label ? "add-tag-item" : "add-tag-header"}
        ref={drag}
        style={{ opacity }}
        data-testid={`box`}
      >
        {field.name || field.label || field.fieldName}
      </div>
    );
  };
