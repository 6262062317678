import React, { useState } from "react";
import Header from "../../shared/Header/Header";
import { ReactComponent as Close } from "../../../assets/images/close.svg";
import { Button, Modal, ModalHeader, ModalBody, ModalFooter } from "reactstrap";
import classnames from "classnames";
import HistoryTab from "../ReviewTimecard/HistoryTab";
import StatusTab from "../ReviewTimecard/StatusTab";
import AllowanceTab from "../ReviewTimecard/AllowanceTab";
import CrewCertificatesTab from "../ReviewTimecard/CrewCertificatesTab";
import { get, post } from "../../../../src/types/api";
import { useServiceStore } from "../../../store";

export default function ReviewTimecard(props: any) {
  const companyId = useServiceStore(serviceStore => serviceStore.companyId);
  const [activeTab, setActiveTab] = useState("2");
  const [statusData, setStatusData] = useState([] as any)
  const [allowanceData, setAllowanceData] = useState([] as any)
  const [crewCertificateData, setCrewCertificateData] = useState([] as any)
  const [compLoader, setCompLoader] = useState(false);
  const [crewIds, setCrewIds] = useState([] as any)
  const [modalData, setModalData] = useState([] as any);
  const [colcerts, setColCerts] = useState([] as any);
  let dat = [] as any
  const { match } = props;
  let timecardId = match.params.tid;
  const toggle = (tab: any) => {
    if (activeTab !== tab) setActiveTab(tab);
  };

  const [crewInfoModal, setCrewInfoModal] = useState(false);
  const [historyData, setHistoryData] = useState([] as any);
  const [certInfoData, setCertInfoData] = useState([] as any);
  const toggleCrewInfo = () => {setCrewInfoModal(!crewInfoModal); setModalData([])};

  /** API : Get Status Data */
  const getStatusData = async () => {
    try {
      setCompLoader(true)
      let url = `/v1/api/time-card/review/notes?timeCardId=${timecardId}`
      const { data } = await get(url)
      if (data) {
        setCompLoader(false)
        setStatusData(data.data.notes || []);
      }
    }
    catch (e) {
      setCompLoader(false)
    }
  }




  /**API : Get History Data */
  const getHistoryData = async () => {
    try {
      setCompLoader(true)
      let url = `/v1/api/time-card/review/activities?timeCardId=${timecardId}`
      const { data } = await get(url)
      if (data) {
        setCompLoader(false)
        setHistoryData(data.data.activities);
      }
    }
    catch (e) {
      setCompLoader(false);
    }
  }

  /**API : Get Allowance Data */
  const getAllowanceData = async (tid: any) => {
    setCompLoader(true)
    try {
      let url = `/v1/api/time-card/review/crew-allowances?timeCardId=${tid}&all=0`
      const { data } = await get(url)
      if (data) {
        setCompLoader(false)
        // const res = {

        //   "allowances": [{
        //     "id": 149455,
        //     "timeCardCrewId": 1373431,
        //     "allowanceTypeId": 7,
        //     "allowanceAmount": 0,
        //     "notes": "NA",
        //     "personPic": "https://s3.amazonaws.com/riskcast-useast01/img/EJc4ca4238/personprofileimg-person-id-3530-87db2832-67a5-31f6-aac2-18fd19d4e7da.png",
        //     "lastName": "AMADO",
        //     "firstName": "ANTHONY B",
        //     "allowanceTypeCode": "PD",
        //     "allowanceTypeName": "Per Diem",
        //     "allowanceTypeColor": "#314860",
        //     "calcTypeLabel": "Lump Sum",
        //     "isTaxableLabel": "N"
        //   },
        //   {
        //     "id": 149456,
        //     "timeCardCrewId": 1373431,
        //     "allowanceTypeId": 7,
        //     "allowanceAmount": 2,
        //     "notes": "NA",
        //     "personPic": "https://s3.amazonaws.com/riskcast-useast01/img/EJc4ca4238/personprofileimg-person-id-3530-87db2832-67a5-31f6-aac2-18fd19d4e7da.png",
        //     "lastName": "AMADO",
        //     "firstName": "ANTHONY B",
        //     "allowanceTypeCode": "PD",
        //     "allowanceTypeName": "Per Diem",
        //     "allowanceTypeColor": "#314860",
        //     "calcTypeLabel": "Lump Sum",
        //     "isTaxableLabel": "N"
        //   }

        //   ]

        // }

        // setAllowanceData(res.allowances)
        setAllowanceData(data.data.allowances);
      }
    }
    catch (e) {
      setCompLoader(false)
    }
  }





  /**API : GET Crew certificate data */
  const getCrewCertificatePayloadData = async (id: any) => {
    try {
      let url = `/v1/api/employee/filterlistsreview?orgEntityId=${companyId}&certIds=all&employeeIds=${id.toString()}`
      const { data } = await get(url)
      let col = [] as any
      if (data) {
        data.data.certificates.map((e: any) => {
          e._children.map((a: any) => {
            col.push(a)
            return a
          })
          return e
        })

        let colcerts = [] as any
        col.map((e: any) => {
          let a = e._certCode + String(e.certificateTypeId)
          const obj = {
            "certificateTypeId": e.certificateTypeId,
            "colName": a,
            "colDays": a + "days",
            "colImg": a + "imgurl",
            "colDaysTxt": a + "daystxt",
            "colHasCert": a + "hascert"
          }
          colcerts.push(obj)
          return e
        })
        setColCerts(colcerts)
        getCrewCertificateData(colcerts, id)
      }
    }
    catch (e) {
      console.log(e);
    }
  }
  /**API : GET Crew ids */
  const getCrewIds = async () => {
    setCompLoader(true)
    try {
      let url = `/v1/api/time-card/${timecardId}`
      const { data } = await get(url)
      if (data) {
        setCompLoader(false)
        if (data && data.data && data.data.crewList) {
          let res = data.data.crewList;
          let result = res.map((a: any) => a.employeeId);
          setCrewIds(result)
          getCrewCertificatePayloadData(result)

        }

        // setAllowanceData(data ?.data ?.allowances || res.allowances);
      }
    }
    catch (e) {
      setCompLoader(false)
    }
  }


  /** API : POST Listing API : crew certificate */
  const getCrewCertificateData = async (colcerts: any, ids: any) => {
    setCompLoader(true)
    try {
      let url = `/v1/api/employee/filtered-roaster-offset`
      const payload = {
        "requestFromTimecard": false,
        "params": {
          "sourceCompanyIds": [
            companyId
          ],
          "employeeSearchText": "",
          "employeeActiveStatus": [
            1,
            0
          ],
          "pageSize": 50,
          "page": 0
        },
        "companies": {
          "companyFilterBy": false,
          "companyIds": [],
          "divisionIds": [],
          "payclassIds": [],
          "unionCodes": []
        },
        "employees": {
          "ids": ids ? ids : crewIds,
          "employeeFilterBy": false,
          "employeeStatus": [],
          "ethnicity": [],
          "gender": [],
          "states": [],
          "city": "",
          "postalCode": ""
        },
        "projects": {
          "ids": []
        },
        "projectRoles": {
          "members": []
        },
        "certTypes": {
          "certsFilterBy": false,
          "ids": [],
          "expirationFloor": 0,
          "daysUntilExpire": 0,
          "displayCertsColumns": 1
        },
        "timeoffTypes": {
          "filterBy": false,
          "ids": [],
          "displayTimeoffColumns": 0
        },
        "serverCols": {
          "certColumns": [
            ...colcerts
          ],
          "timeoffColumns": []
        }
      }
      const { data } = await post(url, payload)
      if (data) {
        setCrewCertificateData(data.data.crewCertInfos)
        setCompLoader(false)
      }
    }
    catch (e) {
      setCompLoader(false)
    }
  }


  /** API : Send Email : crew certificate tab*/
  const sendEmail = async () => {
    try {
      setCompLoader(true)
      let url = `/v1/api/report/employee/certificates`
      const payload = {
        "orgEntityId": companyId,
        "printedDateTime": "04/15/2022 1:12 pm IST",
        "filters": {
          "requestFromTimecard": false,
          "params": {
            "sourceCompanyIds": [
              companyId
            ],
            "employeeSearchText": "",
            "employeeActiveStatus": [
              1,
              0
            ],
            "pageSize": 50,
            "page": 0
          },
          "companies": {
            "companyFilterBy": false,
            "companyIds": [],
            "divisionIds": [],
            "payclassIds": [],
            "unionCodes": []
          },
          "employees": {
            "ids": [
              ...crewIds
            ],
            "employeeFilterBy": false,
            "employeeStatus": [],
            "ethnicity": [],
            "gender": [],
            "states": [],
            "city": "",
            "postalCode": ""
          },
          "projects": {
            "ids": []
          },
          "projectRoles": {
            "members": []
          },
          "certTypes": {
            "certsFilterBy": true,
            "ids": [
              12,
              82,
              318,
              79,
              352,
              1
            ],
            "expirationFloor": 0,
            "daysUntilExpire": 0,
            "displayCertsColumns": 1
          },
          "timeoffTypes": {
            "filterBy": false,
            "ids": [],
            "displayTimeoffColumns": 0
          },
          "serverCols": {
            "certColumns": [
              ...colcerts
            ],
            "timeoffColumns": []
          }
        },
        "sendToEmail": true
      }
      const { data } = await post(url, payload)
      if (data) {
        setCompLoader(false)
      }
    }
    catch (e) {
      setCompLoader(false)
    }

  }

  /** get certificate info */
  const getCrewCertificateInfo = async (id: number, labelName: String) => {
    setCompLoader(true)
    try {
      let url = `/v1/api/person/certificates?personId=${id}`
      const { data } = await get(url)
      if (data) {
        setCompLoader(false)
        setCertInfoData(data.data.certificates)
        dat = data.data.certificates.filter((e: any) => {

          return e._certName === labelName
        })
        setModalData(dat)
      }
    }
    catch (e) {
      setCompLoader(false)
    }
  }



  return (
    <>
      <div className="reviewtc-wrapper">
        <Header breadcrumb={"Review"} title={"Review"} />
        {/* @ts-ignore */}
        <div className="reviewtc-content">
          <div className="steps-container">
            <ul className="steps-list row">
              {/* <li className="step" [className.active]="activeStep === 'S_' + step.stepNo"> */}
              {/* <li
                className={classnames("step col", { active: activeTab === "1" })}
                onClick={() => {
                  toggle("1");
                }}
              >
                <a href="javascript:void(0)">
                  <span className="step-number">1</span>
                  <span className="step-name">Service Ticket Quants</span>
                </a>
              </li> */}
              <li
                className={classnames("step col", { active: activeTab === "2" })}
                onClick={() => {
                  toggle("2");
                  getCrewIds()
                  // if (crewIds) {
                  //   getCrewCertificatePayloadData(crewIds)
                  // }

                }}
              >
                {/* eslint-disable-next-line */}
                <a href="javascript:void(0)">
                  <span className="step-number">2</span>
                  <span className="step-name">Crew Certificates</span>
                </a>
              </li>
              <li
                className={classnames("step col", { active: activeTab === "3" })}
                onClick={() => {
                  toggle("3");
                  getAllowanceData(timecardId);
                }}
              >
                {/* eslint-disable-next-line */}
                <a href="javascript:void(0)">
                  <span className="step-number">3</span>
                  <span className="step-name">Allowances</span>
                </a>
              </li>
              <li
                className={classnames("step col", { active: activeTab === "4" })}
                onClick={() => {
                  toggle("4");
                  getStatusData();
                }}
              >
                {/* eslint-disable-next-line */}
                <a href="javascript:void(0)">
                  <span className="step-number">4</span>
                  <span className="step-name">Status</span>
                </a>
              </li>
              <li
                className={classnames("step col", { active: activeTab === "5" })}
                onClick={() => {
                  toggle("5");
                  getHistoryData()
                }}
              >
                {/* eslint-disable-next-line */}
                <a href="javascript:void(0)">
                  <span className="step-number">5</span>
                  <span className="step-name">History</span>
                </a>
              </li>
            </ul>
          </div>
          <div className="steps-content">
            <div
              className={classnames("step-visit", {
                stepvisible: activeTab === "1",
              })}
            >
              <div className="rr-list-wrapper">
                <div className="rr-list-heading d-flex align-items-center justify-content-between">
                  <h3 className="heading-sub">
                    {/* Activity List on: <b>01/03/2022</b>
                  <span className="heading-sub-entries">
                    Showing entries: <b>06 of 200</b>
                  </span> */}
                    <div className="table-search">
                      <input
                        className="table-search-field table-search-icon"
                        placeholder="Search"
                      ></input>
                      {/* eslint-disable-next-line */}
                      <a href="javascript:void(0)" className="table-search-close">
                        <Close />
                      </a>
                    </div>
                  </h3>

                  <div className="rr-list-btns">
                    <button className="btn btn-primary">
                      <span className="download-docs"></span>
                      +2
                  </button>
                    <button className="btn btn-primary">
                      <span className="send-excel"></span>
                      Export to CSV
                  </button>
                  </div>
                </div>
                <div className="rr-list-content">
                  <div className="r-table-wrapper">
                    <table className="table">
                      <thead>
                        <tr>
                          <th className="ticket-checkbox">
                            <div className="form-group">
                              <div className="custom-control custom-checkbox">
                                <input
                                  type="checkbox"
                                  className="custom-control-input"
                                  name="ticketHeading"
                                  id="ticketHeading"
                                />
                                <label
                                  className="custom-control-label"
                                  //   @ts-ignore
                                  for="ticketHeading"
                                ></label>
                              </div>
                            </div>
                          </th>
                          <th>Line</th>
                          <th>Pole</th>
                          <th>PayCU</th>
                          <th>Estimated Quantity</th>
                          <th>Activity</th>
                          <th>UOM</th>
                          <th>Quantity</th>
                          <th>Hours</th>
                          <th>Action</th>
                        </tr>
                      </thead>
                      <tbody>
                        <tr>
                          <td className="ticket-checkbox">
                            <div className="form-group">
                              <div className="custom-control custom-checkbox">
                                <input
                                  type="checkbox"
                                  className="custom-control-input"
                                  name="ticketRow1"
                                  id="ticketRow1"
                                />
                                <label
                                  className="custom-control-label"
                                  //   @ts-ignore
                                  for="ticketRow1"
                                ></label>
                              </div>
                            </div>
                          </td>
                          <td>96</td>
                          <td>85A</td>
                          <td>OHPO002</td>
                          <td>1</td>
                          <td>Install Pole Hot, Highway</td>
                          <td>Per Pole</td>
                          <td>1</td>
                          <td>0</td>
                          <td>
                            <div className="action-wrapper">
                              <button type="button" className="btn btn-secondary">
                                Edit
                            </button>
                              <button type="button" className="btn btn-secondary">
                                Delete
                            </button>
                            </div>
                          </td>
                        </tr>
                        <tr>
                          <td className="ticket-checkbox">
                            <div className="form-group">
                              <div className="custom-control custom-checkbox">
                                <input
                                  type="checkbox"
                                  className="custom-control-input"
                                  name="ticketRow1"
                                  id="ticketRow1"
                                />
                                <label
                                  className="custom-control-label"
                                  //   @ts-ignore
                                  for="ticketRow1"
                                ></label>
                              </div>
                            </div>
                          </td>
                          <td>96</td>
                          <td>85A</td>
                          <td>OHPO002</td>
                          <td>1</td>
                          <td>Install Pole Hot, Highway</td>
                          <td>Per Pole</td>
                          <td>1</td>
                          <td>0</td>
                          <td>
                            <div className="action-wrapper">
                              <button type="button" className="btn btn-secondary">
                                Edit
                            </button>
                              <button type="button" className="btn btn-secondary">
                                Delete
                            </button>
                            </div>
                          </td>
                        </tr>
                        <tr>
                          <td className="ticket-checkbox">
                            <div className="form-group">
                              <div className="custom-control custom-checkbox">
                                <input
                                  type="checkbox"
                                  className="custom-control-input"
                                  name="ticketRow1"
                                  id="ticketRow1"
                                />
                                <label
                                  className="custom-control-label"
                                  //   @ts-ignore
                                  for="ticketRow1"
                                ></label>
                              </div>
                            </div>
                          </td>
                          <td>96</td>
                          <td>85A</td>
                          <td>OHPO002</td>
                          <td>1</td>
                          <td>Install Pole Hot, Highway</td>
                          <td>Per Pole</td>
                          <td>1</td>
                          <td>0</td>
                          <td>
                            <div className="action-wrapper">
                              <button type="button" className="btn btn-secondary">
                                Edit
                            </button>
                              <button type="button" className="btn btn-secondary">
                                Delete
                            </button>
                            </div>
                          </td>
                        </tr>
                        <tr>
                          <td className="ticket-checkbox">
                            <div className="form-group">
                              <div className="custom-control custom-checkbox">
                                <input
                                  type="checkbox"
                                  className="custom-control-input"
                                  name="ticketRow1"
                                  id="ticketRow1"
                                />
                                <label
                                  className="custom-control-label"
                                  //   @ts-ignore
                                  for="ticketRow1"
                                ></label>
                              </div>
                            </div>
                          </td>
                          <td>96</td>
                          <td>85A</td>
                          <td>OHPO002</td>
                          <td>1</td>
                          <td>Install Pole Hot, Highway</td>
                          <td>Per Pole</td>
                          <td>1</td>
                          <td>0</td>
                          <td>
                            <div className="action-wrapper">
                              <button type="button" className="btn btn-secondary">
                                Edit
                            </button>
                              <button type="button" className="btn btn-secondary">
                                Delete
                            </button>
                            </div>
                          </td>
                        </tr>
                        <tr>
                          <td className="ticket-checkbox">
                            <div className="form-group">
                              <div className="custom-control custom-checkbox">
                                <input
                                  type="checkbox"
                                  className="custom-control-input"
                                  name="ticketRow1"
                                  id="ticketRow1"
                                />
                                <label
                                  className="custom-control-label"
                                  //   @ts-ignore
                                  for="ticketRow1"
                                ></label>
                              </div>
                            </div>
                          </td>
                          <td>96</td>
                          <td>85A</td>
                          <td>OHPO002</td>
                          <td>1</td>
                          <td>Install Pole Hot, Highway</td>
                          <td>Per Pole</td>
                          <td>1</td>
                          <td>0</td>
                          <td>
                            <div className="action-wrapper">
                              <button type="button" className="btn btn-secondary">
                                Edit
                            </button>
                              <button type="button" className="btn btn-secondary">
                                Delete
                            </button>
                            </div>
                          </td>
                        </tr>
                        <tr>
                          <td className="ticket-checkbox">
                            <div className="form-group">
                              <div className="custom-control custom-checkbox">
                                <input
                                  type="checkbox"
                                  className="custom-control-input"
                                  name="ticketRow1"
                                  id="ticketRow1"
                                />
                                <label
                                  className="custom-control-label"
                                  //   @ts-ignore
                                  for="ticketRow1"
                                ></label>
                              </div>
                            </div>
                          </td>
                          <td>96</td>
                          <td>85A</td>
                          <td>OHPO002</td>
                          <td>1</td>
                          <td>Install Pole Hot, Highway</td>
                          <td>Per Pole</td>
                          <td>1</td>
                          <td>0</td>
                          <td>
                            <div className="action-wrapper">
                              <button type="button" className="btn btn-secondary">
                                Edit
                            </button>
                              <button type="button" className="btn btn-secondary">
                                Delete
                            </button>
                            </div>
                          </td>
                        </tr>
                      </tbody>
                    </table>
                  </div>
                  <div className="table-footer-content"></div>
                </div>
              </div>
            </div>
            <div
              className={classnames("step-visit", {
                stepvisible: activeTab === "2",
              })}
            >
              {/* add crew certificate*/}
              <CrewCertificatesTab compLoader={compLoader} crewCertificateData={crewCertificateData} setCrewCertificateData={setCrewCertificateData} sendEmail={sendEmail} toggleCrewInfo={toggleCrewInfo} getCrewCertificateInfo={getCrewCertificateInfo} certInfoData={certInfoData} getCrewIds={getCrewIds}/>
            </div>
            <div
              className={classnames("step-visit", {
                stepvisible: activeTab === "3",
              })}
            >
              {/* add Allowance tab*/}
              <AllowanceTab timecardId={timecardId} allowanceData={allowanceData} setAllowanceData={setAllowanceData} getAllowanceData={getAllowanceData} compLoader={compLoader} />
            </div>
            <div
              className={classnames("step-visit", {
                stepvisible: activeTab === "4",
              })}
            >
              {/* add status tab*/}
              <StatusTab statusData={statusData} setStatusData={setStatusData} compLoader={compLoader} />
            </div>
            <div
              className={classnames("step-visit", {
                stepvisible: activeTab === "5",
              })}
            >
              {/*add history tab*/}
              <HistoryTab historyData={historyData} setHistoryData={setHistoryData} compLoader={compLoader} />
            </div>
          </div>
        </div>

        {/* Crew Informtion Popup */}
        {modalData && modalData.length ? (
          <Modal
          isOpen={crewInfoModal}
          fade={false}
          toggle={toggleCrewInfo}
          className="crew-info-modal modal-lg"
        >
          <ModalHeader toggle={toggleCrewInfo}>

          </ModalHeader>
          <ModalBody>
            <div className="crewinfo-wrapper">
              {modalData[0]._certName} - {modalData[0]._lastName}, {modalData[0]._firstName}-{modalData[0]._personNumber}
              <div className="row">
                <div className="col">
                  <div className="view-certificate">
                    <img
                      className="view-certificate-full"
                      src={modalData[0].imgPaths[0].url}
                      alt="Crew Certificate"
                    />
                  </div>
                </div>
              </div>
              <ModalFooter>
                <Button
                  color="primary"
                  type="button"
                  className="ml-auto"
                  onClick={toggleCrewInfo}
                >
                  Return
              </Button>
              </ModalFooter>
            </div>
          </ModalBody>
        </Modal>
        ) : <></>}

      </div>
    </>
  );
}
