import React, { useState, useMemo, useEffect } from "react";
import { Button, Modal, ModalHeader, ModalBody, ModalFooter } from "reactstrap";
import { post, get } from "../../../../types/api";
import { IServiceTicketDetails } from "./IServiceTicketDetails";
import { useServiceStore } from "../../../../store";
import Loader from "../../../shared/Loader/Loader";
import { AccessPath } from "../../../shared/Constants";

interface ICopyActivityModalProps {
  modal: boolean;
  toggleModal: (pageRefresh?: boolean) => void;
  serviceTicketId: string;
  accessPath: string;
  serviceContractId?: number;
  existingServiceContractItemIds: number[];
  // this array contains the already selected activity's serviceContractItemIds
}

const SelectActivityModal = (props: ICopyActivityModalProps) => {
  // STATE DECLARATION
  const tokenDetails = useServiceStore(
    (serviceStore) => serviceStore.tokenDetails
  );

  const [contractSetupDetails, setContractSetupDetails] = useState({
    serviceContractId: 0,
    activityList: [],
    activityListHeaders: [],
    stActivityListHeaders: [],
    ticketStatus: 0,
    serviceTicketDisplayNumber: '',
  } as IServiceTicketDetails);

  const [serviceContractItemIds, setServiceContractItemIds] = useState<
    number[]
  >([]);
  const [selectAll, setSelectAll] = useState(false);
  const [filteredActivities, setFilteredActivities] = useState([] as any)
  const [searchActivities, setSearchActivities] = useState({} as any)

  // loader state
  const [modalLoader, setModalLoader] = useState(false);

  // SETTER/GETTER METHODS
  const handleActivitySelect = (
    checked: boolean,
    serviceContractItemId: number
  ) => {
    if (checked) {
      setServiceContractItemIds([
        ...serviceContractItemIds,
        serviceContractItemId,
      ]);
    } else {
      const indexOfActivity = serviceContractItemIds.indexOf(
        serviceContractItemId
      );
      setServiceContractItemIds([
        ...serviceContractItemIds.slice(0, indexOfActivity),
        ...serviceContractItemIds.slice(indexOfActivity + 1),
      ]);
    }
  };

  const filterActivities = () => {
    let tempFilteredActivities = domActivitiesValue
    Object.keys(searchActivities).forEach(eachSearch => {
      if(searchActivities[eachSearch]){
        tempFilteredActivities = tempFilteredActivities?.filter((eachActivity: { [x: string]: any; }) => {
          return `${eachActivity[eachSearch]}`.toLowerCase().indexOf(`${searchActivities[eachSearch]}`.toLowerCase()) > -1
        })
      }
    })
    setFilteredActivities(tempFilteredActivities)
  }

  const generateDynamicValues = (details: any) => {
    if (details.activityList && details.activityList.length) {
      return details.activityList.map((eachActivity: any) => {
        let temp = {
          dynamic: {},
          static: {}
        }
        // adding dynamic fields
        details.activityListHeaders.forEach((eachActivityHeader: any) => {
          if(eachActivityHeader.isActive === 1) {
            let fieldType = 'fields';
            if (eachActivity.scFields) {
              fieldType = 'scFields';
            }
            temp = {
              dynamic: {
                ...temp.dynamic,
                [eachActivityHeader.fieldName]: dynamicFieldsTD(eachActivity, eachActivityHeader, fieldType)
              },
              static: {
                ...temp.static
              }
            }
          }
        });
        // adding static fields
        details.stActivityListHeaders.forEach((eachActivityHeader: any) => {
          if(eachActivityHeader.isActive === 1) {
            const res = eachActivity['fields'].find(
              (eachField: any) =>
                (eachActivityHeader.customFieldIndex === eachField.customFieldIndex && eachActivity.id === eachField.serviceTicketItemId)
            );
            temp = {
              static: {
                ...temp.static,
                [eachActivityHeader.fieldName]: res?.fieldValue || "-"
              },
              dynamic: {
                ...temp.dynamic
              }
            }
          }
        })
        return temp
      });
    } else {
      return [];
    }
  }

  const dynamicFieldsTD = (eachActivity: any, eachActivityHeader: { customFieldIndex: any; }, fieldType: string) => {
    const res = eachActivity[fieldType].find(
      (eachField: any) =>
        (eachActivityHeader.customFieldIndex === eachField.customFieldIndex)
    );
    return res?.fieldValue || "-"
  }

  // API CALLING
  const getServiceContractActivity = async () => {
    try {
      const url = `/v1/api/service/contract/id/${props.serviceContractId}/setup`;
      setModalLoader(true);
      const { data } = await get(url);
      setModalLoader(false);
      setContractSetupDetails(data);
    } catch (error) {
      console.log(error);
    }
  };

  const createCopyActivity = async () => {
    const url = `/v1/api/service/ticket/activity`;
    // removing the existing serviceContractItemIds from the selected
    let newServiceContractItemIds: number[] = [];
    serviceContractItemIds.forEach((eachServiceContractItemId) => {
      if (
        !props.existingServiceContractItemIds.includes(
          eachServiceContractItemId
        )
      ) {
        newServiceContractItemIds.push(eachServiceContractItemId);
      }
    });
    // newServiceContractItemIds contain only the not-existing activity's serviceContractItemId
    if (newServiceContractItemIds.length) {
      let promise = newServiceContractItemIds.map(
        async (eachServiceContractItemId) => {
          const payload = {
            id: 0,
            tenantId: tokenDetails?.tenantId,
            serviceContractItemId: eachServiceContractItemId,
            serviceTicketId: props.serviceTicketId,
            estimatedQuantity: props.accessPath === AccessPath.SERVICE_TICKET ? 1 : 0,
            changeNote: "",
            activityList: [],
            fields: [],
          };
          return post(url, payload);
        }
      );
      setModalLoader(true);
      await Promise.all(promise);
      setModalLoader(false);
      props.toggleModal(true);
    }
  };

  // LIFECYCLE METHODS
  // creating the dynamic activity list array using memo
  const dynamicActivityListDom = useMemo(() => {
    return generateDynamicValues(contractSetupDetails)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [contractSetupDetails.activityListHeaders]);

  useEffect(() => {
    if (props.serviceContractId) {
      getServiceContractActivity();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.serviceContractId]);

  useEffect(() => {
    if (
      serviceContractItemIds.length &&
      serviceContractItemIds.length === contractSetupDetails.activityList.length
    ) {
      setSelectAll(true);
    } else {
      if (selectAll) {
        setSelectAll(false);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [serviceContractItemIds, contractSetupDetails.activityList]);

  useEffect(() => {
    // adding the existingServiceContractIds in the serviceContractItemIds array
    // for simplifying the checkbox logic
    setServiceContractItemIds([...props.existingServiceContractItemIds]);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.existingServiceContractItemIds]);

  // making a single array for all the fields
  const domActivitiesValue = useMemo(() => {
    if(dynamicActivityListDom){
      return contractSetupDetails.activityList.map((eachActivity: any, index: number) => {
        return {
          "id": eachActivity.id,
          "Pay Item": eachActivity.payItem,
          "Activity": eachActivity.activity,
          "UOM": eachActivity.UOM,
          ...dynamicActivityListDom[index]?.dynamic,
          "Unit Hours": eachActivity.unitHours,
          ...dynamicActivityListDom[index]?.static
        }
      })
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dynamicActivityListDom])

  // adding search states for all the fields
  useEffect(() => {
    if(domActivitiesValue && domActivitiesValue[0]){
      const tempFields = {...domActivitiesValue[0]}
      delete tempFields.id
      setSearchActivities(Object.keys(tempFields).reduce((prev, current) => ({...prev, [current]: ''}), {}))
      setFilteredActivities(domActivitiesValue)
    }
  }, [domActivitiesValue])

  // filtering the activities to show on dom
  // when search state is changed
  useEffect(() => {
    filterActivities()
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [searchActivities])

  return (
    <Modal
      isOpen={props.modal}
      fade={false}
      toggle={() => {
        props.toggleModal();
      }}
      className="copy-activity-modal modal-xl"
    >
      <ModalHeader
        toggle={() => {
          props.toggleModal();
        }}
      >
        Select Activity
      </ModalHeader>
      <ModalBody>
        <form className="upload-image-form">
          <div className="row">
            <div className="col-12">
              <div className="r-table-wrapper">
                {modalLoader && props.modal && (
                  <Loader showComponentLoader={true} />
                )}
                <table className="table">
                  <thead>
                    <tr>
                      <th className="ticket-checkbox">
                        <div className="form-group">
                          <div className="custom-control custom-checkbox">
                            <input
                              type="checkbox"
                              className="custom-control-input"
                              name="contractActivityHeading"
                              id="contractActivityHeading"
                              checked={selectAll}
                              disabled={
                                props.existingServiceContractItemIds.length ===
                                contractSetupDetails.activityList.length
                              }
                              onChange={(e) => {
                                setSelectAll(e.target.checked);
                                setServiceContractItemIds(() => {
                                  const checked = e.target.checked;
                                  if (checked) {
                                    return contractSetupDetails.activityList.map(
                                      (eachActivity: any) =>
                                        eachActivity.id
                                    );
                                  }
                                  return [
                                    ...props.existingServiceContractItemIds,
                                  ];
                                });
                              }}
                            />
                            {/* @ts-ignore */}
                            <label
                              className="custom-control-label"
                              htmlFor="contractActivityHeading"
                            ></label>
                          </div>
                        </div>
                      </th>
                      {Object.keys(searchActivities).map((label: boolean | React.ReactChild | React.ReactFragment | React.ReactPortal | null | undefined) => <th>{label}</th>)}
                    </tr>
                  </thead>
                  <tbody>
                    <tr>
                      <td />
                      {Object.keys(searchActivities).map((key: string) => {
                        return <td><input onChange={(e) => setSearchActivities({...searchActivities, [key]: e.target.value})}/></td>
                      })}
                    </tr>
                    {filteredActivities.map(
                      (eachActivity: any, index: string | number) => {
                        const fieldsToDisplay = {...eachActivity}
                        delete fieldsToDisplay.id
                        return (
                          <tr key={`${eachActivity.id}-${index}`}>
                            <td className="ticket-checkbox">
                              <div className="form-group">
                                <div className="custom-control custom-checkbox">
                                  <input
                                    type="checkbox"
                                    className="custom-control-input"
                                    name={`contractActivityRow${index}`}
                                    id={`contractActivityRow${index}`}
                                    checked={
                                      selectAll ||
                                      serviceContractItemIds.indexOf(
                                        eachActivity.id
                                      ) > -1
                                    }
                                    disabled={
                                      props.existingServiceContractItemIds.indexOf(
                                        eachActivity.id
                                      ) > -1
                                    }
                                    onChange={(e) => {
                                      handleActivitySelect(
                                        e.target.checked,
                                        eachActivity.id
                                      );
                                    }}
                                  />
                                  {/* @ts-ignore */}
                                  <label
                                    className="custom-control-label"
                                    htmlFor={`contractActivityRow${index}`}
                                  ></label>
                                </div>
                              </div>
                            </td>
                            {Object.values(fieldsToDisplay).map((eachActivity: any) => <td>{eachActivity}</td>)}
                          </tr>
                        );
                      }
                    )}
                  </tbody>
                </table>
              </div>
            </div>
          </div>
          <ModalFooter>
            <Button
              color="secondary"
              onClick={() => {
                props.toggleModal();
              }}
            >
              Cancel
            </Button>
            <Button
              color="primary"
              onClick={createCopyActivity}
              disabled={
                props.existingServiceContractItemIds.length >=
                serviceContractItemIds.length
              }
            >
              Select Activity(s)
            </Button>{" "}
          </ModalFooter>
        </form>
      </ModalBody>
    </Modal>
  );
};

export default SelectActivityModal;
