import {
  Button,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Alert,
} from "reactstrap";
import React, { useState, useEffect } from "react";
import { ReactComponent as EmployeeFul } from "../../../assets/images/employee-ful.svg";
import { ReactComponent as EquipmentFul } from "../../../assets/images/equipment-ful.svg";
import { ReactComponent as Employee } from "../../../assets/images/employee.svg";
import { ReactComponent as Equipment } from "../../../assets/images/equipment.svg";
import moment from "moment";
import { get, post } from "../../../types/api";
import Loader from "../../shared/Loader/Loader";
import { useHistory } from "react-router-dom";
import { useServiceStore, useTimeCardStore } from "../../../store";

interface ITimecardCloneProps {
  cloneModal: boolean;
  toggleClone: () => void;
  timecardId: number;
}

interface IAvailableTimecard {
  id: number;
  tenantId: number;
  projectId: number;
  timeCardSupervisorId: number;
  timeCardDate: number;
  shift: string;
  timeCardStatus: number;
  updatedBy: string;
  createdAt: string;
  updatedAt: string;
  orgEntityId: number;
  projectDisplayNumber: string;
  projectName: string;
  supervisorEmployeeId: number;
  supervisorLastName: string;
  supervisorFirstName: string;
  timeCardDateFormatted: string;
  countOfEmployee?: number;
  countOfEquipment?: number;
  countOfCodes: number;
  countOfEmployees?: number;
  projectNumber: string;
  supervisorEmployeeKey: string;
  supervisiorEmployeeKey?: string;
  supervisorName: string;
  weekEndDateFormatted: Date;
  weekEndDateKey: number;
  projectExportId: number;
  startTimeUtc?: Date;
  endTimeUtc?: Date;
  startTimeLocalString?: string;
  endTimeLocalString?: string;
}

const TimecardCloneModal = (props: ITimecardCloneProps) => {
  const [selectEmployee, setSelectEmployee] = useState(false);
  const toggleEmployee = () => setSelectEmployee(!selectEmployee);

  const [selectEquipment, setSelectEquipment] = useState(false);
  const toggleEquipment = () => setSelectEquipment(!selectEquipment);

  const [availableTimecard, setAvailableTimecard] = useState(
    [] as IAvailableTimecard[]
  );
  const [selectedTimecardIndex, setSelectedTimecardIndex] =
    useState<number>(-1);

  const companyId = useServiceStore(serviceStore => serviceStore.companyId);

  const [modalLoader, setModalLoader] = useState(false);
  const { selectedProjectsSupervisorsShift, timeCardDate } =
    useTimeCardStore.getState();
  const history = useHistory();

  const [alert, setAlert] = useState({
    success: false,
    colour: "success",
    isOpen: false,
    message: "",
  });

  const saveTimecard = async () => {
    const url = `/v1/api/time-card/clone`;
    if (selectedTimecardIndex !== -1) {
      const payload = {
        timeCardCreationDate: timeCardDate.toString(),
        fromTimeCardInfo: {
          ...availableTimecard[selectedTimecardIndex],
          countOfEmployees: selectEmployee
            ? availableTimecard[selectedTimecardIndex].countOfEmployee
            : 0,
          countOfEquipment: selectEquipment
            ? availableTimecard[selectedTimecardIndex].countOfEquipment
            : 0,
          supervisorEmployeeKey:
            availableTimecard[selectedTimecardIndex].supervisiorEmployeeKey,
          updatedAt: null,
        },
      };
      delete payload.fromTimeCardInfo.countOfEmployee;
      delete payload.fromTimeCardInfo.startTimeUtc;
      delete payload.fromTimeCardInfo.endTimeUtc;
      delete payload.fromTimeCardInfo.startTimeLocalString;
      delete payload.fromTimeCardInfo.endTimeLocalString;
      delete payload.fromTimeCardInfo.supervisiorEmployeeKey;

      setModalLoader(true);
      try {
        const res = await post(url, payload);
        setModalLoader(false);
        if (res.status === 200) {
          setAlert({
            success: true,
            colour: "success",
            isOpen: true,
            message: "Successfully timecard created",
          });
          if (res.data && res.data.fromTimeCardInfo) {
            history.push(`/timecardList/${res.data.fromTimeCardInfo.id}`);
          }
        }
        props.toggleClone();
      } catch (error: any) {
        if (error.response && error.response.data) {
          setAlert({
            success: false,
            colour: "danger",
            isOpen: true,
            message: error.response.data?.message,
          });
        }
        setModalLoader(false);
      }
    }
  };

  const getPreviousTimeCard = async () => {
    if (selectedProjectsSupervisorsShift?.project[0]?.id) {
      const timeCardDateString = timeCardDate.toString()
      const url = `/v1/api/time-card/filter?projectId=${
        selectedProjectsSupervisorsShift?.project[0]?.id
      }&supervisorId=${
        selectedProjectsSupervisorsShift?.supervisor[0]?.id
      }&fromDate=${moment(timeCardDateString)
        .subtract(2, "week")
        .format("YYYYMMDD")}&toDate=${moment(timeCardDateString).format(
        "YYYYMMDD"
      )}&orgEntityId=${companyId}`;
      setModalLoader(true);
      const { data } = await get(url);
      setModalLoader(false);
      if (data.data && data.data.length) {
        const sortedData = data.data.sort(
          (a: any, b: any) =>
            new Date(b["timeCardDate"]).getTime() -
            new Date(a["timeCardDate"]).getTime()
        );
        setAvailableTimecard(sortedData);
      }
    }
  };

  useEffect(() => {
    if (props.cloneModal) {
      setAlert({ ...alert, isOpen: false });
      getPreviousTimeCard();
      setSelectedTimecardIndex(-1);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.cloneModal]);

  useEffect(() => {
    if (!selectEmployee && !selectEquipment) {
      setSelectedTimecardIndex(-1);
    }
  }, [selectEmployee, selectEquipment]);

  return (
    <Modal
      isOpen={props.cloneModal}
      fade={false}
      toggle={props.toggleClone}
      className="clone-service-ticket modal-md"
    >
      <ModalHeader toggle={props.toggleClone}>Clone Timecard</ModalHeader>
      <ModalBody>
        {!modalLoader && availableTimecard.length === 0 ? (
          <div className="no-timecard-available">
            <p className="text-center">
              You do not have any Timecard <br />
              since last two weeks
            </p>
            <Button color="primary" type="button" onClick={props.toggleClone}>
              Ok
            </Button>{" "}
          </div>
        ) : (
          <form className="cloneticket-form">
            {modalLoader && <Loader showComponentLoader={true} />}
            <Alert
              color={alert.colour}
              isOpen={alert.isOpen}
              toggle={() => setAlert({ ...alert, isOpen: false })}
            >
              {alert.message}
            </Alert>
            <ul className="available-timecard-list">
              {availableTimecard.map((eachTimeCardCloneData, index) => {
                return (
                  <li
                    key={`available-timecard-${index}`}
                    className="available-timecard d-flex align-items-center justify-content-between"
                  >
                    <div className="ticket-checkbox">
                      <div className="form-group">
                        <div className="custom-control custom-radio">
                          <input
                            type="radio"
                            className="custom-control-input"
                            name="ticketHeading"
                            id={`ticketHeading-${index}`}
                            checked={selectedTimecardIndex === index}
                            onChange={() => {
                              setSelectedTimecardIndex(index);
                              setSelectEmployee(true);
                              setSelectEquipment(true);
                            }}
                          />
                          <label
                            className="custom-control-label"
                            htmlFor={`ticketHeading-${index}`}
                          >
                            {moment(
                              eachTimeCardCloneData.timeCardDateFormatted
                            ).format("dddd D, MMM YYYY")}
                          </label>
                        </div>
                      </div>
                    </div>
                    <div className="available-items d-flex align-items-center justify-content-start">
                      {/* eslint-disable-next-line */}
                      <a
                        className="available-crew employee-item"
                        // eslint-disable-next-line
                        href="javascript:void(0);"
                        onClick={toggleEmployee}
                      >
                        {selectEmployee && selectedTimecardIndex === index ? (
                          <EmployeeFul />
                        ) : (
                          <Employee />
                        )}
                        <small className="crew-number">
                          {eachTimeCardCloneData.countOfEmployee}
                        </small>
                      </a>
                      {/* eslint-disable-next-line */}
                      <a
                        className="available-crew equipment-item"
                        //  eslint-disable-next-line
                        href="javascript:void(0);"
                        onClick={toggleEquipment}
                      >
                        {selectEquipment && selectedTimecardIndex === index ? (
                          <EquipmentFul />
                        ) : (
                          <Equipment />
                        )}
                        <small className="crew-number">
                          {eachTimeCardCloneData.countOfEquipment}
                        </small>
                      </a>
                    </div>
                  </li>
                );
              })}
            </ul>
            <ModalFooter>
              <Button color="secondary" onClick={props.toggleClone}>
                Cancel
              </Button>
              <Button color="primary" type="button" onClick={saveTimecard}>
                Save
              </Button>{" "}
            </ModalFooter>
          </form>
        )}
      </ModalBody>
    </Modal>
  );
};

export default TimecardCloneModal;
