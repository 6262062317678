import React, { useState, useEffect, useMemo, useCallback } from "react";
import { withRouter, RouteComponentProps, useHistory } from "react-router-dom";
import Header from "../../../shared/Header/Header";
import classnames from "classnames";
import { TabContent, TabPane, Nav, NavItem, NavLink } from "reactstrap";
import moment from "moment";
import { get, deleteAPI, put, post } from "../../../../types/api";
import Loader from "../../../shared/Loader/Loader";
import SelectActivityModal from "./SelectActivityModal";
import ServiceTicketCreate, { IServiceValue } from "../ServiceTicketCreate";
import {
  useServerConstants,
  useServiceStore,
  useTimeCardStore,
  useServiceTicketStore
} from "../../../../store";
import Checkbox from "@material-ui/core/Checkbox";
import { DndProvider } from 'react-dnd'
import { HTML5Backend } from 'react-dnd-html5-backend'
import DragDropTableHeader from "../../../shared/DragDrop/DragDropTableHeader";
import { TouchBackend } from 'react-dnd-touch-backend'
import { isMobile } from "react-device-detect";
import { delay, removeExtraField, signFileURL, updateTimeCardStatus } from "../../../shared/Helpers";
import { AccessPath, ReportConditions, reportsShortId } from "../../../shared/Constants";
import { IServiceTicketDetails, IActivityListEdit } from "./IServiceTicketDetails";

import { ToastContainer, toast } from "react-toastify";
import 'react-toastify/dist/ReactToastify.css';
import {
  Button,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter
} from "reactstrap";
import ConfirmModal from "../../../Timecard/TimecardList/ConfirmModal";
import { modalDataArray } from "../../../Timecard/TimecardList/TimecardConstants";
import UploadActivityModal from "./UploadActivityModal";

const isNumeric = (inputtxt: string) => {
  var numbers = /^[0-9]+$/;
  if (inputtxt.match(numbers)) {
    return true;
  } else {
    return false;
  }
};

interface MatchParams {
  timecardId: any;
  serviceTicketId: string;
}
interface IServiceTicketDetailsProps extends RouteComponentProps<MatchParams> {
  buttonLabel: any;
  className: any;
  access: any;
}

const generateDynamicValues = (details: any) => {
  if (details.activityList && details.activityList.length) {
    return details.activityList.map((eachActivity: { [x: string]: any[]; scFields: any; }) => {
      return details.activityListHeaders.map((eachActivityHeader: any) => {
        if (eachActivityHeader.isActive === 1) {
          let fieldType = 'fields';
          if (eachActivity.scFields) {
            fieldType = 'scFields';
          }
          return dynamicFieldsTD(eachActivity, eachActivityHeader, fieldType);
        }
        return '';
      });
    });
  } else {
    return;
  }
};

const dynamicFieldsTD = (eachActivity: any, eachActivityHeader: { customFieldIndex: any; }, fieldType: string) => {
  const res = eachActivity[fieldType].find(
    (eachField: any) =>
      (eachActivityHeader.customFieldIndex === eachField.customFieldIndex)
  );
  return (
    <td key={`activity-list-${eachActivityHeader?.customFieldIndex}`}>
      {res?.fieldValue || "-"}
    </td>
  );
}

// Reusable function for displaying label and value/input pairs
const LabelValueInputItem = ({ label, value, input, show, onChange }: { label: string, value: any, input?: React.ReactNode, show: any, onChange?: (e: React.ChangeEvent<HTMLInputElement>) => void }) => {
  return (
    <>
      {show && (
        <div className="col-6">
          <div className="text-label-value">
            <div className="text-label">{label}: </div>
            {input ? (
                <input
                  type="number"
                  className="form-control"
                  id="percentComplete"
                  min="0"
                  style={{ minWidth: '175px' }}
                  pattern="^[+-]?[0-9]{1,4}(?:\.[0-9]{1,2})?$"
                  value={value}
                  onChange={onChange}
                  aria-describedby="Percent Complete"
                  placeholder="Enter Percent Complete"
                />
              ) : (
                <div>{value}</div>
              )}
          </div>
        </div>
      )}
    </>
  );
};

const ServiceTicketDetails = (props: IServiceTicketDetailsProps) => {

  let { access: ticketAccess } = props;

  // STATE DECLARATION
  const companyId = useServiceStore(serviceStore => serviceStore.companyId);
  const [quantityAvailable, setQuantityAvailable] = useState(false);
  const [hasChangeOutOfScope, setHasChangedOutOfScope] = useState(false);
  const [serviceTicketNotes, setServiceTicketNotes] = useState('');
  const [serviceTicketPercentage, setServiceTicketPercentage] = useState(0);
  const [fileModal, setFileModal] = useState(false);

  const [serviceTicketDetails, setServiceTicketDetails] = useState({
    id: 0,
    serviceTicketName: "",
    workOrderNumber: "",
    poNumber: "",
    addressCrossStreet: "",
    ticketStatus: 0,
    activityListHeaders: [],
    stActivityListHeaders: [],
    activityList: [],
    supervisorFullName: "",
    serviceTicketDisplayNumber: "",
    stNotes: "",
    additionalInfo: "",
    percentComplete: 0,
    estimatedHours: 0,
    todayTimeCardHours: 0,
    todayPlannedHours: 0,
    totalTimeCardHours: 0,
    totalPlannedHours: 0,
    totalTargetHours: 0,
    totalPayrollHours: 0,
    todayTargetHours: 0,
    todayPayrollHours: 0,
    workerCount: 0,
    project: { id: 0, name: '' },
    startDate: "",
    poleNumber: "",
    productivityPercentage: 0,
    serviceContract: { id: 0, name: '' },
    city: '',
    state: ''
  } as IServiceTicketDetails);

  const [editedTicketDetails, setEditedTicketDetails] = useState(
    {} as IActivityListEdit
  );

  const [serviceValue, setServiceValue] = useState({
    projects: [],
    serviceContracts: [],
    ticketStatus: []
  } as IServiceValue)

  // Timecard state
  const timecardId = props.match.params.timecardId || 0;

  const ticketStatusObj = useServerConstants(
    (serverConstants) => serverConstants.status
  );
  const tokenDetails = useServiceStore(
    (serviceStore) => serviceStore.tokenDetails
  );

  const selectedProjectId = useServiceTicketStore(serviceTicketStore => serviceTicketStore.selectedProjectId);
  const { selectedServiceTicketsDetails, timeCardDate, selectedCostCodeProject } = useTimeCardStore.getState();
  // loader functions
  const [pageLoader, setPageLoader] = useState(false);
  const [notesLoader, setNotesLoader] = useState(false);
  const history = useHistory();

  // modal functions
  const [selectActivityModal, setSelectActivityModal] = useState(false);
  const toggleSelectActivityModal = (pageRefresh = false) => {
    setSelectActivityModal(!selectActivityModal);
    if (pageRefresh) {
      getServiceTicketDetails();
    }
  };
  const [editAmtErrorCode, setEditAmtErrorCode] = useState(false);

  const [editTicketModal, setEditTicketModal] = useState(false);
  const toggleEditTicketModal = (refreshPage = false) => {
    setEditTicketModal(!editTicketModal);
  };

  // tab functions
  const [activeTab, setActiveTab] = useState(1);
  const [isSubmitNextButtonActive, setIsSubmitNextButtonActive] = useState(false);
  const [modalActive, setmodalActive] = useState(false);
  const [dataIndex, setDataIndex] = useState('' as any);
  const [modalRemark, setModalRemark] = useState("");
  const [ticketLabelValuePairs, setTicketLabelValuePairs] = useState([
    { label: 'Work Order', value: '', show: false },
    { label: 'Purchase Order', value: '', show: false },
    { label: 'Pole #', value: '', show: false },
    { label: 'Address/Cross Street', value: '', show: false },
    { label: 'Town', value: '', show: false },
    { label: 'State', value: '', show: false },
    { label: 'Additional Information', value: '', show: false },
  ]);
  let labelValuePairs = [
    { label: 'Work Order', value: serviceTicketDetails.workOrderNumber, show: serviceTicketDetails.headers_Native_ServiceTicket?.find((x: any) => x.fieldName === 'workOrderNumber') ? true : false},
    { label: 'Estimated Hours', value: serviceTicketDetails.estimatedHours, show: true },
    { label: 'Pole #', value: serviceTicketDetails.poleNumber, show: serviceTicketDetails.headers_Native_ServiceTicket?.find((x: any) => x.fieldName === 'poleNumber') ? true : false },
    { label: 'Total Payroll Hours', value: serviceTicketDetails.totalPayrollHours, show: true },
    { label: 'Productivity Percentage', value: serviceTicketDetails.productivityPercentage + '%', show: true },
    { label: 'Town', value: serviceTicketDetails.city, show: serviceTicketDetails.headers_Native_ServiceTicket?.find((x: any) => x.fieldName === 'city') ? true : false },
    {
      label: 'Percent Complete',
      value: serviceTicketPercentage,
      input: (
        <input
          type="number"
          className="form-control"
          id="percentComplete"
          min="0"
          style={{ minWidth: '175px' }}
          pattern="^[+-]?[0-9]{1,4}(?:\.[0-9]{1,2})?$"
          value={serviceTicketPercentage}
          onChange={(e) => {
            setServiceTicketPercentage(parseInt(e.target.value))
          }}
          aria-describedby="Percent Complete"
          placeholder="Enter Percent Complete"
        />
      ),
      show: true
    },
    { label: 'State', value: serviceTicketDetails.state, show: serviceTicketDetails.headers_Native_ServiceTicket?.find((x: any) => x.fieldName === 'state') ? true : false },
    { label: 'Additional Information', value: serviceTicketDetails.additionalInfo, show: serviceTicketDetails.headers_Native_ServiceTicket?.find((x: any) => x.fieldName === 'additionalInfo') ? true : false },
  ];

  const [ticketLabelValuePairsTimecard, setTicketLabelValuePairsTimecard] = useState([] as any);

  const toggle = (tab: any) => {
    if (activeTab !== tab) {
      setActiveTab(tab);
      // on first tab enable again submit, next buttons
      if (tab === 1) {
        setIsSubmitNextButtonActive(true);
      }
    }
  };

  // Polling configuration
  const interval = 1000;
  const timeOut = 3 * 60 * 1000;

  // SETTER/GETTER METHODS
  const setActivityEditable = (index: number, setEdit: boolean, editFieldKey: any) => {
    setEditedTicketDetails(() => {
      return {
        ...editedTicketDetails,
        [index]: {
          isEdit: setEdit,
          editFieldKey: editFieldKey || '',
          estimatedQuantity:
            serviceTicketDetails.activityList[index].estimatedQuantity,
          serviceQty: serviceTicketDetails.activityList[index].serviceQty,
          isOutOfScope: serviceTicketDetails.activityList[index].isOutOfScope,
          fields: [...JSON.parse(JSON.stringify(serviceTicketDetails.activityList[index].fields))],
          isChange: serviceTicketDetails.activityList[index].isChange
        },
      };
    });
  };

  // adding edited values to original values
  const setCopiedValuesToOriginal = (index: number) => {
    let tempActivityList = serviceTicketDetails.activityList;
    tempActivityList[index].estimatedQuantity =
      editedTicketDetails[index]?.estimatedQuantity ||
      tempActivityList[index].estimatedQuantity;
    tempActivityList[index].isOutOfScope =
      editedTicketDetails[index]?.isOutOfScope ||
      tempActivityList[index].isOutOfScope;
    tempActivityList[index].isChange =
      editedTicketDetails[index]?.isChange ||
      tempActivityList[index].isChange;
    tempActivityList[index].serviceQty =
      editedTicketDetails[index]?.serviceQty ||
      tempActivityList[index].serviceQty;
    tempActivityList[index].fields =
      editedTicketDetails[index]?.fields ||
      tempActivityList[index].fields;
    // editedTicketDetails[index].isEdit = false;
    // editedTicketDetails[index].editFieldKey = '';
    checkIsQuantityAvailable(tempActivityList);
    setServiceTicketDetails({
      ...serviceTicketDetails,
      activityList: [...tempActivityList],
    });

  };

  // Update service ticket notes, update service ticket api
  const updateServiceTicket = async () => {
    try {
      let url = `/v1/api/service/ticket`;
      const stId = selectedServiceTicketsDetails.serviceTicketsId[activeTab - 1];
      if (stId) {
        url = `${url}/id/${stId}`;
        // creating payload for update service ticket
        const payload = {
          id: stId,
          tenantId: tokenDetails?.tenantId,
          serviceContractId: serviceTicketDetails.serviceContractId,
          serviceTicketName: serviceTicketDetails.serviceTicketName || "service ticket",
          workOrderNumber: serviceTicketDetails.workOrderNumber,
          poNumber: serviceTicketDetails.poNumber,
          addressCrossStreet: serviceTicketDetails.addressCrossStreet,
          ticketStatus: serviceTicketDetails.ticketStatus,
          stNotes: serviceTicketNotes,
          additionalInfo: serviceTicketDetails.additionalInfo,
          percentComplete: serviceTicketPercentage,
          values_Headers_Custom_ServiceTicket: serviceTicketDetails.values_Headers_Custom_ServiceTicket?.map((x: any) => {
            return {
              id: x.id,
              serviceContractFieldId: x.serviceContractFieldId,
              fieldValue: x.fieldValue
            }
          })
        };
        setNotesLoader(true)
        const res = await put(url, payload);
        if (res.status === 200) {
          setServiceTicketDetails({
            ...serviceTicketDetails,
            stNotes: serviceTicketNotes,
            percentComplete: serviceTicketPercentage
          });
        }
      }

    } catch (err) {
      console.log(err);
    } finally {
      setNotesLoader(false);
    }
  };

  // eslint-disable-next-line react-hooks/exhaustive-deps
  useEffect(() => {
    const timerId = setTimeout(() => {
      if(serviceTicketDetails.serviceContractId){
        updateServiceTicket();
      }
    }, 1000);
  
    return () => clearTimeout(timerId);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [serviceTicketNotes]);

  const updateService = useCallback(() => {
    // eslint-disable-next-line
    if (serviceTicketDetails.percentComplete !== serviceTicketPercentage) {
      updateServiceTicket();
    }
    // eslint-disable-next-line
  }, [serviceTicketPercentage]);

  useEffect(() => {
    updateService();
  }, [updateService])

  const updateServiceQty = (index: number, data: any) => {
    setEditedTicketDetails((currentState) => {
      // Filter the properties of the current state where editFieldKey === 'serviceQty' and update value
      const updatedState = Object.fromEntries(
        Object.entries(currentState).map(([key, value]) => {
          if (value?.editFieldKey === 'serviceQty') {
            // Update properties
            value.serviceQty = data.serviceQty;
            value.isChange = data.isChange;
            value.servQtyActIdx = index;
          }
          return [key, value];
        })
      );
      return updatedState;
    });
  }

  // set the edit activity values
  const editActivityDetails = (index: number, data: any) => {
    if (data.hasOwnProperty('serviceQty')) {
      updateServiceQty(index, data)
    } else {
      setEditedTicketDetails(() => {
        return {
          ...editedTicketDetails,
          [index]: {
            ...editedTicketDetails[index],
            ...data
          },
        };
      });
    }
  };

  // Find service ticket id
  const getServiceTicketId = (value?: any) => {
    let serviceTicketId;
    if (value) {
      serviceTicketId = value.id || selectedServiceTicketsDetails.serviceTicketsId[value - 1];
    } else if (props.match.params.serviceTicketId) {
      serviceTicketId = props.match.params.serviceTicketId;
    } else {
      serviceTicketId = selectedServiceTicketsDetails.serviceTicketsId[activeTab - 1];
    }
    return serviceTicketId;
  }

  // API : Get service ticket details
  const getServiceTicketDetails = async (index?: number | undefined) => {
    let serviceTicketId = getServiceTicketId(index);
    // get serviceTicketId

    let url;
    let projectId = '';
    // get service items for timecard
    if (props.match.params.serviceTicketId) {
      url = `/v1/api/service/ticket/id/${props.match.params.serviceTicketId}`;
      projectId = selectedProjectId;
    } else {
      url = `/v1/api/service/ticket/item-activity?serviceTicketId=${serviceTicketId}&timeCardId=${timecardId}&tenantId=${tokenDetails?.tenantId}`;
      projectId = selectedCostCodeProject[0].id;
    }
    setPageLoader(true);
    const { data } = await get(url);
    let res: any;
    res = props.match.params.serviceTicketId ? data : data.data
    const resData = {
      project: { id: projectId, name: '' },
      serviceContract: { id: res.serviceContractId, name: '' },
    }
    
    checkIsQuantityAvailable(res.activityList);
    setServiceTicketDetails({
      ...res,
      ...getProjectServiceLeadman(resData)
    });
    setServiceTicketNotes(res.stNotes);
    setServiceTicketPercentage(res.percentComplete);
    setPageLoader(false);
  };

  const cancelModal = () => {
    setmodalActive(false);
    setModalRemark("");
  };

  const handleTimeCardSubmit = async (updateIndex: any) => {
    try {
      if (updateIndex) {
        setPageLoader(true);
        const res = await updateTimeCardStatus(timecardId, updateIndex, modalRemark);
        if (res.status === 200) {
          history.push(`/timecardList/${timecardId}`);
        }
      }
    } catch (error) {
      setPageLoader(false);
    } finally {
      setmodalActive(false);
      setPageLoader(false);
    }
  }

  const checkIsQuantityAvailable = (sourceArray: any) => {
    // set false if quantity is zero in all activity 
    const isAvailable = sourceArray.find((e: any) => e.serviceQty > 0) ? true : false;
    setQuantityAvailable(isAvailable);
  }

  const deleteItem = async (activityId: number) => {
    const url = `/v1/api/service/ticket/activity/${activityId}`;
    setPageLoader(true);
    await deleteAPI(url);
    setConfirmationModalActive(false);
    getServiceTicketDetails();
  };

  const editActivity = async (index: number) => {
    if (
      editedTicketDetails[index]
    ) {
      const url = `/v1/api/service/ticket/activity/${serviceTicketDetails.activityList[index].id}`;
      const dynamicFieldsData = [] as any;
      editedTicketDetails[index].fields.forEach((e: any) => {
        if (e.fieldValue && (e.serviceTicketItemId === serviceTicketDetails.activityList[index].id)) {
          dynamicFieldsData.push({
            serviceItemFieldValue: e.fieldValue,
            customFieldIndex: e.customFieldIndex,
          });
        }
      });
      // prepare payload
      const payload = {
        id: serviceTicketDetails.activityList[index].id,
        tenantId: tokenDetails?.tenantId,
        serviceContractItemId:
          serviceTicketDetails.activityList[index].serviceContractItemId,
        serviceTicketId:
          serviceTicketDetails.activityList[index].serviceTicketId,
        activityList: [] as any,
        fields: dynamicFieldsData || [],
        estimatedQuantity: editedTicketDetails[index].estimatedQuantity!,
        isChange: editedTicketDetails[index].isChange ? true : false,
      };
      setPageLoader(true);
      const res = await put(url, payload);
      setPageLoader(false);
      if (res.status === 200) {
        // instead of calling the service ticket details api again
        // we copy the edited values in the original value keys
        // only if the edit api is successful
        setCopiedValuesToOriginal(index);
        setEditedTicketDetails(current => {
          const { [index]: deletedObj, ...rest } = current;
          return rest;
        });
      }
    }
  };

  const updateActivityQuantityAndHours = async (index: any) => {
    const url = `/v1/api/service/ticket/item-activity`;
    // prepare payload
    const sQty = editedTicketDetails[index].serviceQty || 0;
    let payload = {
      id: serviceTicketDetails.activityList[index].serviceItemActivityId,
      tenantId: tokenDetails?.tenantId,
      serviceTicketItemId: serviceTicketDetails.activityList[index].id,
      timeCardId: Number(timecardId),
      serviceQty: sQty,
      isOutOfScope: editedTicketDetails[index].isOutOfScope!,
      serviceHours: serviceTicketDetails.activityList[index].unitHours * sQty,
    };
    setPageLoader(true);
    const res = await put(url, payload);
    setPageLoader(false);
    if (res.status === 200) {
      setCopiedValuesToOriginal(index);
      setEditedTicketDetails(current => {
        const { [index]: deletedObj, ...rest } = current;
        return rest;
      });
    }
  };

  const getCreateTicketApi = async () => {
    let url = `/v1/api/service/ticket/loadcreateticket?companyId=${companyId}`;
    url = serviceTicketDetails.project?.id ? `${url}&projectId=${serviceTicketDetails.project.id}` : url;
    setPageLoader(true)
    const { data } = await get(url);
    setPageLoader(false)
    setServiceValue((prevServiceValue) => {
      return {
        ...prevServiceValue,
        projects: data.projects || prevServiceValue.projects,
        serviceContracts:
          data.serviceContract || prevServiceValue.serviceContracts,
      };
    });
  };

  // LIFECYCLE METHODS
  // set service ticket active tab on load 
  useEffect(() => {
    const activeIndex = selectedServiceTicketsDetails.activeIndex;
    // next, submit button hide if first tab is not active
    setIsSubmitNextButtonActive(activeIndex ? false : true);
    toggle(activeIndex + 1);
    getServiceTicketDetails(activeIndex + 1);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedServiceTicketsDetails.activeIndex]);

  // creating the dynamic activity list array using memo
  const dynamicActivityListDom = useMemo(() => {
    return generateDynamicValues(serviceTicketDetails);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [serviceTicketDetails.activityListHeaders]);

  // creating the array of existing serviceContractItemIds
  const existingServiceContractItemIds = useMemo(() => {
    return serviceTicketDetails.activityList.map((eachActivity) => {
      return eachActivity.serviceContractItemId;
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [serviceTicketDetails.activityList]);

  useEffect(() => {
    updateServiceTicketDetails();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [serviceValue])

  const updateServiceTicketDetails = () => {
    // setting contract and lineman name, when id is already set
    // used for populating the dropdowns while editing the ticket details
    if ((serviceTicketDetails.serviceContract?.id && !serviceTicketDetails.serviceContract?.name)) {
      setServiceTicketDetails(prevServiceTicketDetails => {
        const data = getProjectServiceLeadman(prevServiceTicketDetails);
        return {
          ...serviceTicketDetails,
          ...data
        }
      })
    }

    if (serviceTicketDetails.serviceContract?.id) {
      const baseFields = [
        {
          label: 'Project',
          value: getProjectServiceLeadman(serviceTicketDetails).project.name,
          show: true
        },
        {
          label: 'Service Contract',
          value: getProjectServiceLeadman(serviceTicketDetails).serviceContract.name,
          show: true
        }
      ];

      // Ensure 'Project' and 'Service Contract' are removed from the existing ticketLabelValuePairs
      let updatedLabelValuePairs = ticketLabelValuePairs.filter(
        item => item.label !== 'Project' && item.label !== 'Service Contract'
      );

      updatedLabelValuePairs = updatedLabelValuePairs.map(item => {
        const matchingField = serviceTicketDetails.headers_Native_ServiceTicket?.find((field: any) => {
          switch (field.fieldName) {
            case 'workOrderNumber':
              return item.label === 'Work Order';
            case 'poleNumber':
              return item.label === 'Pole #';
            case 'poNumber':
              return item.label === 'Purchase Order';
            case 'addressCrossStreet':
              return item.label === 'Address/Cross Street';
            case 'city':
              return item.label === 'Town';
            case 'state':
              return item.label === 'State';
            case 'additionalInfo':
              return item.label === 'Additional Information';
            default:
              return false;
          }
        });

        if (matchingField) {
          //@ts-ignore
          return { ...item, value: serviceTicketDetails[matchingField.fieldName] || '', show: true };
        }

        return item;
      });

      serviceTicketDetails.values_Headers_Custom_ServiceTicket?.forEach((valueItem: any) => {
        const matchingCustomField = serviceTicketDetails.headers_Custom_ServiceTicket?.find((customField: any) => {
          return customField.id === valueItem.serviceContractFieldId;
        });

        if (matchingCustomField) {
          const alreadyExists = updatedLabelValuePairs.some(pair => pair.label === matchingCustomField.fieldName);
          
          if (!alreadyExists) {
            updatedLabelValuePairs.push({
              label: matchingCustomField.fieldName,
              value: valueItem.fieldValue || '',
              show: true
            });
          }
        }
      });

      updatedLabelValuePairs = [...baseFields, ...updatedLabelValuePairs];

      const uniqueLabelValuePairs = Array.from(
        new Map(updatedLabelValuePairs.map(item => [item.label, item])).values()
      );

      setTicketLabelValuePairs(uniqueLabelValuePairs);
    }
  }

  const getProjectServiceLeadman = (prevServiceTicketDetails: any) => {
    return {
      project: serviceValue.projects.find((eachProject) => (eachProject.id === prevServiceTicketDetails.project?.id)) || prevServiceTicketDetails.project,
      serviceContract: serviceValue.serviceContracts.find((eachContract) => (eachContract.id === prevServiceTicketDetails.serviceContract?.id)) || prevServiceTicketDetails.serviceContract,
    };
  }

  useEffect(() => {
    if (companyId && serviceTicketDetails.project?.id && props.match.params.serviceTicketId) {
      getCreateTicketApi();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [serviceTicketDetails.project?.id]);

  useEffect(() => {
    if (companyId && serviceTicketDetails.id !== 0) {
      serviceTicketDetails.values_Headers_Custom_ServiceTicket?.forEach((valueItem: any) => {
        const matchingCustomField = serviceTicketDetails.headers_Custom_ServiceTicket?.find((customField: any) => {
          return customField.id === valueItem.serviceContractFieldId;
        });
  
        if (matchingCustomField) {
          const alreadyExists = labelValuePairs.some(pair => pair.label === matchingCustomField.fieldName);
          
          if (!alreadyExists) {
            labelValuePairs.push({
              label: matchingCustomField.fieldName,
              value: valueItem.fieldValue || '',
              show: true
            });
          }
        }
      });
  
      setTicketLabelValuePairsTimecard(labelValuePairs)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [serviceTicketDetails.id]);

  const selectActivityModalContainer = (
    <SelectActivityModal
      modal={selectActivityModal}
      toggleModal={toggleSelectActivityModal}
      serviceTicketId={props.match.params.serviceTicketId || selectedServiceTicketsDetails.serviceTicketsId[activeTab - 1]}
      accessPath={props.match.params.serviceTicketId ? AccessPath.SERVICE_TICKET : AccessPath.TIMECARD}
      serviceContractId={serviceTicketDetails.serviceContract?.id}
      existingServiceContractItemIds={existingServiceContractItemIds}
    />
  );

  const [confirmationModalActive, setConfirmationModalActive] = useState(false);
  const [confirmationModalData, setConfirmationModalData] = useState({
    heading: "Delete",
    subHeading:
      "This item has history.  Deleting it will remove it from all timecards along with its quantities.  Continue?",
    modalName: "delete",
    activityId: 0
  } as any);

  const handleConfirmationModalSubmit = () => {
    const activitiesList = activitiesByItemId;
    activitiesList.forEach(async (element: any, index: number) => {
      await deleteActivityOfItem(element.id);
      if (index === activitiesList.length - 1) {
        deleteItem(confirmationModalData.activityId);
      }
    });
  }

  const [activitiesByItemId, setActivitiesByItemId] = useState([] as any);
  const getActivitiesByServiceTicketItemId = async (activityId: any) => {
    setPageLoader(true);
    const url = `/v1/api/service/ticket/item-activity/${activityId}`;
    const { data } = await get(url);
    if (data && data.data && data.data.length) {
      setActivitiesByItemId(data.data);
      setConfirmationModalData({ ...confirmationModalData, activityId })
      setConfirmationModalActive(true);
      setPageLoader(false)
    } else {
      deleteItem(activityId);
    }
  }

  const deleteActivityOfItem = async (id: any) => {
    const url = `/v1/api/service/ticket/item-activity/${id}`;
    await deleteAPI(url);
  }

  const renderTh = (field: any, index: number) => {
    return (
      <DragDropTableHeader
        key={index}
        field={field}
        index={index}
        handleAssignedFields={handleAssignedFields}
        children={field.fieldName}
      />
    );
  };

  const saveActivity = (index: any) => {
    if (!editAmtErrorCode) {
      editActivity(index)
      setEditAmtErrorCode(false);
    } else {
      setEditAmtErrorCode(true);
    }
  }

  const saveActualQuantity = () => {
    for (const key in editedTicketDetails) {
      if (
        editedTicketDetails[key]?.isEdit === false &&
        editedTicketDetails[key]?.editFieldKey === 'serviceQty'
      ) {
        updateActivityQuantityAndHours(key as any)
      }
    }
  };

  const handleAssignedFields = async (dragIndex: number, hoverIndex: number) => {
    try {
      setPageLoader(true);
      let updatedOrderData = [] as any;
      if (serviceTicketDetails.stActivityListHeaders && serviceTicketDetails.stActivityListHeaders.length) {
        updatedOrderData = [...serviceTicketDetails.stActivityListHeaders];
        updatedOrderData.splice(dragIndex, 1);
        updatedOrderData.splice(hoverIndex, 0, serviceTicketDetails.stActivityListHeaders[dragIndex]);
        updatedOrderData.forEach((element: any, index: number) => {
          element.order = index;
        });
      }
      const payload = {
        serviceContractId: serviceTicketDetails.serviceContract?.id,
        belongsTo: 'ST',
        assignedFields: [...removeExtraField(updatedOrderData, ['belongsTo', 'customFieldIndex', 'fieldValue'])],
      }
      // @ts-ignore
      const res = await put(
        `/v1/api/service/contract/assignedFields`,
        payload
      );
      if (res) {
        getServiceTicketDetails();
      }
    } catch (error) {
      setPageLoader(false);
    }
  };



  // Main polling function
  const poll = async (id: number, start: number) => {
    const url = `${process.env.REACT_APP_RISKCAST_API_URL}/api/core/reportlogs/${id}`;
    const res = await get(url);
    const reportLog = res.data;
    // Has end-state been reached?
    if (reportLog.reportProcessStatus === ReportConditions.endStatusCondition) {
      const [projectDisplayNumber] = serviceTicketDetails.serviceTicketDisplayNumber?.split("-ST");
      const fileName = `${projectDisplayNumber}-${serviceTicketDetails.workOrderNumber}-${serviceTicketDetails.poleNumber}`;
      await signFileURL(reportLog.fileUrl, fileName);
      setPageLoader(false);
    } else {
      if (Date.now() - start > timeOut) {
        throw new Error('Timeout error on polling');
      } else {
        // poll again with a short delay
        await delay(interval);
        await poll(id, start);
      }
    }
  };

  // Retrieves the report URL for service ticket 
  const getReportTypeDetails = async () => {
    const url = `${process.env.REACT_APP_RISKCAST_API_URL}/api/reports/field/reporttype/${reportsShortId.serviceTicketReportShortId}`;
    const res = await get(url);

    return res.data?.reportURL || '/reports/field/serviceticket/serviceticketreport';
  };

  const printPage = async () => {
    try {
      setPageLoader(true);

      // get report type details
      const reportURL = await getReportTypeDetails();

      // call service ticket reports api 
      const url = `${process.env.REACT_APP_RISKCAST_API_URL}/api${reportURL}`;

      // call service ticket reports api 
      const payload = {
        serviceTicketId: props.match.params.serviceTicketId || selectedServiceTicketsDetails.serviceTicketsId[activeTab - 1],
        timeCardId: Number(timecardId),
        jsReportShortId: reportsShortId.serviceTicketReportShortId
      };

      const res = await post(url, payload);

      if (res.data && res.data.id) {
        await poll(res.data.id, Date.now());
      } else {
        setPageLoader(false);
      }
    } catch (error) {
      setPageLoader(false);
      // Add toast message
      if (error instanceof Error) {
        toast.warn(error.message, {
          autoClose: 5000,
          toastId: 'stReport'
        });
      }
    }
  };

  const activityListDom =
    <div className="st-list-wrapper">
      <div className="st-list-heading d-flex align-items-center justify-content-between">
        <h3 className="heading-sub">
          Activity List
          <span className="heading-sub-entries">
            Showing entries:{" "}
            <b>{serviceTicketDetails.activityList.length}</b>
          </span>
        </h3>
        <div className="st-list-btns">
          <button
            className="btn btn-primary"
            onClick={() => setFileModal(!fileModal)}
          >
            <span className=""></span>
            Upload Activity Data
          </button>
          <button
            className="btn btn-primary"
            onClick={() => toggleSelectActivityModal()}
            disabled={!ticketAccess.update}
          >
            <span className="copy-acti"></span>
            Select Activity
          </button>

        </div>
      </div>
      <div className="st-list-content">
        <div className="r-table-wrapper fixed-width-table">
          <DndProvider backend={isMobile ? TouchBackend : HTML5Backend}>
            <table className="table">
              <thead>
                <tr>
                  <th>Pay Item</th>
                  <th>Activity</th>
                  <th>UOM</th>
                  {serviceTicketDetails.activityListHeaders.map(
                    (eachHeader: any) => {
                      return (eachHeader.isActive === 1 ? (
                        <th key={`activity-list-header-${eachHeader.fieldName}`}>
                          {eachHeader.fieldName}
                        </th>
                      ) : false)
                    })}
                  <th>Unit Hours</th>
                  {serviceTicketDetails.stActivityListHeaders.map(
                    (e: any, index: number) => {
                      return (e.isActive === 1 ? (
                        renderTh(e, index)
                      ) : false)
                    })}
                  <th>Est. Qty</th>
                  {/* check param */}
                  {!props.match.params.serviceTicketId ? (
                    <>
                      <th>Est. Targ Hrs</th>
                      <th>Act. Qty</th>
                      <th>Act. Targ</th>
                      <th>Out of Scope</th>
                    </>
                  ) : null}
                  <th>Action</th>
                </tr>
              </thead>
              <tbody>
                {serviceTicketDetails.activityList.map(
                  (eachActivity, index) => {
                    return (
                      <tr key={`${eachActivity.activity}-${index}`}>
                        <td>{eachActivity.payItem}</td>
                        <td>{eachActivity.activity}</td>
                        <td>{eachActivity.UOM}</td>
                        {dynamicActivityListDom![index]
                          ? dynamicActivityListDom![index]
                          : ""}
                        <td>{eachActivity.unitHours}</td>

                        {serviceTicketDetails.stActivityListHeaders.map((field: any, activityIndex: number) => {
                          if (field.isActive === 1) {
                            // edit dynamic fields
                            const fieldsArray = editedTicketDetails[index] && editedTicketDetails[index]['fields'] ? editedTicketDetails[index]['fields'] : [];
                            const fieldIndex = fieldsArray.findIndex((eachField: any) => {
                              return (
                                (field.customFieldIndex === eachField.customFieldIndex) &&
                                (eachActivity.id === eachField.serviceTicketItemId))
                            });
                            const currFieldValue = fieldsArray[fieldIndex] && fieldsArray[fieldIndex]['fieldValue'] ? fieldsArray[fieldIndex]['fieldValue'] : '';
                            if ((editedTicketDetails[index]?.isEdit
                              || (editedTicketDetails[index]?.editFieldKey === field.customFieldIndex))
                              && props.match.params.serviceTicketId) {
                              return (
                                <td>
                                  <input
                                    placeholder={`Enter ${field.fieldName}`}
                                    value={currFieldValue || ''}
                                    onChange={(e) => {
                                      const updatedFields = [...editedTicketDetails[index]['fields']] as any;
                                      if (!(fieldIndex >= 0)) {
                                        field['fieldValue'] = e.target.value;
                                        field['serviceTicketItemId'] = eachActivity.id;
                                        updatedFields.push({ ...field });
                                      } else {
                                        updatedFields[fieldIndex]['fieldValue'] = e.target.value;
                                      }
                                      editActivityDetails(
                                        index,
                                        { 'fields': updatedFields }
                                      );
                                    }}
                                    onBlur={() => {
                                      // if single cell is edited
                                      if (editedTicketDetails[index]?.isEdit === false
                                        && editedTicketDetails[index]?.editFieldKey === field.customFieldIndex) {
                                        saveActivity(index);
                                      }
                                    }}
                                  />
                                </td>
                              )
                            } else {
                              // show dynamic fields
                              const res = eachActivity['fields'].find(
                                (eachField: any) =>
                                  (field.customFieldIndex === eachField.customFieldIndex && eachActivity.id === eachField.serviceTicketItemId)
                              );
                              return (
                                <td key={`activity-list-${field?.customFieldIndex + '' + activityIndex}`}
                                  onClick={() => {
                                    // check access and should not be already edit clicked
                                    if (ticketAccess.update && !(editedTicketDetails[activityIndex]?.isEdit
                                      && editedTicketDetails[index]?.editFieldKey === res?.customFieldIndex
                                      && props.match.params.serviceTicketId)) {
                                      setActivityEditable(index, false, res?.customFieldIndex || field.customFieldIndex);
                                    }
                                  }}
                                >
                                  {res?.fieldValue || "-"}
                                </td>
                              )
                            }
                          }
                          return false;
                        })}

                        {/* Est. Qty  */}
                        <td onClick={() => {
                          // check access and should not be already edit clicked
                          if (ticketAccess.update && !(editedTicketDetails[index]?.isEdit
                            && editedTicketDetails[index]?.editFieldKey === 'estimatedQuantity'
                            && props.match.params.serviceTicketId)) {
                            setActivityEditable(index, false, 'estimatedQuantity');
                          }
                        }}
                        >
                          {(editedTicketDetails[index]?.isEdit
                            || editedTicketDetails[index]?.editFieldKey === 'estimatedQuantity') && props.match.params.serviceTicketId ? (
                            <>
                              <input
                                value={
                                  editedTicketDetails[index]
                                    ?.estimatedQuantity
                                }
                                onChange={(e) => {
                                  if (isNumeric(e.target.value) || e.target.value === '') {
                                    setEditAmtErrorCode(false);
                                  } else {
                                    setEditAmtErrorCode(true);
                                  }
                                  editActivityDetails(
                                    index,
                                    { 'estimatedQuantity': e.target.value }
                                  );
                                }}
                                onBlur={() => {
                                  // if single cell is edited
                                  if (editedTicketDetails[index]?.isEdit === false
                                    && editedTicketDetails[index]?.editFieldKey === 'estimatedQuantity') {
                                    saveActivity(index);
                                  }
                                }}
                              />
                              <div className="errorBox">
                                {editAmtErrorCode ? (
                                  <span className="text-danger help-block">
                                    Please Enter Numeric Value
                                  </span>
                                ) : null}
                              </div>
                            </>
                          ) : (
                            eachActivity.estimatedQuantity
                          )}
                        </td>
                        {/* Est. Targ Hrs */}
                        {!props.match.params.serviceTicketId &&
                          <td>{eachActivity.unitHours * eachActivity.estimatedQuantity}</td>
                        }
                        {/* Quantity / Act. Qty */}
                        {!props.match.params.serviceTicketId &&
                          <td onClick={() => {
                            // check timecard open status, access and should not be already edit clicked
                            if (selectedServiceTicketsDetails.timeCardStatus === 1
                              && ticketAccess.update && !editedTicketDetails[index]?.isEdit
                              && !(editedTicketDetails[index]?.editFieldKey === 'serviceQty')) {
                              setActivityEditable(index, false, 'serviceQty');
                            } else {
                              setEditedTicketDetails(current => {
                                const { [index]: deletedObj, ...rest } = current;
                                return rest;
                              });
                            }
                          }}
                            style={{
                              backgroundColor: (editedTicketDetails[index]?.isEdit || editedTicketDetails[index]?.editFieldKey === 'serviceQty')
                                ? '#F2F7F6'
                                : '#fff'
                            }}
                          >
                            {(editedTicketDetails[index]?.isEdit
                              || editedTicketDetails[index]?.editFieldKey === 'serviceQty') ? (
                              <div>
                                <input
                                  style={{ marginRight: '5px' }}
                                  value={
                                    editedTicketDetails[index]?.serviceQty
                                  }
                                  onClick={(e) => e.stopPropagation()}
                                  onChange={(e) => {
                                    editActivityDetails(
                                      index,
                                      {
                                        'serviceQty': e.target.value,
                                        'isChange': !eachActivity.isChange && e.target.value ? true : false
                                      }
                                    );
                                  }}
                                  onBlur={() => {
                                    if (index === editedTicketDetails[index]?.servQtyActIdx
                                      && editedTicketDetails[index]?.isEdit === false
                                      && editedTicketDetails[index]?.editFieldKey === 'serviceQty') {
                                      saveActualQuantity();
                                    }
                                  }}
                                  onKeyDown={(e) => {
                                    if (e.key === 'Enter') {
                                      if (editedTicketDetails[index]?.isEdit === false
                                        && editedTicketDetails[index]?.editFieldKey === 'serviceQty') {
                                        saveActualQuantity();
                                      }
                                    }
                                  }}
                                />
                                {/* Enable the below code if the service quantity save button is necessary. */}
                                {/* <button
                                  type="button"
                                  className="btn btn-primary btn-sm"
                                  onClick={(e) => {
                                    e.preventDefault();
                                    if (editedTicketDetails[index]?.isEdit === false
                                      && editedTicketDetails[index]?.editFieldKey === 'serviceQty') {
                                      saveActualQuantity(Number(editedTicketDetails[index]?.serviceQty));
                                    }
                                  }}
                                >
                                  Save
                                </button> */}
                              </div>
                            ) : (
                              eachActivity.serviceQty
                            )}
                          </td>
                        }
                        {/* Planned hour / Act. Targ*/}
                        {!props.match.params.serviceTicketId &&
                          <td>{eachActivity.unitHours * eachActivity.serviceQty}</td>
                        }
                        {!props.match.params.serviceTicketId && (
                          <td onClick={() => {
                            // check timecard open status, access and should not be already edit clicked
                            if (selectedServiceTicketsDetails.timeCardStatus === 1
                              && ticketAccess.update && !editedTicketDetails[index]?.isEdit
                              && !(editedTicketDetails[index]?.editFieldKey === 'isOutOfScope')) {
                              setActivityEditable(index, false, 'isOutOfScope');
                            }
                          }}>
                            {(editedTicketDetails[index]?.isEdit
                              || editedTicketDetails[index]?.editFieldKey === 'isOutOfScope') ? (
                              <Checkbox
                                checked={editedTicketDetails[index]?.isOutOfScope ? true : false}
                                onChange={(
                                  e: any,
                                  checked: boolean
                                ) => {
                                  editActivityDetails(
                                    index,
                                    {
                                      'isOutOfScope': checked ? 1 : 0,
                                    }
                                  );
                                }}
                                onBlur={() => {
                                  if (editedTicketDetails[index]?.isEdit === false
                                    && editedTicketDetails[index]?.editFieldKey === 'isOutOfScope') {
                                    saveActivity(index);
                                    setHasChangedOutOfScope(!hasChangeOutOfScope);
                                  }
                                }}
                                value={editedTicketDetails[index]?.isOutOfScope}
                                name={`checkBox-${index}`}
                                id={`column-${index}`}
                              />
                            ) : (
                              eachActivity.isOutOfScope ? 'Yes' : 'No'
                            )}
                          </td>
                        )}
                        {/* save and cancel button */}
                        <td>
                          {editedTicketDetails[index]?.isEdit ? (
                            <div className="action-wrapper">
                              <button
                                type="button"
                                className="btn btn-primary"
                                onClick={() => {
                                  saveActivity(index);
                                }}
                              >
                                Save
                              </button>
                              <button
                                type="button"
                                className="btn btn-secondary"
                                onClick={() => {
                                  setActivityEditable(index, false, '');
                                }}
                              >
                                Cancel
                              </button>
                            </div>
                          ) : (
                            <div className="action-wrapper">
                              {/* <button
                                type="button"
                                className="btn btn-secondary"
                                disabled={!ticketAccess.update}
                                onClick={() => {
                                  setActivityEditable(index, true, '');
                                }}
                              >
                                Edit
                              </button> */}
                              {props.match.params.serviceTicketId || (eachActivity.isChange && eachActivity.estimatedQuantity === 0) ? (
                                <button
                                  type="button"
                                  className="btn btn-secondary"
                                  disabled={!ticketAccess.update}
                                  onClick={() => {
                                    getActivitiesByServiceTicketItemId(eachActivity.id);
                                  }}
                                >
                                  Delete
                                </button>
                              ) : ''}
                            </div>
                          )}
                        </td>
                      </tr>
                    );
                  }
                )}
              </tbody>
            </table>
          </DndProvider>
        </div>
      </div>
    </div>

  return (
    <div className="ticket-details-wrapper">
      <ToastContainer
        position="top-right"
        autoClose={5000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
      />
      <Header
        breadcrumb={"Service Ticket Details"}
        title={"Service Ticket Details"}
      />
      <Nav tabs>
        {props.match.params.serviceTicketId ? (
          // single service ticket
          <NavItem>
            <NavLink
              className={classnames({ active: activeTab === 1 })}
              onClick={() => {
                toggle(1);
              }}
            >
              Service Ticket
            </NavLink>
          </NavItem>
        ) : (
          // list of service tickets
          selectedServiceTicketsDetails.serviceTicketsId.map(
            (e: any, index: number) => {
              return (
                <NavItem
                  key={e + " " + index}
                  className={classnames({ disabled: index + 1 > activeTab })}
                  disabled={index + 1 > activeTab ? true : false}
                >
                  <NavLink
                    className={classnames({ active: activeTab === index + 1 })}
                    disabled={index + 1 > activeTab ? true : false}
                    onClick={() => {
                      toggle(index + 1);
                      getServiceTicketDetails(index + 1);
                    }}
                  >
                    Service Ticket {e}
                  </NavLink>
                </NavItem>
              );
            }
          )
        )}
      </Nav>
      {pageLoader && <Loader />}
      <TabContent activeTab={activeTab}>
        {props.match.params.serviceTicketId ? (
          // single tab
          <TabPane tabId={1}>
            <div className="ticket-info-wrapper">
              <div className="ticket-information">
                <div className="row">
                  <div className="col-12">
                    <div className="ticket-info-header">
                      <div className="ticket-info-title">
                        <h2 className="page-title-sub">
                          Service Ticket{" "}
                          {serviceTicketDetails.serviceTicketDisplayNumber
                            ? serviceTicketDetails.serviceTicketDisplayNumber
                            : ""}
                        </h2>
                        <span className="contract-status status-open">
                          {ticketStatusObj[serviceTicketDetails.ticketStatus]}
                        </span>
                      </div>
                      <div className="ticket-info-actions">
                        <button
                          type="button"
                          className="btn btn-secondary"
                          disabled={!ticketAccess.update}
                          onClick={(e) => toggleEditTicketModal()}
                        >
                          Edit
                        </button>
                      </div>
                    </div>
                    <div className="ticket-info-list">
                      <div className="row">
                        {serviceTicketDetails.project?.name !== '' && ticketLabelValuePairs.map((pair, index) => (
                          <LabelValueInputItem
                            key={index}
                            label={pair.label}
                            value={pair.value}
                            show={pair.show}
                          />
                        ))}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            {activityListDom}
          </TabPane>
        ) : (
          // list of tabs
          selectedServiceTicketsDetails.serviceTicketsId.map(
            (e: any, index: number) => {
              return (
                <TabPane key={e + " " + index} tabId={index + 1}>
                  <div className="ticket-info-wrapper">
                    <div className="ticket-information">
                      <div className="row">
                        <div className="col-6">
                          <div className="ticket-info-header">
                            <h2 className="page-title-sub">
                              Service Ticket{" "}
                              {serviceTicketDetails.serviceTicketDisplayNumber
                                ? serviceTicketDetails.serviceTicketDisplayNumber
                                : ""}
                            </h2>
                            <span className="contract-status status-open">
                              {
                                ticketStatusObj[
                                serviceTicketDetails.ticketStatus
                                ]
                              }
                            </span>
                          </div>
                          <div className="ticket-info-list">
                            <div className="row">
                              {ticketLabelValuePairsTimecard.map((pair: any, index: any) => (
                                <LabelValueInputItem
                                  key={index}
                                  label={pair.label}
                                  value={pair.label === 'Percent Complete' ? serviceTicketPercentage : pair.value} // Dynamically pass the state value
                                  onChange={pair.label === 'Percent Complete' ? (e: React.ChangeEvent<HTMLInputElement>) => setServiceTicketPercentage(parseInt(e.target.value)) : undefined}
                                  input={pair.input}
                                  show={pair.show}
                                />
                              ))}
                            </div>
                          </div>
                        </div>
                        <div className="col-6">
                          <div className="ticket-info-timecard">
                            <div className="ticket-timecard-heading">
                              <div className="ticket-timecard-calendar">
                                <h2 className="page-title-sub">
                                  {moment(timeCardDate.toString()).format(
                                    "MMMM Do, YYYY"
                                  )}
                                  {/* eslint-disable-next-line */}
                                  <a
                                    className="ticket-calendar"
                                    //  eslint-disable-next-line
                                    href="javascript:void(0)"
                                  ></a>
                                </h2>
                              </div>
                              <div>
                                <div
                                  className="ticket-timecard-view"
                                  onClick={(e) => {
                                    history.push(`/timecardList/${timecardId}`);
                                  }}
                                >
                                  <span className="btn btn-secondary">
                                    <span className="ticket-time"></span>
                                    View Timecard
                                  </span>
                                </div>
                                <div
                                  className="ticket-timecard-view"
                                  onClick={(e) => {
                                    printPage();
                                  }}
                                >
                                  <span className="btn btn-secondary">
                                    Print
                                  </span>
                                </div>
                              </div>
                            </div>
                            <div className="ticket-timecard-body">
                              <div className="ticket-info-list">
                                <div className="row">
                                  <div className="col-4">
                                    <div className="text-label-value">
                                      <div className="text-label">
                                        Today's Target Hours:{" "}
                                      </div>
                                      <div className="text-value">
                                        {serviceTicketDetails.todayTargetHours}
                                      </div>
                                    </div>
                                  </div>
                                  <div className="col-4">
                                    <div className="text-label-value">
                                      <div className="text-label">
                                        Today's Payroll Hours:{" "}
                                      </div>
                                      <div className="text-value">
                                        {serviceTicketDetails.todayPayrollHours}
                                      </div>
                                    </div>
                                  </div>
                                  <div className="col-4">
                                    <div className="text-label-value">
                                      <div className="text-label">
                                        Workers:{" "}
                                      </div>
                                      <div className="text-value">
                                        {serviceTicketDetails.workerCount}
                                      </div>
                                    </div>
                                  </div>
                                </div>
                                <div className="row">
                                  {notesLoader && (
                                    <Loader showComponentLoader={true} />
                                  )}
                                  <div className="col-12">
                                    <h2 className="page-title-sub">Notes</h2>
                                    <div className="notes-section form-group">
                                      <textarea
                                        className="form-control"
                                        id="ticketNotes"
                                        value={serviceTicketNotes}
                                        onChange={(e) => {
                                          setServiceTicketNotes(e.target.value);
                                        }}
                                        aria-describedby="ticket Notes"
                                        placeholder="Enter Notes"
                                      ></textarea>
                                      {/* eslint-disable-next-line */}
                                      <a
                                        className="send-note"
                                        // eslint-disable-next-line
                                        href="javascript:void(0);"
                                        onClick={() => updateServiceTicket()}
                                      ></a>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>

                  {activityListDom}
                  {/* timecard open condition check  */}
                  {isSubmitNextButtonActive &&
                    selectedServiceTicketsDetails.timeCardStatus === 1 ? (
                    <div className="cta-btn-wrapper">
                      {/* condition check for ST last tab  */}
                      {index <
                        selectedServiceTicketsDetails.serviceTicketsId.length -
                        1 ? (
                        <button
                          type="button"
                          className="btn btn-primary"
                          onClick={() => {
                            toggle(index + 2);
                            getServiceTicketDetails(index + 2);
                          }}
                          disabled={
                            !(quantityAvailable || serviceTicketDetails.stNotes)
                          }
                        >
                          Next
                        </button>
                      ) : (
                        <button
                          type="button"
                          className="btn btn-primary"
                          disabled={
                            !(quantityAvailable || serviceTicketDetails.stNotes || hasChangeOutOfScope)
                          }
                          onClick={(e) => {
                            e.preventDefault();
                            setDataIndex(3);
                            setmodalActive(true);
                          }}
                        >
                          Submit
                        </button>
                      )}
                    </div>
                  ) : null}
                </TabPane>
              );
            }
          )
        )}
        {selectActivityModal && selectActivityModalContainer}
      </TabContent>
      {editTicketModal ? (
        <ServiceTicketCreate
          toggle={toggleEditTicketModal}
          modal={editTicketModal}
          serviceTicketId={Number(props.match.params.serviceTicketId)}
          projectId={serviceTicketDetails.project?.id || 0}
          postUpdate={getServiceTicketDetails}
        />
      ) : null}

      {/* delete activity confiramtion modal for timecard flow  */}
      {confirmationModalActive ? (
        <>
          <Modal
            isOpen={confirmationModalActive}
            fade={false}
            toggle={() => setConfirmationModalActive(false)}
            className="delete-timecard-modal modal-md"
          >
            <ModalHeader toggle={() => setConfirmationModalActive(false)}>
              {confirmationModalData.heading}
            </ModalHeader>
            <ModalBody>
              <form className="delete-timecard-form">
                <p>{confirmationModalData.subHeading}</p>
                <ModalFooter>
                  <Button
                    color="secondary"
                    onClick={() => setConfirmationModalActive(false)}
                  >
                    Cancel
                  </Button>
                  <Button
                    color="primary"
                    type="button"
                    onClick={() => {
                      handleConfirmationModalSubmit();
                    }}
                  >
                    {confirmationModalData.modalName}
                  </Button>{" "}
                </ModalFooter>
              </form>
            </ModalBody>
          </Modal>
        </>
      ) : null}

      {/* Confirmation Popup */}
      {modalActive && (
        <ConfirmModal
          modalActive={modalActive}
          toggleCard={() => setmodalActive(false)}
          modalData={modalDataArray[dataIndex]}
          cancelModal={() => cancelModal()}
          setModalRemark={(e: any) => setModalRemark(e)}
          handleTimeCardSubmit={(e: any) => handleTimeCardSubmit(e)}
        />
      )}

      {/* Upload Activity file */}
      <UploadActivityModal
        isOpen={fileModal}
        toggle={() => setFileModal(!fileModal)}
        serviceTicketId={props.match.params.serviceTicketId}
        tokenDetails={tokenDetails}
        timecardId={timecardId}
        getServiceTicketId={getServiceTicketId}
        serviceTicketDetails={serviceTicketDetails}
        existingServiceContractItemIds={existingServiceContractItemIds}
        selectedServiceTicketsDetails={selectedServiceTicketsDetails}
        activeTab={activeTab}
        getServiceTicketDetails={getServiceTicketDetails}
      />

    </div>
  );
};

export default withRouter(ServiceTicketDetails);
export { generateDynamicValues };
