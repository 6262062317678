import { useState, useEffect, useMemo } from "react";
import moment from "moment";
import { useHistory } from "react-router-dom";
import Header from "../shared/Header/Header";
import FilterTimecard from "./FilterTimecard/FilterTimecard";
import "moment-timezone";
import Loader from "../shared/Loader/Loader";
import { get, post } from "../../types/api";
import { useServiceStore, useTimeCardStore, useAuthStore } from "../../store";
import shallow from "zustand/shallow";
import FullCalendar from '@fullcalendar/react'
import dayGridPlugin from '@fullcalendar/daygrid'
import timeGridPlugin from '@fullcalendar/timegrid'
import interactionPlugin from '@fullcalendar/interaction'
import { isObjEmpty } from "../shared/Helpers";
import SweetAlert from "react-bootstrap-sweetalert";

interface CalendarDates {
  start: string | Date;
  end: string | Date;
}

export default function Timecard() {
  // Side Filter Modal
  const [rightFilter, setModal] = useState(false);
  const toggleFilter = () => setModal(!rightFilter);
  const [filterDropData, setfilterDropData] = useState({} as any);
  // const [filterObj, setFilterObj] = useState({} as any);


  // Calendar states
  const [selectedTimeCardEvents, setSelectedTimeCardEvents] = useState(
    [] as any
  );
  const [timeCardEvents, setTimeCardEvents] = useState([]);
  const [selectedDate, setSelectedDate] = useState('' as string | Date);
  const [calendarDates, setCalendarDates] = useState<CalendarDates>({
    start: '',
    end: ''
  });

  // Page states
  const [loading, setLoading] = useState(false);
  const [filtersLoading, setFiltersLoading] = useState(false);
  const [filterDataloading, setFilterDataLoading] = useState(false);
  const timecardStatus = { APPROVED: 4, OPEN: 1, REJECTED: 3, SUBMITTED: 2 };
  const tmStatusColor = {
    open: "#212121",
    submitted: "#3498DB",
    approved: "#62CB31",
    rejected: "#C0392B",
    exported: "#81C784",
  };

  // Global states
  const orgEntityId = useServiceStore((serviceStore) => serviceStore.companyId);
  const { statusFilter, filterObj } = useTimeCardStore(
    (state) => ({
      statusFilter: state.statusFilter,
      filterObj: state.filterObj,
    }),
    shallow
  );
  const {
    setTimeCardDate,
    setSelectedCostCodeProject,
    setServiceTicketsList,
    setTimeCardEventsData,
    setFilterObj,
  } = useTimeCardStore(timeCardStore => timeCardStore);
  const { selectedProjectsSupervisorsShift } = useTimeCardStore(
    (state) => ({
      selectedProjectsSupervisorsShift: state.selectedProjectsSupervisorsShift,
    }),
    shallow
  );
  const { userSettings } = useAuthStore(
    (state) => ({ userSettings: state.userSettings }),
    shallow
  );

  // Handler/Helper Functions
  const handleEventSelection = ({ event }: { event: any }) => {
    if (event.extendedProps.timeCard && event.extendedProps.timeCard.id) {
      history.push(`/timecardList/${event.extendedProps.timeCard.id}`);
    }
  };

  const handleMonthChange = (payload: any) => {
    setCalendarDates({
      start: new Date(payload.startStr),
      end: new Date(payload.endStr),
    });
  };

  const handleFilterSubmit = (FilterObj: any) => {
    setFilterObj({ ...FilterObj });
    if (Object.keys(FilterObj).length === 0) {
      getFilterData();
      toggleFilter();
      setFilterObj({});
    }
  };

  const handleFilterCross = (key: string, idToRemove: any) => {
    let dataFilter = { ...filterObj };
    dataFilter = {
      ...filterObj,
      [key]: dataFilter[key].filter((id: any) => id !== idToRemove),
    };
    setFilterObj(dataFilter);
  };
  const history = useHistory();

  const clearAllRightFilter = () => {
    setFilterObj({});
  };

  const returnCalData = (timeCard: any) => {
    return {
      id: timeCard.id,
      allDay: true,
      title:
        timeCard.supervisorLastName +
        ", " +
        timeCard.supervisorFirstName.substring(0, 1),
      start: moment.tz(
        timeCard.timeCardDate.toString() + " 12:00 am",
        "YYYYMMDD h:mm a",
        moment.tz.guess(true)
      ).toDate(),
      end: moment
        .tz(
          timeCard.timeCardDate.toString() + " 12:00 am",
          "YYYYMMDD h:mm a",
          moment.tz.guess(true)
        )
        .add(60, "minutes")
        .toDate(),
      timeCard: timeCard,
      backgroundColor:
        timeCard.projectExportId !== 0
          ? tmStatusColor.exported
          : timeCard.timeCardStatus === timecardStatus.OPEN
            ? tmStatusColor.open
            : timeCard.timeCardStatus === timecardStatus.APPROVED
              ? tmStatusColor.approved
              : timeCard.timeCardStatus === timecardStatus.SUBMITTED
                ? tmStatusColor.submitted
                : tmStatusColor.rejected,
      resourceEditable: false,
      supervisorName:
        timeCard.supervisorLastName + "," + timeCard.supervisorFirstName,
      editable: timeCard.projectExportId === 0 && (timeCard.timeCardStatus === timecardStatus.OPEN),
    };
  };
  const eventWrapper = ({ event }: { event: any }) => {
    const icon = event.extendedProps.timeCard.projectExportId !== 0
      ? "fa fa-file-export"
      : event.extendedProps.timeCard.timeCardStatus === timecardStatus.REJECTED
        ? "fa fa-times"
        : event.extendedProps.timeCard.timeCardStatus === timecardStatus.APPROVED
          ? "fa fa-check"
          : event.extendedProps.timeCard.timeCardStatus === timecardStatus.SUBMITTED
            ? "fa fa-paper-plane"
            : "fa fa-pencil";
    return (
      <span>
        <i
          style={{ fontSize: "12px", marginRight: "10px", marginLeft: "5px" }}
          className={icon}
          aria-hidden="true"
        ></i>
        <strong>{event.title}</strong>
        <span style={{ float: "right", marginRight: "2px" }}>
          <i className="fa fa-ticket" aria-hidden="true"></i>
          <span>{` +${event.extendedProps.timeCard.countOfCodes}`}</span>
        </span>
      </span>
    );
  };


  const onSelectSlot = (slotInfo: { start: moment.MomentInput; }) => {
    setTimeCardDate(moment(slotInfo.start).format("YYYYMMDD"));
    // reset project value
    setSelectedCostCodeProject([]);
    setServiceTicketsList([]);
    history.push(`/timecardList`);
  }

  const filterTimecards = () => {
    const timeCards: any[] = [];
    timeCardEvents.forEach((e: any) => {
      if (
        statusFilter === "ALL" ||
        (e.timeCard.timeCardStatus ===
          timecardStatus[statusFilter as keyof typeof timecardStatus] &&
          e.timeCard.projectExportId === 0) ||
        (e.timeCard.projectExportId !== 0 && statusFilter === "EXPORTED")
      ) {
        // Save timecard into filtered array
        timeCards.push(e);
      }
    });
    setSelectedTimeCardEvents(timeCards);
  };

  // API CALLS
  const getRightFilterData = async () => {
    try {
      setFiltersLoading(true);
      const url = `/v1/api/time-card/calendar-filters`;
      const payload = {
        orgEntityId: orgEntityId,
        fromDate: moment().clone().startOf("month").format("YYYYMMDD"),
        toDate: moment().clone().endOf("month").format("YYYYMMDD"),
      };

      const { data } = await post(url, payload);

      if (data.success && data.data) {
        setfilterDropData({ ...data.data });
      } else {
        if (!data.success && data.error) {
          console.error(url)
          console.error(data.error)
        }
        setfilterDropData({});
      }
      setFiltersLoading(false);
    } catch (error) {
      setFiltersLoading(false);
    }
  };

  const updateSelTimeCardEventsByFilter = async (
    FilterObj: any
  ) => {
    try {
      setLoading(true);

      const url = `/v1/api/time-card/filtered`;
      let payload = {
        orgEntityId: orgEntityId,
        fromDate: moment(calendarDates.start).format("YYYYMMDD"),
        toDate: moment(calendarDates.end).format("YYYYMMDD"),
        filters: FilterObj,
      };
      setSelectedDate(calendarDates.start);
      const { data } = await post(url, payload);

      if (data.data) {
        let dataCal: any = data.data.map((each: any) => returnCalData(each));
        setTimeCardEvents(dataCal);
        setSelectedTimeCardEvents(dataCal);
        setLoading(false);
        setModal(false);
      } else {
        // setTimeCardEvents([]);
        setSelectedTimeCardEvents([]);
        setLoading(false);
        setModal(false);
      }
    } catch (error) {
      setLoading(false);
    }
  };

  const getFilterData = async () => {
    if (calendarDates.start
      && calendarDates.end) {
      try {
        const projectId = selectedProjectsSupervisorsShift.project.length
          ? selectedProjectsSupervisorsShift.project[0].id
          : Number(userSettings.selectedProjectId || 0);
        if (projectId) {
          setFilterDataLoading(true);
          const fromDate = moment(calendarDates.start).format("YYYYMMDD");
          const toDate = moment(calendarDates.end).format("YYYYMMDD");
          setSelectedDate(calendarDates.start);
          const supervisorId = selectedProjectsSupervisorsShift.supervisor.length
            ? selectedProjectsSupervisorsShift.supervisor[0].id
            : 0;
          let apiURL = `/v1/api/time-card/filter?projectId=${projectId}&supervisorId=${supervisorId}&fromDate=${fromDate}&toDate=${toDate}&orgEntityId=${orgEntityId}`;

          // @ts-ignore
          const res = await get(apiURL);
          if (res.data && res.data.data) {
            const result = res.data.data.map((timeCard: any) =>
              returnCalData(timeCard)
            );
            setTimeCardEvents(result);
            setSelectedTimeCardEvents(result);
            setTimeCardEventsData(res.data.data);
          }
          setFilterDataLoading(false);
        }
      } catch (error) {
        setFilterDataLoading(false);
      }
    }
  };

  const updateDateDragNDrop = async (newDate: string, originalTimecard: any) => {
    if (originalTimecard.timeCardStatus === timecardStatus.OPEN) {
      let apiUrl = `/v1/api/time-card/update-date`
      const payload = {
        newTimeCardDate: newDate,
        origTimeCard: {
          id: originalTimecard.id,
          tenantId: originalTimecard.tenantId,
          projectId: originalTimecard.projectId,
          timeCardSupervisorId: originalTimecard.timeCardSupervisorId,
          weekEndDateKey: originalTimecard.weekEndDateKey,
          timeCardDate: originalTimecard.timeCardDate,
          shift: originalTimecard.shift,
          timeCardStatus: originalTimecard.timeCardStatus,
          startTimeUtc: originalTimecard.startTimeUtc,
          endTimeUtc: originalTimecard.endTimeUtc,
          startTimeLocalString: originalTimecard.startTimeLocalString,
          endTimeLocalString: originalTimecard.endTimeLocalString,
          projectExportId: originalTimecard.projectExportId,
          indexNumber: originalTimecard.indexNumber || 1,
          timeCardTypeKey: originalTimecard.timeCardTypeKey,
          timeCardTypeDescription: originalTimecard.timeCardTypeDescription,
        }
      }
      setLoading(true)
      await post(apiUrl, payload);
      setLoading(false)
    }
  }

  // REACT HOOKS
  const areSideFiltersActive = useMemo(() => {
    if (!Object.keys(filterObj).length) {
      return false;
    }
    for (const key in filterObj) {
      if (filterObj[key].length) {
        return true;
      }
    }
    return false;
  }, [filterObj]);

  useEffect(
    () => {
      if (areSideFiltersActive) {
        updateSelTimeCardEventsByFilter(filterObj);
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [filterObj]
  );

  useEffect(
    () => {
      if (
        calendarDates.start && calendarDates.end
        && (!selectedDate || calendarDates.start !== selectedDate)
      ) {
        getFilterData();
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [calendarDates]
  );

  useEffect(() => {
    if (statusFilter) {
      filterTimecards();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [statusFilter]);

  // @ts-ignore
  useEffect(() => {
    if (
      (!areSideFiltersActive &&
        selectedProjectsSupervisorsShift.project[0] &&
        selectedProjectsSupervisorsShift.supervisor[0])
      || (
        !areSideFiltersActive &&
        selectedProjectsSupervisorsShift.project[0]
      )
    ) {
      getFilterData();
    }
  }, [selectedProjectsSupervisorsShift, filterObj]); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    if (orgEntityId) {
      getRightFilterData();
    }
  }, [orgEntityId]); // eslint-disable-line react-hooks/exhaustive-deps

  return (
    <div className="timecard-wrapper">
      <Header
        showStatusSection={true}
        showSelectSection={true}
        breadcrumb={"Calendar Dashboard"}
        title={"Calendar Dashboard"}
        areSideFiltersActive={areSideFiltersActive}
      />
      <>
        {(loading || filtersLoading || filterDataloading) && <Loader showComponentLoader={true}></Loader>}
        {/* @ts-ignore */}
        <div className="added-filters-wrapper">
          <ul className="added-filter-list">
            {areSideFiltersActive ? (
              <li
                className="added-filter clear-filter"
                onClick={clearAllRightFilter}
              >
                <span className="label-text-value">
                  <small className="label-text">Clear All</small>
                </span>
                {/* eslint-disable-next-line */}
                <a
                  className="close-icon close-red"
                  //  eslint-disable-next-line
                  href="javascript:void(0)"
                ></a>
              </li>
            ) : null}
            {Object.keys(filterObj).map(function (key) {
              return filterObj[key].length
                ? filterObj[key].map((id: any, i: number) => (
                  <li className="added-filter">
                    <span className="label-text-value">
                      <small className="label-text">{key}:</small>
                      <small className="label-value">{id}</small>
                    </span>
                    {/* eslint-disable-next-line */}
                    <a
                      className="close-icon"
                      onClick={() => handleFilterCross(key, id)}
                      //  eslint-disable-next-line
                      href="javascript:void(0)"
                    ></a>
                  </li>
                ))
                : null;
            })}
          </ul>
        </div>
        <div
          className={
            "timecard-content " +
            (rightFilter ? "filter-open" : "filter-closed")
          }
        >
          {/* eslint-disable-next-line */}
          <a
            //  eslint-disable-next-line
            href="javascript:void(0);"
            onClick={toggleFilter}
            className="filter-icon"
          ></a>
          <div className="filter-overlay" onClick={toggleFilter}></div>
          <div className="filter-float">
            {rightFilter ? (!isObjEmpty(filterDropData) ? (
              <FilterTimecard
                handleFilterSubmit={handleFilterSubmit}
                filterDropData={filterDropData}
                toggleFilter={toggleFilter}
              />
            ) : (
              <SweetAlert
                title=''
                type={'info'}
                confirmBtnBsStyle={'info'}
                onConfirm={() => toggleFilter()}
              >
                {'Something went wrong.'}
              </SweetAlert>
            )) : null}
          </div>
          <div className="calendar-wrapper">
            <FullCalendar
              plugins={[dayGridPlugin, timeGridPlugin, interactionPlugin]}
              headerToolbar={{
                left: 'prev,next today',
                center: 'title',
                right: 'dayGridMonth,timeGridWeek,timeGridDay'
              }}
              initialView='dayGridMonth'
              selectable={true}
              selectMirror={true}
              dayMaxEvents={true}
              droppable={true}
              select={onSelectSlot}
              initialEvents={selectedTimeCardEvents}
              events={selectedTimeCardEvents}
              eventContent={eventWrapper}
              eventClick={handleEventSelection}
              datesSet={handleMonthChange}
              eventDrop={(info) => updateDateDragNDrop(moment(info.event.start).format('YYYYMMDD'), info.oldEvent.extendedProps.timeCard)}
            />
          </div>
        </div>
      </>
      {/* @ts-ignore */}
      {/* <Calendar localizer={localizer} /> */}
    </div>
  );
}
