import React, { useState } from "react";

const defaultStaticValues = {
    id: 0,
    UOM: "",
    unitCost: "",
    unitHours: "",
    activity: "",
    payItem: `NONE`,
    sortOrder: 1,
};

const isNumeric = (inputtxt: string) => {
    var numbers = /^[0-9]+$/;
    if (inputtxt.match(numbers)) {
        return true;
    }
    else {
        return false;
    }
}

const ServiceContractSetup = (props: any) => {

    const [isEditing, setIsEditing] = useState(false);
    const [staticValues, setStaticValues] = useState(defaultStaticValues);

    const [activityListHeaders, setActivityListHeaders] = useState(JSON.parse(JSON.stringify(props.activityListHeaders)));
    const [editUnitHours, setEditUnitHours] = useState(false);
    const [editAmtErrorCode, setEditAmtErrorCode] = useState(false);

    const resetFieldValues = () => {
        const headersData = activityListHeaders.map((e: any) => {
            return {
                ...e,
                fieldValue: "",
            };
        });
        setStaticValues(defaultStaticValues);
        setEditUnitHours(false)
        setEditAmtErrorCode(false)
        setActivityListHeaders(headersData);
        setIsEditing(false);
    };

    const handleEdit = async (rowData: any) => {
        const dynamicValue = activityListHeaders.map(
            (value: { customFieldIndex: any; fieldValue: string }) => {
                const res = rowData.fields.find(
                    (e: any) => value.customFieldIndex === e.customFieldIndex
                );
                value["fieldValue"] = res ? res.fieldValue : '';                
                return value;
            }
        );        

        // const setActivityListEditing = activityList.map((data: any) => {
        //   if (data.id === rowData.id) {
        //     data["isEditing"] = true;
        //   }
        //   return data;
        // });
        setActivityListHeaders(dynamicValue);
        setStaticValues({
            ...staticValues,
            id: rowData.id,
            activity: rowData.activity,
            UOM: rowData.UOM,
            unitCost: rowData.unitCost,
            unitHours: rowData.unitHours,
            payItem: rowData.payItem,
            sortOrder: rowData.sortOrder,
        });
        // setActivityList(setActivityListEditing);
        setIsEditing(true);
    };


    const handleDelete = async (id: any) => {
        await props.deleteActivity(id);
    };


    return (
        <>
            {isEditing ? (
                <tr>
                    <td>
                        <input
                            placeholder="Enter Pay Item"
                            value={staticValues.payItem}
                            onChange={(e) => {
                                setStaticValues({
                                    ...staticValues,
                                    payItem: e.target.value,
                                });
                            }}
                        />
                    </td>
                    <td>
                        <input
                            placeholder="Enter Activity"
                            value={staticValues.activity}
                            onChange={(e) => {
                                setStaticValues({
                                    ...staticValues,
                                    activity: e.target.value,
                                });
                            }}
                        />
                    </td>
                    <td>
                        <input
                            placeholder="Enter UOM"
                            value={staticValues.UOM}
                            onChange={(e) => {
                                setStaticValues({
                                    ...staticValues,
                                    UOM: e.target.value,
                                });
                            }}
                        />
                    </td>
                    {activityListHeaders.map((field: any) => { 
                        return (field.isActive === 1 ? (
                        <td>
                            <input
                                placeholder={`Enter ${field.fieldName}`}
                                value={field.fieldValue || ""}
                                onChange={(e) => {
                                    const res = activityListHeaders.map(
                                        (value: { fieldId: any; fieldValue: string }) => {
                                            if (value.fieldId === field.fieldId) {
                                                value["fieldValue"] = e.target.value;
                                            }                                            
                                            return value;
                                        }
                                    );
                                    setActivityListHeaders(res);
                                }}
                            />
                        </td>
                        ) : '')
                    })}
                    <td>
                        <input
                            placeholder="Enter Unit Hours"
                            value={staticValues.unitHours}
                            onChange={(e) => {
                                if (isNumeric(e.target.value) || e.target.value === '') {
                                    setEditUnitHours(false)
                                } else {
                                    setEditUnitHours(true)
                                }
                                setStaticValues({
                                    ...staticValues,
                                    unitHours: e.target.value,
                                });
                            }}
                        />
                        <div className="errorBox">
                            {editUnitHours ? <span className="text-danger help-block">Please Enter Numeric Value</span> : null}
                        </div>
                    </td>
                    <td>
                        <input
                            placeholder="Enter Unit Amount"
                            value={staticValues.unitCost}

                            onChange={(e) => {
                                if (isNumeric(e.target.value) || e.target.value === '') {
                                    setEditAmtErrorCode(false)
                                } else {
                                    setEditAmtErrorCode(true)
                                }
                                setStaticValues({
                                    ...staticValues,
                                    unitCost: e.target.value,
                                });
                            }}
                        />
                        <div className="errorBox">
                            {editAmtErrorCode ? <span className="text-danger help-block">Please Enter Numeric Value</span> : null}
                        </div>
                    </td>
                    <td>
                        <div className="action-wrapper">
                            <button
                                type="button"
                                onClick={async () => {
                                    const id = isEditing ? staticValues.id : "";
                                    await props.handleSave(id, staticValues,activityListHeaders);
                                    setIsEditing(false)
                                }}
                                className="btn btn-primary"
                            >
                                {isEditing ? "Update" : "Save"}
                            </button>
                            <button
                                type="button"
                                onClick={resetFieldValues}
                                className="btn btn-secondary"
                            >
                                Cancel
                            </button>
                        </div>
                        <div className="errorBox"></div>
                    </td>
                </tr>
            ) : (

                <tr>
                    <td> {props.activity.payItem}</td>
                    <td>{props.activity.activity}</td>
                    <td>{props.activity.UOM}</td>
                    {activityListHeaders.map(
                        (field: any, index: number) => {
                            if(field.isActive === 1) {
                                const res = props.activity.fields.find(
                                    (e: any) =>
                                        field.customFieldIndex === e.customFieldIndex
                                );
                                return <td>{res?.fieldValue || ''}</td>;
                            }
                            return '';
                        }
                    )}
                    <td>{props.activity.unitHours}</td>
                    <td>${props.activity.unitCost}</td>
                    <td>
                        <div className="action-wrapper">
                            <button
                                type="button"
                                onClick={(e) => {
                                    handleEdit(props.activity);
                                }}
                                className="btn btn-secondary"
                                disabled={!props.access.update}
                            >
                                Edit
                            </button>
                            <button
                                type="button"
                                onClick={() => handleDelete(props.activity.id)}
                                className="btn btn-secondary"
                                disabled={!props.access.update}
                            >
                                Delete
                            </button>
                        </div>
                    </td>
                </tr>
            )}
        </>
    );
};

export default ServiceContractSetup;
