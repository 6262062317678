import create from 'zustand';
import { devtools } from 'zustand/middleware'

interface AuthState {
    token: any,
    sourceUrl: any,
    userSettings: any,
    userAccess: any,
    setToken: (token: string) => void,
    setSourceUrl: (sourceUrl: any) => void,
    setUserSettings: (data: any) => void,
    setUserAccess: (data: any) => void,
    clearToken: () => void
}

const useStore = create<AuthState>(devtools(set => ({
    token: '',
    sourceUrl: '',
    userSettings: {},
    userAccess: {},
    setToken: (token: string) =>
        set(() => ({
            token
        })),
    setSourceUrl: (sourceUrl: any) =>
        set(() => ({
            sourceUrl
        })),
    setUserSettings: (data: any) =>
        set(() => ({
            userSettings : data
        })),
    setUserAccess: (data: any) =>
        set(() => ({
            userAccess : data.data
        })),
    clearToken: () =>
        set(() => ({
            token: ''
        }))
})));

export const useAuthStore = useStore;

interface ITokenDetails {
    JWTExpire: string
    autoSyncOffline: number
    email: string
    exp: number
    iat: number
    isServiceAccount: number
    nameOfUser: string
    partners: object
    tenantId: number
    userId: number
    username: string
    zone: string
}

interface IServiceState {
    companyId: String,
    tokenDetails?: ITokenDetails,
    userAccessList?: {[key: string]: any;},
    selectedOrgEntity?: {[key: string]: any;},
    setTokenDetails: (payload: ITokenDetails) => void,
    setUserAccessList: (data: any) => void,
    setSelectedOrgEntity: (data: any) => void,
    setData: (companyId: string) => void,
    clearData: () => void
}

export const useServiceStore = create<IServiceState>(devtools(set => ({
    companyId: '',
    tokenDetails: undefined,
    userAccessList: {},
    selectedOrgEntity: {},
    setTokenDetails: (payload) => set(() => ({ tokenDetails: payload })),
    setUserAccessList: (data:any) => set(() => ({ userAccessList: {...data} })),
    setSelectedOrgEntity: (data:any) => set(() => ({ selectedOrgEntity: {...data} })),
    setData: (companyId: String) => {
        set(() => ({
            companyId
        })) 
    },
    clearData: () =>
        set(() => ({
            companyId: ''
        }))
})));

interface IConstants {
    serviceContract?: {
        CT: {
            defaults: object;
            status: object;
        }
    }
}

interface IServerConstants {
    constants: IConstants;
    status: {[key: number]: string};
    setStatus: (payload: object) => void,
    setConstants: (payload: object) => void,
    clearData: () => void
}


export const useServerConstants = create<IServerConstants>(devtools(set => ({
    constants: {},
    status: {},
    setStatus: (payload: any) => {
        let serverStatus = payload.core.serviceContract?.CT.status || {};
        let result = Object.entries(serverStatus).reduce((previousValue, [key, value]) => ({...previousValue, [value as any]: key}), {})
        set(() => ({
            status: {
                ...result
            }
        }))
    },
    setConstants: (payload: any) => {
        set(() => ({
            constants: {
                ...payload.core
            }
        }))
    },
    clearData: () =>
        set(() => ({}))
})));

interface IServiceContract {
    selectedProject?: any,
    serviceValue?: any,
    setSelectedProject: (data: any) => void,
    setServiceValue: (data: any) => void,
    clearData: () => void
}

export const useServiceContractStore = create<IServiceContract>(devtools(set => ({
    selectedProject: [],
    serviceValue: {},
    setSelectedProject: (data: any) => set(() => ({ selectedProject: data })),
    setServiceValue: (data: any) =>
        set(() => ({
            serviceValue : data
        })),
    clearData: () =>
        set(() => ({
            selectedProject: [],
            serviceValue: {}
        }))
})));

interface ITimeCard {
    statusFilter: string,
    selectedProjectsSupervisorsShift: any,
    projectsAndSupervisors: any,
    timeCardDate: any,
    serviceTicketsList: [],
    timeCardEventsData: [],
    selectedServiceTicketsDetails: any,
    filterObj: any,
    selectedCostCodeProject: any,
    setStatusFilter: (data: any) => void,
    setTimeCardDate: (data: any) => void,
    setFilterObj: (data: any) => void,
    setProjectsAndSupervisors: (data: any) => void,
    setServiceTicketsList: (data: any) => void,
    setTimeCardEventsData: (data: any) => void,
    setSelectedServiceTicketsDetails: (data: any) => void,
    setSelectedCostCodeProject: (data: any) => void,
    setSelectedProjectsSupervisorsShift: (data: any) => void,
}

export const useTimeCardStore = create<ITimeCard>(devtools(set => ({
    statusFilter: "",
    timeCardDate: "",
    selectedCostCodeProject: [] as any,
    serviceTicketsList: [] as any,
    selectedServiceTicketsDetails: {
        serviceTicketsId: [] as any,
        timeCardStatus: -1 as number,
        activeIndex: 0 as number
    },
    timeCardEventsData: [] as any,
    filterObj: {} as any,
    selectedProjectsSupervisorsShift: {
        project: [] as any,
        supervisor: [] as any,
        shift: [] as any,
    },
    projectsAndSupervisors : {
        projects: [] as any,
        supervisors: [] as any,
    },
    setStatusFilter: (data: any) =>
        set(() => ({
            statusFilter : data
        })),
    setFilterObj: (data: any) =>
        set(() => ({
            filterObj : data
        })),
    setTimeCardEventsData: (data: any) =>
        set(() => ({
            timeCardEventsData : data
        })),
    setSelectedCostCodeProject: (data: any) =>
        set(() => ({
            selectedCostCodeProject : data
        })),
    setSelectedServiceTicketsDetails: (data: any) =>
        set(() => ({
            selectedServiceTicketsDetails : data
        })),
    setServiceTicketsList: (data: any) =>
        set(() => ({
            serviceTicketsList : data
        })),
    setTimeCardDate: (data: any) =>
        set(() => ({
            timeCardDate : data
        })),
    setProjectsAndSupervisors: (data: any) =>
        set(() => ({
            projectsAndSupervisors : data
        })),
    setSelectedProjectsSupervisorsShift: (data: any) =>
        set(() => ({
            selectedProjectsSupervisorsShift : data
        }))
})));

interface IServiceTicket {
    selectedProjectId: any,
    setSelectedProjectId: (data: any) => void,
}

export const useServiceTicketStore = create<IServiceTicket>(devtools(set => ({
    selectedProjectId: "",
    setSelectedProjectId: (data: any) =>
        set(() => ({
            selectedProjectId : data
        }))
})));