import React, { useState, useEffect } from "react";
import SelectDropdown from "../../shared/SelectDropdown/SelectDropdown";
import Header from "../../shared/Header/Header";
import { withRouter, useHistory, RouteComponentProps } from "react-router-dom";
import ServiceTicketCreate from "./ServiceTicketCreate";
import { useServiceStore, useServerConstants, useServiceTicketStore, useTimeCardStore } from "../../../store";
import Loader from "../../shared/Loader/Loader";
import Pagination from "../../shared/Pagination/Pagination";
import { get, deleteAPI } from "../../../types/api";
import moment from "moment";
import { toast, ToastContainer } from "react-toastify";
import shallow from "zustand/shallow";
import UploadServiceTicketModal from "./UploadServiceTicketModal";

interface IServiceTicketsComponentProps extends RouteComponentProps {
  access?: any;
}

interface IServiceValueState {
  projects?: Array<object>;
  foremen?: Array<object>;
  contracts?: Array<object>;
}

interface IResult {
  page: number;
  limit: number;
  totalResults: number;
  data: Array<object>;
}

const ServiceTickets = (props: IServiceTicketsComponentProps) => {
  // STATE DECLARATION
  // fetching data from global state
  const companyId = useServiceStore((serviceStore) => serviceStore.companyId);
  const ticketStatus = useServerConstants(
    (serverConstants) => serverConstants.status
  );
  const { setSelectedProjectId } = useServiceTicketStore.getState();
  const { projectsAndSupervisors } = useTimeCardStore(
    (state) => ({ projectsAndSupervisors: state.projectsAndSupervisors }),
    shallow
  );
  let { access: ticketAccess } = props;

  // state for maintaining the service ticket list
  const [serviceValue, setServiceValue] = useState({
    projects: [],
    foremen: [],
    contracts: []
  } as IServiceValueState);
  const [result, setResult] = useState({
    page: 0,
    limit: 25,
    totalResults: 0,
    data: [],
  } as IResult)

  // filters for fetching service ticket list
  const [selectedProject, setSelectedProject] = useState({
    name: "",
    id: undefined,
  });
  const [selectedContract, setSelectedContract] = useState({
    name: "",
    id: undefined,
  });
  const [editServiceTicketId, setEditServiceTicketId] = useState(0);

  const history = useHistory()

  // state for component loader
  const [componentLoader, setComponentLoader] = useState(false);
  const [pageLoader, setPageLoader] = useState(false);
  const [currentPage, setCurrentPage] = useState(0);
  const [pageCount, setPageCount] = useState(0);
  const [pageLimit, setPageLimit] = useState(25);

  // modal functions
  const [modal, setModal] = useState(false);
  const [fileModal, setFileModal] = useState(false);
  const toggle = (refreshPage = false) => {
    setModal(!modal);
    if (refreshPage) {
      getTicketsAndListDropdown(true);
    }
  };

  const toggleModal = (refreshPage: any) => {
    setFileModal(!fileModal);
    if (refreshPage) {
      getTicketsAndListDropdown(true);
    }
  };

  // API CALLING
  // fetch ticket details
  const getTicketsAndListDropdown = async (areResultsFetching = true, page = currentPage) => {
    try {
      let url = `/v1/api/service/ticket?companyId=${companyId}`;
      url = selectedProject.id ? `${url}&projectId=${selectedProject.id}` : url;
      url = selectedContract.id
        ? `${url}&contractId=${selectedContract.id}`
        : url;
      url = `${url}&page=${page}&limit=${pageLimit}`;
      areResultsFetching ? setPageLoader(true) : setComponentLoader(true);
      const res = await get(url);
      areResultsFetching ? setPageLoader(false) : setComponentLoader(false);
      if (res.status === 200) {
        setServiceValue({
          projects: res.data.filters.projects,
          foremen: res.data.filters.leadman,
          contracts: res.data.filters.contracts,
        });
        if (areResultsFetching) {
          const maxRec = res.data?.results?.totalResults; //get from BE
          setPageCount(Math.ceil(maxRec / pageLimit));
          setSelectedProjectId(selectedProject.id);
          setResult({
            ...res.data?.results
          })
        }
      }
    } catch (error) {
      areResultsFetching ? setPageLoader(false) : setComponentLoader(false);
      if (error instanceof Error) {
        toast.warn(error.message, {
          autoClose: 5000,
          toastId: 'stListDropdown'
        });
      }
    }
  };

  const deleteServiceTicket = async (serviceTicketId: number) => {
    const url = `/v1/api/service/ticket/id/${serviceTicketId}`;
    setPageLoader(true);
    await deleteAPI(url);
    setPageLoader(false);
    getTicketsAndListDropdown();
  };


  // SETTER METHODS
  const updateDropdown = (ticketObj: any) => {
    setSelectedProject(ticketObj.selectedProject);
    setSelectedContract(ticketObj.selectedContract);
  };

  const postUpdate = (ticket: any) => {
    history.push(`/serviceTicketDetails/${ticket.id}`);
  }

  const handlePageChange = (page: number) => {
    setCurrentPage(page);
  };


  const handleClear = async (name: any) => {
    if (name === "Service Contract") {
      setSelectedContract({ name: "", id: undefined });
    }
  }


  // LIFECYCLE METHODS
  // populate the dropdown fields and result
  useEffect(() => {
    if (companyId) {
      getTicketsAndListDropdown(!!(selectedProject.id && selectedContract.id), 0)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedProject, selectedContract])

  useEffect(() => {
    getTicketsAndListDropdown();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentPage, pageLimit]);



  return (
    <div className="service-tickets-wrapper">
      <ToastContainer
        position="top-right"
        autoClose={5000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
      />
      <Header breadcrumb={"Service Tickets"} title={"Service Tickets"} />
      {pageLoader && <Loader />}
      <div className="inapp-filter-wrapper">
        <div className="inapp-filters">
          <h2 className="page-title-sub">Search By</h2>
          <form className="contractfilter-form">
            <div className="row">
              <div className="col-3">
                <div className="form-group">
                  {!selectedProject.id && componentLoader && !pageLoader && (
                    <Loader showComponentLoader={true} />
                  )}
                  <SelectDropdown
                    labelKey="projectDisplayNameUI"
                    options={projectsAndSupervisors.filteredProjects || []}
                    label={"Project"}
                    selected={selectedProject?.id ? [selectedProject] : []}
                    onChange={(e: any) => {
                      setSelectedProject(
                        e?.length && e[0]?.id
                          ? e[0]
                          : { name: e, id: undefined }
                      );
                    }}
                    disabled={!ticketAccess.read}
                  />
                </div>
              </div>
              <div className="col-3">
                <div className="form-group">
                  {!selectedContract.id && componentLoader && !pageLoader && (
                    <Loader showComponentLoader={true} />
                  )}
                  <SelectDropdown
                    labelKey="name"
                    options={serviceValue?.contracts}
                    handleClear={handleClear}
                    label={"Service Contract"}
                    selected={selectedContract?.id ? [selectedContract] : []}
                    onChange={(e: any) => {
                      setSelectedContract(
                        e?.length && e[0]?.id
                          ? e[0]
                          : { name: e, id: undefined }
                      );
                    }}
                    disabled={!ticketAccess.read}
                  />
                </div>
              </div>
              <div className="col-3">
                <div className="form-group submit-filter">
                  <button
                    type="button"
                    className="btn btn-primary"
                    disabled={!ticketAccess.read}
                    onClick={() => {
                      getTicketsAndListDropdown(true, 0);
                    }}
                  >
                    Search
                  </button>
                </div>
              </div>
            </div>
          </form>
        </div>
      </div>

      <div className="st-list-wrapper">
        <div className="st-list-heading d-flex align-items-center justify-content-between">
          <h3 className="heading-sub">Ticket List</h3>
          <div className="st-list-btns">
            {selectedContract.id !== undefined && selectedProject.id !== undefined && (<button
              className="btn btn-primary"
              style={{ marginRight: '10px' }}
              onClick={() => setFileModal(!fileModal)}
            >
              <span className=""></span>
              Upload Service Tickets
            </button>)}
            <button
              className="btn btn-primary"
              onClick={() => {
                toggle();
                setEditServiceTicketId(0);
              }}
              disabled={!ticketAccess.create}
            >
              Create Service Ticket
            </button>
          </div>
        </div>
        <div className="st-list-content">
          {/* <div className="nodata-wrapper">
                        <p>Please Add/Create Service Ticket</p>
                    </div> */}
          <div className="r-table-wrapper table-scrollable">
            <table className="table">
              <thead>
                <tr>
                  {/* <th className="ticket-checkbox">
                    <div className="form-group">
                      <div className="custom-control custom-checkbox"> */}
                  {/* <input
                          type="checkbox"
                          className="custom-control-input"
                          name="ticketHeading"
                          id="ticketHeading"
                        /> */}
                  {/* @ts-ignore */}
                  {/* <label
                          className="custom-control-label"
                          htmlFor="ticketHeading"
                        ></label> */}
                  {/* </div>
                    </div>
                  </th> */}
                  <th>Service Ticket</th>
                  <th>Service Contract</th>
                  <th>Work Order</th>
                  <th>Pole Number</th>
                  <th>Date</th>
                  <th>Status</th>
                  <th>Action</th>
                </tr>
              </thead>
              <tbody>
                {result?.data?.map(
                  (eachServiceValue: any, index) => {
                    return (
                      <tr key={eachServiceValue.id + " " + index}>
                        {/* <td className="ticket-checkbox">
                          <div className="form-group">
                            <div className="custom-control custom-checkbox"> */}
                        {/* <input
                                type="checkbox"
                                className="custom-control-input"
                                name="ticketRow1"
                                id="ticketRow1"
                              /> */}
                        {/* @ts-ignore */}
                        {/* <label
                                className="custom-control-label"
                                htmlFor="ticketRow1"
                              ></label> */}
                        {/* </div>
                          </div>
                        </td> */}
                        <td>
                          <span> {eachServiceValue.serviceTicketDisplayNumber} </span>
                        </td>
                        <td>
                          <span> {eachServiceValue["_serviceContractDisplayNumber"]} </span>
                        </td>
                        <td>
                          <span> {eachServiceValue.workOrderNumber} </span>
                        </td>
                        <td>
                          <span> {eachServiceValue.poleNumber} </span>
                        </td>
                        <td>
                          {eachServiceValue.created_at
                            ? moment(eachServiceValue.created_at)
                              .tz("America/Los_Angeles")
                              .format("MM-DD-YYYY")
                            : null}
                        </td>
                        <td>
                          <span className="contract-status status-open">
                            {ticketStatus[eachServiceValue.ticketStatus]}
                          </span>
                        </td>
                        <td>
                          <div className="action-wrapper">
                            <button
                              type="button"
                              className="btn btn-secondary"
                              onClick={e => {
                                history.push(`/serviceTicketDetails/${eachServiceValue.id}`);
                              }}
                              disabled={!ticketAccess.update}
                            >
                              Edit
                            </button>
                            <button
                              type="button"
                              className="btn btn-secondary"
                              onClick={() => {
                                deleteServiceTicket(eachServiceValue.id);
                              }}
                              disabled={!ticketAccess.delete}
                            >
                              Delete
                            </button>
                          </div>
                        </td>
                      </tr>
                    );
                  }
                )}

                {result?.data?.length === 0 ? (
                  <tr>
                    <td colSpan={5} className="text-center">
                      Please select atleast one valid filter
                    </td>
                  </tr>
                ) : null}
              </tbody>
            </table>
          </div>
        </div>
      </div>

      {result?.data?.length ? (
        <div className="table-footer-content">
          <Pagination
            pageCount={pageCount}
            currentPage={currentPage}
            pageLimit={pageLimit}
            setPageLimit={setPageLimit}
            setCurrentPage={setCurrentPage}
            itemsPerPage={pageLimit}
            handlePageChange={handlePageChange}
          />
        </div>
      ) : null}
      {modal ?
        <ServiceTicketCreate
          toggle={toggle}
          modal={modal}
          updateDropdown={updateDropdown}
          serviceTicketId={editServiceTicketId}
          postUpdate={postUpdate}
        />
        : ''}
      {/* Upload Activity file */}
      {fileModal && <UploadServiceTicketModal
        isOpen={fileModal}
        toggle={toggleModal}
        selectedContract={selectedContract}
      />}
    </div>
  );
};

export default withRouter(ServiceTickets);
