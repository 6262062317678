import React from "react";

interface IKeypadProps {
  pressNumber: (digit: number) => void;
  pressDot: () => void;
  pressDelete: () => void;
  clearAll: () => void;
  costEventAlert?: string
}
const Keypad = (props: IKeypadProps) => {
  const numbers = [1, 2, 3, 4, 5, 6, 7, 8, 9]
  return <div className="custom-keypad">
    {props.costEventAlert ? <div className="error-cost-events">
      <span className="error-text">{props.costEventAlert}</span>
    </div> : null}
    <ul className="keypad-list">
      {numbers.map(eachNumber => {
        return <li key={eachNumber}>
        {/* eslint-disable-next-line */}
        <a
          className=""
          // eslint-disable-next-line
          href="javascript:void(0);"
          onClick={() => props.pressNumber(eachNumber)}
        >
          {eachNumber}
        </a>
      </li>
      })}
      <li>
        {/* eslint-disable-next-line */}
        <a
          className=""
          // eslint-disable-next-line
          href="javascript:void(0);"
          onClick={props.pressDot}
        >
          .
        </a>
      </li>

      <li>
        {/* eslint-disable-next-line */}
        <a
          className=""
          // eslint-disable-next-line
          href="javascript:void(0);"
          onClick={() => props.pressNumber(0)}
        >
          0
        </a>
      </li>

      <li>
        {/* eslint-disable-next-line */}
        <a
          className="back-wrap"
          // eslint-disable-next-line
          href="javascript:void(0);"
          onClick={props.pressDelete}
        ></a>
      </li>
      <li>
        {/* eslint-disable-next-line */}
        <a
          className="clear-wrap"
          // eslint-disable-next-line
          href="javascript:void(0);"
          onClick={props.clearAll}
        >
          Clear All
        </a>
      </li>
    </ul>
  </div>
}

export default Keypad