import React, { useEffect, useState } from "react";
import { ReactComponent as Close } from "../../../assets/images/close.svg";
import { ReactComponent as CertiBadge } from "../../../assets/images/certificate-ribbon-full.svg";
import { ReactComponent as CertiCamera } from "../../../assets/images/camera-black.svg";
import { ReactComponent as SendEmail } from "../../../assets/images/send-email.svg";
import { CSVLink } from "react-csv";
import Loader from "../../shared/Loader/Loader";



interface ICrewCertificatesTabProps {
    crewCertificateData: any;
    getCrewIds: () => any;
    setCrewCertificateData: (val: Array<object>) => any;
    sendEmail: (val: any) => any;
    toggleCrewInfo: (val: any) => any;
    getCrewCertificateInfo: (val: number, str: string) => any
    compLoader: boolean;
    certInfoData: any;


}
const CrewCertificatesTab = (props: ICrewCertificatesTabProps) => {
    const [searchText, setSearchText] = useState('')
    const handleSearch = (text: string) => {
        setSearchText(text);
        let data = [];
        data = props.crewCertificateData.filter(
            (el: any) => {
                if (text === "") {
                    return el
                }
                else {
                    return el._firstName.includes(text);
                }
            }
        )
        props.setCrewCertificateData(data);

    }
    useEffect(() => {
        props.getCrewIds();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);
    return (
        <div className="review-certificates">
            {props.compLoader && <Loader showComponentLoader={true} />}
            <div className="review-certificates-header d-flex align-items-center justify-content-between">
                <h3 className="heading-sub">
                    <div className="table-search">
                        <input
                            className="table-search-field table-search-icon"
                            placeholder="Search"
                            onChange={(e) => handleSearch(e.target.value)}
                            value={searchText}
                        ></input>
                        {/* eslint-disable-next-line */}
                        <a href="javascript:void(0)" className="table-search-close">
                            <Close />
                        </a>
                    </div>
                </h3>

                <div className="rr-list-btns">
                    <button className="btn btn-primary">
                        <span className="send-excel"></span>
                        <CSVLink data={props.crewCertificateData}> Export to CSV</CSVLink>
                    </button>
                    <button className="btn btn-primary" onClick={(e) => { props.sendEmail(e) }}>
                        <SendEmail />
                        Send Email
                  </button>
                </div>
            </div>
            {props.crewCertificateData.length ? <ul className="certificates-list">

                {props.crewCertificateData.map((field: any) => (
                    <li className="certificate">
                        <div className="employee-dp-wrapper">
                            <img
                                className="employee-dp"
                                src={field._personPic}
                                alt="Employee DP"
                            />
                        </div>
                        <div className="employee-details-wrapper">
                            <div className="crew-details">
                                <div className="text-label-value">
                                    <div className="text-label">Last Name</div>
                                    <div className="text-value">{field._lastName}</div>
                                </div>
                                <div className="text-label-value">
                                    <div className="text-label">First Name</div>
                                    <div className="text-value">{field._firstName} {field._middleName}</div>
                                </div>
                                <div className="text-label-value">
                                    <div className="text-label">Employee ID</div>
                                    <div className="text-value">{field._personNumber}</div>
                                </div>
                                <div className="text-label-value">
                                    <div className="text-label">Union</div>
                                    <div className="text-value">{field._unionTradeClass}</div>
                                </div>
                                <div className="text-label-value">
                                    <div className="text-label">Payclass</div>
                                    <div className="text-value"></div>
                                </div>
                                <div className="text-label-value">
                                    <div className="text-label">Emergency Contact</div>
                                    <div className="text-value">{field._emergencyContact}</div>
                                </div>
                            </div>
                            <div className="crew-certificates">
                                {field.certificates && field.certificates.length > 0 ? field.certificates.map((cert: any) => (
                                    // eslint-disable-next-line
                                    <a
                                        className="text-label-value"
                                        //  eslint-disable-next-line
                                        href="javascript:void(0);"
                                    >
                                        <small className={'certi-validation ' + (cert._daysUntilExpire > 0 ? '' : 'expired')}>
                                            <CertiBadge />
                                        </small>
                                        <span className="text-label">{cert._certName} </span>
                                        <span className="text-value">{cert._certDateExpiredFormatted}</span>
                                        <small className="certi-camera" onClick={() => {
                                            props.toggleCrewInfo(true)
                                            props.getCrewCertificateInfo(field.personId, cert._certName)                                    
                                        }}>
                                            <CertiCamera />
                                        </small>
                                    </a>
                                )): <div className="no-certificates">No certificates available</div>}
                                
                            </div>
                        </div>
                    </li>

                ))}
            </ul> : <div>No data available</div>}

        </div>
    )
}
export default CrewCertificatesTab;