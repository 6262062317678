import React, { useEffect, useState } from 'react'
import Keypad from './Keypad';

export interface ITimeLogProps {
  currentHours: {[index: string]: number | string};
  setCurrentHours: (arg0: {[index: string]: number | string}) => void
  children?: React.ReactNode
  costEventAlert: string
}

const TimeLog = ({currentHours = {regular: 0, overTime: 0, doubleTime: 0}, setCurrentHours, children, costEventAlert}: ITimeLogProps) => {

  // STATE 
  const [hourTitle, setHourTitle] = useState("")

  // HELPER METHODS
  const handleHourKeys = (value: any) => {
    if(hourTitle){
      value = value.toString();
      setCurrentHours({
        ...currentHours,
        [hourTitle]: Number(`${currentHours[hourTitle]}${value}`),
      });
    }
  };

  const handleDotKey = () => {
    if(hourTitle){
      setCurrentHours({
      ...currentHours,
      [hourTitle]: currentHours[hourTitle] + ".",
    });
  }
  }

  const handleDelete = () => {
    let str = currentHours[hourTitle];
    //remove last 1 char
    const numStr = str.toString();
    if (numStr.length > 1) {
      str = parseFloat(
        numStr.substring(0, numStr.length - 1)
      );
    } else {
      str = 0;
    }
    setCurrentHours({
      ...currentHours,
      [hourTitle]: str,
    });
  }

  useEffect(() => {
    setHourTitle("regular")
  }, [])

  return <React.Fragment>
    <div className="custom-logs-wrapper">
      <ul className="custom-logs-list w-100">
        {Object.entries(currentHours).map(([key, value]) => {
          return <li>
            <div className="custom-log">
              <h6 className="time-type-title">{key}</h6>{" "}
              <div
                className={`custom-block ${hourTitle === key ? "field-selected" : ""}`}
                onClick={() => setHourTitle(key)}
              >
                {value}
              </div>
            </div>
          </li>
        })}
      </ul>
    </div>

    <Keypad
      costEventAlert={costEventAlert}
      pressNumber={handleHourKeys}
      pressDot={handleDotKey}
      pressDelete={handleDelete}
      clearAll={() => { 
        const hoursTemplate = currentHours
        Object.keys(hoursTemplate).forEach((eachHour) => {
          hoursTemplate[eachHour] = 0
        })
        setCurrentHours({
          ...hoursTemplate
        })
      }} />
      {children}
  </React.Fragment>
}

export default TimeLog