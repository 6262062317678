import React, { useState } from 'react'
import {
  Button,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
} from "reactstrap";
import TimeLog from './TimeLog';

interface ISidePanelSelectCostEventProps {
  costEvents: [{ id: number; ceNumber: string; ceDescription: string; _projectNumber: string; }];
  selectCostEvent: (arg: ISidePanelSelectCostEventProps["costEvents"][0]) => void;
  selectedCostEvent: { id?: any; ceDescription?: any; ceNumber?: string; _ceLabel?: string; _budgetCodes?: [ISidePanelBudgetCostCodeProps["selectedBudgetCostCode"]]} | undefined;
}

interface ISidePanelCostEventDetailsProps {
  hoursSum: {[index: string]: number};
  costEventHours: {[index: string]: string | number};
  selectedCostEventCategory: any | undefined;
  showCostEventDetails: boolean;
  selectedCostEvent: ISidePanelSelectCostEventProps["selectedCostEvent"]
  selectedBudgetCostCode: ISidePanelBudgetCostCodeProps["selectedBudgetCostCode"];
  setShowCostEventDetails: (arg: boolean) => void;
  toggleCESidePanel: () => void;
  setCurrentComponentLayer: (arg: number) => void;
}
interface ISidePanelBudgetCostCodeProps {
  selectedCostEvent: ISidePanelSelectCostEventProps["selectedCostEvent"]
  selectedBudgetCostCode: { budgetCodeId: number; _ceBudgetCodeLabel: string; _budgetCodeDescription: string; } | undefined;
  selectBudgetCode: (arg: ISidePanelBudgetCostCodeProps["selectedBudgetCostCode"]) => void;
}

interface ISidePanelCostEventProps extends ISidePanelSelectCostEventProps, ISidePanelCostEventDetailsProps, ISidePanelBudgetCostCodeProps {
  costEventAlert: string;
  setCostEventHours: (arg: ISidePanelCostEventDetailsProps["costEventHours"]) => void;
  currentComponentLayer: number;
}

interface ICostEventSelectModalProps extends ISidePanelSelectCostEventProps {
  isOpen: boolean;
  toggle: () => void;
}



const SidePanelCostEvent = (props: ISidePanelCostEventProps) => {
  const ComponentLayers = [
    <SidePanelSelectCostEvent
      costEvents={props.costEvents}
      selectCostEvent={props.selectCostEvent}
      selectedCostEvent={props.selectedCostEvent}
    />,
    <TimeLog 
      costEventAlert={props.costEventAlert}
      currentHours={props.costEventHours}
      setCurrentHours={props.setCostEventHours}
    >
      {props.selectedCostEvent?._budgetCodes?.length ? <SidePanelBudgetCostCode
        selectedCostEvent={props.selectedCostEvent}
        selectBudgetCode={props.selectBudgetCode}
        selectedBudgetCostCode={props.selectedBudgetCostCode}
      /> : null}
    </TimeLog>,
    <SidePanelCostEventDetails 
      hoursSum={props.hoursSum}
      costEventHours={props.costEventHours}
      toggleCESidePanel={props.toggleCESidePanel}
      selectedCostEvent={props.selectedCostEvent}
      selectedCostEventCategory={props.selectedCostEventCategory}
      showCostEventDetails={props.showCostEventDetails}
      selectedBudgetCostCode={props.selectedBudgetCostCode}
      setShowCostEventDetails={props.setShowCostEventDetails}
      setCurrentComponentLayer={props.setCurrentComponentLayer}
    />
  ]

  return props.showCostEventDetails ? <SidePanelCostEventDetails 
      hoursSum={props.hoursSum}
      costEventHours={props.costEventHours}
      toggleCESidePanel={props.toggleCESidePanel}
      selectedCostEvent={props.selectedCostEvent}
      showCostEventDetails={props.showCostEventDetails}
      selectedCostEventCategory={props.selectedCostEventCategory}
      selectedBudgetCostCode={props.selectedBudgetCostCode}
      setShowCostEventDetails={props.setShowCostEventDetails}
      setCurrentComponentLayer={props.setCurrentComponentLayer}
  /> : ComponentLayers[props.currentComponentLayer]
}

const SidePanelCostEventDetails = (props: ISidePanelCostEventDetailsProps) => {
  const baseHours = {
    regular: Number(props.hoursSum.regular - Number(props.costEventHours.regular)).toFixed(2),
    overTime: Number(props.hoursSum.overTime - Number(props.costEventHours.overTime)).toFixed(2),
    doubleTime: Number(props.hoursSum.doubleTime - Number(props.costEventHours.doubleTime)).toFixed(2)
  }
  return <>
    <ul className="cost-event-list">
      <li>
        {/* eslint-disable-next-line */}
        <a
          // eslint-disable-next-line
          href="javascript:void(0);"  onClick={() => {
            props.setShowCostEventDetails(false)
            props.setCurrentComponentLayer(0)
            props.toggleCESidePanel()
          }} >
          <span className="ce-heading">Base Job Hours</span>
          <span className="ce-time">{baseHours.regular} / {baseHours.overTime} / {baseHours.doubleTime}</span>
        </a>
      </li>
      <li>
        {/* eslint-disable-next-line */}
        <a
          // eslint-disable-next-line
          href="javascript:void(0);" onClick={() => {
            props.setShowCostEventDetails(false)
          }}>
          <span className="ce-heading">{props.selectedCostEventCategory.ceCategoryName} Hours {props.selectedBudgetCostCode?._ceBudgetCodeLabel ? `(${props.selectedBudgetCostCode._ceBudgetCodeLabel})` :  null}</span>
          <span className="ce-time">{Number(props.costEventHours.regular).toFixed(2)} / {Number(props.costEventHours.overTime).toFixed(2)} / {Number(props.costEventHours.doubleTime).toFixed(2)}</span>
          <span className="ce-tag">
            {/* add necessary tag styles */}
            <i className="fa fa-tag"></i> {props.selectedCostEvent?.ceNumber}: {props.selectedCostEvent?.ceDescription}
          </span>
        </a>
      </li>
    </ul>
    {/* Selected Costevnets Ends */}

    {/* Total Loghours Starts */}
    <div className="custom-logs-wrapper">
      <ul className="custom-logs-list w-100">
        <li>
          <div className="custom-log">
            <h6>Total Reg</h6>{" "}
            <div className="custom-block">
              {props.hoursSum.regular.toFixed(2)}
            </div>
          </div>
        </li>
        <li>
          <div className="custom-log">
            <h6>Total Ovt</h6>
            <div className="custom-block">
              {props.hoursSum.overTime.toFixed(2)}
            </div>
          </div>
        </li>
        <li>
          <div className="custom-log">
            <h6>Total Dbl</h6>
            <div className="custom-block">
              {props.hoursSum.doubleTime.toFixed(2)}
            </div>
          </div>
        </li>
      </ul>
    </div>

    {/* Total Loghours Ends */}
    {/* Cost Events Ends */}
  </>
}

const SidePanelSelectCostEvent = (props: ISidePanelSelectCostEventProps) => {
  const [isOpen, setIsOpen] = useState(false)
  const toggleModal = () => setIsOpen(!isOpen)
  return <>
    <ul className="cost-event-list">
      <li>
        {/* eslint-disable-next-line */}
        <a
          // eslint-disable-next-line
          href="javascript:void(0);" onClick={toggleModal}>
          <span className="ce-time">{props.selectedCostEvent?.ceNumber ? `${props.selectedCostEvent.ceNumber}: ${props.selectedCostEvent.ceDescription}` : "Select Cost Event"}</span>
        </a>
      </li>
    </ul>
    {isOpen ? <CostEventSelectModal
      toggle={toggleModal}
      isOpen={isOpen}
      costEvents={props.costEvents}
      selectCostEvent={props.selectCostEvent}
      selectedCostEvent={props.selectedCostEvent}
    /> : null}
  </>
}

const CostEventSelectModal = (props: ICostEventSelectModalProps) => {
  return <Modal
    isOpen={props.isOpen}
    toggle={props.toggle}
    fade={false}
    className="select-cost-event-modal modal-md"
  >
    <ModalHeader toggle={props.toggle}>
      Select Cost Event
    </ModalHeader>
    <ModalBody>
      <div>
        <ul className="cost-event-list-select">
          {props.costEvents.map((eachCostEvent: { id: number; ceNumber: string; ceDescription: string; _projectNumber: string; }) => {
            return <li key={eachCostEvent.id}>
              {/* eslint-disable-next-line */}
              <a
                // eslint-disable-next-line
                href="javascript:void(0);" onClick={() => props.selectCostEvent(eachCostEvent)}>
                <input type={"checkbox"} className="custom-control custom-checkbox" checked={props.selectedCostEvent?.id === eachCostEvent.id} />
                <div className="cost-event-select">
                  <span className="ce-heading">{eachCostEvent.ceNumber}: {eachCostEvent.ceDescription}</span>
                  <span className="ce-project">Project {eachCostEvent._projectNumber}</span>
                </div>
              </a>
            </li>
          })}
        </ul>
      </div>
      <ModalFooter>
        <Button color="secondary" onClick={props.toggle}>
          Cancel
        </Button>
        <Button color="primary" type="button" onClick={() => {
          props.toggle()
        }}>
          Save
        </Button>{" "}
      </ModalFooter>
    </ModalBody>
  </Modal>
}

const SidePanelBudgetCostCode = (props: ISidePanelBudgetCostCodeProps) => {
  const [isOpen, setIsOpen] = useState(false)
  const toggleModal = () => setIsOpen(!isOpen)
  return <>
    <div className='budget-cost-event-wrapper'>
      <span className='budget-cost-event-title'>Select Alternate Cost Code</span>
      <ul className="budget-cost-event-list">
        <li>
          {/* eslint-disable-next-line */}
          <a
            // eslint-disable-next-line
            href="javascript:void(0);" onClick={toggleModal}>
            <span className="ce-time">{props.selectedBudgetCostCode?.budgetCodeId ? `${props.selectedBudgetCostCode._ceBudgetCodeLabel}` : "Select"}</span>
          </a>
        </li>
      </ul>
    </div>
    {isOpen ? <BudgetCostCodeSelectModal
      toggle={toggleModal}
      isOpen={isOpen}
      selectedCostEvent={props.selectedCostEvent}
      selectBudgetCode={props.selectBudgetCode}
      selectedBudgetCostCode={props.selectedBudgetCostCode}
    /> : null}
  </>
}

const BudgetCostCodeSelectModal = (props: any) => {
  return <Modal
    isOpen={props.isOpen}
    toggle={props.toggle}
    fade={false}
    className="select-cost-event-modal modal-md"
  >
    <ModalHeader toggle={props.toggle}>
      Select Cost Code
    </ModalHeader>
    <ModalBody>
      <div>
        <ul className="cost-event-list-select">
          {props.selectedCostEvent._budgetCodes.map((eachBudgetCode: { budgetCodeId: number; _ceBudgetCodeLabel: string; _budgetCodeDescription: string; }) => {
            return <li key={eachBudgetCode.budgetCodeId}>
              {/* eslint-disable-next-line */}
              <a
                // eslint-disable-next-line
                href="javascript:void(0);" onClick={() => props.selectBudgetCode(eachBudgetCode)}>
                <input type={"checkbox"} className="custom-control custom-checkbox" checked={props.selectedBudgetCostCode?.budgetCodeId === eachBudgetCode.budgetCodeId} />
                <div className="cost-event-select">
                  <span className="ce-heading">{eachBudgetCode._ceBudgetCodeLabel}</span>
                  <span className="ce-project">{eachBudgetCode._budgetCodeDescription}</span>
                </div>
              </a>
            </li>
          })}
        </ul>
      </div>
      <ModalFooter>
        <Button color="secondary" onClick={props.toggle}>
          Cancel
        </Button>
        <Button color="primary" type="button" onClick={() => {
          props.toggle()
        }}>
          Save
        </Button>{" "}
      </ModalFooter>
    </ModalBody>
  </Modal>
}

export default SidePanelCostEvent