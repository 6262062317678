export const ItemTypes = {
    BOX: 'box',
    CELL: 'cell',
    CONTRACT: 'contract',
    TICKET: 'ticket',
    CREW: 'crew',
    GROUPED_SERVICE_TICKET: 'groupedServiceTicket'
}

export const StatusType = {
    CLOSED: 'Closed Job',
    DONE: 'Work Complete',
    DRAFT: 'Draft Job',
    INPROGRESS: 'In Progress Job',
    OPEN: 'Open Job'
} as any;

export const AccessPath = {
    SERVICE_TICKET: "serviceTicket",
    TIMECARD: "timecard",
} as any;

export const ToolsPath = {
    SERVICE_TICKET: "sec.fieldOps.tools.serviceTicket"
}

export const States = [
  { name: 'Alabama', abbreviation: 'AL', id: 0 },
  { name: 'Alaska', abbreviation: 'AK', id: 1 },
  { name: 'Arizona', abbreviation: 'AZ', id: 2 },
  { name: 'Arkansas', abbreviation: 'AR', id: 3 },
  { name: 'California', abbreviation: 'CA', id: 4 },
  { name: 'Colorado', abbreviation: 'CO', id: 5 },
  { name: 'Connecticut', abbreviation: 'CT', id: 6 },
  { name: 'Delaware', abbreviation: 'DE', id: 7 },
  { name: 'Florida', abbreviation: 'FL', id: 8 },
  { name: 'Georgia', abbreviation: 'GA', id: 9 },
  { name: 'Hawaii', abbreviation: 'HI', id: 10 },
  { name: 'Idaho', abbreviation: 'ID', id: 11 },
  { name: 'Illinois', abbreviation: 'IL', id: 12 },
  { name: 'Indiana', abbreviation: 'IN', id: 13 },
  { name: 'Iowa', abbreviation: 'IA', id: 14 },
  { name: 'Kansas', abbreviation: 'KS', id: 15 },
  { name: 'Kentucky', abbreviation: 'KY', id: 16 },
  { name: 'Louisiana', abbreviation: 'LA', id: 17 },
  { name: 'Maine', abbreviation: 'ME', id: 18 },
  { name: 'Maryland', abbreviation: 'MD', id: 19 },
  { name: 'Massachusetts', abbreviation: 'MA', id: 20 },
  { name: 'Michigan', abbreviation: 'MI', id: 21 },
  { name: 'Minnesota', abbreviation: 'MN', id: 22 },
  { name: 'Mississippi', abbreviation: 'MS', id: 23 },
  { name: 'Missouri', abbreviation: 'MO', id: 24 },
  { name: 'Montana', abbreviation: 'MT', id: 25 },
  { name: 'Nebraska', abbreviation: 'NE', id: 26 },
  { name: 'Nevada', abbreviation: 'NV', id: 27 },
  { name: 'New Hampshire', abbreviation: 'NH', id: 28 },
  { name: 'New Jersey', abbreviation: 'NJ', id: 29 },
  { name: 'New Mexico', abbreviation: 'NM', id: 30 },
  { name: 'New York', abbreviation: 'NY', id: 31 },
  { name: 'North Carolina', abbreviation: 'NC', id: 32 },
  { name: 'North Dakota', abbreviation: 'ND', id: 33 },
  { name: 'Ohio', abbreviation: 'OH', id: 34 },
  { name: 'Oklahoma', abbreviation: 'OK', id: 35 },
  { name: 'Oregon', abbreviation: 'OR', id: 36 },
  { name: 'Pennsylvania', abbreviation: 'PA', id: 37 },
  { name: 'Rhode Island', abbreviation: 'RI', id: 38 },
  { name: 'South Carolina', abbreviation: 'SC', id: 39 },
  { name: 'South Dakota', abbreviation: 'SD', id: 40 },
  { name: 'Tennessee', abbreviation: 'TN', id: 41 },
  { name: 'Texas', abbreviation: 'TX', id: 42 },
  { name: 'Utah', abbreviation: 'UT', id: 43 },
  { name: 'Vermont', abbreviation: 'VT', id: 44 },
  { name: 'Virginia', abbreviation: 'VA', id: 45 },
  { name: 'Washington', abbreviation: 'WA', id: 46 },
  { name: 'West Virginia', abbreviation: 'WV', id: 47 },
  { name: 'Wisconsin', abbreviation: 'WI', id: 48 },
  { name: 'Wyoming', abbreviation: 'WY', id: 49 }
];

export const ReportConditions = {
    endStatusCondition: 'COMPLETED'
};

export const reportsShortId = {
    serviceTicketReportShortId: 'a5Fq2ywZ_R'
}
