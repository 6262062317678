import axios from "axios";
import { getUserAccessAndFilterProjects } from "../components/shared/Helpers";
import { useAuthStore, useServiceStore } from "../store";

export type Headers = Record<string, string>;
export type QueryParams = Record<string, string | undefined>;

const makeHeaders = async (override: Headers = {})  => {
  let { token, setToken, setSourceUrl } = useAuthStore.getState();
  
  if(!token) {
    token = localStorage.getItem('token') || ""
    setToken(token);
    setSourceUrl(localStorage.getItem('sourceUrl'));
    parseJwt(token);
    await getUserAccessList();
    const userSettings = await getUserSettings();
    await getUserAccessAndFilterProjects(userSettings.selectedProjectId);
  }
  const defaultHeaders = { "Content-Type": "application/json", 'Authorization': `Bearer ${token}` };
  return {
    ...defaultHeaders,
    ...override,
  };
}

export const getUserSettings = async () => {
  let apiURL = `/v1/api/user-settings`;
  // @ts-ignore
  const res = await get(apiURL);
  if (res.data && res.data.data) {
    const { setUserSettings } = useAuthStore.getState();
    const { setData, userAccessList, setSelectedOrgEntity } = useServiceStore.getState();
    setUserSettings(res.data.data);
    setData(res.data.data.selectedCompanyId);
    if(userAccessList && userAccessList.orgEntities && userAccessList.orgEntities.length && res.data.data.selectedCompanyId){
      const selectedOrgEntity = userAccessList.orgEntities.find((entity:any) => entity.id === Number(res.data.data.selectedCompanyId))
      setSelectedOrgEntity(selectedOrgEntity);
    }
    return res.data.data;
  }
  return;
};

export const getLoginDetails = async () => {
const { setToken } = useAuthStore.getState();
    try {
      // @ts-ignore
      const res = await axios.post(
        `${process.env.REACT_APP_RISKCAST_API_URL}/api/core/auth/login`,
        {
          username: "reggie@acme.com",
          password: "fE88ehUfxr94H3v",
        }
      );
      // @ts-ignore
      setToken(res.data.token);
      parseJwt(res.data.token);
      return res.data.token
    } catch (e) {
      console.log(e);
    }
};

export const parseJwt = (token:any) => {
  const { setTokenDetails } = useServiceStore.getState();
  if (token) {
      var base64Url = token.split('.')[1];
      var base64 = base64Url.replace('-', '+').replace('_', '/');
      setTokenDetails(JSON.parse(window.atob(base64)))
  }
}

export const getUserAccessList = async () => {
  const { setUserAccessList, tokenDetails } = useServiceStore.getState();
  const apiURL = `/v1/api/user/access/${tokenDetails?.userId}/list`;
  const resData = await get(apiURL);
  setUserAccessList(resData.data);
};

// creating axios instance
// can be used with interceptors
const axiosInstance = axios.create({
  baseURL: process.env.REACT_APP_API_URL
})

export function getFullUrl(path: string = "", query: QueryParams = {}) {
    const json = JSON.parse(JSON.stringify(query)); // get rid of undefined values
  
    const queryStr = new URLSearchParams(json).toString();
    const q = queryStr ? `?${queryStr}` : "";
  
    return path + q;
}
  

export async function get(
    path: string,
    queryParams?: QueryParams,
    extraHeaders?: Headers
) {
  const headers = await makeHeaders(extraHeaders);
    const fullUrl = getFullUrl(path, queryParams);
    const res = await axiosInstance.get(fullUrl, {
      headers,
    });
  
    return res;
}

export async function deleteAPI(
    path: string,
    queryParams?: QueryParams,
    extraHeaders?: Headers
) {
    const headers = await makeHeaders(extraHeaders);
    const fullUrl = getFullUrl(path, queryParams);
  
    const res = await axiosInstance.delete(fullUrl, {
      headers,
    });
  
    return res;
}

export async function post(
  path: string,
  payload: any,
  queryParams?: QueryParams,
  extraHeaders?: Headers
) {
  const headers = await makeHeaders(extraHeaders);
  const fullUrl = getFullUrl(path, queryParams);

  const res = await axiosInstance.post(fullUrl, payload, {
    headers,
  });

  return res;
}

export async function put(
  path: string,
  payload: any,
  queryParams?: QueryParams,
  extraHeaders?: Headers
) {
  const headers = await makeHeaders(extraHeaders);
  const fullUrl = getFullUrl(path, queryParams);

  const res = await axiosInstance.put(fullUrl, payload, {
    headers,
  });

  return res;
}

const API = {
  get,
  post,
  put,
  deleteAPI
};
export default API;
