import React from "react";
import ReactPaginate from "react-paginate";

// @ts-ignore
function PaginatedItems(props) {

  const { pageCount, currentPage, setCurrentPage, handlePageChange, setPageLimit, pageLimit,customClass } = props

  // Invoke when user click to request another page.
  const handlePageClick = (event: any) => {
    setCurrentPage(event.selected)
    handlePageChange(event.selected)
  };

  return (
    <div className={`pagination-content ${customClass}`}>
      {/* <Items currentItems={currentItems} /> */}
      <ReactPaginate
        breakLabel="..."
        nextLabel="next >"
        onPageChange={handlePageClick}
        pageRangeDisplayed={5}
        pageCount={pageCount}
        previousLabel="< previous"
        initialPage={currentPage}
      // renderOnZeroPageCount={null}
      />
      <select
        className="pagination-select"
        value={pageLimit}
        onChange={(e) => {
          // @ts-ignore
          setPageLimit(e.target.value);
          setCurrentPage(0)
        }}
      >
        <option value="25">25</option>
        <option value="50">50</option>
        <option value="100">100</option>
      </select>
    </div>
  );
}

export default PaginatedItems;
