import React, { useState, useEffect, useMemo, useRef } from "react";
import {
  Button,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
} from "reactstrap";
import Header from "../../shared/Header/Header";
import SelectDropdown from "../../shared/SelectDropdown/SelectDropdown";
import { useHistory } from "react-router-dom";
// import { ReactComponent as Close } from "../../../assets/images/close.svg";
import { ReactComponent as Trash } from "../../../assets/images/trash.svg";
import { AsyncTypeahead } from "react-bootstrap-typeahead";
import "react-bootstrap-typeahead/css/Typeahead.css";
import DummyCerti from "../../../assets/images/certi.jpg";
import { ReactComponent as AddPlus } from "../../../assets/images/add.svg";
import { ReactComponent as AddTicket } from "../../../assets/images/add-ticket.svg";
import { ReactComponent as SendEmail } from "../../../assets/images/send-email.svg";
import { ReactComponent as Calendar } from "../../../assets/images/calendar.svg";
import { ReactComponent as Certificate } from "../../../assets/images/certificate-ribbon.svg";
import DummyUser from "../../../assets/images/dummy-user.svg";
import { ReactComponent as TempLabour } from "../../../assets/images/temp-labour.svg";
import AddCrewModal from "./AddCrewModal";
import AddCodeModal, { ISaveCodePayload } from "./AddCodeModal";
import TimecardCloneModal from "./TimecardCloneModal";
import {
  useServiceStore,
  useTimeCardStore,
  useAuthStore,
} from "../../../store";
import { post, get, put, deleteAPI } from "../../../types/api";
import { withRouter, RouteComponentProps } from "react-router-dom";
import { IAssignedCode } from "./TimecardInterfaces";
import Loader from "../../shared/Loader/Loader";
import moment from "moment";
import shallow from "zustand/shallow";
import Keypad from "./Keypad";
import SidePanelCostEvent from "./SidePanelCostEvent";
import AllowancePanel from "./SidePanel/AllowancePanel";
import { modalDataArray, rightPanelOptions } from "./TimecardConstants";
import PayclassPanel from "./SidePanel/PayclassPanel";
import { arrayMove, formatServiceTicketDisplayNumber, isObjEmpty, preparePayloadLabourAndEquipment, updateTimeCardStatus } from "../../shared/Helpers";
import { DndProvider } from "react-dnd";
import { isMobile } from "react-device-detect";
import { TouchBackend } from "react-dnd-touch-backend";
import { HTML5Backend } from "react-dnd-html5-backend";
import DragDropTableHeader from "../../shared/DragDrop/DragDropTableHeader";
import { ItemTypes } from "../../shared/Constants";
import SweetAlert from 'react-bootstrap-sweetalert';
import { ToastContainer, toast } from "react-toastify";
import 'react-toastify/dist/ReactToastify.css';
import ConfirmModal from "./ConfirmModal";

interface MatchParams {
  timecardId?: string;
}
interface IServiceTicketDetailsProps extends RouteComponentProps<MatchParams> { }

interface ITimecardArrayPayload {
  id: number;
  tenantId?: number;
  timeCardId: number;
  employeeId: number;
  sortOrder: number;
}

interface ISaveCrewPayload {
  projectId?: number;
  timeCardId: number;
  timeCardCrew: ITimecardArrayPayload[];
  timeCardCostCodes: [];
}

interface IAssignedCrew {
  id: number;
  ids: number[];
  employeeId: number;
  count: number;
}

interface ITempAgeOptions {
  id: number;
  orgEntityId: number;
  tempAgencyKey: string;
  tempAgencyName: string;
  tempAgencyDescription: string;
}
interface ITempLaborFormData {
  tenantId: any;
  lastName: any;
  firstName: any;
  personPic: any;
  emailAddress: any;
  tempAgencyId: any;
  tempAgencyKey: any;
  mobileNo: any;
  personId: any;
  orgEntityId: any;
}
const TimecardList = (props: IServiceTicketDetailsProps) => {
  const history = useHistory();
  // Global state
  const tokenDetails = useServiceStore(
    (serviceStore) => serviceStore.tokenDetails
  );
  const orgEntityId = useServiceStore((serviceStore) => serviceStore.companyId);
  const selectedProjectsSupervisorsShift = useTimeCardStore(
    (timeCardStore) => timeCardStore.selectedProjectsSupervisorsShift
  );
  const { userAccess } = useAuthStore(
    (state) => ({ userAccess: state.userAccess }),
    shallow
  );

  // Timecard state
  const [timecardId, setTimecardId] = useState(
    props.match.params.timecardId || 0
  );
  const [filterDropData, setfilterDropData] = useState({} as any);
  const timeCardDefaultValue = {
    crewList: [],
    hourList: [],
    costCodeList: [],
    costCodeListGrouped: [],
    serviceTicketTimecardOrder: [] as any
  };
  const [timeCardData, setTimeCardData] = useState(timeCardDefaultValue as any);
  const [crewCertificateModal, setCrewCertificateModal] = useState(false);
  const [activeModalCrew, setactiveModalCrew] = useState({
    crewMemberInfo: {
      crewKey: "",
      crewName: "",
      crewPic: "",
      defaultUnionTradeClassId: "",
      doubleTimeRate: "",
      employeeId: "",
      id: "",
      isEmployee: "",
      isEquipment: "",
      overTimeRate: "",
      regularRate: "",
      rowSpan: "",
      timeCardSupervisorId: "",
    },
    crewMemberTag: [],
    crewMemberCerti: [],
    otherTimeCards: [],
  });

  const [loadingCrew, setLoadingCrew] = useState(false);
  const [timeCardLoader, setTimeCardLoader] = useState(false);
  const [hourLoader, setHourLoader] = useState(false);
  const [availableCrew, setAvailableCrew] = useState([] as any);
  const [assignedCrew, setAssignedCrew] = useState([] as IAssignedCrew[]);
  const [availableHourTags, setAvailableHourTags] = useState([] as any);
  const [assignTagToHourPayload, setAssignTagToHourPayload] = useState(
    [] as any
  );
  const [removedTagsFromHour, setRemovedTagsFromHour] = useState([] as any);
  const [alreadyAssignedTagsFromHour, setAlreadyAssignedTagsFromHour] =
    useState([] as any);

  const [tempAgeOptions, setTempAgeOptions] = useState([] as ITempAgeOptions[]);

  const [alert, setAlert] = useState({
    success: false,
    colour: "success",
    isOpen: false,
    message: "",
  } as any);

  const [costEventAlert, setCostEventAlert] = useState("");

  // htmlRegex to remove tags from string
  const htmlRegexG = /<(?:"[^"]*"['"]*|'[^']*'['"]*|[^'">])+>/g;

  const [addTempLabFormFields, setAddTempLabFormFields] = useState({
    tenantId: "",
    lastName: "",
    firstName: "",
    personPic: "",
    emailAddress: "",
    tempAgencyId: "",
    tempAgencyKey: "",
    mobileNo: "",
    personId: "",
    orgEntityId: "",
  } as ITempLaborFormData);

  const {
    timeCardDate,
    setSelectedCostCodeProject,
    setServiceTicketsList,
    setTimeCardDate,
    projectsAndSupervisors,
    setSelectedServiceTicketsDetails,
    timeCardEventsData,
    setTimeCardEventsData,
    setSelectedProjectsSupervisorsShift
  } = useTimeCardStore.getState();
  const [assignedCode, setAssignedCode] = useState([] as IAssignedCode[]);

  const [modalRemark, setModalRemark] = useState("");

  const [isRightPanelOpened, toggleRightPanel] = useState({
    ...rightPanelOptions,
  });
  const handleRightPanel = (values : any, scrollObj = { scroll : false } as any) => {
    toggleRightPanel({
      ...rightPanelOptions,
      ...values,
    });
    if(scrollObj.scroll) {
      setTimeout(() => {
        scrollToSelectedDiv(scrollObj.currRow, scrollObj.currCol);
      }, 500)
    }
  }
  // Hours for crew state
  const [modalData, setmodalData] = useState({
    heading: "",
    subHeading: "",
    modalName: "",
    modalIndex: "",
    addRemarks: true
  });
  const [deleteServiceTicketId, setDeleteServiceTicketId] = useState(0);
  const [modalActive, setmodalActive] = useState(false);
  const [hoursCurrData, setCurrHours] = useState({} as any);
  
  const [hoursClicked, setHoursClicked] = useState("regular");
  const [selectedHourCells, setSelectedHourCells] = useState({} as any);
  
  const [deleteIndex, setDeleteIndex] = useState(-1);

  // Pay class store
  const [selectedPayclassCells, setSelectedPayclassCells] = useState({} as any);
  const [selectedAllowanceCells, setSelectedAllowanceCells] = useState({} as any);
  const [selectedEmployee, setSelectedEmployee] = useState({} as any);
  const [cloneModal, setCloneModal] = useState(false);
  const toggleClone = async () => {
    const isTimecardOpenForThisWeek =
      !cloneModal && (await checkIsTimecardOpenForThisWeek());
    if (!isTimecardOpenForThisWeek) {
      setCloneModal(!cloneModal);
    }
  };

  const { userSettings } = useAuthStore(
    (state) => ({ userSettings: state.userSettings }),
    shallow
  );

  const addPeopleEquipment = useRef(null);

  const toggleDeleteCard = () => setmodalActive(!modalActive);

  const [deleteSCCardModal, setDeleteSCCardModal] = useState(false);
  const toggleDeleteSCCard = () => setDeleteSCCardModal(!deleteSCCardModal);

  const [addCrewModal, setAddCrewModal] = useState(false);
  const toggleAddCrew = async () => {
    const isTimecardOpenForThisWeek =
      !addCrewModal && !timecardId && (await checkIsTimecardOpenForThisWeek());
    if (!isTimecardOpenForThisWeek) {
      setAddCrewModal(!addCrewModal);
    }
  };
  const [addCodeModal, setAddCodeModal] = useState(false);
  const toggleAddCode = async () => {
    const isTimecardOpenForThisWeek =
      !addCodeModal && !timecardId && (await checkIsTimecardOpenForThisWeek());
    if (!isTimecardOpenForThisWeek) {
      setAddCodeModal(!addCodeModal);
    }
  };

  const [tempLabourModal, setTempLabourModal] = useState(false);
  const toggleTempLabour = () => setTempLabourModal(!tempLabourModal);

  const [crewInfoModal, setCrewInfoModal] = useState(false);
  const [crewTagsData, setCrewTagsData] = useState([] as any);

  const [costEventCategories, setCostEventCategories] = useState({} as { [projectId: number]: [] })
  const [selectedProjectId, setSelectedProjectId] = useState(0)
  const [selectedCostEventCategory, setSelectedCostEventCategory] = useState(undefined as any | undefined)
  const [allTags, selectAllTags] = useState([] as any)
  const [costEvent, setCostEvents] = useState({} as { [projectIdAndCeCategoryId: string]: [{ id: number; ceNumber: string; ceDescription: string; _projectNumber: string; }] })
  const [isCESidePanelOpen, setCESidePanelOpen] = useState(false)
  const [selectedCostEvent, setSelectedCostEvent] = useState({} as { id?: any; ceNumber?: string; _ceLabel?: string; ceDescription?: string; _budgetCodes?: [typeof selectedBudgetCostCode] } | undefined)
  const [currentComponentLayer, setCurrentComponentLayer] = useState(0)
  const [costEventHours, setCostEventHours] = useState({
    regular: 0,
    overTime: 0,
    doubleTime: 0
  } as { [index: string]: string | number })
  const [showCostEventDetails, setShowCostEventDetails] = useState(false)
  const [selectedBudgetCostCode, setSelectedBudgetCostCode] = useState({} as {
    budgetCodeId: number; _ceBudgetCodeLabel: string; _budgetCodeDescription: string; _budgetCodeKey?: string; budgetCodeProjectId?: number;
  } | undefined)


  // API CALLS
  const toggleCrewInfo = async (e: any) => {
    const crewMemberData = { ...activeModalCrew };
    crewMemberData["crewMemberInfo"] = e;
    setTimeCardLoader(true);
    const certiApiURL = `/v1/api/time-card/helper/crew-member/${crewMemberData.crewMemberInfo?.employeeId}?timeCardId=${timecardId}&crewId=${crewMemberData.crewMemberInfo?.id}`;
    const certiApires = await get(certiApiURL);
    if (certiApires.data && certiApires.data.data) {
      crewMemberData["crewMemberCerti"] =
        certiApires?.data?.data?.certificates || [];
      crewMemberData["otherTimeCards"] =
        certiApires.data?.data?.otherTimeCards || [];
    } else {
      crewMemberData["crewMemberCerti"] = [];
    }

    const apiURL = `/v1/api/time-card/helper/employee-tags?orgEntityId=${orgEntityId}`;
    const res = await get(apiURL);
    setTimeCardLoader(false);
    if (res.data && res.data.data) {
      const iterableData: any = [...res.data.data].map((e: any) => ({
        ...e,
        isChecked: certiApires?.data?.data?.tags?.find(
          (each: any) => each.id === e.id
        )
          ? true
          : false,
      }));
      crewMemberData["crewMemberTag"] = iterableData;
    } else {
      crewMemberData["crewMemberTag"] = [];
    }
    setactiveModalCrew({ ...crewMemberData });
    setCrewInfoModal(!crewInfoModal);
  };

  const getTimeCardData = async (currDate?: any) => {
    const projectId = selectedProjectsSupervisorsShift.project.length
      ? selectedProjectsSupervisorsShift.project[0].id
      : Number(userSettings.selectedProjectId || 0);
    if (projectId) {
      let fromDate;
      let toDate;
      if (currDate) {
        fromDate = moment(moment(currDate).startOf("month"))
          .subtract(7, "days")
          .format("YYYYMMDD");
        toDate = moment(moment(currDate).endOf("month"))
          .add(7, "days")
          .format("YYYYMMDD");
      } else {
        fromDate = moment()
          .startOf("month")
          .subtract(7, "days")
          .format("YYYYMMDD");
        toDate = moment().endOf("month").add(7, "days").format("YYYYMMDD");
      }

      const supervisorId = selectedProjectsSupervisorsShift.supervisor.length
        ? selectedProjectsSupervisorsShift.supervisor[0].id
        : 0;
      let apiURL = `/v1/api/time-card/filter?projectId=${projectId}&supervisorId=${supervisorId}&fromDate=${fromDate}&toDate=${toDate}&orgEntityId=${orgEntityId}`;

      // @ts-ignore
      const res = await get(apiURL);
      if (res.data && res.data.data) {
        setTimeCardEventsData(res.data.data);
      }
    }
  };

  const updateTagsCrewInfo = async () => {
    if (crewTagsData.length) {
      const url = `/v1/api/time-card/helper/employee-tags`;
      const dataPayload = [...crewTagsData].map((each) => ({
        tenantId: tokenDetails?.tenantId,
        crewId: activeModalCrew?.crewMemberInfo?.id,
        id: each.id,
      }));
      const payload: any = {
        tags: [...dataPayload],
      };
      await put(url, payload);
      setCrewInfoModal(!crewInfoModal);
      getTimecardById();
    }
  };

  const getTempAgeList = async () => {
    getAssignedCode();
    setAlert({ ...alert, isOpen: false });
    const apiURL = `/v1/api/time-card/helper/temp-agencies?orgEntityId=${orgEntityId}`;
    // @ts-ignore
    const res = await get(apiURL);
    if (res.data && res.data.data) {
      setTempAgeOptions([...res.data.data]);
    } else {
      setTempAgeOptions([]);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  };

  const addtemplabourFunc = async (laborData: any) => {
    try {
      const url = `/v1/api/time-card/helper/temp-labour`;
      if (laborData) {
        const payload = {
          tenantId: tokenDetails?.tenantId,
          lastName: laborData?.lastName,
          firstName: laborData?.firstName,
          personPic: laborData?.personPic,
          emailAddress: laborData?.emailAddress,
          tempAgencyId: laborData?.tempAgencyId,
          tempAgencyKey: laborData?.tempAgencyKey,
          mobileNo: laborData?.mobileNo,
          personId: 0,
          orgEntityId: orgEntityId,
        };
        const { data } = await post(url, payload);
        getAvailableCrew();
        return data.id;
      }
    } catch (error: any) {
      if (
        error.response &&
        error.response.data &&
        error.response.data.error &&
        error.response.data.error.code &&
        error.response.data.error.code === "ER_DUP_ENTRY"
      ) {
        setAlert({
          success: false,
          colour: "info",
          isOpen: true,
          message: "Timecard already exist",
        });
      }
    }
  };

  /** API : GET assigned code */
  const getAssignedCode = async () => {
    try {
      if (timecardId) {
        const url = `/v1/api/time-card/helper/assigned-cost-codes?timeCardId=${timecardId}`;
        const { data } = await get(url);
        const sortedCostCode = sortCostCodeList(data.data);
        return arrangeAssignedCostCode(sortedCostCode);
      }
    } catch (error) {
      console.log(error);
    }
  };

  const arrangeAssignedCostCode = (data: any) => {
    let result: IAssignedCode[] = [];
    data.forEach((eachAssignedCode: IAssignedCode) => {
      let existingAssignedCode = result.findIndex(
        (eachResult) => eachResult.id === eachAssignedCode.id
      );
      if (existingAssignedCode > -1 && (eachAssignedCode.serviceTicketId === result[existingAssignedCode].serviceTicketId)) {
        result[existingAssignedCode].count =
          result[existingAssignedCode].count + 1;
        result[existingAssignedCode].ids.push(eachAssignedCode.id);
      } else {
        // added ids array for adding all the id of that id
        result.push({
          ...eachAssignedCode,
          count: 1,
          ids: [eachAssignedCode.id],
        });
      }
    });
    setAssignedCode(result);
    return result;
  }

  // API to create timecard (can be used in crew modal and service ticket)
  const createTimecard = async () => {
    try {
      const url = `/v1/api/time-card`;
      const payload = {
        tenantId: tokenDetails?.tenantId,
        projectId: selectedProjectsSupervisorsShift?.project[0]?.id,
        timeCardSupervisorId:
          selectedProjectsSupervisorsShift?.supervisor[0]?.id,
        timeCardDate: timeCardDate,
        shift: selectedProjectsSupervisorsShift?.shift[0]?.value,
        timeCardStatus: 0,
      };
      const { data } = await post(url, payload);
      setTimecardId(data.id);
      await history.push(`/timecardList/${data.id}`);
      return data.id;
    } catch (error: any) {
      if (
        error.response &&
        error.response.data &&
        error.response.data.error &&
        error.response.data.error.code &&
        error.response.data.error.code === "ER_DUP_ENTRY"
      ) {
        setAlert({
          success: false,
          colour: "info",
          isOpen: true,
          message: "Timecard already exist",
        });
      }
    }
  };

  const checkOpenCounts = async () => {
    let url = `/v1/api/time-card/open-count?projectId=${selectedProjectsSupervisorsShift?.project[0]?.id}&supervisorId=${selectedProjectsSupervisorsShift?.supervisor[0]?.id}&shiftId=${selectedProjectsSupervisorsShift?.shift[0]?.value}&dateKey=${timeCardDate}`;
    const { data } = await get(url);
    return data.data.openCount;
  };

  // API to save hours
  const saveHours = async () => {
    try {
      const url = `/v1/api/time-card/helper/hours/batch`;
      // prepare payload for hours
      const hours = await prepareHoursPayload();
      const payload = {
        hours,
      };
      const res = await post(url, payload);
      if (res.status === 200) {
        // return newly added hours Ids to add tags if tags are selected
        const newHourIds = await getTimecardById(timeCardData.hourList);
        return newHourIds;
      }
    } catch (error: any) {
      console.log(error.response);
    }
  };

  // API to save tags
  const assignTagToHour = async (newHourIds = []) => {
    try {
      setHourLoader(true);
      if (assignTagToHourPayload.length) {
        for (const tagKey in assignTagToHourPayload) {
          const hourTag = assignTagToHourPayload[tagKey];
          // For existing hours add tags
          for (const [, value] of Object.entries(selectedHourCells)) {
            const hourCell = value as any;
            // existing hour verify by id
            if (hourCell.hour && hourCell.hour.id) {
              const doesTagExist =
                hourCell.hour._tags &&
                hourCell.hour._tags.find(
                  (e: any) => e.timeCardTagId === hourTag.id
                );
              if (!doesTagExist) {
                const payload = {
                  id: 0,
                  tenantId: tokenDetails?.tenantId,
                  refObjectType: hourTag.timeCardTagType,
                  refObjectId: hourCell.hour.id,
                  timeCardTagId: hourTag.id,
                };
                const url = `/v1/api/time-card/hour/tags/${payload.refObjectId}`;
                await post(url, payload);
              }
            }
          }
          // add tags for hours which are created with tags
          for (const key in newHourIds) {
            const id = newHourIds[key];
            const payload = {
              id: 0,
              tenantId: tokenDetails?.tenantId,
              refObjectType: hourTag.timeCardTagType,
              refObjectId: id,
              timeCardTagId: hourTag.id,
            };
            const url = `/v1/api/time-card/hour/tags/${payload.refObjectId}`;
            await post(url, payload);
          }
        }
      }
      // if multiple time selected remove existing not selected tag
      if (
        assignTagToHourPayload.length &&
        Object.keys(selectedHourCells) &&
        Object.keys(selectedHourCells).length > 1
      ) {
        for (const [, value] of Object.entries(selectedHourCells)) {
          // selected hours
          const hourCell = value as any;
          for (const tagKey in hourCell.hour._tags) {
            const e = hourCell.hour._tags[tagKey];
            const removeNotSelectedTags = assignTagToHourPayload.findIndex(
              (hourTag: any) => e.timeCardTagId === hourTag.id
            );
            if (removeNotSelectedTags === -1) {
              const url = `/v1/api/time-card/hour/tags/${e.id}`;
              await deleteAPI(url);
            }
          }
        }
      } else {
        // single time card selected
        if (removedTagsFromHour.length) {
          for (const key in removedTagsFromHour) {
            const tag = removedTagsFromHour[key];
            const url = `/v1/api/time-card/hour/tags/${tag.id}`;
            await deleteAPI(url);
          }
        }
      }
      getTimecardById();
      //reset values
      setHourLoader(false);
      resetSidePanel()
    } catch (error: any) {
      console.log(error.response);
    }
  };

  // API to get timecard data
  const getTimecardById = async (hourList = []) => {
    const tId = timecardId;
    let newHourIds = [] as any;
    // check timecard Id exist
    if (tId) {
      const url = `/v1/api/time-card/${tId}`;
      setTimeCardLoader(true);
      const { data } = await get(url);
      setTimeCardLoader(false);
      getAssignedCrew(timecardId);

      // check api response
      if (data && data.data) {
        // check projectId
        if (data.data.projectId) {
          const res = projectsAndSupervisors.projects.find(
            (e: any) => e.id === data.data.projectId
          );
          // use in select cost code popup
          setSelectedCostCodeProject([res]);
        }
        // find duplicate crew for rowSpan
        data.data.crewList.forEach((c: any) => {
          data.data.crewList.forEach((b: any) => {
            if (
              (b.isEmployee && c.employeeId === b.employeeId) ||
              (b.isEquipment && c.equipmentId === b.equipmentId)
            ) {
              if (c.hasOwnProperty("rowSpan")) {
                c.rowSpan += 1;
                c.ids.push(b.id);
              } else {
                c.rowSpan = 1;
                c.ids = [b.id];
              }
            }
          });
        });
        // group code list by service ticket id to display by service in table
        let costCodeListGrouped = [] as any;
        let costCodeList = [] as any;
        const serviceTicketTimecardOrder = [] as any;
        if (
          data.data &&
          data.data.costCodeList &&
          data.data.costCodeList.length
        ) {
          costCodeList = sortCostCodeList(data.data.costCodeList);
          costCodeListGrouped = groupByKey(costCodeList, "serviceTicketId");
          Object.keys(costCodeListGrouped).forEach((key, groupIndex) => {
            const stOrder = costCodeListGrouped[key][0].serviceTicketTimecardOrder;
            serviceTicketTimecardOrder.push([key, stOrder]);
          });
          serviceTicketTimecardOrder.sort((a: any, b: any) => a[1] - b[1]);
        }
        setTimeCardData({
          ...data.data,
          crewList: data.data.crewList,
          costCodeList,
          costCodeListGrouped,
          serviceTicketTimecardOrder
        });
        arrangeAssignedCostCode(costCodeList)
        // if tags are selected then find newly added hour id and add tag to that
        newHourIds = await filterNewlyAddedHours(data, hourList);
      }
    }
    else {
      setTimeCardData(timeCardDefaultValue)
      setAssignedCode([]);
    }
    return newHourIds;
  };

  /** API : GET available crew */
  const getAvailableCrew = async (query = '') => {
    try {
      if (selectedProjectsSupervisorsShift?.project[0]?.id) {
        setLoadingCrew(true);
        let url = `/v1/api/time-card/helper/available-crew?employeeId=1&projectId=${selectedProjectsSupervisorsShift.project[0].id}&timeCardId=${timecardId}`;
        url = query ? `${url}&searchText=${query}` : url;
        setTimeCardLoader(true);
        const { data } = await get(url);
        setTimeCardLoader(false);
        setAvailableCrew([...data?.data.employees, ...data?.data.equipment] || []);
      }
    } catch (error) {
      console.log(error);
    } finally {
      setLoadingCrew(false)
    }
  };

  /** API : GET assigned crew */
  const getAssignedCrew = async (id: any) => {
    try {
      if (id || timecardId) {
        const url = `/v1/api/time-card/helper/assigned-crew?timeCardId=${id ? id : timecardId
          }`;
        setTimeCardLoader(true);
        const { data } = await get(url);
        setTimeCardLoader(false);
        let result: IAssignedCrew[] = [];
        data.data.forEach((eachAssignedCrew: IAssignedCrew) => {
          let existingAssignedCrew = result.findIndex(
            (eachResult) =>
              eachResult.employeeId === eachAssignedCrew.employeeId
          );
          if (existingAssignedCrew > -1) {
            result[existingAssignedCrew].count =
              result[existingAssignedCrew].count + 1;
            result[existingAssignedCrew].ids.push(eachAssignedCrew.id);
          } else {
            // added ids array for adding all the id of that employeeId
            result.push({
              ...eachAssignedCrew,
              count: 1,
              ids: [eachAssignedCrew.id],
            });
          }
        });
        setAssignedCrew(result);
      }
    } catch (error) {
      console.log(error);
    }
  };

  /** API : GET available tags for hours */
  const getAvailableHourTags = async () => {
    try {
      if (orgEntityId) {
        const url = `/v1/api/time-card/hour/tags?orgEntityId=${orgEntityId}`;
        const { data } = await get(url);
        if (data && data.data) {
          setAvailableHourTags(data.data);
        }
      }
    } catch (error) {
      console.log(error);
    }
  };

  /** Add crew, get the id, update assignedCrew */
  const addCrew = async (employeeOrEquipmentId: number, type: string) => {
    try {
      if (employeeOrEquipmentId) {
        setTimeCardLoader(true);
        let assignedCrewArr = assignedCrew;
        const assignedCode = await getAssignedCode();
        let alreadyExistingAssignedIndex: number = assignedCrew.findIndex(
          (eachAssignedCrew: any) =>
            eachAssignedCrew[type] === employeeOrEquipmentId
        );
        if (alreadyExistingAssignedIndex > -1) {
          assignedCrewArr = [
            ...assignedCrew.slice(0, alreadyExistingAssignedIndex),
            {
              ...assignedCrew[alreadyExistingAssignedIndex],
              count: assignedCrew[alreadyExistingAssignedIndex].count + 1,
            },
            ...assignedCrew.slice(alreadyExistingAssignedIndex + 1),
          ];
          setAssignedCrew(assignedCrewArr);
        } else {
          let selectedAvailableCrew = availableCrew.find(
            (eachAvailableCrew: any) =>
              eachAvailableCrew[type] === employeeOrEquipmentId
          );
          assignedCrewArr = [
            ...assignedCrew,
            { ...selectedAvailableCrew, count: 1 },
          ];
          setAssignedCrew(assignedCrewArr);
        }

        let createdTimecardId: number;
        let fetchedTimeCardVar: any;
        if (!timecardId) {
          createdTimecardId = await createTimecard();
          fetchedTimeCardVar = createdTimecardId;
        }
        const url = `/v1/api/time-card/helper/crew-and-cost-codes`;
        let sortOrder = -1;
        const payload: ISaveCrewPayload = {
          projectId: selectedProjectsSupervisorsShift.project[0].id,
          timeCardId: Number(timecardId),
          timeCardCrew: [],
          timeCardCostCodes: timeCardCostCodePayload(assignedCode),
        };

        assignedCrewArr.forEach((eachCrew) => {
          if (eachCrew.count === 1) {
            sortOrder++;
            payload.timeCardCrew.push(
              createTimecardPayload(eachCrew, 0, sortOrder, createdTimecardId)
            );
          } else {
            let index = 0;
            while (index < eachCrew.count) {
              sortOrder++;
              payload.timeCardCrew.push(
                createTimecardPayload(
                  eachCrew,
                  index,
                  sortOrder,
                  createdTimecardId
                )
              );
              index++;
            }
          }
        });
        await put(url, payload);
        if (!timecardId) {
          setTimecardId(fetchedTimeCardVar);
        } else {
          getTimecardById();
        }
      }
    } catch (error) {
      console.log(error);
    }
  };

  const sortCostCodeList = (codeList: any) => {
    let costCode = [...codeList];
    costCode = costCode.sort(
      (a: any, b: any) => a.serviceTicketId - b.serviceTicketId
    );
    costCode = costCode.sort(
      (a: any, b: any) => a.serviceTicketTimecardOrder - b.serviceTicketTimecardOrder
    );
    return costCode;
  }


  // get service ticket details
  const getServiceTicketApi = async (serviceTicketId: any) => {
    try {
      let url = `/v1/api/service/ticket/id/${serviceTicketId}`
      return await get(url);
    } catch (error) {
      console.log(error);
    }
  }


  /** API: DELETE TimeCard */
  const deleteCurrentnTimeCard = async (timeCardId: any) => {
    try {
      setTimeCardLoader(true);
      const url = `/v1/api/time-card/${timeCardId}`;
      await deleteAPI(url);
      history.push(`/timecard`);
    } catch (error) {
      if(error instanceof Error) {
        toast.warn(error.message, {
          autoClose: 3000,
          toastId: 'deleteCrew'
        });
      }
    } finally {
      toggleDeleteCard();
      setTimeCardLoader(false);
    }
  };

  /** API: DELETE crew memeber */
  const deleteAssignedCrew = async (employeeId: number) => {
    try {
      setTimeCardLoader(true);
      const assignedCrewIndex = assignedCrew.findIndex(
        (eachAssignedCrew: IAssignedCrew) =>
          eachAssignedCrew.employeeId === employeeId
      );
      let promise = assignedCrew[assignedCrewIndex].ids.map((eachId) => {
        const url = `/v1/api/time-card/helper/crew-member/${eachId}`;
        return deleteAPI(url);
      });
      await Promise.all(promise);
      getTimecardById();
    } catch (error) {
      if(error instanceof Error) {
        toast.warn(error.message, {
          autoClose: 3000,
          toastId: 'deleteCrew'
        });
      }
    } finally {
      setTimeCardLoader(false);
      setmodalActive(false);
    }
  };

  /** API: DELETE assigned service ticket */
  const deleteAssignedServiceTicket = async () => {
    try {
      setTimeCardLoader(true);
      const url = `/v1/api/time-card/helper/cards/crew/${timecardId}/servicetickets/${deleteServiceTicketId}`;
      await deleteAPI(url);
      getTimecardById();
    } catch (error) {
      console.log(error);
      if(error instanceof Error) {
        toast.warn(error.message, {
          autoClose: 3000,
          toastId: 'deleteServiceTicket'
        });
      }
    } finally {
      setTimeCardLoader(false);
      setmodalActive(false);
    }
  };

  const getFilterData = async () => {
    const url = `/v1/api/time-card/calendar-filters`;
    const payload = {
      orgEntityId: orgEntityId,
      fromDate: moment().clone().startOf("month").format("YYYYMMDD"),
      toDate: moment().clone().endOf("month").format("YYYYMMDD"),
    };

    const { data } = await post(url, payload);

    if (data.data) {
      setfilterDropData({ ...data.data });
    } else {
      setfilterDropData({});
    }
  };

  const handleTimeCardSubmit = async (updateIndex: any) => {
    try {
      if (updateIndex === "0") {
        deleteCurrentnTimeCard(timecardId);
      } else if((updateIndex === "5" || updateIndex === "7") && deleteIndex !== -1) {
        deleteAssignedCode(deleteIndex);
      } else if(updateIndex === "6" && deleteIndex !== -1) {
        deleteAssignedCrew(deleteIndex);
      } else if(updateIndex === "8") {
        deleteAssignedServiceTicket();
      }
      else if (updateIndex) {
        setTimeCardLoader(true);
        const res = await updateTimeCardStatus(timecardId, updateIndex, modalRemark);
        if (res.status === 200) {
          getTimecardById();
          setTimeCardLoader(false);
          setmodalActive(false);
        }
      }
    } catch (error: any) {
      console.log(error);
    }
  };

  /* API to save code */
  const deleteAssignedCode = async (index: number) => {
    try {
      const newAssignedCode = [
        ...assignedCode.slice(0, index),
        ...assignedCode.slice(index + 1),
      ]
      setAssignedCode(newAssignedCode)
      setTimeCardLoader(true);
      const url = `/v1/api/time-card/helper/crew-and-cost-codes`;
      let sortOrder = -1;
      const payload: ISaveCodePayload = {
        projectId: selectedProjectsSupervisorsShift.project[0].id,
        timeCardId: Number(timecardId),
        timeCardCrew: timeCardCrewPayload(assignedCrew) || [],
        timeCardCostCodes: [],
      };
      const createTimecardPayload = (eachCode: any, index = 0) => {
        sortOrder++;
        const serviceTicketId = eachCode.serviceTicketId
        return {
          id:
            eachCode.labourTimeCardBudgetCodeId ||
            eachCode.equipmentTimeCardBudgetCodeId ||
            0,
          tenantId: tokenDetails?.tenantId,
          timeCardId: Number(timecardId),
          budgetCodeId:
            eachCode.labourBudgetCodeId || eachCode.equipmentBudgetCodeId,
          projectLocationId: 0,
          budgetCodeKey:
            eachCode.labourBudgetCodeKey || eachCode.equipmentBudgetCodeKey,
          timeCardDate: timeCardDate,
          budgetCodeProjectId: eachCode.projectId,
          serviceTicketId: serviceTicketId,
          workOrderCostEventId: 0,
          sortOrder,
        };
      };
      newAssignedCode.forEach((eachCode: any) => {  
        if (eachCode.count === 1) {
          const costCodeObj = createTimecardPayload(eachCode);
          preparePayloadLabourAndEquipment(eachCode, costCodeObj, payload.timeCardCostCodes);
        } else {
          let index = 0;
          while (index < eachCode.count) {
            const costCodeObj = createTimecardPayload(eachCode, index);
            preparePayloadLabourAndEquipment(eachCode, costCodeObj, payload.timeCardCostCodes);
            index++;
          }
        }
      });
      const res = await put(url, payload);
      if (res.status === 200) {
        setmodalActive(false);
        getTimecardById();
      }
    } catch (error) {
      console.log(error);
    }
  };

  const getCostEventCategories = async () => {
    const url = `/v1/api/cost-events/categories?projectId=${timeCardData.projectId}&timeCardDate=${timeCardData.timeCardDate}&orgEntityId=${timeCardData.orgEntityId}`
    const { data } = await get(url)
    let costEventCategories: { [index: number]: any } = {}
    data.data.costEventCatagories.forEach((eachCostEventCategory: { projectId: number; }) => {
      costEventCategories[eachCostEventCategory.projectId] = costEventCategories[eachCostEventCategory.projectId] ? [...costEventCategories[eachCostEventCategory.projectId], eachCostEventCategory] : [eachCostEventCategory]
    })
    setCostEventCategories(costEventCategories)
  }

  const getCostEvent = async () => {
    const url = `/v1/api/cost-events?projectId=${timeCardData.projectId}&timeCardId=${timecardId}`
    const { data } = await get(url)
    let costEvents: { [index: string]: any } = {}
    data.data.costEvents.forEach((eachCostEvent: { projectId: number; ceCategoryId: number }) => {
      if (costEvents[`${eachCostEvent.projectId}-${eachCostEvent.ceCategoryId}`]) {
        costEvents[`${eachCostEvent.projectId}-${eachCostEvent.ceCategoryId}`] = [...costEvents[`${eachCostEvent.projectId}-${eachCostEvent.ceCategoryId}`], eachCostEvent]
      } else {
        costEvents[`${eachCostEvent.projectId}-${eachCostEvent.ceCategoryId}`] = [eachCostEvent]
      }
    })
    setCostEvents(costEvents)
  }


  // HANDLER/HELPER METHODS

  /* prepare payload  */
  const timeCardCrewPayload = (assignedCrew: any, customSortOrder = true) => {
    try {
      let createdTimecardId: number;
      let sortOrder = -1;
      const timeCardCrew = [] as any;
      assignedCrew.forEach((eachCrew: any) => {
        if (eachCrew.count === 1 || !eachCrew.count) {
          sortOrder++;
          const sOrder = customSortOrder ? sortOrder : eachCrew.sortOrder;
          timeCardCrew.push(
            createTimecardPayload(
              eachCrew,
              0,
              sOrder,
              createdTimecardId
            )
          );
        } else {
          let index = 0;
          while (index < eachCrew.count) {
            sortOrder++;
            const sOrder = customSortOrder ? sortOrder : eachCrew.sortOrder;
            timeCardCrew.push(
              createTimecardPayload(
                eachCrew,
                index,
                sOrder,
                createdTimecardId
              )
            );
            index++;
          }
        }
      });
      return timeCardCrew;
    } catch (error) {
      console.log(error);
    }
  };

  // group array by key
  const groupByKey = (list: any, key: any) =>
    list.reduce(
      (hash: any, obj: any) => ({
        ...hash,
        [obj[key]]: (hash[obj[key]] || []).concat(obj),
      }),
      {}
    );

  const cancelModal = () => {
    setmodalActive(false);
    setmodalData({
      heading: "",
      subHeading: "",
      modalName: "",
      modalIndex: "",
      addRemarks: true,
    });
    setModalRemark("");
  };


  const handleCheckBoxClick = (index: number, isChecked: boolean, tag: any) => {
    const crewMemberData = { ...activeModalCrew }; // For Form Submit
    let data: any = [...crewMemberData?.crewMemberTag];
    data[index] = { ...tag, isChecked: isChecked };
    crewMemberData["crewMemberTag"] = data;
    setactiveModalCrew({ ...crewMemberData });

    if (isChecked) {
      if (!crewTagsData.find((each: any) => each.id === tag.id)) {
        setCrewTagsData([...crewTagsData, { ...tag }]);
      }
    } else {
      if (crewTagsData.find((each: any) => each.id === tag.id)) {
        setCrewTagsData(
          [...crewTagsData].filter((each: any) => each.id !== tag.id)
        );
      }
    }
  };

  const toggleCrewCertificate = (
    closeModal: boolean = false,
    certi?: any,
    index?: number
  ) => {
    if (closeModal) {
      setCrewCertificateModal(!crewCertificateModal);
    } else {
    }
  };

  const handleTempLabIpVal = (value: any, name: any, reset?: boolean) => {
    if (!reset) {
      const objTempFormData = { ...addTempLabFormFields };
      if (name !== "tempAgencyKey") {
        objTempFormData[name as keyof typeof addTempLabFormFields] = value;
      } else {
        objTempFormData.tempAgencyId = value[0].id;
        objTempFormData.tempAgencyKey = value[0].tempAgencyKey;
      }
      setAddTempLabFormFields({ ...objTempFormData });
    } else {
      //Reset to blank IP Fields
    }
  };

  const handleHourKeys = (value: any) => {
    value = value.toString();
    let hourValue = '';
    if(hoursCurrData[hoursClicked] === undefined) {
      hourValue = value;
    } else {
      hourValue = hoursCurrData[hoursClicked] + "" + value;
    }
    setCurrHours({
      ...hoursCurrData,
      [hoursClicked]: parseFloat(hourValue),
    });
  };

  const checkIsTimecardOpenForThisWeek = async () => {
    if (!selectedProjectsSupervisorsShift?.project[0]) {
      setAlert({
        success: false,
        colour: "info",
        isOpen: true,
        message: "Select project, foreman and shift",
      });
      return true;
    }
    const _requireSubmitTimecardBeforeCreateNew =
      selectedProjectsSupervisorsShift?.project[0]
        ._requireSubmitTimecardBeforeCreateNew;
    if (_requireSubmitTimecardBeforeCreateNew) {
      const openCount = await checkOpenCounts();
      if (openCount >= 1) {
        setAlert({
          success: false,
          colour: "info",
          isOpen: true,
          message:
            "Cannot create new timecard. This week still contains OPEN timecards for this foreman. You must submit any open timecards before a new one can be created.",
        });
        return true;
      }
    }
    return false;
  };



  // prepare hours payload
  const prepareHoursPayload = async () => {
    const hours = [] as any;
    const weekEndDate = moment(moment(timeCardDate).endOf("week")).format(
      "YYYYMMDD"
    );
    const hasTags = assignTagToHourPayload.length ? true : false;
    Object.keys(selectedHourCells).forEach(
      (key: any, index: number, hourArrayKeys: any) => {
        const hourCell = selectedHourCells[key];
        const hour = hourCell.hour;
        const employee = hourCell.employee;
        const costCode = hourCell.costCode;
        const payload = {
          id: hour.id ? hour.id : 0,
          hourCategoryKey: "BASE",
          regHours: selectedCostEvent?.id ? Number(hoursCurrData.regular) - Number(costEventHours.regular) : Number(hoursCurrData.regular),
          ovtHours: selectedCostEvent?.id ? Number(hoursCurrData.overTime) - Number(costEventHours.overTime) : Number(hoursCurrData.overTime),
          othHours: selectedCostEvent?.id ? Number(hoursCurrData.doubleTime) - Number(costEventHours.doubleTime) : Number(hoursCurrData.doubleTime),
          ceId: 0,
          timeCardId: timecardId,
          timeCardCrewId: employee.id,
          timeCardBudgetCodeId:
            costCode.labourTimeCardBudgetCodeId ||
            costCode.equipmentTimeCardBudgetCodeId,
          projectId:
            costCode.projectId ||
            selectedProjectsSupervisorsShift?.project[0]?.id,
          timeCardDate: timeCardDate,
          weekEndDateKey: weekEndDate,
          weekEndDateId: 0,
          timeCardStatus: 0,
          crewName: employee.crewName,
          employeeId: employee.employeeId,
          budgetCodeProjectId:
            costCode.projectId ||
            selectedProjectsSupervisorsShift?.project[0]?.id,
          budgetCodeKey:
            costCode.labourBudgetCodeKey || costCode.equipmentBudgetCodeKey,
          budgetCodeId:
            costCode.labourBudgetCodeId || costCode.equipmentBudgetCodeId,
          projectLocationId: costCode.projectLocationId,
          costTypeClassKey: costCode.costCodeClassKey,
          // serviceTicketId: costCode.serviceTicketId,
          costCodeId: costCode.id,
          qtyClaimedBy: costCode.qtyClaimedBy,
          qtyDefaultNote: costCode.qtyDefaultNote,
          ceBudgetCodeId: 0,
          deleted: false,
          hasTags: hasTags,
          ceBudgetCodeLabel: "",
          ceCategoryId: 0,
          ceLabel: "",
          costEventId: 0
        }
        hours.push(payload);
        if (selectedCostEvent?.id) {
          const cePayload = {
            ...payload
          }
          cePayload.hourCategoryKey = selectedCostEventCategory.ceCategoryName
          cePayload.ceId = selectedCostEvent?.id
          cePayload.regHours = Number(costEventHours.regular)
          cePayload.ovtHours = Number(costEventHours.overTime)
          cePayload.othHours = Number(costEventHours.doubleTime)
          cePayload.ceCategoryId = selectedCostEventCategory.ceCategoryId
          cePayload.ceLabel = selectedCostEvent._ceLabel || ""
          cePayload.costEventId = selectedCostEvent?.id
          if (selectedBudgetCostCode?.budgetCodeId) {
            cePayload.ceBudgetCodeLabel = selectedBudgetCostCode?._ceBudgetCodeLabel || ""
            cePayload.budgetCodeId = selectedBudgetCostCode.budgetCodeId
            cePayload.budgetCodeKey = selectedBudgetCostCode._budgetCodeKey
            cePayload.budgetCodeProjectId = selectedBudgetCostCode.budgetCodeProjectId
            cePayload.ceBudgetCodeId = costCode.labourBudgetCodeId
          }
          hours.push(cePayload)
        }
      }
    );
    return hours;
  };

  // filter new added hours
  const filterNewlyAddedHours = async (data: any, hourList: any) => {
    let newHourIds = [] as any;
    if (
      assignTagToHourPayload.length &&
      data.data.hourList &&
      data.data.hourList.length &&
      data.data.hourList.length !== hourList.length
    ) {
      if (hourList.length === 0) {
        newHourIds = data.data.hourList.map((e: any) => e.id);
      } else {
        data.data.hourList.forEach((element: any, index: number) => {
          const elementIndex = hourList.findIndex(
            (e: any) => e.id === element.id
          );
          if (elementIndex === -1) {
            newHourIds.push(element.id);
          }
        });
      }
    } else {
      setSelectedHourCells({});
    }
    return newHourIds;
  };


  /* prepare payload  */
  const timeCardCostCodePayload = (assignedCode: any, customSortOrder = true) => {
    try {
      let createdTimecardId: number;
      let sortOrder = -1;
      const timeCardCode = [] as any;
      const createTimecardCodePayload = (eachCode: any, index = 0) => {
        sortOrder++;
        return {
          id:
            eachCode.labourTimeCardBudgetCodeId ||
            eachCode.equipmentTimeCardBudgetCodeId ||
            0,
          tenantId: tokenDetails?.tenantId,
          timeCardId: timecardId || createdTimecardId,
          budgetCodeId:
            eachCode.labourBudgetCodeId || eachCode.equipmentBudgetCodeId,
          projectLocationId: eachCode.projectLocationId || 0,
          budgetCodeKey:
            eachCode.labourBudgetCodeKey || eachCode.equipmentBudgetCodeKey,
          timeCardDate: timeCardDate,
          budgetCodeProjectId: eachCode.projectId,
          serviceTicketId: eachCode.serviceTicketId,
          workOrderCostEventId: 0,
          sortOrder: customSortOrder ? sortOrder : eachCode.sortOrder,
        };
      };
      if (assignedCode && assignedCode.length) {
        assignedCode.forEach((eachCode: any) => {
          if (eachCode.count === 1 || !eachCode.count) {
            const costCodeObj = createTimecardCodePayload(eachCode);
            preparePayloadLabourAndEquipment(eachCode, costCodeObj, timeCardCode);
          } else {
            let index = 0;
            while (index < eachCode.count) {
              const costCodeObj = createTimecardCodePayload(eachCode, index);
              preparePayloadLabourAndEquipment(eachCode, costCodeObj, timeCardCode);
              index++;
            }
          }
        });
      }
      return timeCardCode;
    } catch (error) {
      console.log(error);
    }
  };

  // prepare crew payload
  const createTimecardPayload = (
    eachCrew: any,
    index = 0,
    sortOrder: number,
    createdTimecardId: number
  ) => {
    return {
      id: !eachCrew.count ? eachCrew.id : (eachCrew.ids && eachCrew?.ids[index] ? eachCrew?.ids[index] : 0),
      tenantId: tokenDetails?.tenantId,
      timeCardId: Number(timecardId) || createdTimecardId,
      employeeId: eachCrew.employeeId || 0,
      regRate: eachCrew.regularRate,
      ovtRate: eachCrew.overTimeRate,
      crewStatus: 0,
      othRate: eachCrew.doubleTimeRate,
      crewClassDisplayName: eachCrew.classDisplayName,
      crewUnionTradeClass: eachCrew.unionTradeClass,
      crewUnionTradeClassId: eachCrew.unionTradeClassId,
      equipmentAssetProjectId: eachCrew.equipmentAssetProjectId || 0,
      sortOrder,
    };
  };

  const addTempLoabor = () => {
    toggleTempLabour();
    addtemplabourFunc({ ...addTempLabFormFields });
  };


  const setTagsDefaultValues = () => {
    // set difault values
    setAlreadyAssignedTagsFromHour([]);

    let availableTags = [] as any;
    availableTags = availableHourTags.map((tag: any) => {
      tag.isChecked = false;
      return tag;
    });
    setAvailableHourTags(availableTags);
  };

  const toggleCESidePanel = () => setCESidePanelOpen(!isCESidePanelOpen)

  const resetSidePanel = () => {
    toggleRightPanel({
      ...rightPanelOptions,
      isHoursOpened: false,
    });
    setHoursClicked("regular");
    setAssignTagToHourPayload([]);
    setRemovedTagsFromHour([]);
    setSelectedHourCells({});
    setCESidePanelOpen(false)
    setCurrentComponentLayer(0)
    setSelectedCostEvent({})
    setSelectedCostEventCategory(undefined)
    setShowCostEventDetails(false)
    setCostEventHours({ regular: 0, overTime: 0, doubleTime: 0 })
    setCostEventAlert("")
    // setCurrHours(hoursPreData);
  }


  // REACT HOOKS, LIFECYCLE METHODS

  // @ts-ignore

  useEffect(() => {
    if (
      (props.match.params.timecardId &&
        timecardId !== props.match.params.timecardId) ||
      !props.match.params.timecardId
    ) {
      setTimecardId(props.match.params.timecardId || 0);
    }
  }, [props.match.params.timecardId]); // eslint-disable-line react-hooks/exhaustive-deps

  // @ts-ignore
  useEffect(() => {
    getTimecardById();
    getAvailableHourTags();
  }, [timecardId]); // eslint-disable-line react-hooks/exhaustive-deps


  // Get Assigned code for timecard
  useEffect(() => {
    getTempAgeList();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    getAvailableCrew();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedProjectsSupervisorsShift?.project[0]?.id]);

  useEffect(() => {
    if (orgEntityId) {
      getFilterData();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [orgEntityId]);

  // Clearing the tags array when the modal is closed
  useEffect(() => {
    if (!crewInfoModal) {
      setCrewTagsData([]);
    }
  }, [crewInfoModal]);

  useEffect(() => {
    if (timeCardData.timeCardDate) {
      setTimeCardDate(timeCardData.timeCardDate)
      setSelectedProjectsSupervisorsShift({
        ...selectedProjectsSupervisorsShift,
        supervisor: [projectsAndSupervisors.supervisors.find((x: any) => x.id === timeCardData.timeCardSupervisorId)]
      })
      if (timeCardData.projectId) {
        getCostEventCategories()
        getCostEvent()
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [timeCardData])

  useEffect(() => {
    if (selectedProjectId) {
      let allTagsToShow = []
      if (!selectedCostEventCategory) {
        allTagsToShow = [
          ...availableHourTags,
          ...(costEventCategories[selectedProjectId] ? costEventCategories[selectedProjectId] : [])
        ]
      } else {
        allTagsToShow = [
          ...availableHourTags
        ]
      }
      selectAllTags(allTagsToShow)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedProjectId, selectedCostEventCategory])

  useEffect(() => {
    if (selectedCostEvent?._budgetCodes?.length && !selectedBudgetCostCode?.budgetCodeId) {
      setSelectedBudgetCostCode(selectedCostEvent._budgetCodes[0])
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedCostEvent])

  const timecardStatusColorData = useMemo(() => {
    const iconMap = {
      Open: "fa fa-pencil",
      Approved: "fa fa-check",
      Rejected: "fa fa-times",
      Submitted: "fa fa-paper-plane",
    };
    type iconMapType = keyof typeof iconMap;
    if (
      filterDropData &&
      filterDropData.timecardStatuses &&
      timeCardData.timeCardStatus
    ) {
      const status: { id: any; name: iconMapType } =
        filterDropData.timecardStatuses.find(
          (eachStatus: { id: any }) =>
            eachStatus.id === timeCardData.timeCardStatus
        );
      return {
        headingClassName: `item-${status.name.toLowerCase()}`,
        statusClassName: `status-${status.name.toLowerCase()}`,
        status: status.name,
        icon: iconMap[status.name],
      };
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [timeCardData, filterDropData]);


  const handlePayclassCellClick = (e: any) => {
    const isAlreadySelected = selectedPayclassCells[e.id];
    if (!isAlreadySelected) {
      if (
        !(
          isRightPanelOpened.isAllowanceOpened ||
          isRightPanelOpened.isHoursOpened
        )
      ) {
        // add new hour cell to selected
        const selectedCells = {
          ...selectedPayclassCells,
          [e.id]: {
            employee: e
          },
        };
        setSelectedPayclassCells(selectedCells);
        setSelectedEmployee(e);
        // toggle
        toggleRightPanel({
          ...rightPanelOptions,
          isPayClassOpened: true,
        });
      }
    } else {
      // remove added hour cell
      const selectedPayclass = selectedPayclassCells;
      delete selectedPayclass[e.id];
      setSelectedHourCells({
        ...selectedPayclass,
      });
      // check of object is empty
      if (isObjEmpty(selectedPayclass)) {
        toggleRightPanel({
          ...rightPanelOptions,
          isPayClassOpened: false,
        });
        setHoursClicked("regular");
      }
    }
  }

  const handleAllowanceCellClick = (e: any) => {
    const isAlreadySelected = selectedAllowanceCells[e.id];
    if (!isAlreadySelected) {
      if (
        !(
          isRightPanelOpened.isPayClassOpened ||
          isRightPanelOpened.isHoursOpened
        )
      ) {
        // add new hour cell to selected
        const selectedCells = {
          ...selectedAllowanceCells,
          [e.id]: {
            employee: e
          },
        };
        setSelectedAllowanceCells(selectedCells);
        setSelectedEmployee(e);
        // toggle
        toggleRightPanel({
          ...rightPanelOptions,
          isAllowanceOpened: true,
        });
      }
    } else {
      // remove added hour cell
      const selectedAllowance = selectedAllowanceCells;
      delete selectedAllowance[e.id];
      setSelectedAllowanceCells({
        ...selectedAllowance,
      });
      // check of object is empty
      if (isObjEmpty(selectedAllowance)) {
        toggleRightPanel({
          ...rightPanelOptions,
          isAllowanceOpened: false,
        });
        setHoursClicked("regular");
      }
    }
  }

  const handleAssignedFields = async (dragIndex: number, hoverIndex: number) => {
    try {
      let updatedOrderData = [] as any;
      if (timeCardData.costCodeList && timeCardData.costCodeList.length) {
        updatedOrderData = [...timeCardData.costCodeList];
        updatedOrderData.splice(dragIndex, 1);
        updatedOrderData.splice(hoverIndex, 0, timeCardData.costCodeList[dragIndex]);
        if (updatedOrderData[hoverIndex - 1] && updatedOrderData[hoverIndex + 1] &&
          updatedOrderData[hoverIndex - 1].serviceTicketId === updatedOrderData[hoverIndex + 1].serviceTicketId &&
          updatedOrderData[hoverIndex + 1].serviceTicketId !== updatedOrderData[hoverIndex].serviceTicketId) {
          return
        }
      }
      // @ts-ignore
      const url = `/v1/api/time-card/helper/crew-and-cost-codes`;
      const payload: ISaveCrewPayload = {
        projectId: selectedProjectsSupervisorsShift.project[0].id,
        timeCardId: Number(timecardId),
        timeCardCrew: timeCardCrewPayload(timeCardData.crewList) || [],
        timeCardCostCodes: timeCardCostCodePayload(updatedOrderData, true),
      };
      await put(url, payload);
      getTimecardById();
      getAssignedCode();
    } catch (error) {
      console.log(error)
    }
  };

  const handleCrewFields = async (dragIndex: number, hoverIndex: number) => {
    try {
      let updatedOrderData = [] as any;
      if (timeCardData.crewList && timeCardData.crewList.length) {
        updatedOrderData = [...timeCardData.crewList];
        const rowSpan = updatedOrderData[dragIndex].rowSpan;
        const moveRow = updatedOrderData.splice(dragIndex, rowSpan);
        updatedOrderData.splice(hoverIndex, 0, ...moveRow);
      }
      // @ts-ignore
      const url = `/v1/api/time-card/helper/crew-and-cost-codes`;
      const payload: ISaveCrewPayload = {
        projectId: selectedProjectsSupervisorsShift.project[0].id,
        timeCardId: Number(timecardId),
        timeCardCrew: timeCardCrewPayload(updatedOrderData, true) || [],
        timeCardCostCodes: timeCardCostCodePayload(timeCardData.costCodeList),
      };

      await put(url, payload);
      getTimecardById();
    } catch (error) {
      console.log(error)
    }
  };

  const handleGroupedServiceTicketFields = async (dragIndex: number, hoverIndex: number) => {
    try {
      const updatedSTOrder = arrayMove(timeCardData.serviceTicketTimecardOrder, dragIndex, hoverIndex);
      const updateOrderForIds = [] as any;
      // check ST timecard order for each service ticket
      updatedSTOrder.forEach((e: any, index: number) => {
        if(e[1] !== index) {
          updateOrderForIds.push({serviceTicketId: Number(e[0]), serviceTicketTimecardOrder: index});
        }
      })
      if(updateOrderForIds.length) {
        setTimeCardLoader(true);
        updateOrderForIds.forEach(async (e: any, index: number) => {
          const serviceTicketId = e.serviceTicketId;
          const { data } = await getServiceTicketApi(serviceTicketId) as any;
          const url = `/v1/api/service/ticket/id/${serviceTicketId}`;
          const serviceTicketDetail = data;
          // creating payload for create/update
          const payload = {
            id: serviceTicketDetail.id,
            tenantId: serviceTicketDetail?.tenantId || tokenDetails?.tenantId,
            serviceContractId: serviceTicketDetail.serviceContractId || 0,
            leadEmployeeId: serviceTicketDetail.leadEmployeeId,
            serviceTicketName: serviceTicketDetail.serviceTicketName || 'service-ticket-name',
            workOrderNumber: serviceTicketDetail.workOrderNumber || "abc-123",
            poNumber: serviceTicketDetail.poNumber,
            targetHours: serviceTicketDetail.targetHours,
            ticketStatus: serviceTicketDetail.ticketStatus,
            serviceTicketTimecardOrder: e.serviceTicketTimecardOrder,
            stNotes: serviceTicketDetail.stNotes || "",
            additionalInfo: serviceTicketDetail.additionalInfo || "",
            values_Headers_Custom_ServiceTicket: serviceTicketDetail.values_Headers_Custom_ServiceTicket?.map((x: any) => {
              return {
                id: x.id,
                serviceContractFieldId: x.serviceContractFieldId,
                fieldValue: x.fieldValue
              }
            })
          };
          await put(url, payload);
          if(index === updateOrderForIds.length - 1) {
            getTimecardById();
          }
        })
      }
    } catch (error) {
      console.log(error)
    }
  };

  // use this fun when open service ticket from timecard
  const openServiceTickets = (serviceId : any) => {
    const ids = Object.keys(timeCardData.costCodeListGrouped);
    let activeIndex = 0;
    // find selected service ticket index 
    if(serviceId) {
      activeIndex = ids.findIndex(e => e === serviceId) || 0;
    }
    setSelectedServiceTicketsDetails({
      serviceTicketsId : ids,
      timeCardStatus : timeCardData.timeCardStatus,
      activeIndex,
    });
    history.push(`/timecardServiceTicketDetails/${timecardId}`);
  }

  const divRefs = useRef<(HTMLDivElement | null)[][]>([])

  const scrollToSelectedDiv = (currRow : number, currCol : number) => {
    if(divRefs.current) {
      divRefs.current[currRow][currCol]?.scrollIntoView({ behavior: "smooth", block: "nearest" })
    }
  }

  const renderCostCodeTh = (field: any, index: number, attributes: object, children: any) => {
    return (
      <DragDropTableHeader
        key={index}
        field={field}
        index={index}
        accept={ItemTypes.TICKET}
        handleAssignedFields={handleAssignedFields}
        attributes={attributes}
        children={children}
      />
    );
  };

  const renderGroupedServiceTicketTh = (field: any, index: number, attributes: object, children: any) => {
    return (
      <DragDropTableHeader
        key={index}
        field={field}
        index={index}
        accept={ItemTypes.GROUPED_SERVICE_TICKET}
        handleAssignedFields={handleGroupedServiceTicketFields}
        attributes={attributes}
        children={children}
      />
    );
  };

  const renderCrewTd = (field: any, index: number, attributes: object, children: any) => {
    return (
      <DragDropTableHeader
        key={index}
        field={field}
        index={index}
        accept={ItemTypes.CREW}
        handleAssignedFields={handleCrewFields}
        attributes={attributes}
        children={children}
        tableSection="td"
      />
    );
  };



  // const diableSaveBtn = crewTagsData.length > 0
  return (
    <div className="timecard-list-wrapper">
      {timeCardLoader || hourLoader ? (
        <Loader showComponentLoader={true} />
      ) : null}
      <ToastContainer
        position="top-right"
        autoClose={5000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
      />
      {alert && alert.isOpen ? (
        <SweetAlert
          title=''
          type={alert.colour}
          confirmBtnBsStyle={alert.colour}
          onConfirm={() => setAlert({ ...alert, isOpen: false })}
        >
          {alert.message}
        </SweetAlert>
      ) : null}
      <div className="timecard-list-header-container">
        <div className="timecard-header-date">
          <Header
            showSelectSection={true}
            breadcrumb={"Time Card"}
            title={"Time Card"}
            selectedTimecardDetails={{
              projectId: timeCardData.projectId,
              supervisorId: timeCardData.supervisorEmployeeId,
              shiftId: timeCardData.shift,
            }}
          />
          <div className="timecard-list-date">
            <div className="timecard-list-date-heading">
              <div className="timecard-list-date-calendar">
                <h2
                  className={`page-title-sub ${timecardStatusColorData?.headingClassName}`}
                  onClick={() => {
                      history.push(`/timecard`);
                    }
                  }
                >
                  <i
                    style={{
                      fontSize: "15px",
                      marginRight: "10px",
                      marginLeft: "5px",
                    }}
                    className={timecardStatusColorData?.icon}
                    aria-hidden="true"
                  ></i>
                  {moment(timeCardDate.toString()).format("MMMM Do, YYYY")}
                  {/* eslint-disable-next-line */}
                  <a
                    className="timecard-list-calendar"
                    //  eslint-disable-next-line
                    href="javascript:void(0)"
                  >
                    <Calendar />{" "}
                  </a>
                </h2>
              </div>
              {timecardStatusColorData?.status ? (
                <div
                  className={`contract-status ${timecardStatusColorData?.statusClassName}`}
                >
                  {timecardStatusColorData?.status}
                </div>
              ) : null}
            </div>
          </div>
        </div>
        <div className="timecard-list-action">
          {!(
            timeCardData &&
            timeCardData.crewList &&
            timeCardData.crewList.length > 0
          ) ? (
            <button className="btn btn-primary mr-3" onClick={toggleClone}>
              <span className="icon-clone mr-2"></span>
              Clone
            </button>
          ) : null}
          {timecardId ? (
            <button
              className="btn btn-primary mr-3"
              onClick={(e) => {
                openServiceTickets(0);
              }}
            >
              Submit
            </button>
          ) : null}
          {timeCardData &&
            timeCardData.crewList &&
            timeCardData.crewList.length > 0 &&
            timeCardData.timeCardStatus === 1 
            ? (<></>) 
            : timeCardData.timeCardStatus === 2 ? (
            // show approve and reject for submitted status 
            <>
              <button
                className="btn btn-primary mr-3"
                onClick={() => {
                  setmodalActive(true);
                  setmodalData(modalDataArray[1]);
                }}
              >
                Approve
              </button>
              <button
                className="btn btn-primary mr-3"
                onClick={() => {
                  setmodalActive(true);
                  setmodalData(modalDataArray[2]);
                }}
              >
                Reject
              </button>
            </>
          ) : timeCardData.timeCardStatus === 3 ||
            timeCardData.timeCardStatus === 4 ? (
            // show reopen if rejected or approved 
            <button
              className="btn btn-primary mr-3"
              onClick={() => {
                setmodalActive(true);
                setmodalData(modalDataArray[4]);
              }}
            >
              Reopen
            </button>
          ) : null}
          {/* show delete for open and rejected status  */}
          {timecardId && (timeCardData.timeCardStatus === 1 || timeCardData.timeCardStatus === 3)? (
            <button
              className="btn btn-primary mr-3"
              onClick={() => {
                setmodalActive(true);
                setmodalData(modalDataArray[0]);
              }}
            >
              <span className="icon-trash mr-2"></span>
              Delete
            </button>
          ) : null}

          {timecardId ? (
            <button
              className="btn btn-primary"
              onClick={() => {
                history.push(`/timecardReview/${timecardId}`);
              }}
            >
              Review
            </button>
          ) : null}
        </div>
      </div>
      <div
        className={
          "timecard-table " +
          (isRightPanelOpened.isAllowanceOpened ||
            isRightPanelOpened.isPayClassOpened ||
            isRightPanelOpened.isHoursOpened
            ? "is-filling"
            : "")
        }
      >
        <button
          className="arrow-wrapper left-arrow"
          type="button"
          onClick={(e) => {
            const selectedTimeCardDate = Number(
              moment(timeCardDate.toString())
                .subtract(1, "days")
                .format("YYYYMMDD")
            );
            // search timecard
            const eventIndex = timeCardEventsData.findIndex(
              (e: any) => e.timeCardDate === selectedTimeCardDate
            );
            const endDate = Number(
              moment(selectedTimeCardDate.toString())
                .endOf("month")
                .format("YYYYMMDD")
            );
            if (endDate === selectedTimeCardDate) {
              getTimeCardData(selectedTimeCardDate.toString());
            }
            if (eventIndex > -1) {
              const timeCard = timeCardEventsData[eventIndex] as any;
              setTimeCardDate(timeCard.timeCardDate);
              history.push(`/timecardList/${timeCard.id}`);
            } else {
              // if timecard not exist
              setTimecardId(0);
              setTimeCardData(timeCardDefaultValue);
              setAssignedCode([]);
              setTimeCardDate(selectedTimeCardDate);
              // reset project value
              setSelectedCostCodeProject([]);
              setServiceTicketsList([]);
              history.push(`/timecardList`);
            }
          }}
        >
          {" "}
        </button>
        <button
          className="arrow-wrapper right-arrow"
          type="button"
          onClick={(e) => {
            const selectedTimeCardDate = Number(
              moment(timeCardDate.toString()).add(1, "days").format("YYYYMMDD")
            );
            // search timecard
            const eventIndex = timeCardEventsData.findIndex(
              (e: any) => e.timeCardDate === selectedTimeCardDate
            );
            const startDate = Number(
              moment(selectedTimeCardDate.toString())
                .startOf("month")
                .format("YYYYMMDD")
            );
            if (startDate === selectedTimeCardDate) {
              getTimeCardData(selectedTimeCardDate.toString());
            }
            if (eventIndex > -1) {
              const timeCard = timeCardEventsData[eventIndex] as any;
              setTimeCardDate(timeCard.timeCardDate);
              history.push(`/timecardList/${timeCard.id}`);
            } else {
              setTimecardId(0);
              setTimeCardData(timeCardDefaultValue);
              setAssignedCode([]);
              setTimeCardDate(selectedTimeCardDate);
              // reset project value
              setSelectedCostCodeProject([]);
              setServiceTicketsList([]);
              history.push(`/timecardList`);
            }
          }}
        >
          {" "}
        </button>
        <div className="r-table-wrapper">
          <table
            className={
              "table" +
              (timeCardData.crewList && timeCardData.crewList.length === 0
                ? " table-body-empty"
                : "") +
              (timeCardData.costCodeList &&
                timeCardData.costCodeList.length !== 0
                ? " table-costcode-filled"
                : "")
            }
          >
            <thead>
              <tr>
                <th rowSpan={2} className="crew-col">
                  <div className="crew">
                    <div className="col-heading d-flex align-items-center justify-content-between">
                      <span>Crew</span>
                      <div className="add-templabour">
                        {/* eslint-disable-next-line */}
                        <a
                          //  eslint-disable-next-line
                          href="javascript:void(0)"
                          onClick={() => {
                            if(timecardStatusColorData?.status.toLowerCase() === 'approved') {
                              toast.info("Can't add any more crew to an approved timecard.", {
                                autoClose: 2000,
                              });
                            } else {
                              toggleTempLabour();
                            }
                          }}
                          className="temp-labour"
                        >
                          <TempLabour />
                          Add Temp Labour
                        </a>
                      </div>
                    </div>
                    <div className="crew-search">
                      <div className={`has-typehead`}>
                        <AsyncTypeahead
                          id="quickAdd"
                          // @ts-ignore
                          labelKey={(option: any) => `${option.crewName}`}
                          ref={addPeopleEquipment}
                          onChange={(e: any) => {
                            // reset input value
                            if(e && e.length && addPeopleEquipment && addPeopleEquipment.current) {
                              // @ts-ignore: Object is possibly 'null'.
                              setTimeout(() => addPeopleEquipment.current.clear(), 0);
                            }
                            // add crew
                            if(timecardStatusColorData?.status.toLowerCase() === 'approved') {
                              toast.info("Can't add any more crew to an approved timecard.", {
                                autoClose: 2000,
                              });
                            } else {
                              let id;
                              let type;
                              if( e && e.length && e[0].equipmentAssetProjectId) {
                                id = e && e[0] && e[0].equipmentId;
                                type = 'equipmentId'
                              } else {
                                id = e && e.length && e[0].employeeId;
                                type = 'employeeId'
                              }
                              addCrew(id, type);
                            }
                          }}
                          isLoading={loadingCrew}
                          onSearch={getAvailableCrew}
                          filterBy={['crewName','crewKey']}
                          minLength={0}
                          placeholder="Add People or Equipment"
                          multiple={false}
                          options={availableCrew}
                        />
                        <i className="crew-search-icon"></i>
                        {/* eslint-disable-next-line */}
                        {/* <a
                          // eslint-disable-next-line
                          href="javascript:void(0)"
                          className="crew-search-close"
                        >
                          <Close />
                        </a> */}
                      </div>
                    </div>
                  </div>
                  {userAccess &&
                    userAccess.allowTimecardAllowance !== 1 &&
                    timeCardData.costCodeList.length !== 0 ? (
                    // eslint-disable-next-line
                    <a
                      // eslint-disable-next-line
                      href="javascript:void(0);"
                      className="add-ticket-circle"
                      onClick={() => {
                        if(timecardStatusColorData?.status.toLowerCase() === 'approved') {
                          toast.info("Can't add any more cost codes to an approved timecard.", {
                            autoClose: 2000,
                          });
                        } else {
                          toggleAddCode();
                        }
                      }}
                    >
                      <AddTicket />
                    </a>
                  ) : (
                    ""
                  )}
                </th>

                {userAccess && userAccess.allowTimecardEditPayclass === 1 ? (
                  <th rowSpan={2} className="payclass-col">
                    <div className="col-heading">Pay Class</div>
                  </th>
                ) : (
                  ""
                )}

                {userAccess && userAccess.allowTimecardAllowance === 1 ? (
                  <th rowSpan={2} className="allowances-col">
                    <div className="col-heading text-center">
                      Allowances ($)
                    </div>
                    {timeCardData.costCodeList.length !== 0 ? (
                      // eslint-disable-next-line
                      <a
                        // eslint-disable-next-line
                        href="javascript:void(0);"
                        className="add-ticket-circle"
                        onClick={() => {
                        if(timecardStatusColorData?.status.toLowerCase() === 'approved') {
                          toast.info("Can't add any more cost codes to an approved timecard.", {
                            autoClose: 2000,
                          });
                        } else {
                          toggleAddCode();
                        }
                      }}
                      >
                        <AddTicket />
                      </a>
                    ) : (
                      ""
                    )}
                  </th>
                ) : (
                  ""
                )}

                {/* Empty Timecard table heading */}
                {timeCardData.costCodeList.length === 0 ? (
                  <th rowSpan={2} className="empty-timecard text-center">
                    {/* eslint-disable-next-line  */}
                    <a
                      // eslint-disable-next-line
                      href="javascript:void(0);"
                      className="add-service-ticket"
                      onClick={toggleAddCode}
                    >
                      <AddPlus />
                      <span className="ml-2">
                        Click here to Select/Add the Service Tickets{" "}
                      </span>
                    </a>
                  </th>
                ) : (
                  <DndProvider backend={isMobile ? TouchBackend : HTML5Backend}>
                    {timeCardData.serviceTicketTimecardOrder.map(
                      (stOrder: any, index: any) => {
                        const key = stOrder[0];
                        const ticketCodes = timeCardData.costCodeListGrouped[key];
                        const serviceTicketDetail = ticketCodes[0];
                        return renderGroupedServiceTicketTh(serviceTicketDetail, index, {
                          colSpan: ticketCodes.length,
                          className: "service-ticket-holder",
                          onClick: () => {}
                        },
                          <div className="service-ticket-name" 
                            onClick={() => {
                              openServiceTickets(key);
                            }}>
                            {serviceTicketDetail.serviceTicketId === 0 && 'None'} {serviceTicketDetail.serviceTicketDisplayNumber ? formatServiceTicketDisplayNumber(serviceTicketDetail) : ''}
                            {serviceTicketDetail.serviceTicketId !== 0 ? 
                              // eslint-disable-next-line 
                              <a
                                // eslint-disable-next-line
                                href="javascript:void(0)"
                                className="service-ticket-trash pl-1"
                                onClick={(e : any) => {
                                  e.stopPropagation();
                                  setmodalActive(true);
                                  setDeleteServiceTicketId(serviceTicketDetail.serviceTicketId);
                                  setmodalData(modalDataArray[8]);
                                }}
                              >
                                <Trash />
                              </a>
                            : null}
                          </div>
                        )

                      }
                    )}
                  </DndProvider>
                )}
              </tr>
              {/* heading second row for cost code */}
              <tr>
                {timeCardData.costCodeList.length !== 0
                  ?
                  <DndProvider backend={isMobile ? TouchBackend : HTML5Backend}>
                    {timeCardData.costCodeList.map((e: any, index: any) => {
                      return (
                        renderCostCodeTh(e, index, { className: "service-ticket-holder" },
                          <div className="service-ticket-jobcode">
                            <span className="col-heading text-center">
                              {e.costCodeKey} - {e.costCodeDescription}
                            </span>
                            {!(timecardStatusColorData?.status.toLowerCase() === 'approved') ? (
                              // eslint-disable-next-line 
                              <a
                                // eslint-disable-next-line
                                href="javascript:void(0)"
                                className="service-ticket-trash"
                                onClick={() => {
                                  setmodalActive(true);
                                  setDeleteIndex(index);
                                  const laborHrs = timeCardData.hourList.filter((x: any) => x.timeCardBudgetCodeId === e.labourTimeCardBudgetCodeId);
                                  const equipHrs = timeCardData.hourList.filter((x: any) => x.timeCardBudgetCodeId === e.equipmentTimeCardBudgetCodeId);
                                  if(laborHrs.length === 0 &&equipHrs.length === 0){
                                    setmodalData(modalDataArray[5]);
                                  } else {
                                    setmodalData(modalDataArray[7]);
                                  }
                                }}
                              >
                                <Trash />
                              </a>
                            ) : null}
                          </div>
                        )
                      );
                    })}
                  </DndProvider>
                  : ""}
              </tr>
            </thead>
            <tbody>
              {/* Empty Timecard table body */}
              {timeCardData &&
                timeCardData.crewList &&
                timeCardData.crewList.length > 0 ? (
                <>
                  {timeCardData.crewList.map((e: any, index: number) => {
                    const empSelectedPayClass = timeCardData.htmlCrewCells
                      .length
                      ? timeCardData.htmlCrewCells[index].payClassCell.replace(
                        htmlRegexG,
                        ""
                      )
                      : "";
                    const empSelectedAllowance = timeCardData.htmlCrewCells
                      .length
                      ? timeCardData.htmlCrewCells[index].allowanceCell.replace(
                        htmlRegexG,
                        ""
                      )
                      : "";
                    const hourListOfCrew = timeCardData.hourList.filter(
                      (hour: any) => hour.timeCardCrewId === e.id
                    );
                    const hourListOfCrewAndBudget = [] as any;
                    timeCardData.costCodeList.forEach((e: any) => {
                      const res = hourListOfCrew.filter(
                        (element: any) =>
                          e.labourTimeCardBudgetCodeId ===
                          element.timeCardBudgetCodeId ||
                          e.equipmentTimeCardBudgetCodeId ===
                          element.timeCardBudgetCodeId
                      );
                      if (res && res.length) {
                        let cellValue: { ceDetails: any; sumValues: any } = {
                          ceDetails: null,
                          sumValues: {
                            regular: 0,
                            overTime: 0,
                            doubleTime: 0
                          }
                        }
                        res.forEach((eachRes: typeof res) => {
                          if (eachRes.ceId) {
                            cellValue = {
                              ...cellValue,
                              ceDetails: {
                                ...eachRes
                              }
                            }
                          } else {
                            cellValue = {
                              ...cellValue,
                              ...eachRes
                            }
                          }
                          cellValue.sumValues = {
                            regular: cellValue.sumValues.regular + eachRes.regHours,
                            overTime: cellValue.sumValues.overTime + eachRes.ovtHours,
                            doubleTime: cellValue.sumValues.doubleTime + eachRes.othHours
                          }
                        })
                        hourListOfCrewAndBudget.push(cellValue);
                      } else {
                        hourListOfCrewAndBudget.push(undefined);
                      }
                    });
                    const currRow = index;
                    return (
                      <tr key={index + " " + e.crewName} 
                        >
                          <React.Fragment>
                            {/* handle single and multirow crew */}
                            {e.rowSpan === 1 ||
                              (e.rowSpan > 1 && e.ids.indexOf(e.id) === 0) ? (
                                <DndProvider backend={isMobile ? TouchBackend : HTML5Backend}>
                                {renderCrewTd(e, index, {className: "crew-holder", rowSpan: e.rowSpan}, 
                                  <div className="crew-info">
                                  {/* eslint-disable-next-line */}
                                  <a
                                    //  eslint-disable-next-line
                                    href="javascript:void(0)"
                                    className="crew-employee"
                                    onClick={() => {
                                      toggleCrewInfo(e);
                                    }}
                                  >
                                    <img
                                      src={e.crewPic}
                                      width="40px"
                                      height="40px"
                                      alt=""
                                      style={{ borderRadius: "50%" }}
                                    />
                                  </a>
                                  <div className="crew-logs log-info">
                                    {/* eslint-disable-next-line */}
                                    <a
                                      //  eslint-disable-next-line
                                      href="javascript:void(0)"
                                      className="crew-name"
                                    >
                                      {e.crewName}
                                    </a>
                                    <span className="daily-log">
                                      DY: {e.dayRegHours.toFixed(2)}/{e.dayOvtHours.toFixed(2)}/
                                      {e.dayOthHours.toFixed(2)}
                                    </span>
                                    {/* <span className="daily-log has-logshare">DY: 007/16/12</span> */}
                                    <span className="weekly-log">
                                      WK: {e.wkRegHours.toFixed(2)}/{e.wkOvtHours.toFixed(2)}/
                                      {e.wkOthHours.toFixed(2)}
                                    </span>
                                    {/* crew tags in crew column */}
                                    <ul className="log-tags-list">
                                      {e._tags && e._tags.length > 0
                                        ? e._tags.map((tag: any) => {
                                          return (
                                            <li
                                              className="log-tag"
                                              key={
                                                e.id + " " + tag._timeCardTagCode
                                              }
                                            >
                                              <i
                                                className="fa fa-tag"
                                                style={{
                                                  paddingRight: "5px",
                                                  color: tag._timeCardTagColor,
                                                }}
                                                aria-hidden="true"
                                              ></i>
                                              {tag._timeCardTagCode}
                                            </li>
                                          );
                                        })
                                        : ""}
                                    </ul>
                                  </div>
                                  {!(timecardStatusColorData?.status.toLowerCase() === 'approved') ? (
                                    // eslint-disable-next-line 
                                    <a
                                      //  eslint-disable-next-line
                                      href="javascript:void(0)"
                                      className="crew-trash"
                                    >
                                      <Trash
                                        onClick={() => {
                                          setmodalActive(true);
                                          setDeleteIndex(e.employeeId);
                                          setmodalData(modalDataArray[6]);
                                        }}
                                      />
                                    </a>
                                  ) : null}
                                </div>)}
                              </DndProvider>
                              
                            ) : (
                              ""
                            )}

                            {/* payclass cell  */}
                            {userAccess &&
                              userAccess.allowTimecardEditPayclass === 1 ? (
                              empSelectedPayClass ? (
                                <td
                                  className={`payclass-td 
                                    ${selectedPayclassCells[e.id] ? "hours-cell" : ""} 
                                    ${e.isEquipment  ? "is-disabled" : ""}
                                    `}
                                  onClick={() => {
                                    // open payclass if row is not equipment type 
                                    if(!e.isEquipment) {
                                      handlePayclassCellClick(e)
                                    }
                                  }}
                                >
                                  <div
                                    className={`col-heading payclass-info ${e.defaultUnionTradeClassId !==
                                      e.unionTradeClassId
                                      ? "has-payclass-modified"
                                      : ""
                                      } `}
                                  >
                                    {timeCardData.htmlCrewCells[
                                      index
                                    ].payClassCell.replace(htmlRegexG, "")}
                                  </div>
                                </td>
                              ) : (
                                <td
                                  className={`payclass-td 
                                    ${selectedPayclassCells[e.id] ? "hours-cell" : ""} 
                                    ${e.isEquipment  ? "is-disabled" : ""}
                                    `}
                                  onClick={() => {
                                    // open payclass if row is not equipment type 
                                    if(!e.isEquipment) {
                                      handlePayclassCellClick(e)
                                    }
                                  }}
                                ></td>
                              )
                            ) : (
                              ""
                            )}

                            {/* allowance cell  */}
                            {userAccess &&
                              userAccess.allowTimecardAllowance === 1 ? (
                              empSelectedAllowance ? (
                                <td
                                  className={`allowance-td 
                                    ${selectedAllowanceCells[e.id] ? "hours-cell" : ""}
                                    ${e.isEquipment  ? "is-disabled" : ""}
                                  `}
                                  onClick={() => {
                                    // open payclass if row is not equipment type 
                                    if(!e.isEquipment) {
                                      handleAllowanceCellClick(e)
                                    }
                                  }}
                                >
                                  <div className="col-heading allowances-info has-allowances-note">
                                    {timeCardData.htmlCrewCells[
                                      index
                                    ].allowanceCell.replace(htmlRegexG, "")}
                                  </div>
                                </td>
                              ) : (
                                <td
                                  className={`allowance-td 
                                    ${selectedAllowanceCells[e.id] ? "hours-cell" : ""}
                                    ${e.isEquipment  ? "is-disabled" : ""}
                                  `}
                                  onClick={() => {
                                    // open payclass if row is not equipment type 
                                    if(!e.isEquipment) {
                                      handleAllowanceCellClick(e)
                                    }
                                  }}
                                ></td>
                              )
                            ) : (
                              ""
                            )}

                            {/* Hours cell for each service ticket*/}
                            {hourListOfCrewAndBudget.length > 0 ? (
                              hourListOfCrewAndBudget.map(
                                (hour: any, index: any) => {
                                  const currCol = index;
                                  if (hour) {
                                    return (
                                      <td
                                        className={`
                                          ${selectedHourCells[e.id +
                                            timeCardData.costCodeList[index].costCodeKey +
                                            timeCardData.costCodeList[index].serviceTicketId] ? "hours-cell": ""}
                                          ${(e.isEmployee === 1 &&
                                            timeCardData.costCodeList[index]
                                              .laborTimeCardBudgetCodeId ===
                                              0) ||
                                          (e.isEquipment === 1 &&
                                            timeCardData.costCodeList[index]
                                              .equipmentTimeCardBudgetCodeId ===
                                              0) ? 'is-disabled' : ''}
                                        `}
                                        key={index + " " + hour.id}
                                        onClick={() => {
                                          // Check Invalid Employee/Equipment Code
                                          if (
                                            !(
                                              (e.isEmployee === 1 &&
                                                timeCardData.costCodeList[index]
                                                  .laborTimeCardBudgetCodeId ===
                                                  0) ||
                                              (e.isEquipment === 1 &&
                                                timeCardData.costCodeList[index]
                                                  .equipmentTimeCardBudgetCodeId ===
                                                  0)
                                            )
                                          ) {
                                            const isAlreadySelected =
                                              selectedHourCells[
                                                e.id +
                                                  timeCardData.costCodeList[
                                                    index
                                                  ].costCodeKey +
                                                  timeCardData.costCodeList[
                                                    index
                                                  ].serviceTicketId
                                              ];
                                            if (!isAlreadySelected) {
                                              if (
                                                !(
                                                  isRightPanelOpened.isAllowanceOpened ||
                                                  isRightPanelOpened.isPayClassOpened
                                                )
                                              ) {
                                                const firstSelectedHourCell: typeof selectedHourCells =
                                                  Object.values(
                                                    selectedHourCells
                                                  )[0];
                                                if (
                                                  firstSelectedHourCell &&
                                                  (firstSelectedHourCell.costEventId !==
                                                    timeCardData
                                                      ._timeCardBudgetCodes[
                                                      index
                                                    ]._costEventId ||
                                                    selectedProjectId !==
                                                      timeCardData.costCodeList[
                                                        index
                                                      ].projectId)
                                                ) {
                                                  return toast.info(
                                                    "This cost code is a different type and can't be selected",
                                                    {
                                                      autoClose: 2000,
                                                    }
                                                  );
                                                }
                                                // add new hour cell to selected
                                                const selectedCells = {
                                                  ...selectedHourCells,
                                                  [e.id +
                                                  timeCardData.costCodeList[
                                                    index
                                                  ].costCodeKey +
                                                  timeCardData.costCodeList[
                                                    index
                                                  ].serviceTicketId]: {
                                                    costCode:
                                                      timeCardData.costCodeList[
                                                        index
                                                      ],
                                                    employee: e,
                                                    hour: hour,
                                                    costEventId:
                                                      timeCardData
                                                        ._timeCardBudgetCodes[
                                                        index
                                                      ]._costEventId,
                                                  },
                                                };
                                                setSelectedProjectId(
                                                  timeCardData.costCodeList[
                                                    index
                                                  ].projectId
                                                );
                                                setSelectedHourCells(
                                                  selectedCells
                                                );
                                                // select tags
                                                if (
                                                  Object.keys(selectedCells)
                                                    .length === 1
                                                ) {
                                                  Object.keys(
                                                    selectedCells
                                                  ).forEach((key: any) => {
                                                    const hourCell =
                                                      selectedCells[key];
                                                    // set isChecked
                                                    if (
                                                      hourCell.hour._tags &&
                                                      hourCell.hour._tags.length
                                                    ) {
                                                      setAlreadyAssignedTagsFromHour(
                                                        hourCell.hour._tags
                                                      );

                                                      let availableTags =
                                                        [] as any;
                                                      availableTags =
                                                        availableHourTags.map(
                                                          (tag: any) => {
                                                            hourCell.hour._tags.some(
                                                              (e: any) => {
                                                                if (
                                                                  tag.id ===
                                                                  e.timeCardTagId
                                                                ) {
                                                                  tag.isChecked =
                                                                    true;
                                                                  return true;
                                                                } else {
                                                                  tag.isChecked =
                                                                    false;
                                                                }
                                                                return false;
                                                              }
                                                            );
                                                            return tag;
                                                          }
                                                        );

                                                      setAvailableHourTags(
                                                        availableTags
                                                      );
                                                    } else {
                                                      // set default values
                                                      setTagsDefaultValues();
                                                    }
                                                    if (hour.ceDetails) {
                                                      const existingCostEventCategory: typeof selectedCostEventCategory =
                                                        costEventCategories[
                                                          timeCardData
                                                            .costCodeList[index]
                                                            .projectId
                                                        ].find(
                                                          (
                                                            eachCostEventCategory: any
                                                          ) =>
                                                            hour.ceDetails
                                                              ._ceCategoryId ===
                                                            eachCostEventCategory.ceCategoryId
                                                        );
                                                      const existingCostEvent =
                                                        costEvent[
                                                          `${timeCardData.costCodeList[index].projectId}-${existingCostEventCategory?.ceCategoryId}`
                                                        ].find(
                                                          (
                                                            eachCostEvent: any
                                                          ) =>
                                                            eachCostEvent.id ===
                                                            hour.ceDetails.ceId
                                                        );
                                                      setSelectedCostEventCategory(
                                                        existingCostEventCategory
                                                      );
                                                      setSelectedCostEvent(
                                                        existingCostEvent
                                                      );
                                                      setCostEventHours({
                                                        regular:
                                                          hour.ceDetails
                                                            .regHours,
                                                        overTime:
                                                          hour.ceDetails
                                                            .ovtHours,
                                                        doubleTime:
                                                          hour.ceDetails
                                                            .othHours,
                                                      });
                                                      setCESidePanelOpen(true);
                                                      setShowCostEventDetails(
                                                        true
                                                      );
                                                      setSelectedBudgetCostCode(
                                                        {
                                                          _ceBudgetCodeLabel:
                                                            hour._ceBudgetCodeLabel,
                                                          budgetCodeId:
                                                            hour.ceBudgetCodeId,
                                                          _budgetCodeDescription:
                                                            "",
                                                        }
                                                      );
                                                    }
                                                  });
                                                } else {
                                                  setTagsDefaultValues();
                                                }
                                                if (
                                                  !isRightPanelOpened.isHoursOpened
                                                ) {
                                                  setCurrHours({
                                                    regular:
                                                      hour.sumValues.regular,
                                                    overTime:
                                                      hour.sumValues.overTime,
                                                    doubleTime:
                                                      hour.sumValues.doubleTime,
                                                  });
                                                }
                                                // toggle
                                                handleRightPanel(
                                                  { isHoursOpened: true },
                                                  {
                                                    scroll:
                                                      !isRightPanelOpened.isHoursOpened
                                                        ? true
                                                        : false,
                                                    currRow,
                                                    currCol,
                                                  }
                                                );
                                              }
                                            } else {
                                              // remove added hour cell
                                              const selectedHour =
                                                selectedHourCells;
                                              delete selectedHour[
                                                e.id +
                                                  timeCardData.costCodeList[
                                                    index
                                                  ].costCodeKey +
                                                  timeCardData.costCodeList[
                                                    index
                                                  ].serviceTicketId
                                              ];
                                              setSelectedHourCells({
                                                ...selectedHour,
                                              });
                                              // check of object is empty
                                              if (isObjEmpty(selectedHour)) {
                                                setCurrHours({
                                                  regular: 0,
                                                  overTime: 0,
                                                  doubleTime: 0,
                                                });
                                                resetSidePanel();
                                              }
                                              // set default values
                                              setTagsDefaultValues();
                                            }
                                            setAssignTagToHourPayload([]);
                                            setRemovedTagsFromHour([]);
                                          }
                                        }}
                                      >
                                        <div className="log-info" ref={(el) => {
                                              divRefs.current[currRow] = divRefs.current[currRow] || [];
                                              divRefs.current[currRow][currCol] = el;
                                            }}>
                                          <div className="col-heading">
                                            {`${hour.sumValues.regular.toFixed(2)} / ${hour.sumValues.overTime.toFixed(2)} / ${hour.sumValues.doubleTime.toFixed(2)}`}
                                          </div>
                                          {/* hour tags in cost code column */}
                                          <ul className="log-tags-list">
                                            {(hour._tags && hour._tags.length > 0) || (hour.ceDetails && hour.ceDetails._tags && hour.ceDetails._tags.length > 0)
                                              ? (hour.ceDetails && hour.ceDetails._tags ? hour.ceDetails._tags : hour._tags).map((tag: any) => {
                                                return (
                                                  <li
                                                    className="log-tag"
                                                    key={
                                                      hour.id +
                                                      " " +
                                                      tag._timeCardTagCode
                                                    }
                                                  >
                                                    <i
                                                      className="fa fa-tag"
                                                      style={{
                                                        paddingRight: "5px",
                                                        color:
                                                          tag._timeCardTagColor,
                                                      }}
                                                      aria-hidden="true"
                                                    ></i>
                                                    {tag._timeCardTagCode}
                                                  </li>
                                                );
                                              })
                                              : ""}
                                          </ul>
                                        </div>
                                      </td>
                                    );
                                  } else {
                                    return (
                                      <td
                                        className={`${selectedHourCells[
                                          e.id +
                                          timeCardData.costCodeList[index]
                                            .costCodeKey +
                                          timeCardData.costCodeList[index]
                                            .serviceTicketId
                                        ]
                                          ? "hours-cell"
                                          : ""
                                          }
                                          ${(e.isEmployee === 1 &&
                                            timeCardData.costCodeList[index]
                                              .laborTimeCardBudgetCodeId ===
                                              0) ||
                                          (e.isEquipment === 1 &&
                                            timeCardData.costCodeList[index]
                                              .equipmentTimeCardBudgetCodeId ===
                                              0) ? 'is-disabled' : ''}
                                          `}
                                        key={index + "" + e.id}
                                        onClick={() => {
                                          // Check Invalid Employee/Equipment Code
                                          if (
                                            !(
                                              (e.isEmployee === 1 &&
                                                timeCardData.costCodeList[index]
                                                  .laborTimeCardBudgetCodeId ===
                                                  0) ||
                                              (e.isEquipment === 1 &&
                                                timeCardData.costCodeList[index]
                                                  .equipmentTimeCardBudgetCodeId ===
                                                  0)
                                            )
                                          ) {
                                            const isAlreadySelected =
                                              selectedHourCells[
                                                e.id +
                                                  timeCardData.costCodeList[
                                                    index
                                                  ].costCodeKey +
                                                  timeCardData.costCodeList[
                                                    index
                                                  ].serviceTicketId
                                              ];
                                            if (!isAlreadySelected) {
                                              if (
                                                !(
                                                  isRightPanelOpened.isAllowanceOpened ||
                                                  isRightPanelOpened.isPayClassOpened
                                                )
                                              ) {
                                                const firstSelectedHourCell: typeof selectedHourCells =
                                                  Object.values(
                                                    selectedHourCells
                                                  )[0];
                                                if (
                                                  firstSelectedHourCell &&
                                                  (firstSelectedHourCell.costEventId !==
                                                    timeCardData
                                                      ._timeCardBudgetCodes[
                                                      index
                                                    ]._costEventId ||
                                                    selectedProjectId !==
                                                      timeCardData.costCodeList[
                                                        index
                                                      ].projectId)
                                                ) {
                                                  return toast.info(
                                                    "This cost code is a different type and can't be selected",
                                                    {
                                                      autoClose: 2000,
                                                    }
                                                  );
                                                }
                                                // add new hour cell to selected
                                                setSelectedHourCells({
                                                  ...selectedHourCells,
                                                  [e.id +
                                                  timeCardData.costCodeList[
                                                    index
                                                  ].costCodeKey +
                                                  timeCardData.costCodeList[
                                                    index
                                                  ].serviceTicketId]: {
                                                    costCode:
                                                      timeCardData.costCodeList[
                                                        index
                                                      ],
                                                    employee: e,
                                                    hour: {},
                                                    costEventId:
                                                      timeCardData
                                                        ._timeCardBudgetCodes[
                                                        index
                                                      ]._costEventId,
                                                  },
                                                });
                                                setSelectedProjectId(
                                                  timeCardData.costCodeList[
                                                    index
                                                  ].projectId
                                                );
                                                if (
                                                  !isRightPanelOpened.isHoursOpened
                                                ) {
                                                  setCurrHours({
                                                    regular: 0,
                                                    overTime: 0,
                                                    doubleTime: 0,
                                                  });
                                                }
                                                handleRightPanel(
                                                  { isHoursOpened: true },
                                                  {
                                                    scroll:
                                                      !isRightPanelOpened.isHoursOpened
                                                        ? true
                                                        : false,
                                                    currRow,
                                                    currCol,
                                                  }
                                                );
                                              }
                                            } else {
                                              // remove added hour cell
                                              const selectedHour =
                                                selectedHourCells;
                                              delete selectedHour[
                                                e.id +
                                                  timeCardData.costCodeList[
                                                    index
                                                  ].costCodeKey +
                                                  timeCardData.costCodeList[
                                                    index
                                                  ].serviceTicketId
                                              ];
                                              setSelectedHourCells({
                                                ...selectedHour,
                                              });
                                              // check of object is empty
                                              if (isObjEmpty(selectedHour)) {
                                                setCurrHours({
                                                  regular: 0,
                                                  overTime: 0,
                                                  doubleTime: 0,
                                                });
                                                toggleRightPanel({
                                                  ...rightPanelOptions,
                                                  isHoursOpened: false,
                                                });
                                                setHoursClicked("regular");
                                              }
                                            }
                                            // set default isChecked for tags
                                            setTagsDefaultValues();
                                          }
                                        }}
                                      >
                                        <div className="log-info" ref={(el) => {
                                              divRefs.current[currRow] = divRefs.current[currRow] || [];
                                              divRefs.current[currRow][currCol] = el;
                                            }}>
                                          <div className="col-heading">{ }</div>
                                        </div>
                                      </td>
                                    );
                                  }
                                }
                              )
                            ) : (
                              <td className="timecard-td-lockedcell"></td>
                            )}
                          </React.Fragment>
                        </tr>
                    );
                  })}
                  {/* row for crew total daily hours  */}
                  <tr className={`last-row`}>
                    {timeCardData.costCodeList.length !== 0 ? (
                      <>
                        <td className={`crew-holder ${timecardStatusColorData?.statusClassName}`}></td>
                        {userAccess &&
                          userAccess.allowTimecardEditPayclass === 1 ? (
                          <td className={`payclass-td ${timecardStatusColorData?.statusClassName}`}></td>
                        ) : (
                          ""
                        )}
                        {userAccess &&
                          userAccess.allowTimecardAllowance === 1 ? (
                          <td className={`allowance-td ${timecardStatusColorData?.statusClassName}`}></td>
                        ) : (
                          ""
                        )}
                        {timeCardData.costCodeList.map((e: any, index: any) => {
                          let sumOfHours = {
                            regHours: 0,
                            ovtHours: 0,
                            othHours: 0,
                          };

                          timeCardData.hourList.forEach((element: any) => {
                            if (
                              e.labourTimeCardBudgetCodeId ===
                              element.timeCardBudgetCodeId ||
                              e.equipmentTimeCardBudgetCodeId ===
                              element.timeCardBudgetCodeId
                            ) {
                              sumOfHours.regHours += element.regHours;
                              sumOfHours.ovtHours += element.ovtHours;
                              sumOfHours.othHours += element.othHours;
                            }
                          });
                          return (
                            <>
                              <td
                                key={e.costCodeKey + " " + index}
                                className={`service-ticket-holder ${timecardStatusColorData?.statusClassName}`}
                              >
                                <div className="service-ticket-jobcode">
                                  <div className="col-heading text-center">
                                    {sumOfHours.regHours.toFixed(2) +
                                      " / " +
                                      sumOfHours.ovtHours.toFixed(2) +
                                      " / " +
                                      sumOfHours.othHours.toFixed(2)}
                                  </div>
                                </div>
                                <div className="service-ticket-jobcode">
                                  <span className="col-heading text-center">
                                    {e.costCodeKey} - {e.costCodeDescription}
                                  </span>
                                </div>
                              </td>
                            </>
                          );
                        })}
                      </>
                    ) : (
                      ""
                    )}
                  </tr>
                </>
              ) : (
                // show empty row
                <tr>
                  <td className="empty-timecard text-center">
                    {/* eslint-disable-next-line */}
                    <a
                      // eslint-disable-next-line
                      href="javascript:void(0);"
                      className="add-crew-member"
                      onClick={toggleAddCrew}
                    >
                      <AddPlus />
                      <b className="ml-2">Click here to Select/Add the Crew </b>
                    </a>
                  </td>
                  {/* cell for payclass */}
                  {userAccess && userAccess.allowTimecardEditPayclass === 1 ? (
                    <td className="timecard-td-lockedcell"></td>
                  ) : (
                    ""
                  )}
                  {/* cell for allowance */}
                  {userAccess && userAccess.allowTimecardAllowance === 1 ? (
                    <td className="timecard-td-lockedcell"></td>
                  ) : (
                    ""
                  )}
                  {/* cell for cost code  */}
                  {timeCardData.costCodeList.length !== 0 ? (
                    <td
                      className="timecard-td-lockedcell"
                      colSpan={timeCardData.costCodeList.length}
                    ></td>
                  ) : (
                    <td className="timecard-td-lockedcell"></td>
                  )}
                </tr>
              )}
            </tbody>
          </table>
        </div>
        {isRightPanelOpened.isPayClassOpened ? (
          <PayclassPanel
            selectedEmployee={selectedEmployee}
            toggleRightPanel={toggleRightPanel}
            getTimecardById={getTimecardById}
            setSelectedPayclassCells={setSelectedPayclassCells}
            selectedPayclassCells={selectedPayclassCells}
            timecardId={timecardId}
            tokenDetails={tokenDetails}
            selectedProjectsSupervisorsShift={selectedProjectsSupervisorsShift}
          />
        ) : (
          ""
        )}
        {isRightPanelOpened.isHoursOpened ? (
          <div className="table-filling-wrapper">
            <form className="tf-content">
              <div className="tf-header">
                {/* Loghours Start */}
                {/* <h5 className="tf-title">Log Hours</h5> */}
                <h5 className="tf-title">{currentComponentLayer === 1 ? `How many ${selectedCostEventCategory.ceCategoryName} hours?` : "Hours Detail"}</h5>
                {/* Loghours End */}

                {/* eslint-disable-next-line */}
                <a
                  // eslint-disable-next-line
                  href="javascript:void(0);"
                  onClick={resetSidePanel}
                  className="tf-close"
                ></a>
              </div>
              <div className="tf-body">
                {isCESidePanelOpen ? <SidePanelCostEvent
                  costEventAlert={costEventAlert}
                  showCostEventDetails={showCostEventDetails}
                  setShowCostEventDetails={setShowCostEventDetails}
                  costEvents={costEvent[`${selectedProjectId}-${selectedCostEventCategory.ceCategoryId}`]}
                  selectCostEvent={setSelectedCostEvent}
                  selectedCostEvent={selectedCostEvent}
                  selectedCostEventCategory={selectedCostEventCategory}
                  toggleCESidePanel={toggleCESidePanel}
                  currentComponentLayer={currentComponentLayer}
                  costEventHours={costEventHours}
                  setCostEventHours={setCostEventHours}
                  hoursSum={hoursCurrData}
                  selectedBudgetCostCode={selectedBudgetCostCode}
                  selectBudgetCode={setSelectedBudgetCostCode}
                  setCurrentComponentLayer={setCurrentComponentLayer}
                /> : <React.Fragment>
                  <div className="custom-logs-wrapper">
                    <ul className="custom-logs-list w-100">
                      <li>
                        <div className="custom-log">
                          <h6>Regular</h6>{" "}
                          <div
                            className={`custom-block ${hoursClicked === "regular" ? "field-selected" : ""
                              }`}
                            onClick={() => {
                              setHoursClicked("regular");
                            }}
                          >
                            {hoursCurrData.regular ? hoursCurrData.regular : 0}
                          </div>
                        </div>
                      </li>
                      <li>
                        <div className="custom-log">
                          <h6>Overtime</h6>
                          <div
                            className={`custom-block ${hoursClicked === "overTime" ? "field-selected" : ""
                              }`}
                            onClick={() => {
                              setHoursClicked("overTime");
                            }}
                          >
                            {hoursCurrData.overTime ? hoursCurrData.overTime : 0}
                          </div>
                        </div>
                      </li>
                      <li>
                        <div className="custom-log">
                          <h6>Doubletime</h6>
                          <div
                            className={`custom-block ${hoursClicked === "doubleTime" ? "field-selected" : ""
                              }`}
                            onClick={() => {
                              setHoursClicked("doubleTime");
                            }}
                          >
                            {hoursCurrData.doubleTime
                              ? hoursCurrData.doubleTime
                              : 0}
                          </div>
                        </div>
                      </li>
                    </ul>
                  </div>
                  <div className="log-info">
                    {/* right panel hour tags */}
                    <ul className="log-tags-list">
                      {allTags.length > 0
                        ? allTags.map((tag: any) => {
                          return (
                            <li
                              className="ticket-checkbox"
                              key={tag.id ? tag.id + " " + tag.timeCardTagAltKey : tag.ceCategoryId}
                            >
                              <div className="form-group">
                                <div className="custom-control custom-checkbox">
                                  <input
                                    type="checkbox"
                                    className="custom-control-input"
                                    name={tag.timeCardTagAltKey || tag.ceCategoryName}
                                    id={tag.timeCardTagAltKey || tag.ceCategoryId}
                                    checked={tag.isChecked || false}
                                    onChange={(e) => {
                                      if (tag.ceCategoryName) {
                                        setSelectedCostEventCategory(tag)
                                      } else {
                                        if (e.target.checked) {
                                          const availableTags =
                                            availableHourTags.map((hour: any) => {
                                              if (tag.id === hour.id) {
                                                hour.isChecked = true;
                                              }
                                              return hour;
                                            });
                                          setAvailableHourTags(availableTags);
                                          // if tag was already assigned and not yet removed
                                          const existingTagIndex =
                                            removedTagsFromHour.findIndex(
                                              (e: any) =>
                                                e.timeCardTagId === tag.id
                                            );
                                          if (existingTagIndex > -1) {
                                            setRemovedTagsFromHour([
                                              ...removedTagsFromHour.slice(
                                                0,
                                                existingTagIndex
                                              ),
                                              ...removedTagsFromHour.slice(
                                                existingTagIndex + 1
                                              ),
                                            ]);
                                          } else {
                                            // add selected tag
                                            setAssignTagToHourPayload([
                                              ...assignTagToHourPayload,
                                              tag,
                                            ]);
                                          }
                                        } else {
                                          // ** Add tag to removed tag list
                                          const selectedTag =
                                            alreadyAssignedTagsFromHour.find(
                                              (tag: any) =>
                                                tag.timeCardTagId === tag.id
                                            );
                                          if (selectedTag) {
                                            setRemovedTagsFromHour([
                                              ...removedTagsFromHour,
                                              selectedTag,
                                            ]);
                                          }
                                          // set tag list isChecked to false
                                          const availableTags =
                                            availableHourTags.map((hour: any) => {
                                              if (tag.id === hour.id) {
                                                hour.isChecked = false;
                                              }
                                              return hour;
                                            });
                                          setAvailableHourTags(availableTags);
                                          // remove unchecked tag
                                          const selectedTagsList =
                                            assignTagToHourPayload.filter(
                                              (e: any) =>
                                                e.timeCardTagId === tag.id
                                            );
                                          setAssignTagToHourPayload([
                                            ...selectedTagsList,
                                          ]);
                                        }
                                      }
                                    }}
                                  />
                                  <label
                                    className="custom-control-label"
                                    htmlFor={tag.timeCardTagAltKey || tag.ceCategoryId}
                                  >
                                    <span className="log-tag">
                                      <i
                                        className="fa fa-tag"
                                        style={{
                                          paddingRight: "5px",
                                          color: tag.timeCardTagColor || tag.ceCategoryTagColor,
                                        }}
                                        aria-hidden="true"
                                      ></i>
                                      {tag.timeCardTagName || tag.ceCategoryName}
                                    </span>
                                  </label>
                                </div>
                              </div>
                            </li>
                          );
                        })
                        : ""}
                      {selectedCostEventCategory
                        ? <li
                          className="ticket-checkbox"
                          key={selectedCostEventCategory.ceCategoryId}
                        >
                          <div className="form-group">
                            <div className="custom-control custom-checkbox">
                              <input
                                type="checkbox"
                                className="custom-control-input"
                                name={selectedCostEventCategory.ceCategoryName}
                                id={selectedCostEventCategory.ceCategoryId}
                                checked={true}
                                onChange={() => {
                                  setSelectedCostEventCategory(undefined)
                                }}
                              />
                              <label
                                className="custom-control-label"
                                htmlFor={selectedCostEventCategory.ceCategoryId}
                              >
                                <span className="log-tag">
                                  <i
                                    className="fa fa-tag"
                                    style={{
                                      paddingRight: "5px",
                                      color: selectedCostEventCategory.ceCategoryTagColor,
                                    }}
                                    aria-hidden="true"
                                  ></i>
                                  {selectedCostEventCategory.ceCategoryName}
                                </span>
                              </label>
                            </div>
                          </div>
                        </li>
                        : ""}
                    </ul>
                  </div>
                  <Keypad
                    costEventAlert={costEventAlert}
                    pressNumber={handleHourKeys}
                    pressDot={() => {
                      setCurrHours({
                        ...hoursCurrData,
                        [hoursClicked]: hoursCurrData[hoursClicked] + ".",
                      });
                    }}
                    pressDelete={() => {
                      let str = hoursCurrData[hoursClicked];
                      //remove last 1 char
                      const numStr = str.toString();
                      if (numStr.length > 1) {
                        str = parseFloat(
                          numStr.substring(0, numStr.length - 1)
                        );
                      } else {
                        str = 0;
                      }
                      setCurrHours({
                        ...hoursCurrData,
                        [hoursClicked]: str,
                      });
                    }}
                    clearAll={() => {
                      setCurrHours({
                        ...hoursCurrData,
                        regular: 0,
                        overTime: 0,
                        doubleTime: 0,
                      });
                    }}
                  />

                </React.Fragment>}
                {/* Loghours End */}


              </div>
              <div className="tf-footer d-flex align-items-center justify-content-end">
                {(!isCESidePanelOpen || showCostEventDetails) && <button
                  type="button"
                  className="btn btn-secondary mr-3"
                  onClick={resetSidePanel}
                >
                  Cancel{" "}
                </button>}
                {(isCESidePanelOpen && !showCostEventDetails) && <button
                  type="button"
                  className="btn btn-secondary mr-3"
                  onClick={() => {
                    if (currentComponentLayer) {
                      setCurrentComponentLayer(currentComponentLayer - 1)
                    } else {
                      toggleCESidePanel()
                    }
                    setCostEventAlert("")
                  }}
                >
                  Back{" "}
                </button>}
                {selectedCostEventCategory && currentComponentLayer !== 2 && !showCostEventDetails ? <button
                  type="button"
                  className="btn btn-primary"
                  onClick={() => {
                    if (currentComponentLayer === 0 && hoursCurrData.regular === 0 && hoursCurrData.overTime === 0 && hoursCurrData.doubleTime === 0) {
                      return setCostEventAlert("Enter some base hours");
                    }
                    if (currentComponentLayer === 1 && ((hoursCurrData.regular < costEventHours.regular) || (hoursCurrData.overTime < costEventHours.overTime) || (hoursCurrData.doubleTime < costEventHours.doubleTime))) {
                      return setCostEventAlert("Cost event hours are more than base hours");
                    }
                    if (isCESidePanelOpen) {
                      setCurrentComponentLayer(currentComponentLayer + 1)
                    } else {
                      toggleCESidePanel()
                    }
                    setCostEventAlert("")
                  }}
                  disabled={isCESidePanelOpen && currentComponentLayer === 0 && !selectedCostEvent?.ceNumber}
                >
                  Next{" "}
                </button> : null}
                {!selectedCostEventCategory || (isCESidePanelOpen && currentComponentLayer === 2) || showCostEventDetails ? <button
                  type="button"
                  className="btn btn-primary"
                  onClick={async () => {
                    toggleRightPanel({
                      ...rightPanelOptions,
                      isHoursOpened: false,
                    });
                    setHoursClicked("regular");
                    const newHourIds = await saveHours();
                    assignTagToHour(newHourIds);
                  }}
                >
                  Save{" "}
                </button> : null}
              </div>
            </form>
          </div>
        ) : (
          ""
        )}
        {isRightPanelOpened.isAllowanceOpened ? (
          <AllowancePanel
            selectedEmployee={selectedEmployee}
            toggleRightPanel={toggleRightPanel}
            getTimecardById={getTimecardById}
            setSelectedAllowanceCells={setSelectedAllowanceCells}
            selectedAllowanceCells={selectedAllowanceCells}
          />
        ) : (
          ""
        )}
      </div>
      {/* Delete Time Card Popup */}
      {modalActive && (
        <ConfirmModal 
          modalActive
          toggleCard={toggleDeleteCard}
          modalData={modalData}
          cancelModal={cancelModal}
          setModalRemark={setModalRemark}
          handleTimeCardSubmit={handleTimeCardSubmit}
        />
      )}
      
      {/* Delete Service Ticket/Crew Popup */}
      <Modal
        isOpen={deleteSCCardModal}
        fade={false}
        toggle={toggleDeleteSCCard}
        className="delete-sc-modal modal-md"
      >
        <ModalHeader toggle={toggleDeleteSCCard}>
          Are you sure want to Delete Service Ticket ?
        </ModalHeader>
        <ModalBody>
          <ModalFooter>
            <Button color="secondary" onClick={toggleDeleteSCCard}>
              Cancel
            </Button>
            <Button color="primary" type="button" onClick={toggleDeleteSCCard}>
              Delete
            </Button>{" "}
          </ModalFooter>
        </ModalBody>
      </Modal>
      {/* Clone Popup */}
      <TimecardCloneModal
        cloneModal={cloneModal}
        toggleClone={toggleClone}
        timecardId={Number(timecardId)}
      />
      {/* Add Crew Popup */}
      <AddCrewModal
        addCrewModal={addCrewModal}
        toggleAddCrew={toggleAddCrew}
        getTimecardById={getTimecardById}
        createTimecardPayload={createTimecardPayload}
        timeCardCostCodePayload={timeCardCostCodePayload}
        getAssignedCode={getAssignedCode}
        timecardId={Number(timecardId)}
        createTimecard={createTimecard}
      />
      {/* Add Code Popup */}
      <AddCodeModal
        addCodeModal={addCodeModal}
        toggleAddCode={toggleAddCode}
        getAssignedCode={getAssignedCode}
        setAssignedCode={setAssignedCode}
        createTimecardPayload={createTimecardPayload}
        assignedCode={assignedCode}
        getTimecardById={getTimecardById}
        timecardId={Number(timecardId)}
        createTimecard={createTimecard}
      />
      {/* Crew Informtion Popup */}
      <Modal
        isOpen={crewInfoModal}
        fade={false}
        toggle={(e: any) => setCrewInfoModal(false)}
        className={
          "crew-info-modal " + (crewCertificateModal ? "modal-lg" : "modal-xl")
        }
      >
        <ModalHeader toggle={(e: any) => setCrewInfoModal(false)}>
          Crew Informartion
        </ModalHeader>
        <ModalBody>
          <div className="crewinfo-wrapper">
            {crewCertificateModal ? (
              <div className="row">
                <div className="col">
                  <div className="view-certificate">
                    <img
                      className="view-certificate-full"
                      src={DummyCerti}
                      alt="Crew Certificate"
                    />
                  </div>
                </div>
              </div>
            ) : (
              <>
                <div className="row mb-4">
                  <div className="col-6">
                    <div className="crew-information d-flex align-items-start justify-content-start">
                      <div className="crew-img mr-2">
                        <img
                          className="crew-user-img"
                          src={
                            activeModalCrew?.crewMemberInfo?.crewPic ||
                            DummyUser
                          }
                          alt="Crew"
                        />
                      </div>
                      <div className="crew-names">
                        <h5>
                          {activeModalCrew?.crewMemberInfo?.crewName || ""}
                        </h5>
                        <p>
                          <b>EE ID:</b>{" "}
                          {activeModalCrew?.crewMemberInfo?.employeeId || ""}
                        </p>
                        <p>
                          <b>PY:</b> {activeModalCrew?.crewMemberInfo?.id || ""}
                        </p>
                        <p>
                          <b>UN:</b>{" "}
                          {activeModalCrew?.crewMemberInfo
                            ?.defaultUnionTradeClassId || ""}
                        </p>
                        <p>
                          <b>UID:</b>{" "}
                          {activeModalCrew?.crewMemberInfo
                            ?.timeCardSupervisorId || ""}
                        </p>
                      </div>
                    </div>
                  </div>
                  <div className="col-6">
                    <div className="crew-certificates-wrapper">
                      <div className="crew-certificates-heading d-flex align-items-center justify-content-between">
                        <h6>Certificates({[...activeModalCrew?.crewMemberCerti].length})</h6>
                        {/* eslint-disable-next-line */}
                        <a className="send-email" href="javascript:void(0);">
                          Send Email
                          <SendEmail />
                        </a>
                      </div>
                      <div className="crew-certificates-content">
                        <ul className="crew-certificates-list">
                          {activeModalCrew?.crewMemberTag?.length > 0 &&
                            [...activeModalCrew?.crewMemberCerti].map(
                              (eachCert: any, index: number) => (
                                <li key={eachCert?.category + " " + index}>
                                  {/* eslint-disable-next-line */}
                                  <a
                                    className="crew-certificate d-flex align-items-start justify-content-start"
                                    onClick={() =>
                                      toggleCrewCertificate(
                                        false,
                                        eachCert,
                                        index
                                      )
                                    }
                                  >
                                    <Certificate />
                                    <h6 className="ml-2">
                                      {eachCert?.category}{" "}
                                      <small>{`${eachCert?.validNumMonths} Valid Months`}</small>
                                    </h6>
                                  </a>
                                </li>
                              )
                            )}
                        </ul>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="row mb-4">
                  <div
                    className={
                      activeModalCrew.otherTimeCards.length ? "col-6" : "col-12"
                    }
                  >
                    <div className="crew-tags-wrapper">
                      <div className="crew-tags-heading d-flex align-items-center justify-content-start">
                        <h6>Employee Tags</h6>
                      </div>
                      <div className="crew-tags-content crew-log-info">
                        {/* right panel hour tags */}
                        <ul className="log-tags-list">
                          {activeModalCrew?.crewMemberTag?.length > 0
                            ? activeModalCrew?.crewMemberTag.map(
                              (eachTag: any, index: number) => {
                                return (
                                  <li
                                    className="ticket-checkbox"
                                    key={eachTag?.name + " " + index}
                                  >
                                    <div className="form-group">
                                      <div className="custom-control custom-checkbox">
                                        <input
                                          type="checkbox"
                                          className="custom-control-input"
                                          name={eachTag.name}
                                          id={eachTag.timeCardTagAltKey}
                                          checked={eachTag.isChecked || false}
                                          onChange={(e) => {
                                            handleCheckBoxClick(
                                              index,
                                              e.target.checked,
                                              eachTag
                                            );
                                          }}
                                        />
                                        <label
                                          className="custom-control-label"
                                          htmlFor={eachTag?.name}
                                        >
                                          <span className="log-tag">
                                            <i
                                              className="fa fa-tag"
                                              style={{
                                                paddingRight: "5px",
                                                color: eachTag.color,
                                              }}
                                              aria-hidden="true"
                                            ></i>
                                            {eachTag?.name}
                                          </span>
                                        </label>
                                      </div>
                                    </div>
                                  </li>
                                );
                              }
                            )
                            : ""}
                        </ul>
                      </div>
                    </div>
                  </div>
                  {activeModalCrew.otherTimeCards.length ? (
                    <div className="col-6">
                      <div className="crew-additionaltc-wrapper">
                        <div className="crew-additionaltc-heading d-flex align-items-center justify-content-start">
                          <h6>
                            Additional Timecards -{" "}
                            {
                              activeModalCrew.otherTimeCards[0][
                              "timeCardDateFormatted"
                              ]
                            }
                            , Shift {activeModalCrew.otherTimeCards[0]["shift"]}
                          </h6>
                        </div>
                        <div className="crew-additionaltc-content">
                          <ul className="crew-additionaltc-list">
                            <li>
                              <div className="text-label-value">
                                <div className="text-label">Project </div>
                                {activeModalCrew.otherTimeCards.map(
                                  ({ project }) => (
                                    <div className="text-value">{project}</div>
                                  )
                                )}
                              </div>
                            </li>
                            <li>
                              <div className="text-label-value">
                                <div className="text-label">Foreman</div>
                                {activeModalCrew.otherTimeCards.map(
                                  ({ foreman }) => (
                                    <div className="text-value">{foreman}</div>
                                  )
                                )}
                              </div>
                            </li>
                            <li>
                              <div className="text-label-value">
                                <div className="text-label">Hours</div>
                                {activeModalCrew.otherTimeCards.map(
                                  ({ hours }) => (
                                    <div className="text-value">{hours}</div>
                                  )
                                )}
                              </div>
                            </li>
                          </ul>
                        </div>
                      </div>
                    </div>
                  ) : null}
                </div>
              </>
            )}
            <ModalFooter>
              {crewCertificateModal ? (
                <Button
                  color="primary"
                  type="button"
                  className="ml-auto"
                  onClick={() => toggleCrewCertificate(true)}
                >
                  Return
                </Button>
              ) : (
                <>
                  <Button
                    color="secondary"
                    onClick={(e: any) => setCrewInfoModal(false)}
                  >
                    Cancel
                  </Button>
                  <Button
                    color="primary"
                    type="button"
                    // disabled={diableSaveBtn || false}
                    onClick={updateTagsCrewInfo}
                  >
                    Save
                  </Button>{" "}
                </>
              )}
            </ModalFooter>
          </div>
        </ModalBody>
      </Modal>
      {/* Add Temporary Labour Popup */}
      <Modal
        isOpen={tempLabourModal}
        fade={false}
        toggle={toggleTempLabour}
        className="add-templabour-modal modal-md"
      >
        <ModalHeader toggle={toggleTempLabour}>
          Add Temporary Labour
        </ModalHeader>
        <ModalBody>
          <form className="addtemplabour-form">
            <div className="row">
              <div className="col-6">
                <div className="form-group">
                  <SelectDropdown
                    labelKey="tempAgencyName"
                    options={tempAgeOptions && tempAgeOptions.length ? tempAgeOptions : []}
                    label={"Temporary Agency"}
                    onChange={(e: any) =>
                      handleTempLabIpVal(e, "tempAgencyKey")
                    }
                  />
                </div>
              </div>
              <div className="col-6">
                <div className="form-group">
                  <input
                    type="text"
                    className="form-control"
                    id="tempEmpoyeeFirstname"
                    aria-describedby="tempEmpoyeeFirstname"
                    placeholder="Enter"
                    onChange={(e: any) =>
                      handleTempLabIpVal(e.target.value, "firstName")
                    }
                    required
                  />
                  {/* @ts-ignore */}
                  <label className="text-label" htmlFor="tempEmpoyeeFirstname">
                    First Name
                  </label>
                </div>
              </div>
              <div className="col-6">
                <div className="form-group">
                  <input
                    type="text"
                    className="form-control"
                    id="tempEmpoyeeLastname"
                    aria-describedby="tempEmpoyeeLastname"
                    placeholder="Enter"
                    onChange={(e: any) =>
                      handleTempLabIpVal(e.target.value, "lastName")
                    }
                    required
                  />
                  {/* @ts-ignore */}
                  <label className="text-label" htmlFor="tempEmpoyeeLastname">
                    Last Name
                  </label>
                </div>
              </div>
              <div className="col-6">
                <div className="form-group">
                  <input
                    type="email"
                    className="form-control"
                    id="tempEmpoyeeEmail"
                    aria-describedby="tempEmpoyeeEmail"
                    placeholder="Enter"
                    onChange={(e: any) =>
                      handleTempLabIpVal(e.target.value, "emailAddress")
                    }
                    required
                  />
                  {/* @ts-ignore */}
                  <label className="text-label" htmlFor="tempEmpoyeeEmail">
                    Email Address
                  </label>
                </div>
              </div>
              <div className="col-6">
                <div className="form-group">
                  <input
                    type="text"
                    className="form-control"
                    id="tempEmpoyeePhone"
                    aria-describedby="tempEmpoyeePhone"
                    placeholder="Enter"
                    onChange={(e: any) =>
                      handleTempLabIpVal(e.target.value, "mobileNo")
                    }
                    required
                  />
                  {/* @ts-ignore */}
                  <label className="text-label" htmlFor="tempEmpoyeePhone">
                    Mobile Phone Number
                  </label>
                </div>
              </div>
            </div>
            <ModalFooter>
              <Button color="secondary" onClick={toggleTempLabour}>
                Cancel
              </Button>
              <Button color="primary" type="button" onClick={addTempLoabor}>
                Save
              </Button>{" "}
            </ModalFooter>
          </form>
        </ModalBody>
      </Modal>
    </div>
  );
};

export default withRouter(TimecardList);
