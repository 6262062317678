import React, { useState } from "react";
import DatePicker from "react-datepicker";

const DateSelector = ({ ...props }) => {
  const [focused, setFocused] = useState(false);

  return (
    // 'is-disabled' next to 'has-calendar'
    <div
      className={`has-calendar ${focused ? "is-focused" : ""} ${
        props.selected === null ? "empty" : ""
      }`}
    >
      {/* @ts-ignore */}
      <DatePicker
        selectsRange={props.selectsRange}
        startDate={props.startDate}
        endDate={props.endDate}
        selected={props.selected}
        onChange={(e: any) => {
          props.onChange(e);
        }}
        isClearable={props.isClearable}
        disabled={props.disabled}
        onFocus={(e: any) => (focused === true ? "" : setFocused(true))}
        onBlur={(e: any) => (focused === false ? "" : setFocused(false))}
        placeholderText={props.placeholderText}
      />
      <label className="text-label">{props.labelText}</label>
      <i className="has-icon icon-calendar"></i>
    </div>
  );
};

export default DateSelector;
